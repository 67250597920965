import {
  Relation,
  AST,
  Page,
  UI,
  f,
  Case,
  Values,
  Callout,
  If,
  Constant,
  Markup,
} from '@cotera/era';

export const Overview = ({
  QAOutputRelation,
  config,
  cleanedMessages,
  additionalComponents,
}: {
  QAOutputRelation: Relation;
  config: {
    category: string;
    sub_category: string;
  }[];
  cleanedMessages: string;
  additionalComponents: (t: Relation) => Markup[];
}): AST._Page => {
  return Page(['id'] as const, ({ id }) => {
    const idMessages = QAOutputRelation.select((t) =>
      t.renameWith((name) => name.toLowerCase())
    )
      .where((t) => t.attr('id').eq(id))
      .select((t) => {
        const getScore = config
          .map((item) =>
            If(t.attr(item.sub_category.toLowerCase()).eq('PERFECT'), {
              then: 1,
              else: 0,
            })
          )
          .reduce((l, r) => l.add(r), Constant(0));

        return {
          ...t.star(),
          TICKET_SCORE: getScore,
        };
      });

    const PivotCols = Values(config);

    const LongData = idMessages
      .innerJoin(PivotCols, (row, pivotCols) => ({
        on: true,
        select: {
          ...pivotCols.star(),
          ...row.star(),
        },
      }))
      .select((t) => ({
        ...t.pick('category', 'sub_category'),
        value: Case(
          config.map((name) => ({
            when: t.attr('sub_category').eq(name.sub_category),
            then: t.attr(name.sub_category.toLowerCase()),
          }))
        ),
        ...t.except(
          'category',
          'sub_category',
          ...config.map((name) => name.sub_category.toLowerCase())
        ),
      }))
      .select((t) => t.renameWith((name) => name.toLowerCase()))
      .select((t) => ({
        ...t.star(),
        category_score: If(t.attr('value').eq('PERFECT'), {
          then: 1,
          else: -1,
        }),
      }));

    const TicketImperfections = LongData.where((t) =>
      t.attr('value').isDistinctFrom('PERFECT')
    );

    const FormattedConversation = idMessages.chart.ForEachRow((row) => {
      let cleanedMessage = row.getField(cleanedMessages);

      // Replace message numbers dynamically
      for (let i = 1; i <= 100; i++) {
        const messageLabel1 = `Message ${i}`;
        const messageLabel2 = `MESSAGE ${i}`;
        const formattedLabel1 =
          i === 1 ? `**${messageLabel1}**` : `\n\n**${messageLabel1}**`;
        const formattedLabel2 =
          i === 1 ? `**${messageLabel1}**` : `\n\n**${messageLabel2}**`;
        cleanedMessage = cleanedMessage
          .replace(messageLabel1, formattedLabel1)
          .replace(messageLabel2, formattedLabel2);
      }

      return f`${cleanedMessage
        .replace('(AGENT)', '*(AGENT)*\n\n')
        .replace('(CUSTOMER)', '*(CUSTOMER)*\n\n')}`;
    });

    return [
      UI.Header({
        title: 'QA Email Report',
        caption: f`This report breaks down the email performance for conversation id ${id}`,
      }),
      UI.Block([
        Callout({
          rels: [
            idMessages.select((t) => ({
              title: 'Ticket Score',
              value: f`${t.attr('TICKET_SCORE')} / ${config.length}`,
            })),
            idMessages.select((t) => ({
              title: 'Agent Name',
              value: t.attr('agent').cast('string'),
            })),
            idMessages.select((t) => ({
              title: 'Ticket Created At',
              value: t.attr('created_at').dateFmt('%h %d, %Y'),
            })),
          ],
        }),
      ]),
      UI.Block(
        [
          TicketImperfections.chart.ForEachRow(
            (row) =>
              f`**${row.getField('category')}**: ${row.getField('value')}\n\n`
          ),
        ],
        { title: 'Ticket Imperfection Explanation' }
      ),
      UI.Block(
        [
          LongData.chart.BarChart((t) => ({
            x: t.attr('sub_category'),
            y: t.attr('category_score'),
            category: t.attr('category'),
            style: If(t.attr('category_score').eq(1), {
              then: 'positive',
              else: 'negative',
            }),
          })),
        ],
        { title: 'Ticket Scoring Breakdown' }
      ),
      UI.Block([FormattedConversation], { title: 'Conversation Breakdown' }),
      ...(additionalComponents(idMessages) ?? []),
    ];
  });
};
