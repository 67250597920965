import { BaseWorkspaceNodeViewModel, NodeTransform } from './base';
import {
  GroupByState,
  useGroupBy,
  GroupByBuilder,
} from '../components/group-by';
import { Relation, Sum, TC, Count } from '@cotera/era';
import { mapValues } from 'lodash';
import { useSubscribe } from '@cotera/client/app/etc';
import { v4 } from 'uuid';

export class GroupByViewModel extends BaseWorkspaceNodeViewModel {
  t = 'group-by';

  override transforms: NodeTransform[] = [];

  groupBy: GroupByState;
  override parent: BaseWorkspaceNodeViewModel;

  constructor(
    name: string,
    position: { x: number; y: number },
    groupBy: GroupByState,
    parent: BaseWorkspaceNodeViewModel
  ) {
    super(name, v4(), position);
    this.groupBy = groupBy;
    this.parent = parent;
    parent.subscribe(() => this.notifySubscribers());
  }

  get baseRel() {
    return this.parent.rel;
  }

  get rel() {
    return this.parent.rel.apply(this.transforms);
  }

  get artifactId() {
    return this.parent.artifactId;
  }

  override getRel(type: 'source' | 'artifact') {
    return this.parent.getRel(type);
  }

  updateGroupBy = (groupBy: GroupByState) => {
    this.groupBy = groupBy;

    this.transforms = [
      {
        fn: (rel: Relation) => {
          if (this.groupBy.groupBy.length === 0) {
            return rel.select((t) => ({
              ...t.star(),
            }));
          }
          return rel
            .groupBy((t) => t.pick(...this.groupBy.groupBy))
            .select((t) => {
              return mapValues(rel.attributes, (attr, key) => {
                if (this.groupBy.groupBy.includes(key)) {
                  return t.attr(key);
                }
                if (TC.isNumeric(attr)) {
                  return Sum(t.attr(key));
                }

                return Count(); //TODO
              });
            });
        },
      },
    ];

    this.notifySubscribers();
  };
}

const View: React.FC<{ node: GroupByViewModel }> = ({ node }) => {
  const rel = useSubscribe(node, (s) => s.baseRel);

  const [groupBy, groupByActions] = useGroupBy((state) => {
    return node.updateGroupBy({
      groupBy: state.groupBy,
      select: state.select,
    });
  });

  return (
    <GroupByBuilder
      key={node.id}
      groupBy={groupBy}
      rel={rel}
      actions={groupByActions}
    />
  );
};

export const GroupByNode = {
  ViewModel: GroupByViewModel,
  View,
};
