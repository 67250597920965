import { Cell } from '../components/cell';
import { Button, Icon } from '@cotera/client/app/components/ui';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  useDropdown,
} from '@cotera/client/app/components/headless';
import React from 'react';
import { Expression, Relation } from '@cotera/era';
import { WatchableViewModel } from '@cotera/client/app/etc';
import { NewColumnAction } from './column-action.type';
import { useSideModal } from '../../app';

export type ApplyFn = (props: {
  t: 'eraql' | 'decision-tree';
  value: Expression;
  column: string;
}) => void;

const Header: React.FC<{
  actions: NewColumnAction[];
  vm: WatchableViewModel<{
    rel: Relation;
    baseRel: Relation;
  }>;
  additionalProps: Record<string, unknown>;
  onApply: ApplyFn;
}> = ({ actions, additionalProps, onApply, vm }) => {
  return (
    <>
      <div className="flex relative flex-col px-3 w-full h-full pb-2 bg-white -mt-0.5 items-center">
        <Dropdown>
          <DropdownTrigger>
            <Button inline icon="add" text="Add Column" />
          </DropdownTrigger>
          <DropDown
            actions={actions}
            additionalProps={additionalProps}
            onApply={onApply}
            vm={vm}
          />
        </Dropdown>
      </div>
    </>
  );
};

const DropDown: React.FC<{
  actions: NewColumnAction[];
  vm: WatchableViewModel<{
    rel: Relation;
    baseRel: Relation;
  }>;
  additionalProps: Record<string, unknown>;
  onApply: ApplyFn;
}> = ({ actions, additionalProps, onApply, vm }) => {
  const sideModelHistory = useSideModal((s) => s.actions.push);
  const toggle = useDropdown((s) => s.actions.toggle);

  return (
    <DropdownContent>
      {actions.map(({ label, icon, View }) => (
        <DropdownItem
          icon={<Icon icon={icon} />}
          key={label}
          onClick={() => {
            toggle();
            sideModelHistory({
              view: () => (
                <View
                  onApply={onApply}
                  additionalProps={additionalProps}
                  vm={vm}
                />
              ),
            });
          }}
        >
          {label}
        </DropdownItem>
      ))}
    </DropdownContent>
  );
};

const Body = () => {
  return <Cell column="cotera-extra-column">&nbsp;</Cell>;
};

export const NewColumn = {
  Header,
  Body,
  DropDown,
};
