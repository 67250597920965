export type RateLimiter = {
  acquire: () => Promise<void>;
  release: () => Promise<void>;
};

export class Semaphore implements RateLimiter {
  private max: number;
  private current: number = 0;
  private queue: Array<() => void> = [];

  constructor(max: number, private opts: { delay: number } = { delay: 0 }) {
    this.max = max;
  }

  async acquire(): Promise<void> {
    if (this.current < this.max) {
      this.current++;
      return;
    }

    return new Promise((resolve) => this.queue.push(resolve));
  }

  async release(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, this.opts.delay));
    this.current--;

    if (this.queue.length > 0) {
      const next = this.queue.shift();
      if (next) next();
    }
  }
}

export class NoopRateLimiter implements RateLimiter {
  async acquire(): Promise<void> {
    return;
  }

  async release() {
    return;
  }
}
