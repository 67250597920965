import { Caption } from './caption';
import { Span } from './span';
import { P } from './p';
import { Title } from './title';
import { DateTime } from 'luxon';

const DateDisplay: React.FC<{ date: Date; time?: boolean }> = ({
  date,
  time,
}) => {
  if (!time) {
    return <>{DateTime.fromJSDate(date).toUTC().toFormat('yyyy/MM/dd')}</>;
  }
  return <>{DateTime.fromJSDate(date).toUTC().toISO()?.replace('.000', '')}</>;
};

export const Text = {
  Caption,
  Span,
  P,
  Title,
  Date: DateDisplay,
};
