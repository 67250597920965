import { Contract, GET, POST } from '@cotera/contracts';
import { z } from 'zod';

export const ConfigContract = Contract.new({
  createEntityDefinition: POST({
    params: z.object({
      name: z.string(),
    }),
    errors: z.object({ msg: z.string() }),
    output: z.object({
      id: z.string(),
      name: z.string(),
    }),
  }),
  config: GET({
    params: z.object({}),
    output: z.object({
      entities: z.array(z.object({ name: z.string(), id: z.string() })),
    }),
    errors: z.never(),
  }),
});
