import {
  Relation,
  UI,
  f,
  And,
  Page,
  AST,
  Expression,
  MakeStruct,
  Values,
  UnionAll,
} from '@cotera/era';
import { CompanyNamePrettify } from '../constants';

const filter = (r: Relation, e: Expression) =>
  r
    .where((t) => And(t.attr('COMPANY').eq(CompanyNamePrettify(e))))
    .select((t) => ({
      ID: t.attr('ID'),
      REVIEW: t.attr('TEXT'),
    }));

export const AutomatedNoteTakingOverview = ({
  CxData,
}: {
  CxData: Relation;
}): AST._Page => {
  return Page(['companyName'] as const, ({ companyName }) => {
    const name = CompanyNamePrettify(companyName);
    const Data = filter(CxData, companyName).select((t) => ({
      REVIEW: MakeStruct({
        __link: f`/apps/automated-notes/${companyName}/${t.attr('ID')}/`,
        text: t.attr('REVIEW'),
        action: 'tab',
      }),
    }));

    return [
      UI.Header({
        title: f`${name}`,
        caption: 'Automated Note Taking',
      }),
      UI.State(
        {
          Data,
        },
        ({ Data }) => {
          return [
            f`This app demonstrates how AI can automate note taking from customer interactions. 

Note taking allows you to do anything from QA, to feature request extraction, to summarization to understand what your customers are saying at scale. It can run in the background, all the time, and alert you about things it finds.

Click on a review to have our AI take notes on it!

Check out the [explainer video](https://www.loom.com/share/d96668a70b4248b9bd5d30b1968d0b51).

If you think this is interesting, [book a chat with us here.](https://calendly.com/cotera-founders/30-minutes)`,
            Data,
          ];
        }
      ),
    ];
  });
};

export const AutomatedNotesOverview = ({
  CxData,
}: {
  CxData: Relation;
}): AST._Page => {
  return Page(
    ['companyName', 'reviewId'] as const,
    ({ companyName, reviewId }) => {
      const Data = UnionAll([
        filter(CxData, companyName)
          .where((t) => t.attr('ID').eq(reviewId))
          .select((t) => t.pick('REVIEW')),
        Values([{ REVIEW: '    ' }]),
      ]);

      return [
        UI.Header({
          title: f`${CompanyNamePrettify(companyName)}`,
          caption: 'Automated Note Taking',
        }),
        f`This is an example of our AI extracting notes from your messages.

Note taking allows you to do anything from QA, to feature request extraction, to summarization to understand what your customers are saying at scale.

Here, we're just asking the AI to look for feature requests, product feedback and comments on your CX team! The same system can be used to answer more or less any question you can think of though. What's more, it can run autopilot, in the background, all the time.

Check out the [explainer video](https://www.loom.com/share/d96668a70b4248b9bd5d30b1968d0b51).

If you think this is interesting, [book a chat with us here.](https://calendly.com/cotera-founders/30-minutes)`,
        Data.limit(1),
        UI.Divider(),
        '# Copilot Summary',
        `The AI can take a little bit to think, but it's worth it!`,
        Data.chart.LLMSummary({
          prompt: summaryPrompt,
          model: 'gpt-4o',
        }),
      ];
    }
  );
};

const summaryPrompt = `IMPORTANT. DO NOT FORGET THIS. You will be told the data is a csv, but it is not. It is just a single review. You MUST perform your task by treating the entire text as a single review. The data has the word 'REVIEW' followed by a new line. You must also ignore that bit.

Your job is to take notes on a review for a company.

There are four types of notes to take. 

The first is a simple summary of the message. Try to be brief, and focus on the customers main point.

The second is for feature requests. If the customer asked for a specific feature, describe what they asked for.

The third is product feedback. If the customer had other feedback about the product, detail it here.

The fourth is feedback on the CX team itself. If the customer has any compliments or complaints about the CX team, put them in here!`;
