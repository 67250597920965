import { classNames, Formatters } from '@cotera/client/app/components/utils';

export type Style = 'positive' | 'negative' | 'warning' | 'neutral';

export const TEXT_STYLE: Record<Style, string> = {
  positive: '!text-green-500',
  negative: '!text-rose-500',
  warning: '!text-amber-500',
  neutral: '!text-primary-text',
};

export const EnhancedPercentage: React.FC<{
  value: number;
  className?: string;
  style: Style;
}> = (props): React.ReactNode => {
  const styleClassName = TEXT_STYLE[props.style];
  const className = classNames(styleClassName, props.className);
  const sign = props.value !== 0 ? (props.value > 0 ? '+' : '-') : '';
  const num = Formatters.number(Math.abs(props.value), '%');
  return <span className={className}>{`${sign}${num}`}</span>;
};
