import { Layout } from '@cotera/client/app/layout';
import { useParams } from 'react-router-dom';
import { EntityResolver, Tags, ERA_QL_TAG_MAP } from '@cotera/sdk/core';
import {
  Copyable,
  DatasetNode,
  DisplayError,
  FilterNode,
  NotFound,
  Protected,
  SampleNode,
  SampleViewModel,
  SideModal,
  SideModalProvider,
  TransformableRelation,
  useSideModal,
  WorkspaceNodes,
  WorkspaceViewModel,
} from '@cotera/client/app/components/app';
import { useEntity } from './hooks';
import { useEraScopesAwareRelIR } from '@cotera/client/app/pages/apps/compiler/macro-expansion/scopes';
import { EraCache, Relation } from '@cotera/era';
import { Entity } from '@cotera/api';
import { AddEntityContainer } from './components/setup-entity';
import { Button, Center, Loading } from '@cotera/client/app/components/ui';
import { ChildrenProps } from '../../components/utils';
import React, { Suspense, useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ApplyFn, NewColumn } from '../../components/data-grid-2';
import {
  DecisionTreeColumn,
  EreQLColumn,
  LlmColumn,
} from '../../components/data-grid-2/columns';
import {
  defaultRegistry,
  StringHeader,
} from '../../components/data-grid-2/registry';
import { Assert } from '@cotera/utilities';
import { Cell } from '../../components/data-grid-2/components/cell';
import { BaseHeader } from '../../components/data-grid-2/components/header';
import { useDeepMemo } from '../../hooks/deep-memo';
import { Dropdown, DropdownTrigger } from '../../components/headless';

export const EntityPage: React.FC = () => {
  const { entityName } = useParams() as { entityName: string };
  const entity = useEntity(entityName);

  if (!entity) {
    return (
      <Layout>
        <NotFound resource="Entity" />
      </Layout>
    );
  }

  let child: React.ReactNode;
  if (Object.keys(entity.columns).length === 0) {
    child = <SetupView entity={entity} />;
  } else {
    child = <HasColumnsView entity={entity} />;
  }

  return (
    <Layout>
      <ViewContainer entity={entity}>{child}</ViewContainer>
    </Layout>
  );
};

const ViewContainer: React.FC<{ entity: Entity } & ChildrenProps> = ({
  children,
}) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <DisplayError error={error} />}
    >
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

const SetupView: React.FC<{ entity: Entity }> = ({ entity }) => {
  return (
    <Protected scopes={[['manage', 'entities']]} fallback={<></>}>
      <AddEntityContainer entity={entity} />
    </Protected>
  );
};

const HasColumnsView: React.FC<{ entity: Entity }> = ({ entity }) => {
  const resolver = EntityResolver.fromColumnInfo(entity.name, entity.columns);
  const rel = resolver.columns();

  const relIR = Relation.wrap(useEraScopesAwareRelIR(rel)).andThen(
    EraCache.skipFunctionalTransfromSplit
  );

  const dataset = useDeepMemo(
    () =>
      new DatasetNode.ViewModel(
        entity.name,
        { x: 0, y: 0 },
        TransformableRelation.fromRel(relIR)
      ),
    [entity.name, relIR.sqlHash()]
  );

  const filter = useMemo(
    () =>
      new FilterNode.ViewModel(
        'Filter',
        { x: 0, y: 0 },
        { connective: 'and', items: [] },
        dataset,
        {
          useAutoFilters: false,
        }
      ),
    [dataset]
  );

  const sample = useMemo(
    () => new SampleNode.ViewModel(entity.name, { x: 0, y: 0 }, filter),
    [filter, entity.name]
  );

  const vm = useMemo(
    () => new WorkspaceViewModel([dataset, filter, sample]),
    [dataset, filter, sample]
  );

  return (
    <SideModalProvider>
      <WorkspaceNodes
        vm={vm}
        overrides={{
          sample: ({ node }) => (
            <SampleView vm={node as SampleViewModel} entity={entity} />
          ),
        }}
      />
      <SideModal />
    </SideModalProvider>
  );
};

const entityDatagridRegistry = defaultRegistry.clone();

const useRegistry = (vm: SampleViewModel, entity: Entity, applyFn: ApplyFn) => {
  const sideModal = useSideModal((s) => s.actions.push);

  return useMemo(() => {
    return entityDatagridRegistry
      .unshift((_, ty) => ty.tags.includes(Tags.TOPIC), {
        icon: 'sparkles',
        displayType: 'topic',
        header: (rel, column, ty) => (
          <StringHeader
            rel={rel}
            column={column}
            ty={ty}
            registry={entityDatagridRegistry}
          />
        ),
      })
      .unshift((_, ty) => ty.tags.includes(Tags.EXPR_COLUMN), {
        displayType: 'expression',
        header: (rel, column, ty) => (
          <StringHeader
            rel={rel}
            column={column}
            ty={ty}
            registry={entityDatagridRegistry}
          />
        ),
        headerActions: [
          (_, column) => {
            return {
              label: 'Edit',
              icon: 'edit',
              onClick: () => {
                const columnInfo = entity.columns[column];
                const meta = columnInfo?.meta;
                Assert.assert(meta?.t === 'eraql');

                sideModal({
                  view: () => (
                    <EreQLColumn.View
                      vm={vm}
                      onApply={applyFn}
                      additionalProps={{
                        columnName: column,
                        entityName: entity.name,
                        eraql: meta.eraql,
                      }}
                    />
                  ),
                });
              },
            };
          },
        ],
        cell: (column, ty, value) => {
          return (
            <Cell column={column}>
              <Copyable>{String(value)}</Copyable>
            </Cell>
          );
        },
      })
      .unshift((_, ty) => ty.tags.includes(Tags.DECISON_TREE), {
        displayType: 'decision-tree',
        header: (rel, column, ty) => (
          <StringHeader
            rel={rel}
            column={column}
            ty={ty}
            registry={entityDatagridRegistry}
          />
        ),
        headerActions: [
          (_, column) => {
            return {
              label: 'Edit',
              icon: 'edit',
              onClick: () => {
                const columnInfo = entity.columns[column];
                const meta = columnInfo?.meta;
                Assert.assert(meta?.t === 'eraql');
                Assert.assert(meta?.k?.t === 'decision-tree');

                sideModal({
                  view: () => (
                    <DecisionTreeColumn.View
                      vm={vm}
                      onApply={applyFn}
                      additionalProps={{
                        columnName: column,
                        entityName: entity.name,
                        eraql: meta.eraql,
                      }}
                    />
                  ),
                });
              },
            };
          },
        ],
      })
      .unshift((_, ty) => ty.tags.includes(Tags.TOPICS), {
        icon: 'sparkles',
        displayType: 'topics',
        header: (_, column, ty) => (
          <BaseHeader
            attr={{ name: column, ty }}
            registry={entityDatagridRegistry}
          />
        ),
        cell: (column, ty, value) => {
          return (
            <Cell column={column}>
              <Copyable>{JSON.stringify(value)}</Copyable>
            </Cell>
          );
        },
        headerActions: [
          (_, column) => {
            const columnInfo = entity.columns[column];
            const meta = columnInfo?.meta;
            Assert.assert(meta?.t === 'udd');
            Assert.assert(meta?.k?.t === 'topics');

            return {
              label: 'Edit',
              icon: 'edit',
              link: `/entities/${entity.name}/topics/${meta.k!.id}`,
            };
          },
        ],
      });
  }, [vm, entity, applyFn, sideModal]);
};

const SampleView: React.FC<{ vm: SampleViewModel; entity: Entity }> = ({
  vm,
  entity,
}) => {
  const closeSideModal = useSideModal((s) => s.actions.close);
  const applyHandler = useCallback<ApplyFn>(
    ({ t: colType, value, column }) => {
      if (colType === 'eraql' || colType === 'decision-tree') {
        closeSideModal();
        vm.parent.setTransform(
          (rel) =>
            rel.select((t) => ({
              ...t.star(),
              [column]: value.tag(ERA_QL_TAG_MAP[colType]),
            })),
          column
        );
        vm.addColumn(column);
      }
    },
    [vm, closeSideModal]
  );

  const columnRegistry = useRegistry(vm, entity, applyHandler);

  return (
    <SampleNode.View
      vm={vm}
      registry={columnRegistry}
      quickActions={[
        <Dropdown>
          <DropdownTrigger>
            <Button
              icon="plus"
              text="Add Column"
              iconOnly
              tooltip="left"
              small
              inline
              compact
              overrides={{ height: 'h-fit', py: '' }}
            />
          </DropdownTrigger>
          <NewColumn.DropDown
            actions={[
              EreQLColumn,
              LlmColumn,
              DecisionTreeColumn,
              // AssumptionColumn,
            ]}
            vm={vm}
            additionalProps={{ entityName: entity.name }}
            onApply={applyHandler}
          />
        </Dropdown>,
      ]}
      extraColumns={[
        {
          header: (
            <NewColumn.Header
              vm={vm}
              actions={[
                EreQLColumn,
                LlmColumn,
                DecisionTreeColumn,
                // AssumptionColumn,
              ]}
              additionalProps={{
                entityName: entity.name,
              }}
              onApply={applyHandler}
            />
          ),
          cell: ({ index }) => <NewColumn.Body key={index} />,
        },
      ]}
    />
  );
};
