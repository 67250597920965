import { Org, SuperAdminContract, UntenantedClient } from '@cotera/api';
import { Contract, RouteDefinition, RouteDefinitions } from '@cotera/contracts';
import { useReducer } from 'react';
import { classNames } from '@cotera/client/app/components/utils';
import { ContractForm } from './contract-form';

type PathState = {
  displayedContracts: Contract<any>[];
  path: string[];
};

const pathReducer = (
  state: PathState,
  action: {
    type: 'drill';
    index: number;
    selected: string;
    contract: Contract<any>;
  }
) => {
  switch (action.type) {
    case 'drill':
      return {
        displayedContracts: [
          ...state.displayedContracts.slice(0, action.index + 1),
          action.contract,
        ],
        path: [...state.path.slice(0, action.index), action.selected],
      };
  }
};

const initialState: PathState = {
  displayedContracts: [SuperAdminContract],
  path: [] as string[],
};

type SuperAdminProps = {
  client: UntenantedClient;
  orgs: Org[];
};

export const SuperAdmin: React.FC<SuperAdminProps> = ({ client, orgs }) => {
  const [state, dispatch] = useReducer<typeof pathReducer>(
    pathReducer,
    initialState
  );

  return (
    <div className="flex flex-row h-full">
      {state.displayedContracts.map((contract: Contract<any>, index: number) =>
        contract instanceof Contract ? (
          <Column
            key={index}
            contract={contract}
            currentlySelected={state.path[index]}
            drill={(selected) =>
              dispatch({
                type: 'drill',
                index,
                selected,
                contract: contract.routes[selected],
              })
            }
          />
        ) : (
          <div key={index} className="flex-grow">
            <ContractForm
              superAdmin={client.superAdmin}
              path={state.path}
              orgs={orgs}
            />
          </div>
        )
      )}
    </div>
  );
};

const Column: React.FC<{
  contract: Contract<any>;
  currentlySelected?: string;
  drill: (selected: string) => void;
}> = ({ contract, currentlySelected, drill }) => {
  const { routes } = contract;
  const contracts: any = {};
  const functions: any = {};

  for (const [route, def] of Object.entries(routes)) {
    if (def instanceof Contract) {
      contracts[route] = def;
    } else {
      functions[route] = def;
    }
  }

  return (
    <div className="flex flex-col px-3 py-2 space-y-1 w-72 bg-white rounded h-full border-r border-divider">
      <ColumnHeader text="Contracts" />
      <ColumnItems
        items={contracts}
        currentlySelected={currentlySelected}
        onClick={drill}
      />
      <ColumnHeader text="Actions" />
      <ColumnItems
        items={functions}
        currentlySelected={currentlySelected}
        onClick={drill}
      />
    </div>
  );
};

const ColumnHeader: React.FC<{ text: string }> = ({ text }) => (
  <div className="text-sm font-medium leading-6 text-muted-text uppercase py-1 px-2">
    {text}
  </div>
);

const ColumnItems: React.FC<{
  items: Contract<RouteDefinitions>;
  currentlySelected?: string;
  onClick: (name: string) => void;
}> = ({ items, currentlySelected, onClick }) => {
  const keys = Object.keys(items);

  if (keys.length === 0) {
    return (
      <span className="text-muted-text px-3 py-2 text-sm uppercase">-</span>
    );
  }

  const isRouteDefinition = (item: any): item is RouteDefinition => {
    return !(item instanceof Contract);
  };

  return (
    <>
      {keys.map((name) => {
        // See `makeAltApiClient`, this could probably all be implemented with that.
        const route = (items as any)[name];
        return (
          <button
            key={name}
            className={classNames(
              name === currentlySelected
                ? 'bg-standard-background text-indigo-600'
                : 'text-standard-text hover:bg-standard-background hover:text-standard-text',
              'flex items-center justify-between rounded px-3 py-2 text-md font-medium'
            )}
            onClick={() => {
              onClick(name);
            }}
          >
            <span className="truncate">{name}</span>
            {isRouteDefinition(route) && <MethodSpan def={route} />}
          </button>
        );
      })}
    </>
  );
};

const MethodSpan: React.FC<{ def: RouteDefinition }> = ({ def }) => {
  const { method } = def;
  const styles = 'text-xs uppercase rounded-xl px-2 py-1';
  let color: string;
  switch (method) {
    case 'GET':
      color = 'bg-green-100 text-green-800';
      break;
    case 'PUT':
      color = 'bg-yellow-100 text-yellow-800';
      break;
    case 'POST':
      color = 'bg-tertiary-background text-indigo-800';
      break;
    case 'DELETE':
      color = 'bg-red-100 text-red-800';
      break;
    default:
      throw new Error('Method not supported');
  }
  return <span className={classNames(styles, color)}>{method}</span>;
};
