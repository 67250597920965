import { UI, AST, Relation, Markup } from '@cotera/era';
import { Overview } from './overview';

export const ConversationDetail = ({
  QaAppInput,
  config,
  cleanedMessages,
  additionalComponents = () => [],
}: {
  QaAppInput: Relation;
  config: {
    category: string;
    sub_category: string;
  }[];
  cleanedMessages: string;
  additionalComponents?: (t: Relation) => Markup[];
}): AST._App => {
  return UI.App({
    title: 'Conversation Breakdown',
    icon: 'bar-chart',
    pages: {
      '/:id': Overview({
        QAOutputRelation: QaAppInput,
        config,
        cleanedMessages,
        additionalComponents,
      }),
    },
    menu: [],
  });
};
