import { Text, Chip, Divider, Loading } from '@cotera/client/app/components';
import { AST, Relation } from '@cotera/era';
import { CopilotIcon } from '@cotera/client/app/components/app/icons/copilot';
import { classNames } from '@cotera/client/app/components/utils';
import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import Markdown from 'markdown-to-jsx';
import { z } from 'zod';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { Card } from '@cotera/client/app/components/headless';

const STYLE: Record<
  'positive' | 'negative' | 'neutral' | 'warning',
  { dot: string; icon?: (props: { className?: string }) => React.ReactNode }
> = {
  negative: {
    dot: 'border-rose-500 text-rose-500 bg-white',
    icon: XMarkIcon,
  },
  positive: {
    dot: 'border-emerald-500 text-emerald-500 bg-white',
    icon: CheckBadgeIcon,
  },
  neutral: {
    dot: 'border-slate-500 text-standard-text bg-white',
    icon: InformationCircleIcon,
  },
  warning: {
    dot: 'border-yellow-500 text-yellow-500 bg-white',
    icon: ExclamationTriangleIcon,
  },
} as const;

export const RenderInsights: React.FC<{
  section: AST._Insights;
}> = ({ section }) => {
  const result = useDuckDBQuery({ rel: Relation.wrap(section.rel) });

  return (
    <Card.Container noBackground bottomPadding={false}>
      {section.config.title && (
        <>
          <Card.Title
            size="lg"
            className="font-medium text-gray-800 w-full relative flex justify-between items-center mb-2"
          >
            {section.config.title}
            <div className="flex items-center">
              <CopilotIcon className="w-5 h-5" theme="primary" />
              <Text.Caption className="text-xs">Copilot Insights</Text.Caption>
            </div>
          </Card.Title>
          <Divider className="mb-4" />
        </>
      )}
      <Card.Content padding={false}>
        <div className="flex mt-2 justify-between items-center">
          <div
            className={classNames(
              'flex flex-wrap',
              section.config.wrap ? 'flex-col' : 'items-center '
            )}
          >
            {result.data.data
              .toArrayOf(
                z.object({
                  style: z
                    .enum(['positive', 'negative', 'neutral', 'warning'])
                    .optional(),
                  title: z.string(),
                })
              )
              .map((row, i) => {
                const Icon = STYLE[row['style'] ?? 'neutral']?.icon;
                return (
                  <div key={i} className="flex flex-row items-center mb-4 mr-4">
                    <div
                      className={classNames(
                        'mr-2 rounded-full self-start mt-0.5',
                        STYLE[row['style'] ?? 'neutral']?.dot ??
                          STYLE['neutral']!.dot
                      )}
                    >
                      {Icon ? (
                        <Icon className="w-4 h-4" />
                      ) : (
                        <div className="w-4 h-4" />
                      )}
                    </div>
                    <div className="text-standard-text rounded text-sm">
                      <Markdown>{row['title']}</Markdown>
                    </div>
                  </div>
                );
              })}
          </div>
          {!section.config.title && (
            <Chip
              type="secondary"
              tight
              className="flex items-center mb-2 self-start shrink-0"
            >
              <CopilotIcon className="w-5 h-5" theme="primary" />{' '}
              <Text.Caption className="text-xs pr-4">
                Copilot Insights
              </Text.Caption>
            </Chip>
          )}
        </div>
      </Card.Content>
    </Card.Container>
  );
};

export const RenderInsightsSkeleton: React.FC<{}> = () => {
  return (
    <div className="flex items-center">
      <Loading.Shimmer className="w-[12px] h-[12px] mr-4" />
      <Loading.Shimmer className="w-[200px] h-[12px]" />
    </div>
  );
};
