import { Result, err, ok } from 'neverthrow';
import { Expression } from './expression';
import { Case, Constant, If, f } from './utilities';

type Fmt = (x: Expression) => Expression;

const a: Fmt = (x) =>
  Case(
    ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => ({
      when: x.datePart('dow').eq(i),
      then: day,
    }))
  );

const A: Fmt = (x) =>
  Case(
    [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ].map((day, i) => ({ when: x.datePart('dow').eq(i), then: day }))
  );

const b: Fmt = (x) =>
  Case(
    [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ].map((month, i) => ({ when: x.datePart('month').eq(i + 1), then: month }))
  );

const B: Fmt = (x) =>
  Case(
    [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ].map((month, i) => ({ when: x.datePart('month').eq(i + 1), then: month }))
  );

const C: Fmt = (x) => x.datePart('year').div(100).floor();

const d: Fmt = (x) =>
  If(x.datePart('day').lt(10), {
    then: f`0${x.datePart('day')}`,
    else: x.datePart('day').cast('string'),
  });

const e: Fmt = (x) =>
  If(x.datePart('day').lt(10), {
    then: f`${x.datePart('day')}`,
    else: x.datePart('day').cast('string'),
  });

const h: Fmt = (x) =>
  Case(
    [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ].map((mon, i) => ({ when: x.datePart('month').eq(i + 1), then: mon }))
  );

const H: Fmt = (x) => x.datePart('hour').cast('string');

const I: Fmt = (x) =>
  Case([
    { when: x.datePart('hour').eq(0), then: '12' },
    { when: x.datePart('hour').lt(10), then: f`0${x.datePart('hour')}` },
    { when: x.datePart('hour').lte(12), then: f`${x.datePart('hour')}` },
    { when: x.datePart('hour').gt(12), then: f`${x.datePart('hour').sub(12)}` },
  ]);

const j: Fmt = (x) =>
  x
    .dateTrunc('week')
    .datePart('week')
    .sub(1)
    .mul(7)
    .add(x.datePart('dow'))
    .cast('string');

const k: Fmt = (x) =>
  If(x.datePart('hour').lt(10), {
    then: f` ${x.datePart('hour')}`,
    else: x.datePart('hour').cast('string'),
  });

const l: Fmt = (x) =>
  Case([
    { when: x.datePart('hour').eq(0), then: '12' },
    { when: x.datePart('hour').lte(12), then: f` ${x.datePart('hour')}` },
    {
      when: x.datePart('hour').gt(12),
      then: x.datePart('hour').sub(12).cast('string'),
    },
  ]);

const m: Fmt = (x) =>
  If(x.datePart('month').lt(10), {
    then: f`0${x.datePart('month')}`,
    else: x.datePart('month').cast('string'),
  });

const M: Fmt = (x) => x.datePart('minute').cast('string');

const p: Fmt = (x) => If(x.datePart('hour').lt(12), { then: 'AM', else: 'PM' });

const S: Fmt = (x) =>
  If(x.datePart('seconds').lt(10), {
    then: f`0${x.datePart('seconds')}`,
    else: x.datePart('seconds').cast('string'),
  });

const u: Fmt = (x) =>
  If(x.datePart('dow').eq(0), { then: 7, else: x.datePart('dow') }).cast(
    'string'
  );

const U: Fmt = (x) =>
  If(x.datePart('week').sub(1).lt(10), {
    then: f`0${x.datePart('week').sub(1)}`,
    else: x.datePart('week').sub(1),
  });

const w: Fmt = (x) => x.datePart('dow').cast('string');

const y: Fmt = (x) =>
  x.datePart('year').sub(x.datePart('year').round(-2)).cast('string');

const Y: Fmt = (x) => x.datePart('year').cast('string');

// Time zone (we currently only support UTC, but keep the type to 'string' for
// backcompat when we support more)
const Z: Fmt = () => Constant('UTC', { ty: 'string' });

const DATE_CODES: Record<string, (t: Expression) => Expression> = {
  a,
  A,
  b,
  B,
  c: (x) => f`${a(x)} ${b(x)} ${e(x)} ${H(x)}:${M(x)}:${S(x)} ${Y(x)}`,
  C,
  d,
  D: (x) => f`${m(x)}/${d(x)}/${y(x)}`,
  e,
  F: (x) => f`${Y(x)}-${m(x)}-${d(x)}`,
  // %g	last two digits of year of ISO week number (see %G)
  // %G	year of ISO week number (see %V); normally useful only with %V
  h,
  H,
  I,
  j,
  k,
  l,
  m,
  M,
  //   %n	a newline
  n: () => f`\n`,
  // %N	nanoseconds (000000000..999999999)
  p,
  P: (x) => p(x).lower(),
  r: (x) => f`${I(x)}:${M(x)}:${S(x)} ${p(x)}`,
  R: (x) => f`${H(x)}:${M(x)}`,
  // %s	seconds since 1970-01-01 00:00:00 UTC
  S,
  // %t	a tab
  t: () => f`\t`,
  T: (x) => f`${H(x)}:${M(x)}:${S(x)}`,
  u,
  U,
  // %V	ISO week number, with Monday as the first day of the week (01, 02, …, 52, 53)
  w,
  // %w	weekday as a decimal number [0,6], with 0 representing Sunday
  // %W	week number of the year (Monday as the first day of the week) as a decimal number [00,53]
  x: (x) => f`${m(x)}/${d(x)}/${y(x)}`,
  X: (x) => f`${H(x)}:${M(x)}:${S(x)}`,
  y,
  Y,
  Z,
  // %z	UTC offset in the form +HHMM or -HHMM
  // %%	a literal '%' character
  '%': () => f`%`,
};

export const dateFmt = (
  x: Date | Expression,
  fmt: string
): Result<Expression, { msg: string }> => {
  const target = Expression.wrap(x);
  let rest = fmt;
  const parts: (Expression | string)[] = [];

  outer: while (rest.length > 0) {
    if (rest.startsWith('%')) {
      const letter = rest[1];

      if (letter === undefined) {
        return err({ msg: 'Mismatched %' });
      }

      const func = DATE_CODES[letter];
      if (!func) {
        return err({ msg: `Letter "${letter}" is not supported` });
      }
      parts.push(func(target));
      rest = rest.slice(2);
      continue outer;
    } else {
      const idx = rest.indexOf('%');
      if (idx === -1) {
        parts.push(rest);
        break outer;
      } else {
        parts.push(rest.slice(0, idx));
        rest = rest.slice(idx);
        continue outer;
      }
    }
  }

  if (parts.length === 0) {
    return ok(Constant(''));
  }

  const combined: Expression = parts
    .map((part) => Expression.wrap(part))
    .reduce((l, r) => l.concat(r));

  return ok(combined);
};

/* 
Examples

a: 'May 20, 2024` => 'Mon'
A: 'May 20, 2024` => 'Monday'
b: 'May 20, 2024` => 'May'
B: 'May 20, 2024` => 'May'
c: 'May 7, 2024` => 'Tue May  7 00:00:00 2024'
C: 'May 7, 2024` => '20'
d: 'May 7, 2024` => '07'
D: 'May 7, 2024` => '05/07/24'
e: 'May 7, 2024` => '7'
F: 'May 7, 2024` => '2024-05-07'
h: 'May 7, 2024` => 'May'
H: 'May 7, 2024` => '0'
I: 'May 7, 2024` => '12'
j: 'May 7, 2024` => '128'
k: 'May 7, 2024` => '0'
l: 'May 7, 2024` => '12'

*/
