// This file is super slow to typecheck, and slows down my editor on boot. As
// an (annoying) optimization, I've made it a js file with a .d.ts in order to
// speed up the type check. Be careful editing this file
//
// eslint-disable-next-line
// @ts-nocheck
export { inferType, validatorForType } from './inference';
export * from './base';

import { z } from 'zod';
import { AST } from '../ast';
import {
  AttributeTypeSchema,
  ExtendedAttributeTypeSchema,
  IdentifierSchema,
  LiteralSchema,
  PrimitiveSchema,
} from './base';
import { VALUE_FORMAT_OPTIONS, AXIS_SCALE_OPTIONS } from '../ast/mu';
export * as Credentials from './credentials';

// It's kinda annoying these are all in the same file, but that's the module
// boundary in JS and it's _really_ easy to have your bundler mess up cyclical
// deps. If you want to move these outside of this module, be extra careful
// with your import/export-ing and triple check these cyclical parsers dont
// break in older bundlers

export const MarkupSchema = z.lazy(() =>
  z.discriminatedUnion('t', [
    ChartSchema,
    MarkupVarSchema,
    MacroApplyVarsToMarkupSchema,
    TextSchema,
    TabsSchema,
    ImageSchema,
    BlockSchema,
    StatsSchema,
    InsightsSchema,
    CalloutSchema,
    SectionsSchema,
    PageSchema,
    ForEachSchema,
    HeaderSchema,
    ExprControlsV2Schema,
    RelControlsV2Schema,
    WidthSchema,
    DividerSchema,
    NoopSchema,
    MacroSectionCaseSchema,
    UiStateSchema,
  ])
);

export const RelSchema = z.lazy(() =>
  z.discriminatedUnion('t', [
    SelectSchema,
    AggregateSchema,
    JoinSchema,
    ValuesSchema,
    TableSchema,
    FileSchema,
    UnionSchema,
    GenerateSeriesSchema,
    MacroApplyVarsToRelSchema,
    MacroRelIfSchema,
    RelVarSchema,
    InformationSchemaSchema,
  ])
);

export const ExprSchema = z.lazy(() =>
  z.discriminatedUnion('t', [
    ScalarSchema,
    AttrSchema,
    CastSchema,
    FunctionCallExprSchema,
    CaseSchema,
    MatchSchema,
    ExprVarSchema,
    InvariantsSchema,
    MakeArraySchema,
    MakeStructSchema,
    GetFieldSchema,
    WindowSchema,
    MacroExprCase,
    MacroApplyVarsToExprSchema,
  ])
);

const ExprVarSchema = z.object({
  t: z.literal('expr-var'),
  name: IdentifierSchema,
  scope: z.string(),
  ty: ExtendedAttributeTypeSchema,
  default: ExprSchema.nullable(),
});

const RelVarSchema = z.object({
  t: z.literal('rel-var'),
  scope: z.string(),
  name: IdentifierSchema,
  attributes: z.record(IdentifierSchema, ExtendedAttributeTypeSchema),
  default: RelSchema.nullable(),
});

const NoopSchema = z.object({ t: z.literal('noop') });

const MacroSectionCaseSchema = z.object({
  t: z.literal('macro-section-case'),
  cases: z.array(z.object({ when: ExprSchema, then: MarkupSchema })),
  else: MarkupSchema,
});

const MarkupVarSchema = z.object({
  t: z.literal('markup-var'),
  scope: z.string(),
  identifier: z.string(),
});

const TabsSchema = z.object({
  t: z.literal('tabs'),
  style: z.enum(['tabs', 'select']),
  tabs: z.array(
    z.object({
      title: z.string(),
      section: MarkupSchema,
    })
  ),
});

const TextSchema = z.object({
  t: z.literal('text'),
  text: ExprSchema,
  modifier: z.enum(['start-case', 'none']),
});

const DividerSchema = z.object({
  t: z.literal('divider'),
  text: ExprSchema,
});

const ImageSchema = z.object({
  t: z.literal('image'),
  uri: z.string().url(),
  description: z.string(),
  align: z.enum(['left', 'right', 'center']),
  size: z.enum(['lg', 'md', 'sm']),
});

const ChartSizeSchema = z.enum(['lg', 'md', 'sm']);

const ChartFormatSchema = z.record(
  z.string(),
  z.enum(VALUE_FORMAT_OPTIONS).or(z.string())
);

const AxisSchema = z.object({
  label: z.string().nullable(),
  scale: z.enum(AXIS_SCALE_OPTIONS).nullable(),
});

const AxisesSchema = z.object({
  x: AxisSchema,
  y: AxisSchema,
});

const ChartConfigSchema = z.discriminatedUnion('t', [
  z.object({ t: z.literal('sankey-chart') }),
  z.object({ t: z.literal('radar-chart') }),
  z.object({ t: z.literal('summary-chart') }),
  z.object({ t: z.literal('index-list') }),
  z.object({ t: z.literal('relation-info') }),
  z.object({ t: z.literal('area-bump-chart'), axis: AxisesSchema }),
  z.object({ t: z.literal('data-grid'), defaultRowCount: z.number() }),
  z.object({ t: z.literal('semantic-search-results') }),
  z.object({
    t: z.literal('line-chart'),
    axis: AxisesSchema,
    type: z.enum(['stacked', 'normal', 'area']),
  }),
  z.object({
    t: z.literal('scatter-plot'),
    axis: AxisesSchema,
    numTicks: z.number(),
    dynamicSizePoints: z.boolean(),
  }),
  z.object({
    t: z.literal('bar-chart'),
    axis: AxisesSchema,
    trendline: z.boolean(),
    type: z.enum(['grouped', 'stacked']),
    direction: z.enum(['horizontal', 'vertical']),
  }),
  z.object({ t: z.literal('pie-chart'), labels: z.boolean() }),
  z.object({
    t: z.literal('llm-summary'),
    prompt: z.string(),
    model: z.string().optional().nullable(),
  }),
  z.object({
    t: z.literal('histogram-chart'),
    axis: AxisesSchema,
    type: z.enum(['grouped', 'stacked']),
    direction: z.enum(['horizontal', 'vertical']),
  }),
  z.object({
    t: z.literal('heatmap-chart'),
    axis: AxisesSchema,
    colors: z.array(
      z.object({
        shade: z.enum(['200', '300', '400', '500']),
        color: z.enum(['Red', 'Green', 'Yellow', 'Indigo', 'Orange']),
      })
    ),
  }),
]);

const ChartSchema = z.object({
  t: z.literal('chart'),
  rel: RelSchema,
  title: ExprSchema.nullable(),
  size: ChartSizeSchema,
  format: ChartFormatSchema,
  config: ChartConfigSchema,
});

const PageSchema = z.object({
  t: z.literal('page'),
  params: z.array(z.string()).readonly(),
  config: z.object({ cache: z.record(z.string().array()) }),
  body: z.object({
    scope: z.string(),
    macro: MarkupSchema,
  }),
});

const UiStateSchema = z.object({
  t: z.literal('ui-state'),
  defaults: z.object({
    rels: z.record(IdentifierSchema, RelSchema),
    exprs: z.record(IdentifierSchema, ExprSchema),
  }),
  url: z.object({
    params: z.string().array().readonly(),
    path: z.array(z.string().or(z.object({ name: z.string() }))).nullable(),
  }),
  body: z.object({
    scope: z.string(),
    macro: MarkupSchema,
  }),
});

const ExprControlsV2Schema = z.object({
  t: z.literal('expr-controls-v2'),
  var: ExprVarSchema,
  label: z.boolean(),
  config: z
    .discriminatedUnion('t', [
      z.object({
        t: z.literal('picklist'),
        options: z.string().array().readonly(),
        display: z.enum(['picklist', 'toggle', 'tab-selector', 'nav']),
      }),
      z.object({
        t: z.literal('pickfrom'),
        rel: RelSchema,
        display: z.enum(['picklist', 'toggle', 'tab-selector', 'nav']),
      }),
    ])
    .nullable(),
});

const RelControlsV2Schema = z.object({
  t: z.literal('rel-controls-v2'),
  var: RelVarSchema,
  config: z.discriminatedUnion('t', [
    z.object({
      t: z.literal('slice-builder'),
      quickFilters: z
        .object({
          left: z.string(),
          right: z.unknown().optional().nullable(),
          operator: z.string(),
          t: PrimitiveSchema,
        })
        .array(),
    }),
    z.object({ t: z.literal('defintion-picker') }),
    z.object({ t: z.literal('editable-table') }),
    z.object({ t: z.literal('file-picker') }),
    z.object({ t: z.literal('semantic-search') }),
  ]),
});

const BlockSchema = z.object({
  t: z.literal('block'),
  sections: z.array(MarkupSchema),
  title: ExprSchema,
  border: z.boolean(),
});

const WidthSchema = z.object({
  t: z.literal('width'),
  section: MarkupSchema,
  size: z.enum(['2/3', '1/3', '1/2']),
});

const SectionsSchema = z.object({
  t: z.literal('sections'),
  sections: z.array(MarkupSchema),
  config: z.discriminatedUnion('t', [
    z.object({ t: z.enum(['row', 'sections']) }),
    z.object({ t: z.literal('grid'), columns: z.number() }),
  ]),
});

const MenuItemSchema = z.object({
  title: z.string(),
  icon: z.enum(AST.MARKUP_ICONS).nullable().optional(),
  slug: z.string(),
});

const HeaderSchema = z.object({
  t: z.literal('header'),
  title: ExprSchema,
  caption: ExprSchema,
});

export const AppSchema = z.object({
  t: z.literal('app'),
  title: z.string(),
  icon: z.enum(AST.MARKUP_ICONS),
  pages: z.record(PageSchema),
  menu: MenuItemSchema.array().readonly(),
  context: z.string().nullable(),
});

const MacroApplyVarsToMarkupSchema = z.object({
  t: z.literal('macro-apply-vars-to-markup'),
  vars: z.object({
    rels: z.record(IdentifierSchema, RelSchema),
    exprs: z.record(IdentifierSchema, ExprSchema),
    sections: z.record(IdentifierSchema, MarkupSchema),
  }),
  displayName: z.string().nullable(),
  scope: z.string(),
  section: MarkupSchema,
});

const MacroApplyVarsToRelSchema = z.object({
  t: z.literal('macro-apply-vars-to-rel'),
  vars: z.object({
    rels: z.record(IdentifierSchema, RelSchema),
    exprs: z.record(IdentifierSchema, ExprSchema),
  }),
  scope: z.string(),
  sources: z.object({ from: RelSchema }),
  displayName: z.string().nullable(),
});

const ForEachSchema = z.object({
  t: z.literal('for-each'),
  rel: RelSchema,
  body: z.object({
    scope: z.string(),
    macro: MarkupSchema,
  }),
});

const StatsSchema = z.object({
  t: z.literal('stats'),
  stats: z.array(
    z.object({
      config: z.object({
        title: ExprSchema.nullable(),
        info: ExprSchema.nullable(),
        unit: ExprSchema.nullable(),
        style: ExprSchema.nullable(),
        caption: ExprSchema.nullable(),
      }),
      rel: RelSchema,
    })
  ),
  caption: ExprSchema,
});

const InsightsSchema = z.object({
  t: z.literal('insights'),
  rel: RelSchema,
  config: z.object({
    title: z.string().nullable(),
    wrap: z.boolean(),
  }),
});

const CalloutSchema = z.object({
  t: z.literal('callout'),
  rels: RelSchema.array(),
});

const MacroRelIfSchema = z.object({
  t: z.literal('macro-rel-case'),
  cases: z
    .object({
      when: ExprSchema,
      then: RelSchema,
    })
    .array(),
  else: RelSchema,
});

const TableSchema = z.object({
  t: z.literal('table'),
  name: IdentifierSchema,
  schema: IdentifierSchema,
  attributes: z.record(IdentifierSchema, ExtendedAttributeTypeSchema),
});

const GenerateSeriesSchema = z.object({
  t: z.literal('generate-series'),
  start: ExprSchema,
  stop: ExprSchema,
});

const FileSchema = z.object({
  t: z.literal('file'),
  uri: z.string(),
  attributes: z.record(IdentifierSchema, ExtendedAttributeTypeSchema),
});

const sortDirectionSchema = z.enum(['asc', 'desc']);

const SelectSchema = z.object({
  t: z.literal('select'),
  distinct: z.boolean(),
  sources: z.object({
    from: RelSchema,
  }),
  limit: z.number().nullable(),
  condition: ExprSchema.nullable(),
  offset: z.number().nullable(),
  selection: z.record(ExprSchema),
  orderBys: z.array(
    z.object({
      expr: ExprSchema,
      direction: sortDirectionSchema,
    })
  ),
  meta: z.record(z.string().or(z.boolean())),
});

const JoinTypeSchema = z.enum(['inner', 'left', 'right']);

const JoinSchema = z.object({
  t: z.literal('join'),
  how: JoinTypeSchema,
  sources: z.object({
    left: RelSchema,
    right: RelSchema,
  }),
  condition: ExprSchema,
  selection: z.record(ExprSchema),
});

const UnionSchema = z.object({
  t: z.literal('union'),
  all: z.boolean(),
  sources: z.object({
    left: RelSchema,
    right: RelSchema,
  }),
});

const ValuesSchema = z.object({
  t: z.literal('values'),
  values: z.array(z.record(IdentifierSchema, LiteralSchema)),
  attributes: z.record(IdentifierSchema, ExtendedAttributeTypeSchema),
});

const AggregateSchema = z.object({
  t: z.literal('aggregate'),
  groupedAttributes: z.array(z.string()),
  sources: z.object({ from: RelSchema }),
  selection: z.record(IdentifierSchema, ExprSchema),
});

const InformationSchemaSchema = z.object({
  t: z.literal('information-schema'),
  schemas: z.string().array(),
  type: z.enum(['columns', 'tables']),
});

export const ScalarSchema = z.object({
  t: z.literal('scalar'),
  ty: ExtendedAttributeTypeSchema,
  val: LiteralSchema,
});

const GetFieldSchema = z.object({
  t: z.literal('get-field'),
  name: IdentifierSchema,
  expr: ExprSchema,
});

const MakeStructSchema = z.object({
  t: z.literal('make-struct'),
  fields: z.record(IdentifierSchema, ExprSchema),
});

const MakeArraySchema = z.object({
  t: z.literal('make-array'),
  elements: z.array(ExprSchema),
});

const AttrSchema = z.object({
  t: z.literal('attr'),
  name: IdentifierSchema,
  source: z.enum(['from', 'left', 'right']),
  ty: ExtendedAttributeTypeSchema,
});

const functionCallIdentifierSchema = z.enum(AST.FUNCTION_IDENTIFIERS);

const FunctionCallExprSchema = z.object({
  t: z.literal('function-call'),
  op: functionCallIdentifierSchema,
  args: z.array(ExprSchema),
  display: z.enum(['pipe', 'fc', 'infix', 'postfix']).nullable(),
});

const windowOpSchema = z.enum(AST.WINDOW_OPS);

const WindowSchema = z.object({
  t: z.literal('window'),
  op: windowOpSchema,
  args: ExprSchema.array(),
  over: z.object({
    partitionBy: ExprSchema.array(),
    orderBy: z
      .object({ expr: ExprSchema, direction: sortDirectionSchema })
      .array(),
  }),
  frame: z
    .object({
      preceding: z.number().or(z.literal('unbounded')),
      following: z.number().or(z.literal('unbounded')),
    })
    .nullable(),
});

const MacroApplyVarsToExprSchema = z.object({
  t: z.literal('macro-apply-vars-to-expr'),
  vars: z.object({
    exprs: z.record(IdentifierSchema, ExprSchema),
  }),
  displayName: z.string().nullable(),
  scope: z.string(),
  sources: z.object({ from: ExprSchema }),
});

const MacroExprCase = z.object({
  t: z.literal('macro-expr-case'),
  cases: z.array(z.object({ when: ExprSchema, then: ExprSchema })),
  else: ExprSchema,
});

const InvariantsSchema = z.object({
  t: z.literal('invariants'),
  expr: ExprSchema,
  invariants: z.record(IdentifierSchema, ExprSchema),
});

const CastSchema = z.object({
  t: z.literal('cast'),
  targetTy: AttributeTypeSchema,
  expr: ExprSchema,
});

const CaseSchema = z.object({
  t: z.literal('case'),
  cases: z.array(z.object({ when: ExprSchema, then: ExprSchema })),
  else: ExprSchema.optional(),
});

const MatchSchema = z.object({
  t: z.literal('match'),
  expr: ExprSchema,
  cases: z.record(ExprSchema),
});

export const Expr = {
  MacroExprCase,
  MatchSchema,
  ScalarSchema,
  ExprVarSchema,
  GetFieldSchema,
  MakeStructSchema,
  MakeArraySchema,
  AttrSchema,
  functionCallIdentifierSchema,
  FunctionCallExprSchema,
  windowOpSchema,
  WindowSchema,
  InvariantsSchema,
  CastSchema,
  CaseSchema,
  MacroApplyVarsToExprSchema,
};
