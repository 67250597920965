import { Relation, UI, f, And, Desc, Page, AST } from '@cotera/era';
import { CompanyNamePrettify } from '../constants';
import { GetTopNCategories } from '../product-accomplishments/components';

export const Overview = ({ CxData }: { CxData: Relation }): AST._Page => {
  return Page(['companyName'] as const, ({ companyName }) => {
    const Data = CxData.where((t) =>
      And(
        t.attr('COMPANY').eq(CompanyNamePrettify(companyName)),
        t.attr('FEATURE_REQUEST').neq(''),
        t.attr('FEATURE_SUBCATEGORY').neq(''),
        t.attr('FEATURE_SUBCATEGORY').isNotNull()
      )
    ).select((t) => ({
      REVIEW: t.attr('TEXT'),
      ...t.pick(
        'TIMESTAMP',
        'SUMMARY',
        'FEATURE_REQUEST',
        'FEATURE_SUBCATEGORY',
        'SENTIMENT'
      ),
    }));

    return [
      UI.Header({
        title: f`${CompanyNamePrettify(companyName)}`,
        caption: 'Top Feature Request Categories',
      }),
      UI.State(
        {
          Data,
        },
        ({ Data }) => {
          const TopFeatureRequests = GetTopNCategories(
            Data,
            'FEATURE_SUBCATEGORY',
            8
          ).orderBy((t) => t.attr('PERCENT').desc());

          const MostRecent1000 = Data.orderBy((t) => Desc(t.attr('TIMESTAMP')))
            .limit(1000)
            .select((t) =>
              t.pick('REVIEW', 'FEATURE_REQUEST', 'FEATURE_SUBCATEGORY')
            );

          return [
            f`This app gives you some insight into common feauture requests that your customers make. 

It is based on publicly available data from Google Reviews, and shows you a chart of the most common feature requests, an AI copilot summary of which features are being requested in those reviews, and a data grid of the reviews.

You can filter the data to look at certain slices of the data in more detail! Just hit '+' in the top right to add some filters, and then use the green play button to filter the data down.

The copilot summary will automatically update to provide some more detail of the reviews that meet your filter criteria.

Check out the [explainer video](https://www.loom.com/share/7b6d309a4c5d4894894a0b7e421da3db).

If you think this is interesting, [book a chat with us here.](https://calendly.com/cotera-founders/30-minutes)`,
            Data.SliceBuilder(),
            TopFeatureRequests.chart.BarChart(
              (t) => ({
                x: t.attr('FEATURE_SUBCATEGORY'),
                y: t.attr('PERCENT'),
                category: t.attr('FEATURE_SUBCATEGORY'),
              }),
              {
                title: 'Top Feature Requests',
                format: {
                  y: '%',
                },
              }
            ),
            UI.Divider(),
            '# Copilot Summary',
            'This is an AI generated summary of the common feature requests in the current data view. Filter the data to update the summary!',
            `The AI can take a little bit to think, but it's worth it!`,
            MostRecent1000.chart.LLMSummary({
              prompt: summaryPrompt,
              model: 'o1-mini',
            }),
            UI.Block([Data]),
          ];
        }
      ),
    ];
  });
};

const summaryPrompt = `You are a CX analyst.
You are tasked with analyzing customer feedback to summarize feature requests. You will be presented with a series of reviews and your job is to extract the three most common feature request categories from it. Feature requests categories are provided in the FEATURE_SUBCATEGORY column.

Firstly, you should produce an overall summary of the kinds of features people are asking for. Use data from the FEATURE_REQUEST column for this.

For each of the feature request categories, that you think is most common:
- Pick the appropriate feature category from the FEATURE_SUBCATEGORY column
- Estimate the % of reviews that have this feature request..
- Provide a description of what the feature request is. Use data from the FEATURE_REQUEST column for this.
- Pick a quote from a ticket where they ask for this feature. Use the REVIEW column for this.`;
