import { useEffect, useRef } from 'react';

export const useNearBottom = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
  offset = 150
) => {
  const lastCallRef = useRef<number | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    const element = ref.current;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = element;
      const positionY = scrollHeight - scrollTop - clientHeight;

      if (positionY <= offset) {
        if (lastCallRef.current === null || lastCallRef.current !== positionY) {
          callback();
          lastCallRef.current = positionY;
        }
      }
    };

    element.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [ref, callback, offset]);
};
