import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import { Ty } from '@cotera/era';

export const DataPreview: React.FC<{
  records: Ty.Row[];
  columnName: string;
  renderers?: Record<string, (props: { value: string }) => React.ReactNode>;
  hideColumns?: string[];
}> = ({ records, columnName, renderers = {}, hideColumns = [] }) => {
  const columns = Object.keys(records[0] ?? {}).filter(
    (column) => !hideColumns.includes(column)
  );
  return (
    <table className="table-auto text-sm text-left rtl:text-right text-gray-500 overflow-x-scroll">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
        <tr>
          {columns.map((column, idx) => {
            return (
              <th
                key={idx}
                className={classNames(
                  'p-2',
                  column === columnName ? ColorScheme.text['primary'] : ''
                )}
                style={{
                  width: `${Math.max(150, 100 / columns.length)}%`,
                }}
              >
                {column}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {records.map((record, idx) => {
          return (
            <tr key={idx} className="border-b">
              {columns.map((column, idx) => {
                return (
                  <td key={idx} className="p-2 max-w-[300px]">
                    <p className="max-h-[80px] overflow-y-scroll break-words">
                      {renderers[column]
                        ? renderers[column]({ value: String(record[column]) })
                        : String(record[column])}
                    </p>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
