import { AST, Expression, TC } from '@cotera/era';
import { Operator } from './constants';
import { Connective, FilterGroup, FilterItem } from './types';
import { err, ok, Result } from 'neverthrow';
import { Assert } from '@cotera/utilities';
import { makeDefaultFilterGroup } from './filter-builder';

// Helper to generate unique IDs for FilterItem
const generateId = (() => {
  let counter = 0;
  return () => `item-${counter++}`;
})();

const NOT_MAPPING = {
  'does not exist': 'exists',
  'not equals': 'equals',
  'greater than': 'less than',
  'less than': 'greater than',
  contains: 'does not contain',
  exists: 'does not exist',
  equals: 'not equals',
  'does not contain': 'contains',
} as const;

const SUPPOPRTED_OPERATORS = [
  'equals',
  'not equals',
  'greater than',
  'less than',
  'contains',
  'does not exist',
] as const;

type SupportedOperator = (typeof SUPPOPRTED_OPERATORS)[number];

// Helper to determine the operator type
const getOperator = (
  op: AST.FunctionIdentifier,
  ctx: 'not' | 'identity'
): Operator => {
  const makeOp = (): SupportedOperator => {
    switch (op) {
      case 'eq':
        return 'equals';
      case 'neq':
        return 'not equals';
      case 'gt':
        return 'greater than';
      case 'lt':
        return 'less than';
      case 'like':
        return 'contains';
      case 'is_null':
        return 'does not exist';
      default:
        throw new Error(`Unsupported operator: ${op}`);
    }
  };

  const operator: SupportedOperator = makeOp();

  return ctx === 'not' ? NOT_MAPPING[operator] : operator;
};

function parseArgs(args: AST.ExprFR[]): (string | null)[] {
  return args.map((arg) => (arg.t === 'scalar' ? String(arg.val) : null));
}

// Recursive function to parse the AST
function parseAst(ast: AST.ExprFR, ctx: 'not' | 'identity'): FilterGroup {
  if (ast.t === 'function-call' && ast.op === 'not') {
    return parseAst(ast.args[0]!, 'not');
  }

  if (ast.t === 'function-call' && ast.op === 'is_null') {
    Assert.assert(ast.args[0]!.t === 'attr', 'Expected attribute');
    return {
      connective: 'and',
      items: [
        {
          id: generateId(),
          value: {
            key: ast.args[0]!.name,
            value: [null],
            operator: getOperator(ast.op, ctx),
          },
        },
      ],
    };
  }
  if (ast.t === 'function-call' && (ast.op === 'and' || ast.op === 'or')) {
    return {
      connective: ast.op as Connective,
      items: ast.args
        .map((arg) => {
          const parsedItem = parseAst(arg, ctx);
          return parsedItem.items.length === 1 && parsedItem.items[0]!.value
            ? parsedItem.items[0] // Single item with a value
            : ({ group: parsedItem, id: generateId() } as FilterItem); // Nested FilterGroup
        })
        .filter((x) => x !== undefined) as FilterItem[],
    };
  } else if (ast.t === 'function-call' && ast.op) {
    const [attr, value] = ast.args;
    if (ast.op === 'one_of') {
      Assert.assert(attr!.t === 'attr', 'Expected attribute');
      Assert.assert(value!.t === 'make-array', 'Expected array');

      return {
        connective: 'and',
        items: [
          {
            id: generateId(),
            value: {
              key: attr!.name,
              value: value.elements.map((x) => parseArgs([x])).flat(),
              operator: 'one-of',
            },
          },
        ],
      };
    }
    if (attr!.t === 'attr' && value!.t === 'scalar') {
      return {
        connective: 'and', // Default connective for single item group
        items: [
          {
            id: generateId(),
            value: {
              key: attr.name,
              value: parseArgs([value]),
              operator: getOperator(ast.op, ctx),
            },
          },
        ],
      };
    }
  } else if (ast.t === 'attr' && TC.isBoolean(ast.ty)) {
    return {
      connective: 'and',
      items: [
        {
          id: generateId(),
          value: {
            key: ast.name,
            value: ['true'],
            operator: 'equals',
          },
        },
      ],
    };
  } else if (ast.t === 'scalar' && ast.val === true) {
    return makeDefaultFilterGroup();
  }

  throw new Error('Unsupported AST structure');
}

export function convertToFilterGroup(
  data: Expression
): Result<FilterGroup, Error> {
  try {
    return ok(parseAst(data.ast, 'identity') as FilterGroup);
  } catch (e) {
    return err(e as Error);
  }
}
