import { useSqlInWarehouseDialect } from '@cotera/client/app/hooks';
import { Modal, Loading, toast } from '@cotera/client/app/components/ui';
import { Relation, Ty } from '@cotera/era';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { assertOk } from '@cotera/utilities';
import { useDefinition } from '@cotera/client/app/etc/data/manifest';
import { Card } from '@cotera/client/app/components/headless';

export const ViewSql: React.FC<{
  rel: Relation;
  open: boolean;
  onChange: (open: boolean) => void;
}> = ({ rel, open, onChange }) => {
  return (
    <Modal
      priority="high"
      open={open}
      onClose={(val) => onChange(val)}
      contentClassName="w-[70%]"
    >
      <ShowSql rel={rel} />
    </Modal>
  );
};

export const ViewSqlForRelId: React.FC<{
  relId: string;
  open: boolean;
  onChange: (open: boolean) => void;
}> = ({ relId, open, onChange }) => {
  const def = useDefinition({ id: relId });

  return (
    <Modal
      priority="high"
      open={open}
      onClose={(val) => onChange(val)}
      contentClassName="w-[70%]"
    >
      {def.data.isErr() ? (
        <Card.Container>
          <Card.Title>Error</Card.Title>
          <Card.Content>{def.data.error.errorType}</Card.Content>
        </Card.Container>
      ) : (
        <ShowSql rel={Relation.wrap(assertOk(def.data))} />
      )}
    </Modal>
  );
};

export const ShowSql: React.FC<{ rel: Relation }> = ({ rel }) => {
  const sql = useSqlInWarehouseDialect({ rel });

  return <View data={sql.data} />;
};

const View: React.FC<{
  data?: {
    dialect: string;
    sql: string;
    params: readonly Ty.Scalar[];
  };
}> = ({ data }) => {
  return (
    <Card.Container bottomPadding={false} noBackground>
      <Card.Header>
        <Card.Title className="flex items-center">
          SQL{data ? `- ${data.dialect}` : ''}
        </Card.Title>
      </Card.Header>
      <Card.Content>
        {data ? (
          <pre className="w-full p-2 rounded bg-gray-100 border border-divider font-mono overflow-auto my-2 max-h-[500px]">
            {data.sql}
          </pre>
        ) : (
          <Loading.Shimmer className="w-full h-[50px]" />
        )}
        <details>
          <summary>Parameters</summary>
          <pre className="w-full p-2 rounded bg-gray-100 border border-divider font-mono overflow-auto my-2 max-h-[500px]">
            {JSON.stringify(data?.params ?? [], null, 2)}
          </pre>
        </details>
        <ClipboardIcon
          className="ml-auto h-6 w-6 text-gray-500 cursor-pointer mt-2"
          onClick={async () => {
            if (data) {
              await navigator.clipboard.writeText(data.sql);
              toast.info('Copied query to clipboard');
            }
          }}
        />
      </Card.Content>
    </Card.Container>
  );
};
