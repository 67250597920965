import { EntityColumnAssumptionTableRef } from '@cotera/api';
import { Constant, Relation, Ty, Eq, From } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { err, ok, Result } from 'neverthrow';
import {
  MANIFEST_EAGER_DEF,
  MANIFEST_INFORMATATION_SCHEMA_NAME,
} from '../runtime';
import _ from 'lodash';
import type { CoteraCol, ResolutionError, ViewResolution } from './cotera-col';
import { AssumptionConfig, PrimaryKeyConfig } from './config';

const STANDARD_PRIMARY_KEY_NAME = '__ID';

type AssumptionView =
  | 'WAREHOUSE_TYPE'
  | 'WAREHOUSE_TABLE_NAME'
  | 'WAREHOUSE_TABLE_SCHEMA'
  | 'WAREHOUSE_COLUMN_NAME';

const ASSUMPTION_VIEW: Record<
  AssumptionView,
  (ref: EntityColumnAssumptionTableRef) => string
> = {
  WAREHOUSE_TYPE: (ref) => ref.warehouseTypeName,
  WAREHOUSE_TABLE_NAME: (ref) => ref.tableName,
  WAREHOUSE_TABLE_SCHEMA: (ref) => ref.tableSchema,
  WAREHOUSE_COLUMN_NAME: (ref) => ref.tableColName,
};

export const ASSUMPTION_VIEW_TYS: Record<
  AssumptionView,
  Ty.ExtendedAttributeType
> = {
  WAREHOUSE_TYPE: Ty.ty('string'),
  WAREHOUSE_TABLE_NAME: Ty.nn('string'),
  WAREHOUSE_TABLE_SCHEMA: Ty.nn('string'),
  WAREHOUSE_COLUMN_NAME: Ty.nn('string'),
};

const isAssumptionView = (x: string): x is AssumptionView =>
  (ASSUMPTION_VIEW as any)[x] !== undefined;

export class Assumption implements CoteraCol {
  static fromRef(params: {
    readonly name: string;
    readonly ty: Ty.ExtendedAttributeType;
    readonly ref: EntityColumnAssumptionTableRef;
  }) {
    const { name, ty, ref } = params;
    return new this({ name, ty, config: { t: 'assumption', ref } });
  }

  constructor(
    private readonly params: {
      readonly name: string;
      readonly ty: Ty.ExtendedAttributeType;
      readonly config: AssumptionConfig;
    }
  ) {}

  serialize(): AssumptionConfig {
    return this.params.config;
  }

  rename(): AssumptionConfig {
    return this.serialize();
  }

  get ref() {
    return this.params.config.ref;
  }

  get ty() {
    return this.params.ty;
  }

  get name() {
    return this.params.name;
  }

  availableViews(): Record<string, Ty.ExtendedAttributeType> {
    return ASSUMPTION_VIEW_TYS;
  }

  resolve(
    target: 'default' | { view: string }
  ): Result<ViewResolution, ResolutionError> {
    if (target === 'default') {
      return ok({ t: 'assume', assume: this });
    }

    const { view } = target;

    if (isAssumptionView(view)) {
      const fn = ASSUMPTION_VIEW[view];
      const expr = Constant(fn(this.ref), { ty: 'string' });
      return ok({ t: 'apply', apply: { t: 'expr', expr }, dependsOn: [] });
    }

    return err({ t: 'view-not-found', name: this.name, view });
  }

  eventStream(): null {
    return null;
  }

  static mergeAssumptions(
    pk: PrimaryKey,
    assumptions: Assumption[]
  ): Result<Relation, ResolutionError> {
    const assumptionTables: {
      [schema: string]: {
        [table: string]: {
          primaryKeyColName: string;
          attributes: {
            [name: string]: {
              ty: Ty.ExtendedAttributeType;
              targetName: string;
            };
          };
          containsPrimaryKey: boolean;
        };
      };
    } = {};

    for (const assumption of [...assumptions, pk]) {
      const { tableName, tableSchema, primaryKeyColName, tableColName } =
        assumption.ref;
      const schema = assumptionTables[tableSchema] ?? {};
      const table = schema[tableName];

      if (
        table?.primaryKeyColName &&
        table.primaryKeyColName !== primaryKeyColName
      ) {
        return err({ t: 'assumption-error' });
      }

      const containsPrimaryKey =
        table?.containsPrimaryKey || assumption instanceof PrimaryKey;

      schema[tableName] = {
        attributes: {
          ...table?.attributes,
          [tableColName]: {
            ty: assumption.ty,
            targetName: assumption.name,
          },
        },
        containsPrimaryKey,
        primaryKeyColName: table?.primaryKeyColName ?? primaryKeyColName,
      };
      assumptionTables[tableSchema] = schema;
    }

    const builtAssumptions: { source: Relation; primary: boolean }[] =
      Object.entries(assumptionTables).flatMap(([schema, tables]) =>
        Object.entries(tables).map(([name, def]) => {
          const { containsPrimaryKey, primaryKeyColName } = def;
          const attributes = {
            ..._.mapValues(def.attributes, (x) => x.ty),
            [primaryKeyColName]: pk.idType,
          };

          let source: Relation =
            schema === MANIFEST_INFORMATATION_SCHEMA_NAME
              ? MANIFEST_EAGER_DEF({ name, attributes })
              : From({ name, schema, attributes });

          source = From(source)
            .select(
              (t) => ({
                ...(primaryKeyColName in def.attributes
                  ? t.star()
                  : t.except(primaryKeyColName)),
                [STANDARD_PRIMARY_KEY_NAME]: t.attr(primaryKeyColName),
              }),
              { condition: (t) => t.attr(primaryKeyColName).isNotNull() }
            )
            .select((t) => ({
              //exclude the aliased columns as the will be included by the following line under their aliased name
              ...t.except(...Object.keys(def.attributes)),
              ...Object.fromEntries(
                Object.entries(def.attributes).map(([name, { targetName }]) => [
                  targetName,
                  t.attr(name),
                ])
              ),
            }));

          return { source, primary: containsPrimaryKey };
        })
      );

    const sorted = _.sortBy(builtAssumptions, (x) => x.primary).map(
      (x) => x.source
    );

    return ok(
      sorted
        .reduce((curr, next) =>
          curr.leftJoin(next, (l, r) => ({
            on: Eq(
              l.attr(STANDARD_PRIMARY_KEY_NAME),
              r.attr(STANDARD_PRIMARY_KEY_NAME)
            ),
            select: {
              ...l.star(),
              ...r.star(),
              [STANDARD_PRIMARY_KEY_NAME]: l.attr(STANDARD_PRIMARY_KEY_NAME),
            },
          }))
        )
        .select((t) => t.except(STANDARD_PRIMARY_KEY_NAME))
    );
  }
}

export class PrimaryKey extends Assumption implements CoteraCol {
  static override fromRef(params: {
    ty: Ty.IdType | Ty.ExtendedAttributeType;
    ref: EntityColumnAssumptionTableRef;
  }): PrimaryKey {
    const { ty, ref } = params;
    return new this({ ty, config: { t: 'assumption', ref, primary: true } });
  }

  idType: Ty.IdType;

  constructor(params: {
    ty: Ty.IdType | Ty.ExtendedAttributeType;
    config: PrimaryKeyConfig;
  }) {
    const { ty, config } = params;
    const nnTy = Ty.nn(ty);
    super({ name: 'id', config, ty: nnTy });
    Assert.assert(nnTy.ty.k === 'id');
    this.idType = nnTy.ty;
  }
}
