import { AST } from '../../ast';
import { Assert } from '../../utils';

export const assertConstantString = <T extends string>(
  expr: AST.ExprIR,
  options: readonly [T, ...T[]]
): T => {
  Assert.assert(expr.t === 'scalar' && typeof expr.val === 'string');
  const opt = options.find((x) => x === expr.val);
  Assert.assert(opt !== undefined);
  return opt;
};
