import React from 'react';
import { ChartLayout } from '../layout';
import { ChartProps } from '../types';
import { ChartContext } from '../context';
import { makeChartContextHook } from '../context/context';
import {
  Text,
  Loading,
  If,
  Divider,
  LegendItem,
} from '@cotera/client/app/components/ui';
import { Tooltip } from '../tooltip';
import sortBy from 'lodash/sortBy';
import {
  Formatters,
  makeValueFormatter,
} from '@cotera/client/app/components/utils';
import { uniqBy } from 'lodash';

const ChartLoading = () => (
  <div className="w-full">
    <Loading.Shimmer className="h-10 w-full mb-2" />
    <div className="flex justify-between mb-2">
      <Text.Caption className="text-xs">Category</Text.Caption>
      <Text.Caption className="text-xs">Value</Text.Caption>
    </div>
    <Divider className="mb-4" />
    <div className="flex flex-col">
      <div className="flex justify-between">
        <Loading.Shimmer className="h-3 mb-4 w-[25%]" />
        <Loading.Shimmer className="h-3 mb-4 w-[15%]" />
      </div>
      <div className="flex justify-between">
        <Loading.Shimmer className="h-3 mb-4 w-[25%]" />
        <Loading.Shimmer className="h-3 mb-4 w-[15%]" />
      </div>
      <div className="flex justify-between">
        <Loading.Shimmer className="h-3 mb-4 w-[25%]" />
        <Loading.Shimmer className="h-3 mb-4 w-[15%]" />
      </div>
    </div>
  </div>
);

type Datum = {
  category: string;
  value: number;
};

export const BreakdownBar: React.FC<ChartProps<Datum>> = ({
  chartHeight,
  data,
  loading,
  format,
  ...layoutProps
}) => {
  return (
    <ChartContext data={data} labelKeys={['category']}>
      <ChartLayout
        {...layoutProps}
        menuItems={[]}
        hideLabels
        titleBorder={false}
      >
        <If condition={loading ?? false}>
          <div
            style={{ height: chartHeight }}
            className="flex flex-col w-full justify-center items-center"
          >
            <ChartLoading />
          </div>
        </If>
        <If condition={!loading && data.length > 0}>
          <Chart format={format} />
        </If>
      </ChartLayout>
    </ChartContext>
  );
};

const useTypedChartContext = makeChartContextHook<Datum>();

const Chart: React.FC<Pick<ChartProps<Datum>, 'format' | 'onLegendClick'>> = ({
  format,
  onLegendClick,
}) => {
  const data = useTypedChartContext((s) => s.data);
  const labels = useTypedChartContext((s) => s.labels);
  const hiddenLabels = useTypedChartContext((s) => s.hiddenItems);
  const toggle = useTypedChartContext((s) => s.actions.toggle);
  const focus = useTypedChartContext((s) => s.actions.focus);
  const clearFocus = useTypedChartContext((s) => s.actions.clearFocus);

  const total = data.reduce((acc, d) => acc + d.value, 0);

  const dataWithPercentage = sortBy(
    data.map((d) => ({
      ...d,
      percentage: d.value / total,
    })),
    (x) => x.percentage
  ).reverse();

  const sortedLabels = uniqBy(
    [
      ...dataWithPercentage.map(
        (d) => labels.find((l) => l.label === d.category)!
      ),
      ...hiddenLabels.map((l) => labels.find((x) => x.label === l)!),
    ],
    'label'
  );

  const formatter = makeValueFormatter(format?.['value'] ?? 'number');

  return (
    <div className="flex flex-col w-full">
      <div className="flex rounded mb-4 w-full h-10 overflow-hidden">
        {dataWithPercentage.map((x, i) => {
          return (
            <Tooltip.Renderer
              key={i}
              content={
                <Tooltip.Container>
                  <Tooltip.Item>
                    <Tooltip.Dot
                      color={
                        labels.find((l) => l.label === x.category)?.color ?? ''
                      }
                    />
                    <strong>{x.category}</strong>:&nbsp;
                    <span className="mr-1 inline-block">
                      {formatter(x.value)}
                    </span>
                    <Text.Caption>
                      | {Formatters.number(x.percentage * 100, '%')}
                    </Text.Caption>
                  </Tooltip.Item>
                </Tooltip.Container>
              }
            >
              <div
                key={i}
                className="grow-0 shrink-0 bg-gray-200 h-full last:rounded-r-md first:rounded-l-md transition-all duration-100"
                style={{
                  width: `${x.percentage * 100}%`,
                  background: labels.find((l) => l.label === x.category)?.color,
                }}
              />
            </Tooltip.Renderer>
          );
        })}
      </div>
      <div className="flex justify-between border-b border-divider pb-2 mb-4">
        <Text.Caption className="text-xs">Category</Text.Caption>
        <Text.Caption className="text-xs">Value</Text.Caption>
      </div>
      <table className="table-auto">
        <tbody>
          {sortedLabels.map((x, i) => {
            const datum = dataWithPercentage.find(
              (d) => d.category === x.label
            );
            return (
              <tr key={i} className="mb-2">
                <td className="min-w-max w-full pb-2">
                  <LegendItem
                    label={x.label}
                    color={x.color}
                    hidden={hiddenLabels.includes(x.label)}
                    onClick={() => {
                      //if is focused, unfocus
                      if (labels.every((l) => l.label === x.label)) {
                        clearFocus();
                        onLegendClick?.(
                          labels.map((l) => ({
                            id: l.label,
                            hidden: false,
                          }))
                        );
                      } else {
                        const hidden = toggle(x.label);
                        onLegendClick?.(
                          labels.map((l) => {
                            return {
                              id: l.label,
                              label: l.label,
                              hidden: hidden.includes(l.label),
                            };
                          })
                        );
                      }
                    }}
                    onDoubleClick={() => {
                      focus(x.label);
                      onLegendClick?.(
                        labels.map((l) => ({
                          id: l.label,
                          hidden: l.label !== x.label,
                        }))
                      );
                    }}
                    as="div"
                  />
                </td>
                <td className="text-right w-auto whitespace-nowrap pb-2">
                  <Text.Caption className="text-xs mr-4">
                    {datum && (
                      <span className="font-medium">
                        {formatter(datum.value)}
                      </span>
                    )}
                  </Text.Caption>
                </td>
                <td className="text-right w-auto whitespace-nowrap pb-2">
                  <Text.Caption className="text-xs">
                    {datum && Formatters.number(datum.percentage * 100, '%')}
                  </Text.Caption>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
