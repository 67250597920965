import { Entity } from '@cotera/api';
import { Watchable, WatchableViewModel } from '@cotera/client/app/etc';
import { Case, Constant, EraQL, Expression, Relation, TC } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { v4 } from 'uuid';

export type ExpressionInput = {
  textValue?: string;
  expr: {
    tc: TC.ExprTypeCheck;
    expr: Expression;
  };
  error?: {
    t: string;
    msg: string;
  };
};

export class ExpressionItem extends Watchable {
  private _filter: ExpressionInput;
  constructor(
    readonly id: string,
    filter: ExpressionInput | null,
    private _then: ExpressionInput & { textValue: string }
  ) {
    super();
    this._filter = filter ?? {
      expr: {
        tc: TC.checkExpr(Constant(false).ir()) as TC.ExprTypeCheck,
        expr: Constant(false),
      },
    };
  }

  static from(item: ExpressionItem) {
    return new ExpressionItem(item.id, item.filter, item.then);
  }

  get name() {
    return this._then.textValue;
  }

  get filter() {
    return this._filter;
  }

  get then() {
    return this._then;
  }

  setFilter(
    filter: {
      tc: TC.ExprTypeCheck;
      expr: Expression;
    } | null,
    textValue?: string,
    error?: { t: string; msg: string }
  ) {
    let expr = this._filter.expr;
    if (
      filter?.expr.ty.ty.k !== 'primitive' ||
      filter?.expr.ty.ty.t !== 'boolean'
    ) {
      error = {
        t: 'Type Error',
        msg: 'Filter expression must be of type boolean',
      };
    } else {
      expr = filter;
    }
    this._filter = {
      expr,
      textValue: textValue ?? EraQL.exprToEraQL(expr.expr.ir()),
      error,
    };

    this.notifySubscribers();

    return this;
  }

  setThen(
    then: {
      tc: TC.ExprTypeCheck;
      expr: Expression;
    } | null,
    textValue: string,
    error?: { t: string; msg: string }
  ) {
    const existing = this._then.expr;
    this._then = {
      expr:
        then !== null
          ? {
              tc: then.tc,
              expr: then.expr,
            }
          : existing,
      error,
      textValue,
    };

    this.notifySubscribers();

    return this;
  }

  override isEqual(other: ExpressionItem) {
    return JSON.stringify(this) === JSON.stringify(other);
  }
}

export class DecisionTreeViewModel extends Watchable {
  readonly isNew: boolean = true;
  private _items: Record<string, ExpressionItem> = {};

  constructor(
    readonly entity: Entity,
    private relVm: WatchableViewModel<{
      rel: Relation;
      baseRel: Relation;
      isRelReady(): boolean;
    }>,
    private _columnName: string = '',
    readonly savedEraQl?: string
  ) {
    super();
    this.isNew = _columnName.length === 0;

    if (savedEraQl) {
      const parsedCaseStatement = EraQL.parseExpr(savedEraQl, {
        attributes: relVm.baseRel.attributes,
      }).map((expr) => {
        Assert.assert(expr.t === 'case');

        return expr.cases.map((c) => {
          const id = v4();
          const filter = c.when;
          const then = c.then;

          return new ExpressionItem(
            id,
            {
              expr: {
                tc: TC.checkExpr(filter) as TC.ExprTypeCheck,
                expr: Expression.fromAst(filter),
              },
              textValue: EraQL.exprToEraQL(filter),
            },
            {
              expr: {
                tc: TC.checkExpr(then) as TC.ExprTypeCheck,
                expr: Expression.fromAst(then),
              },
              textValue: EraQL.exprToEraQL(then).slice(1, -1), //removes the wrapping single quotes
            }
          );
        });
      });

      Assert.assert(parsedCaseStatement.isOk());

      this._items = parsedCaseStatement.value.reduce((acc, item) => {
        return {
          ...acc,
          [item.id]: item,
        };
      }, {});

      this.items.forEach((item) => {
        item.subscribe(() => {
          this.notifySubscribers();
        });
      });
    }

    this.relVm.subscribe(() => {
      this.notifySubscribers();
    });
  }

  get isReady() {
    return this.relVm.isRelReady();
  }

  get rel() {
    return this.relVm.rel;
  }

  get baseRel() {
    return this.relVm.baseRel;
  }

  get columnName() {
    return this._columnName;
  }

  get items() {
    return Object.values(this._items);
  }

  get caseEraQl() {
    return EraQL.exprToEraQL(this.caseExpression.ir());
  }

  get caseExpression() {
    return this.items.length === 0
      ? Constant(false)
      : Case(
          this.items.map((item) => ({
            when: item.filter.expr.expr,
            then: item.then.expr.expr,
          }))
        );
  }

  get errors() {
    return {
      columnName:
        this._columnName.length === 0 ? 'Column name is required' : undefined,
      empty:
        this.items.length === 0 ? 'At least one item is required' : undefined,
      items:
        this.items
          .map((item) => item.filter.error?.msg ?? item.then.error?.msg)
          .filter((x) => x !== undefined).length > 0
          ? 'Some items have errors'
          : undefined,
    };
  }

  moveItem(from: number, to: number) {
    if (from === to) {
      return;
    }
    const items = Object.values(this._items);
    const item = items[from]!;
    const newItems = [...items.slice(0, from), ...items.slice(from + 1)];
    newItems.splice(to, 0, item);

    this._items = newItems.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item,
      };
    }, {});

    this.notifySubscribers();
  }

  hasErrors() {
    return Object.values(this.errors).some((x) => x !== undefined);
  }

  getItem(id: string) {
    return this._items[id];
  }

  clear() {
    if (this.isNew) {
      this._columnName = '';
      this._items = {};
      this.notifySubscribers();
    }
  }

  addItem(name: string) {
    const id = v4();
    const checked = TC.checkExpr(Constant(name).ir());

    const expressionItem = new ExpressionItem(id, null, {
      expr: {
        tc: checked as TC.ExprTypeCheck,
        expr: Constant(name),
      },
      textValue: name,
    });
    expressionItem.subscribe(() => {
      this.notifySubscribers();
    });

    this._items = {
      ...this._items,
      [id]: expressionItem,
    };

    this.notifySubscribers();
  }

  removeItem(id: string) {
    const { [id]: _, ...rest } = this._items;
    this._items = rest;
    this.notifySubscribers();
  }

  setColumnName(columnName: string) {
    this._columnName = columnName;
    this.notifySubscribers();
  }
}
