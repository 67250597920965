import {
  BaseWorkspaceNodeViewModel,
  TransformableRelation,
  NodeTransform,
} from './base';
import { Divider } from '@cotera/client/app/components/ui';
import { v4 } from 'uuid';
import { startTransition, Suspense, useEffect } from 'react';
import { useLoadArtifact } from '@cotera/client/app/etc/data/duckdb';
import { Relation } from '@cotera/era';

export class DatasetViewModel extends BaseWorkspaceNodeViewModel {
  t = 'dataset';
  private _artifactRel?: TransformableRelation;
  type: 'sample' | 'full' = 'sample';
  private _artifactId: string | null = null;

  override readonly transforms: NodeTransform[] = [];

  constructor(
    name: string,
    position: { x: number; y: number },
    private _rel: TransformableRelation
  ) {
    super(name, v4(), position);
  }

  get originalRel() {
    return this._rel;
  }

  get rel() {
    if (this.type === 'sample' && this._artifactRel !== undefined) {
      return this._artifactRel;
    }

    return this.originalRel;
  }

  override getRel(type: 'source' | 'artifact'): Relation {
    if (type === 'source') {
      return this.originalRel;
    }

    return this._artifactRel ?? this.originalRel;
  }

  override get artifactId() {
    return this._artifactId;
  }

  registerArtifact(rel: TransformableRelation, artifactId: string | null) {
    this._artifactRel = rel;
    this._artifactId = artifactId;

    startTransition(() => {
      this.notifySubscribers();
    });
  }

  updateType(type: 'sample' | 'full') {
    this.type = type;
  }
}

const View: React.FC<{ node: DatasetViewModel }> = ({ node }) => {
  return (
    <>
      <Divider caption={node.name} />
      <Suspense fallback={null}>
        <DataLoader node={node} />
      </Suspense>
    </>
  );
};

const DataLoader = ({ node }: { node: DatasetViewModel }) => {
  const { data } = useLoadArtifact({
    rel: node.originalRel,
  });

  useEffect(() => {
    node.registerArtifact(
      new TransformableRelation(
        data.artifactRel.ast,
        data.artifactRel.typecheck
      ),
      data.fromArtifactId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.artifactRel.sqlHash()]);

  return null;
};

export const DatasetNode = {
  ViewModel: DatasetViewModel,
  View,
};
