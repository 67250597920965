import React, { Suspense, useState } from 'react';
import { useReasons } from './hooks';
import { Loading, Modal } from '../../components';
import { List, Card } from '@cotera/client/app/components/headless';
import { useFuzzySearch } from '../../hooks/use-fuzzy-search';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import { classNames } from '../../components/utils';
import { Layout } from '../../layout';
import {
  Divider,
  Button,
  Title,
  Text,
  Section,
  Icon,
} from '@cotera/client/app/components/ui';
import Sticky from 'react-sticky-el';
import { Link } from 'react-router-dom';
import { toast } from '../../components/ui/toaster';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Assert } from '@cotera/utilities';
import { useIdeConfig } from '../../hooks/entities';

const useAdd = () => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();
  const orgId = useWhoami((s) => s.org.id);

  return useMutation({
    mutationFn: async ({
      name,
      entityId,
    }: {
      name: string;
      entityId: string;
    }) => Assert.assertOk(await client.reasons.add({ name, entityId })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['reasons', orgId],
      });
    },
  });
};

export const ListReasons: React.FC = () => {
  const config = useIdeConfig();

  return (
    <Layout>
      <Section direction="vertical" className="w-full">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex justify-between w-2/3 mb-4 items-center">
            <Title title="Manage Reasons" type="title" />
            <AddKeyword entities={config.data.entities} />
          </div>
          <Suspense fallback={<Loading.Dots />}>
            <View />
          </Suspense>
        </div>
      </Section>
    </Layout>
  );
};

const AddKeyword: React.FC<{
  entities: { id: string; name: string }[];
}> = ({ entities }) => {
  const [value, setValue] = useState('');
  const [entity, setEntity] = useState<{
    value: string;
    display: string;
  }>();
  const [open, setOpen] = useState(false);
  const add = useAdd();

  return (
    <>
      <Button
        icon="plus"
        text="Add Reason"
        theme="secondary"
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} priority="high" onClose={setOpen} padding={false}>
        <Section direction="vertical" className="bg-white">
          <Title title="Add Reason" type="section" className="mb-4" />
          <Divider className="mb-4" />
          <form onSubmit={(e) => e.preventDefault()}>
            <ComboBox.Single
              label="Entity"
              value={entity}
              onChange={setEntity}
              className="mb-2"
              options={({ query }) => (
                <ComboBox.StaticOptions
                  options={entities.map((x) => ({
                    value: x.id,
                    display: x.name,
                  }))}
                  query={query}
                />
              )}
            />
            <Inputs.Text
              label="Name"
              value={value}
              onChange={setValue}
              className="mb-4"
            />
            <div className="flex justify-end">
              <Button
                text="Save"
                theme="primary"
                disabled={!value || !entity}
                onClick={async () => {
                  await add.mutateAsync({
                    name: value,
                    entityId: entity!.value,
                  });
                  toast.success('Reason added');
                }}
              />
            </div>
          </form>
        </Section>
      </Modal>
    </>
  );
};

const View: React.FC = () => {
  const { data } = useReasons();
  const [searchValue, setSearchValue] = useState('');
  const search = useFuzzySearch(data ?? [], ['name']);
  const results = searchValue.length > 0 ? search(searchValue) : data;

  return (
    <div className="flex flex-col w-2/3">
      <Sticky
        mode="top"
        wrapperClassName="z-10 relative"
        scrollElement={'.main'}
        stickyClassName="bg-white z-10 border-b-2 border-divider pt-4"
      >
        <Inputs.Text
          autoFocus
          icon="search"
          value={searchValue}
          onChange={setSearchValue}
          className="mb-4"
        />
      </Sticky>
      <Divider className="mb-4" />
      <Card.Container>
        <List.Ul className="pl-2 pr-4 py-2" hasFocus={true}>
          {results.map((item, i) => {
            return (
              <List.Li
                as={Link}
                key={i}
                to={`/reasons/${item.id}`}
                className={classNames(
                  'data-[focus]:text-indigo-300 transition-colors rounded cursor-pointer px-2 py-2 flex border-b boder-divder mb-2'
                )}
              >
                <li className="flex flex w-full  justify-between">
                  {item.name}
                  <div className="flex items-center">
                    <Text.Caption>{item.numExamples} examples</Text.Caption>
                    <Icon
                      theme="regular"
                      icon="chevron-right"
                      className="ml-2"
                    />
                  </div>
                </li>
              </List.Li>
            );
          })}
        </List.Ul>
      </Card.Container>
    </div>
  );
};
