import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';
import { useDrafts } from './inbox/context';

export const CrtlZ = () => {
  const popHistory = useDrafts((s) => s.pop);

  useKeyPress('z', (e) => {
    if (e.ctrlKey || e.metaKey) {
      popHistory();
    }
  });

  return null;
};
