import {
  Constant,
  Relation,
  Ty,
  Histogram as HistogramRel,
  TC,
} from '@cotera/era';
import { CategoricalAttributes } from '@cotera/sdk/core';
import React, { Suspense } from 'react';
import { NamedAttr } from './types';
import { Column, HeaderContext, CellContext, Row } from '@tanstack/react-table';
import { BaseCell, Cell } from './cell';
import { getActionName, isAction, isLink } from './utils';
import { z } from 'zod';
import { ErrorBoundary } from 'react-error-boundary';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Loading, Text, Tooltip } from '@cotera/client/app/components/ui';
import { Categorical, Histogram } from '@cotera/client/app/components/data-vis';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { METADATA_CONFIG } from '@cotera/client/app/etc/duckdb/constants';
import { FFI_ACTIONS } from './ffi-actions/registry';

type HeaderProps = {
  rel: Relation;
  attr: NamedAttr;
  column: Column<any, any>;
  filterValue?: string;
  summary?: {
    min: string | null;
    max: string | null;
    unique: number | null;
  };
  onClick?: (value: any) => void;
};

const HeaderContainer: React.FC<HeaderProps> = (props) => {
  return (
    <BaseHeader attr={props.attr}>
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorStats error={error} />}
      >
        <Suspense fallback={<LoadingStats />}>
          <HeaderView {...props} />
        </Suspense>
      </ErrorBoundary>
    </BaseHeader>
  );
};

const HeaderView: React.FC<HeaderProps> = (props) => {
  if (isLink(props.attr.ty)) {
    return <EmptyStats />;
  }

  const ty = Ty.shorthandToTy(props.attr.ty);

  if (ty.ty.k === 'array' || ty.ty.k === 'struct') {
    return <EmptyStats />;
  }

  if (ty.ty.k === 'enum') {
    return <CategoricalHeader {...props} />;
  }

  if (ty.ty.k === 'id') {
    return <CategoricalHeader {...props} />;
  }

  if (ty.ty.k === 'range') {
    return <ContinuousHeader {...props} />;
  }

  switch (ty.ty.t) {
    case 'string':
    case 'boolean':
      return <CategoricalHeader {...props} />;
    case 'int':
    case 'float':
      return <ContinuousHeader {...props} />;
    case 'timestamp':
      return <TimestampHeader {...props} />;
    default:
      return <EmptyStats />;
  }
};

export const Header = React.memo(
  HeaderContainer,
  (prev, next) =>
    next.filterValue === prev.filterValue &&
    next.rel.isAstEq(prev.rel) &&
    next.attr.name === prev.attr.name
);

export function createColumn<T = unknown>(props: {
  data: NamedAttr;
  header?: (props: HeaderContext<T, unknown>) => JSX.Element;
  cell?: (props: CellContext<T, unknown>) => JSX.Element;
}): Column<T, unknown> {
  const createFilterFn = (ty: Ty.Shorthand) => {
    if (Ty.isEnum(ty)) {
      return 'equalsString';
    }
    if (
      TC.implementsTy({
        subject: ty,
        req: 'string',
      })
    ) {
      return 'includesString';
    }

    if (
      TC.implementsTy({
        subject: ty,
        req: 'boolean',
      })
    ) {
      return (row: Row<unknown>, columnId: string, filterValue: any) =>
        String(row.getValue(columnId)) === String(filterValue);
    }

    if (
      TC.implementsTy({
        subject: ty,
        req: 'float',
      }) ||
      TC.implementsTy({
        subject: ty,
        req: 'int',
      })
    ) {
      return (row: Row<unknown>, columnId: string, filterValue: any) => {
        const { min, max } = filterValue;
        return (
          Number(row.getValue(columnId)) >= min &&
          Number(row.getValue(columnId)) <= max
        );
      };
    }

    return undefined;
  };

  return {
    header:
      props.header ?? (() => <BaseHeader attr={props.data}>{''}</BaseHeader>),
    accessorKey: props.data.name,
    enableMultiSort: true,
    filterFn: createFilterFn(props.data.ty),
    cell:
      (props.cell &&
        ((cell: CellContext<T, unknown>) => (
          <BaseCell links={{}} cell={cell}>
            {props.cell!(cell)}
          </BaseCell>
        ))) ??
      ((cell: CellContext<T, unknown>) => (
        <Cell cell={cell} attr={props.data} />
      )),
  } as unknown as Column<T, unknown>;
}

const mapType = (t: NamedAttr['ty']): React.ReactNode => {
  const ty = Ty.shorthandToTy(t);

  if (isAction(t)) {
    return FFI_ACTIONS[getActionName(t)].displayType;
  }

  if (isLink(t)) {
    return 'link';
  }

  const full = Ty.displayTy(ty);

  const inner = isLink(t)
    ? 'link'
    : ty.ty.k === 'primitive'
    ? ty.ty.t
    : ty.ty.k;

  if (ty.ty.k === 'primitive') {
    return <span>{inner}</span>;
  } else {
    return (
      <Tooltip side="top" text={full}>
        <span>{inner}</span>
      </Tooltip>
    );
  }
};

export const BaseHeader = ({
  children,
  attr,
}: {
  attr: NamedAttr;
  children?: ChildrenProps['children'];
}) => {
  return (
    <div className="flex relative flex-col px-3 w-full pb-2 bg-white">
      <div className="whitespace-nowrap font-medium inline-block w-[calc(100%-8px)] overflow-hidden text-ellipsis">
        {attr.name}
      </div>
      <Text.Caption className="mb-2 whitespace-nowrap text-ellipsis verflow-hidden">
        {mapType(attr.ty)}
      </Text.Caption>
      {children}
    </div>
  );
};

export const ContinuousHeader: React.FC<HeaderProps> = ({
  rel,
  attr,
  column,
  onClick,
}) => {
  const { data } = useDuckDBQuery({
    rel: HistogramRel(
      rel,
      (r) => ({ target: r.attr(attr.name), group: Constant('all') }),
      { numBuckets: 10 }
    ),
  });

  return (
    <div className="w-full h-[45px]">
      <Histogram
        activeBucket={(column.getFilterValue() as any) ?? {}}
        onClick={(min, max) => {
          const { min: cMin, max: cMax } =
            (column.getFilterValue() as any) ?? {};
          if (cMin === min && cMax === max) {
            column.setFilterValue(undefined);
          } else {
            column.setFilterValue({
              min,
              max,
            });
          }
          onClick?.({ min, max });
        }}
        buckets={data.data.toArrayOf(
          z.object({
            count: z.number().default(0),
            start: z.number().nullable(),
            end: z.number().nullable(),
          })
        )}
      />
    </div>
  );
};

const CategoricalHeader: React.FC<HeaderProps> = ({
  rel,
  attr,
  column,
  summary,
  onClick,
}) => {
  const result = useDuckDBQuery({
    rel: CategoricalAttributes(rel, (r) => r.attr(attr.name), {
      discreteThreshold: METADATA_CONFIG.MAX_CATEGORIES,
    }),
  });

  return (
    <div className="w-full h-[45px]">
      <Categorical
        categories={result.data.data.toArrayOf(
          z.object({ count: z.number(), value: z.string().nullable() })
        )}
        numDistinct={summary?.unique ?? 0}
        active={[column.getFilterValue() as string]}
        onClick={(c) => {
          if (column.getFilterValue() === c) {
            column.setFilterValue(undefined);
          } else {
            column.setFilterValue(String(c));
          }
          onClick?.(c);
        }}
      />
    </div>
  );
};

const TimestampHeader = ({ summary }: HeaderProps) => {
  return (
    <div className="flex flex-col w-full">
      {summary?.min && (
        <Text.Caption className="text-xs mb-1">
          Min: <Text.Date date={new Date(summary.min)} />
        </Text.Caption>
      )}
      {summary?.max && (
        <Text.Caption className="text-xs">
          Max: <Text.Date date={new Date(summary.max)} />
        </Text.Caption>
      )}
    </div>
  );
};

const LoadingStats = () => (
  <div className="h-[45px] flex flex-col">
    <Loading.Shimmer className="h-[25px] w-full mb-2" />
    <Loading.Shimmer className="h-[8px] w-1/2 " />
  </div>
);

const EmptyStats = () => <div className="h-[45px]"></div>;

const ErrorStats: React.FC<{ error?: any }> = ({ error }) => {
  return (
    <Tooltip text={String(error)} side="bottom">
      <div className="h-[45px] flex flex-col">
        <div className="h-[25px] w-full text-red-500 font-medium text-xs">
          Unable to load data
        </div>
      </div>
    </Tooltip>
  );
};
