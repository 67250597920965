import { Ty } from '@cotera/era';
import type { TC } from '@cotera/era';
import type { SinkConfig } from '@cotera/api';

export const SinkOutputTypeReqs: Partial<
  Record<SinkConfig['t'], TC.RelInterfaceShorthand>
> = {
  kustomerAddNoteToConversation: {
    conversation_id: [Ty.nn('string')],
    note_body: [Ty.nn('string')],
  },
};
