import { AST } from '../../ast';
import { displayTy } from '../../ty/ty';
import { Assert, Freeze } from '../../utils';
import { Constant, Expression, MakeStruct } from '../../builder';
import _ from 'lodash';

export const exprToEraQL = (ast: AST.ExprFR): string => {
  switch (ast.t) {
    case 'scalar': {
      switch (typeof ast.val) {
        case 'string':
          return `'${ast.val}'`;
        case 'bigint':
        case 'boolean':
        case 'number':
          return ast.val.toString();
        case 'object': {
          if (ast.val === null) {
            const ty = escapeStringLit(displayTy(ast.ty));
            return `null_of('${ty}')`;
          }
          if (ast.val instanceof Date) {
            return exprToEraQL(
              Constant(ast.val.toISOString()).cast('timestamp').ir()
            );
          }

          if (Freeze.isReadonlyArray(ast.val)) {
            Assert.assert(ast.ty.ty.k === 'array');
            const ty = ast.ty.ty.t;
            return exprToEraQL({
              t: 'make-array',
              elements: ast.val.map((x) => Constant(x, { ty }).ir()),
            });
          }

          const struct = ast.val;

          Assert.assert(ast.ty.ty.k === 'struct');

          return exprToEraQL({
            t: 'make-struct',
            fields: _.mapValues(ast.ty.ty.fields, (ty, fieldName) => {
              const v = struct[fieldName] ?? null;
              return Constant(v, { ty }).ir();
            }),
          });
        }
        default:
          throw new Error(`LOGIC BUG => UNREACHABLE ${ast.val}`);
      }
    }
    case 'attr': {
      return `"${ast.name}"`;
    }
    case 'make-array': {
      return `[${ast.elements.map((elem) => exprToEraQL(elem)).join(', ')}]`;
    }
    case 'get-field': {
      return `get(${exprToEraQL(ast.expr)}, '${escapeStringLit(ast.name)}')`;
    }
    case 'function-call': {
      const args = ast.args.map((arg) => exprToEraQL(arg));

      if (ast.op === 'empty_array_of') {
        const [ty, ..._rest] = ast.args;
        if (ty?.t === 'scalar' && ty.val === 'int') {
          return '[]';
        }
      } 

      const display = ast.display ?? 'pipe';
      switch (display) {
        case 'fc': {
          return `${ast.op}(${args.join(', ')})`;
        }
        case 'infix': {
          switch (ast.op) {
            case 'add':
              return `(${args.join(` + `)})`;
            case 'sub':
              return `(${args.join(` - `)})`;
            case 'mul':
              return `(${args.join(` * `)})`;
            case 'div':
              return `(${args.join(` / `)})`;
            case 'to_the_power_of':
              return `(${args.join(` ^ `)})`;
            case 'eq':
              return `(${args.join(` == `)})`;
            case 'neq':
              return `(${args.join(` != `)})`;
            case 'gte':
              return `(${args.join(` >= `)})`;
            case 'gt':
              return `(${args.join(` > `)})`;
            case 'lte':
              return `(${args.join(` <= `)})`;
            case 'lt':
              return `(${args.join(` < `)})`;
            case 'coalesce':
              return `(${args.join(` ?? `)})`;
            case 'and':
              return `(${args.join(` && `)})`;
            case 'or':
              return `(${args.join(` || `)})`;
            default:
              throw new Error(`"${ast.op}" can not be infix`);
          }
        }
        case 'pipe': {
          const [arg, ...rest] = args;
          if (arg === undefined) {
            return `${ast.op}()`;
          }
          return `${arg} |> ${ast.op}(${rest.join(', ')})`;
        }
        case 'postfix': {
          if (ast.op === 'tag') {
            // TODO: handle rendering nicer tags
            return `tag(${args.join(', ')})`;
          }
          throw new Error(`Cant display "${ast.op}" as postfix`);
        }
        default:
          return Assert.unreachable(display);
      }
    }
    case 'cast': {
      return `cast(${exprToEraQL(ast.expr)}, '${escapeStringLit(
        displayTy(ast.targetTy)
      )}')`;
    }
    case 'make-struct': {
      return renderStruct(ast);
    }
    case 'invariants': {
      return `invariants(${exprToEraQL(ast.expr)}, ${exprToEraQL({
        t: 'make-struct',
        fields: ast.invariants,
      })})`;
    }
    case 'case': {
      const cases = ast.cases.map(({ when, then }) => {
        const ir = MakeStruct({
          when: Expression.fromAst(when),
          then: Expression.fromAst(then),
        }).ir();

        Assert.assert(ir.t === 'make-struct');

        return renderStruct(ir, (a, b) => (a > b ? -1 : 1));
      });

      const elseClause = ast.else
        ? exprToEraQL(MakeStruct({ else: Expression.fromAst(ast.else) }).ir())
        : null;

      const args = _.compact([`[${cases.join(', ')}]`, elseClause]);

      return `case(${args.join(', ')})`;
    }
    case 'window':
      throw new Error('TODO');
    case 'expr-var':
    case 'macro-expr-case':
    case 'macro-apply-vars-to-expr':
      throw new Error(`TODO, handle ${ast.t}`);
    default:
      return Assert.unreachable(ast);
  }
};

const renderStruct = (
  ast: AST._MakeStruct<AST.ExprMacroChildren>,
  orderBy: (l: string, r: string) => -1 | 0 | 1 = (a, b) => (a > b ? 1 : -1)
): string => {
  const kvs = Object.entries(ast.fields)
    .sort(([l], [r]) => orderBy(l, r))
    .map(([name, val]) => `${escapeStructKey(name)}: ${exprToEraQL(val)}`)
    .join(', ');

  return `{ ${kvs} }`;
};

const escapeStructKey = (str: string): string => str;
const escapeStringLit = (str: string): string => str;
