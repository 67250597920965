import _ from 'lodash';
import { AST } from '../ast';
import {
  DATE_DIFF_UNITS,
  DATE_TRUNC_UNITS,
  MATH_FUNCTIONS,
} from '../ast/func-identifier';
import { Ty } from '../ty';
import { DateTime, DateTimeUnit } from 'luxon';
import { compareLiterals } from './compare-literals';
import { narrowestSuperTypeOf } from '../type-checker/implements';
import { v4 } from 'uuid';
import { ISO8601_REGEX, Freeze } from '../utils';
import { Expression } from '../builder/expression';
import { Assert } from '@cotera/utilities';
import { compileLikePattern } from './compile-like-pattern';
import { Constant, Eq, Or } from '../builder/utilities';
import { TC, TyStackTrace } from '../type-checker';
import { IRChildren } from '../ast/base';

const BINARY_FUNS: Record<
  Exclude<(typeof MATH_FUNCTIONS)[number], 'round'>,
  (l: number, r: number) => number
> = {
  add: (l, r) => l + r,
  sub: (l, r) => l - r,
  mul: (l, r) => l * r,
  div: (l, r) => l / r,
  to_the_power_of: (l, r) => Math.pow(l, r),
};

const PRIMITIVE_CAST_FUNS: Record<
  Ty.PrimitiveAttributeType,
  (x: Ty.Scalar) => Ty.Scalar
> = {
  string: (x) => {
    if (x instanceof Date) {
      return x.toISOString();
    }
    if (typeof x === 'string') {
      return x;
    }
    if (typeof x === 'number' || typeof x === 'boolean') {
      return x.toString();
    }

    return JSON.stringify(x);
  },
  boolean: (x) => {
    if (typeof x === 'number') {
      return x === 0 ? false : true;
    }

    if (typeof x === 'boolean') {
      return x;
    }

    if (x === 'false') {
      return false;
    }

    if (x === 'true') {
      return true;
    }

    throw new Error(`Unable to turn ${x} to a boolean`);
  },
  int: (x) => {
    if (typeof x === 'number') {
      // As far as we can tell, the SQL spec defines casting a float to an int
      // to round to the nearest integer
      return Math.round(x);
    }
    if (typeof x === 'string') {
      return parseInt(x, 10);
    }
    if (typeof x === 'boolean') {
      return x ? 1 : 0;
    }

    throw new Error(`Unable cast ${x} to an int`);
  },
  float: (x) => {
    if (typeof x === 'number') {
      return x;
    }
    if (typeof x === 'string') {
      return parseFloat(x);
    }

    throw new Error(`Unable cast ${x} to an float`);
  },
  super: (x) => {
    return x;
  },
  timestamp(x) {
    if (typeof x === 'string' && x.match(ISO8601_REGEX)) {
      return new Date(x);
    }
    if (x instanceof Date) {
      return x;
    }
    throw new Error(`Cannot convert ${x} to timestamp`);
  },
  day(x) {
    return this.timestamp(x);
  },
  month(x) {
    return this.timestamp(x);
  },
  year(x) {
    return this.timestamp(x);
  },
};

export type ExprEvalCtx = {
  sources: Partial<Record<'from' | 'left' | 'right', Ty.Row>>;
  rows: Ty.Row[];
};

type ExecExpr<T extends AST.ExprIR> = (expr: T, ctx: ExprEvalCtx) => Ty.Scalar;

export const evalExprIR = (
  expr: AST.ExprIR,
  ctx?: Partial<ExprEvalCtx>
): Ty.Scalar | null => {
  const { sources = {}, rows = [] } = ctx ?? {};

  switch (expr.t) {
    case 'scalar':
      return doDecodeScalar(expr, { sources, rows });
    case 'cast':
      return doEvalCast(expr, { sources, rows });
    case 'function-call':
      return doEvalFunctionCall(expr, { sources, rows });
    case 'get-field':
      return doGetField(expr, { sources, rows });
    case 'case':
      return doCase(expr, { sources, rows });
    case 'make-struct':
      return doMakeStruct(expr, { sources, rows });
    case 'make-array':
      return doMakeArray(expr, { sources, rows });
    case 'attr':
      return doAttr(expr, { sources, rows });
    case 'invariants':
      return doInvariants(expr, { sources, rows });
    case 'window':
      throw new Error(
        `Unable to evaluate expr ir of type "${expr.t}", this implies a logic error in the type checker`
      );
    default:
      return Assert.unreachable(expr);
  }
};

class InvariantError extends Error {
  constructor(readonly invariantName: string) {
    super();
  }
}

const doInvariants: ExecExpr<AST._Invariants<IRChildren>> = (
  expr,
  opts
): Ty.Scalar => {
  const child = evalExprIR(expr.expr, opts);
  for (const [name, invariant] of Object.entries(expr.invariants)) {
    const res = evalExprIR(invariant, opts);
    if (res !== true) {
      throw new InvariantError(name);
    }
  }
  return child;
};

const doAttr: ExecExpr<AST._Attribute> = (expr, opts): Ty.Scalar => {
  const row = assertScalarCtx(opts).sources[expr.source];
  if (row === undefined) {
    throw new Error(`Source "${expr.source}" Not Found`);
  }
  const attrVal = row[expr.name];
  if (attrVal === undefined) {
    throw new Error(
      `Attribute "${expr.name}" not found, only: [${Object.keys(row)
        .map((x) => `"${x}"`)
        .join(', ')}]`
    );
  }

  return attrVal;
};

const doCase: ExecExpr<AST._Case<IRChildren>> = (expr, opts): Ty.Scalar => {
  for (const { when, then } of expr.cases) {
    const cond = evalExprIR(when, opts);
    if (cond === null) {
      continue;
    }
    Assert.assert(typeof cond === 'boolean');
    if (cond) {
      return evalExprIR(then, opts);
    }
  }

  return expr.else ? evalExprIR(expr.else, opts) : null;
};

const doMakeArray: ExecExpr<AST._MakeArray<IRChildren>> = (
  expr,
  opts
): Ty.Scalar => expr.elements.map((elem) => evalExprIR(elem, opts));

const doMakeStruct: ExecExpr<AST._MakeStruct<IRChildren>> = (
  expr,
  opts
): Ty.Scalar => {
  return _.mapValues(expr.fields, (field) => evalExprIR(field, opts));
};

const doGetField: ExecExpr<AST._GetField<IRChildren>> = (
  expr,
  opts
): Ty.Scalar => {
  const from = evalExprIR(expr.expr, opts);
  if (from === null) {
    return null;
  }
  Assert.assert(
    typeof from === 'object' &&
      !(from instanceof Date) &&
      !Freeze.isReadonlyArray(from)
  );
  return from[expr.name] ?? null;
};

const doEvalCast: ExecExpr<AST._Cast<IRChildren>> = (expr, opts): Ty.Scalar => {
  const from = evalExprIR(expr.expr, opts);

  if (from === null) {
    return from;
  }

  if (expr.targetTy.k === 'primitive') {
    return PRIMITIVE_CAST_FUNS[expr.targetTy.t](from);
  }

  throw new Error(
    `TODO CAST FROM \`${JSON.stringify(from)}\` to ${Ty.displayTy(
      expr.targetTy
    )}`
  );
};

const doEvalFunctionCall: ExecExpr<AST._FunctionCall<IRChildren>> = (
  expr,
  ctx
): Ty.Scalar => {
  const evaledArgs = () => expr.args.map((arg) => evalExprIR(arg, ctx));
  const { op } = expr;

  switch (op) {
    // Math
    case 'add':
    case 'mul':
    case 'sub':
    case 'div':
    case 'to_the_power_of': {
      return evaledArgs().reduce((l, r) => {
        if (l === null || r === null) {
          return null;
        }

        Assert.assert(
          typeof l === 'number' && typeof r === 'number',
          'Math Requires Numbers'
        );

        return BINARY_FUNS[op](l, r);
      });
    }
    case 'is_numeric_string': {
      const [arg, ...rest] = evaledArgs();
      if (arg === null) {
        return null;
      }
      Assert.assert(
        arg !== undefined && typeof arg === 'string' && rest.length === 0,
        `${op} is a unary function that only works on strings`
      );

      return /^\d+$/.test(arg);
    }
    case 'ln':
    case 'log_2':
    case 'log_10':
    case 'abs':
    case 'is_nan':
    case 'floor':
    case 'ceil': {
      const [arg, ...rest] = evaledArgs();
      if (arg === null) {
        return null;
      }
      Assert.assert(
        arg !== undefined && typeof arg === 'number' && rest.length === 0,
        `${op} is a unary function that only works on strings`
      );

      switch (op) {
        case 'abs':
          return Math.abs(arg);
        case 'floor':
          return Math.floor(arg);
        case 'ceil':
          return Math.ceil(arg);
        case 'ln':
          return Math.log(arg);
        case 'log_2':
          return Math.log2(arg);
        case 'log_10':
          return Math.log10(arg);
        case 'is_nan':
          return isNaN(arg);
        default:
          return Assert.unreachable(op);
      }
    }
    case 'cosine_distance': {
      const [l, r, ...rest] = evaledArgs();
      if (l === null || r === null) {
        return null;
      }

      Assert.assert(
        l !== undefined &&
          Array.isArray(l) &&
          l.every((n) => typeof n === 'number') &&
          r !== undefined &&
          Array.isArray(r) &&
          r.every((n) => typeof n === 'number') &&
          rest.length === 0,
        `cosine_distance is a binary function that takes two number arrays as arguments.`
      );
      return cosineDistance(l, r);
    }
    case 'round': {
      const [target, percision, ...rest] = evaledArgs();
      Assert.assert(
        typeof target === 'number' &&
          typeof percision === 'number' &&
          rest.length === 0
      );

      return Number(target.toFixed(percision));
    }
    // Records
    case 'get_from_record': {
      const [target, name, ...rest] = evaledArgs();
      Assert.assert(
        typeof target === 'object' &&
          !(target instanceof Date) &&
          typeof name === 'string' &&
          !Freeze.isReadonlyArray(target) &&
          rest.length === 0
      );
      return target?.[name] ?? null;
    }
    // Comparisons
    case 'eq':
    case 'neq':
    case 'gt':
    case 'lt':
    case 'gte':
    case 'lte': {
      const [l, r, ...rest] = evaledArgs();
      Assert.assert(l !== undefined && r !== undefined && rest.length === 0);
      const ty = narrowestSuperTypeOf(
        expr.args.map((arg) => Expression.fromAst(arg).ty)
      );
      Assert.assert(ty.isOk(), 'Typechecker is working');
      return compareLiterals(ty.value, l, op, r);
    }
    // Text Manipulation
    case 'format': {
      return evaledArgs()
        .map(scalarAsString)
        .reduce((l, r) => `${l ?? ''}${r ?? ''}`, '');
    }
    case 'concat':
      return evaledArgs().reduce((l, r) => {
        if (l === null || r === null) {
          return null;
        }

        return `${l.toString()}${r.toString()}`;
      });
    case 'length': {
      const [arg, ...rest] = evaledArgs();
      if (arg === null) {
        return null;
      }
      Assert.assert(
        arg !== undefined && typeof arg === 'string' && rest.length === 0,
        'length is a unary function that only works on strings'
      );

      return arg.length;
    }
    case 'replace': {
      const [source, oldChars, newChars, ...rest] = evaledArgs();

      Assert.assert(
        typeof source === 'string' &&
          typeof oldChars === 'string' &&
          typeof newChars === 'string' &&
          rest.length === 0,
        'Type checker works'
      );

      return source.replace(
        new RegExp(
          `${[...oldChars]
            .map((char) => ('/.*+?|(){}'.includes(char) ? `\\${char}` : char))
            .join('')}`,
          'g'
        ),
        newChars
      );
    }
    case 'upper':
    case 'lower': {
      const [arg, ...rest] = evaledArgs();
      if (arg === null) {
        return null;
      }
      Assert.assert(
        arg !== undefined && typeof arg === 'string' && rest.length === 0,
        `${op} is a unary function that only works on strings`
      );

      switch (op) {
        case 'upper':
          return arg.toUpperCase();
        case 'lower':
          return arg.toLowerCase();
        default:
          return Assert.unreachable(op);
      }
    }
    case 'split_part': {
      const [target, splitOn, ordinal, ...rest] = evaledArgs();
      if (target === null || splitOn === null || ordinal === null) {
        return null;
      }

      Assert.assert(
        target !== undefined &&
          typeof target === 'string' &&
          splitOn !== undefined &&
          typeof splitOn === 'string' &&
          ordinal !== undefined &&
          typeof ordinal === 'number' &&
          rest.length === 0
      );

      return target.split(splitOn).at(ordinal - 1) ?? '';
    }
    case 'like': {
      const [target, pattern, ...rest] = evaledArgs();

      if (target === null || pattern === null) {
        return null;
      }

      Assert.assert(
        target !== undefined &&
          typeof target === 'string' &&
          pattern !== undefined &&
          typeof pattern === 'string' &&
          rest.length === 0
      );

      return compileLikePattern(pattern).test(target);
    }
    // Logic
    case 'is_null': {
      const [arg, ...rest] = evaledArgs();
      Assert.assert(arg !== undefined && rest.length === 0);
      return arg === null;
    }
    case 'is_not_null': {
      const [arg, ...rest] = evaledArgs();
      Assert.assert(arg !== undefined && rest.length === 0);
      return arg !== null;
    }
    case 'and':
    case 'or':
      return evaledArgs().reduce((l, r) => {
        if (l === null || r === null) {
          return null;
        }

        Assert.assert(
          typeof l === 'boolean' && typeof r === 'boolean',
          'boolean logic requires booleans'
        );

        switch (op) {
          case 'or':
            return l || r;
          case 'and':
            return l && r;
          default:
            return Assert.unreachable(op);
        }
      });
    case 'not': {
      const [arg, ...rest] = evaledArgs();
      if (arg === null) {
        return null;
      }
      Assert.assert(
        arg !== undefined && typeof arg === 'boolean' && rest.length === 0,
        'not is a unary function that only works on booleans'
      );

      return !arg;
    }
    case 'null_if': {
      const [lhs, rhs, ...rest] = evaledArgs();
      const [lhsTy, rhsTy, ...restTys] = expr.args.map(
        (x) => Expression.fromAst(x).ty
      );
      Assert.assert(
        lhs !== undefined &&
          rhs !== undefined &&
          lhsTy !== undefined &&
          rhsTy !== undefined &&
          rest.length === 0 &&
          restTys.length === 0
      );
      const l = Constant(lhs, { ty: lhsTy });
      const r = Constant(rhs, { ty: rhsTy });
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return evalExprIR(Eq(l, r).ir(), ctx) ? null : lhs;
    }
    case 'coalesce': {
      const [lhs, rhs, ...rest] = evaledArgs();
      Assert.assert(
        lhs !== undefined && rhs !== undefined && rest.length === 0
      );
      return lhs === null ? rhs : lhs;
    }
    // Random
    case 'random': {
      return Math.random();
    }
    case 'gen_random_uuid': {
      return v4();
    }
    case 'type_of': {
      const wantedTy = TC.checkExpr(expr);
      Assert.assert(!(wantedTy instanceof TyStackTrace));
      Assert.assert(wantedTy.ty.ty.k === 'enum');
      const output = wantedTy.ty.ty.t[0];
      Assert.assert(output !== undefined);
      return output;
    }
    case 'empty_array_of': {
      return [];
    }
    case 'null_of': {
      return null;
    }
    case 'one_of': {
      const [target, choices] = evaledArgs();

      Assert.assert(
        target !== undefined && choices !== undefined && Array.isArray(choices)
      );

      return choices.length === 0
        ? false
        : Or(...choices.map((val) => Eq(target, val))).evaluate();
    }
    case 'tag': {
      const [target, tag, ...rest] = evaledArgs();
      Assert.assert(
        target !== undefined &&
          tag !== undefined &&
          typeof tag === 'string' &&
          rest.length === 0
      );
      return target;
    }
    // Timestamp
    case 'now': {
      return DateTime.utc().toJSDate();
    }
    case 'date_part': {
      const [target, unit] = evaledArgs();

      Assert.assert(target instanceof Date && typeof unit === 'string');
      const t = DateTime.fromJSDate(target).toUTC();

      switch (unit) {
        case 'seconds':
          return t.second;
        case 'year':
          return t.year;
        case 'hour':
          return t.hour;
        case 'day':
          return t.day;
        case 'quarter':
          return t.quarter;
        case 'minute':
          return t.minute;
        case 'month':
          return t.month;
        case 'week':
          return t.weekNumber;
        case 'dow':
          return t.weekday;
        default:
          throw new Error(`Invalid DateTime Unit ${unit}`);
      }
    }
    case 'date_add': {
      const [target, amount, unit] = evaledArgs();

      if (target === null || amount === null) {
        return null;
      }

      Assert.assert(
        target instanceof Date && typeof amount === 'number' && unit === 'days'
      );

      return DateTime.fromJSDate(target).plus({ days: amount }).toJSDate();
    }
    case 'date_trunc': {
      const [target, unit] = evaledArgs();
      const isUnit = (x: string): x is (typeof DATE_TRUNC_UNITS)[number] =>
        DATE_TRUNC_UNITS.includes(x as any);
      Assert.assert(
        target instanceof Date && typeof unit === 'string' && isUnit(unit)
      );

      return DateTime.fromJSDate(target).toUTC().startOf(unit).toJSDate();
    }
    case 'date_diff': {
      const [a, b, unit] = evaledArgs();

      const isUnit = (x: string): x is (typeof DATE_DIFF_UNITS)[number] =>
        DATE_DIFF_UNITS.includes(x as any);

      Assert.assert(
        a instanceof Date &&
          b instanceof Date &&
          typeof unit === 'string' &&
          isUnit(unit)
      );

      let startOfUnit: DateTimeUnit;

      switch (unit) {
        case 'days':
          startOfUnit = 'day';
          break;
        case 'years':
          startOfUnit = 'year';
          break;
        case 'seconds':
          startOfUnit = 'second';
          break;
      }

      const end = DateTime.fromJSDate(b).toUTC().startOf(startOfUnit);
      const begin = DateTime.fromJSDate(a).toUTC().startOf(startOfUnit);
      return end.diff(begin, unit).as(unit);
    }
    case 'nan': {
      return NaN;
    }
    case 'impure': {
      const [arg, ...rest] = evaledArgs();
      Assert.assert(arg !== undefined && rest.length === 0);
      return arg;
    }
    case 'count': {
      const [argIr, ...rest] = expr.args;

      Assert.assert(argIr !== undefined && rest.length === 0);

      return assertAggCtx(ctx)
        .rows.map((from) => evalExprIR(argIr, { sources: { from }, rows: [] }))
        .filter((t) => t !== null).length;
    }
    case 'count_distinct': {
      const [argIr, ...rest] = expr.args;

      Assert.assert(argIr !== undefined && rest.length === 0);
      const seen = new Set<string>();

      for (const from of assertAggCtx(ctx).rows) {
        const res = evalExprIR(argIr, { sources: { from }, rows: [] });
        if (res !== null) {
          seen.add(res.toString());
        }
      }

      return seen.size;
    }
    case 'array_agg': {
      const [argIr, ...rest] = expr.args;

      Assert.assert(argIr !== undefined && rest.length === 0);

      return assertAggCtx(ctx).rows.map((from) =>
        evalExprIR(argIr, { sources: { from }, rows: [] })
      );
    }
    case 'string_agg': {
      const [targetIr, delimIr, ...rest] = expr.args;

      Assert.assert(
        targetIr !== undefined && delimIr !== undefined && rest.length === 0
      );
      const delim = evalExprIR(delimIr);
      Assert.assert(typeof delim === 'string');

      return assertAggCtx(ctx)
        .rows.map((from) =>
          evalExprIR(targetIr, { sources: { from }, rows: [] })
        )
        .join(delim);
    }
    case 'sum': {
      const [argIr, ...rest] = expr.args;

      Assert.assert(argIr !== undefined && rest.length === 0);

      return assertAggCtx(ctx)
        .rows.map((from) => evalExprIR(argIr, { sources: { from }, rows: [] }))
        .reduce<number>((acc, next) => {
          const i = next ?? 0;
          Assert.assert(typeof i === 'number');
          return acc + i;
        }, 0);
    }
    case 'min': {
      const [argIr, ...rest] = expr.args;

      Assert.assert(argIr !== undefined && rest.length === 0);

      return assertAggCtx(ctx)
        .rows.map((from) => evalExprIR(argIr, { sources: { from }, rows: [] }))
        .reduce<number | null>((acc, next): number | null => {
          if (acc === null) {
            Assert.assert(typeof next === 'number' || next === null);
            return next;
          }
          if (next === null) {
            return acc;
          }
          Assert.assert(typeof acc === 'number' && typeof next == 'number');
          return Math.min(acc, next);
        }, null);
    }
    case 'max': {
      const [argIr, ...rest] = expr.args;

      Assert.assert(argIr !== undefined && rest.length === 0);

      return assertAggCtx(ctx)
        .rows.map((from) => evalExprIR(argIr, { sources: { from }, rows: [] }))
        .reduce<number | null>((acc, next): number | null => {
          if (acc === null) {
            Assert.assert(typeof next === 'number' || next === null);
            return next;
          }
          if (next === null) {
            return acc;
          }
          Assert.assert(typeof acc === 'number' && typeof next == 'number');
          return Math.max(acc, next);
        }, null);
    }
    case 'avg': {
      const [argIr, ...rest] = expr.args;

      Assert.assert(argIr !== undefined && rest.length === 0);

      const vals = assertAggCtx(ctx).rows.map((from) =>
        evalExprIR(argIr, { sources: { from }, rows: [] })
      );

      const sum = vals.reduce<number>((acc, next) => {
        const i = next ?? 0;
        Assert.assert(typeof i === 'number');
        return acc + i;
      }, 0);

      return sum / vals.length;
    }
    default:
      throw new Error(
        `Unable to evaluate expr of type "${expr.op}", this implies a logic error in the type checker`
      );
  }
};

const doDecodeScalar: ExecExpr<AST._Scalar> = (
  { val, ty },
  opts
): Ty.Scalar => {
  if (val === null) {
    return null;
  }

  if (ty.ty.k === 'struct') {
    Assert.assert(
      typeof val === 'object' &&
        !(val instanceof Date) &&
        !Freeze.isReadonlyArray(val)
    );
    return _.mapValues(ty.ty.fields, (ty, name) =>
      doDecodeScalar({ t: 'scalar', ty, val: val[name] ?? null }, opts)
    );
  } else if (ty.ty.k === 'array') {
    const t = ty.ty.t;
    Assert.assert(Freeze.isReadonlyArray(val));
    return val.map((x) => doDecodeScalar({ t: 'scalar', ty: t, val: x }, opts));
  } else if (ty.ty.k === 'primitive') {
    if (ty.ty.t === 'timestamp') {
      Assert.assert(typeof val === 'string' || val instanceof Date);
      return new Date(val);
    }
    return val;
  } else if (
    ty.ty.k === 'id' ||
    ty.ty.k === 'range' ||
    ty.ty.k == 'enum' ||
    ty.ty.k === 'record'
  ) {
    return val;
  } else {
    return Assert.unreachable(ty.ty);
  }
};

/**
 * Calculates the cosine distance between two vectors.
 * @param a - The first vector
 * @param b - The second vector
 * @returns The cosine distance between vectors a and b
 */
function cosineDistance(a: number[], b: number[]): number {
  // Check if vectors have the same length
  if (a.length !== b.length) {
    throw new Error('Vectors must have the same length');
  }

  // Calculate dot product
  const dotProduct = a.reduce((sum, _, i) => sum + a[i]! * b[i]!, 0);

  // Calculate magnitudes
  const magnitudeA = Math.sqrt(a.reduce((sum, val) => sum + val * val, 0));
  const magnitudeB = Math.sqrt(b.reduce((sum, val) => sum + val * val, 0));

  // Calculate cosine similarity
  const cosineSimilarity = dotProduct / (magnitudeA * magnitudeB);

  // Calculate and return cosine distance
  return 1 - cosineSimilarity;
}

const assertAggCtx = (ctx: ExprEvalCtx): { rows: Ty.Row[] } => {
  if ('rows' in ctx) {
    return ctx;
  }
  const err = new Error(
    'Expected to be in a agg execution ctx, but in aggregate ctx, this implies a type checker logic error'
  );

  if ((Error as any).captureStackTrace) {
    (Error as any).captureStackTrace(err, assertScalarCtx);
  }

  throw err;
};

const assertScalarCtx = (
  ctx: ExprEvalCtx
): { sources: Partial<Record<'from' | 'left' | 'right', Ty.Row>> } => {
  if ('sources' in ctx) {
    return ctx;
  }
  const err = new Error(
    'Expected to be in a scalar execution ctx, but in aggregate ctx, this implies a type checker logic error'
  );

  if ((Error as any).captureStackTrace) {
    (Error as any).captureStackTrace(err, assertScalarCtx);
  }

  throw err;
};

const scalarAsString = (x: Ty.Scalar): string | null => {
  if (x === null) {
    return null;
  }
  if (x instanceof Date) {
    return x.toISOString();
  }
  if (
    typeof x === 'number' ||
    typeof x === 'string' ||
    typeof x === 'boolean'
  ) {
    return x.toString();
  }

  if (Freeze.isReadonlyArray(x)) {
    return `[${x
      .map((item) => scalarAsString(item))
      .map((x) => `${x}`)
      .join(', ')}]`;
  }
  return `{${Object.entries(x).map(
    ([key, val]) => `"${key}": ${scalarAsString(val)}`
  )}}`;
};
