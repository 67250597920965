import { Alert } from '@cotera/client/app/components/ui';

export const DisplayError: React.FC<{
  error: { name?: string; errorType?: string; message?: string };
}> = ({ error: { message, name, errorType } }) => (
  <div className="mt-20">
    <Alert
      variant="error"
      message="Something went wrong!"
      detail={
        <div className="flex flex-col w-full">
          <h3 className="mb-2 font-medium">{name ?? errorType ?? 'Error'}</h3>
          <p className="mb-2">{message}</p>
        </div>
      }
    />
  </div>
);
