import { Inputs } from '@cotera/client/app/components/forms';
import { Text } from '@cotera/client/app/components/ui';
import { Hint, TextHints } from '@cotera/client/app/components/app';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import { AST, EraQL, TC, Ty } from '@cotera/era';
import { startCase } from 'lodash';
import { getValidOperatorsForType, useHints } from '../hints';
import { useRef } from 'react';
import { parseExprResult } from '../utils';
import { Result } from 'neverthrow';

type InputType = typeof Inputs.TextArea | typeof Inputs.Text;

export const ExpressionEditor: React.FC<{
  attributes: Record<string, Ty.ExtendedAttributeType>;
  name?: string;
  eraql: string;
  ty: Ty.ExtendedAttributeType;
  error?: {
    t: string;
    msg: string;
  };
  as?: InputType;
  onChange: (
    value: string,
    eraql: Result<
      {
        tc: TC.ExprTypeCheck;
        ast: AST.ExprIR;
      },
      {
        t: string;
        msg: string;
      }
    >
  ) => void;
}> = ({
  attributes,
  eraql,
  onChange,
  error,
  ty,
  name,
  as: Input = Inputs.TextArea,
}) => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);

  const changeHandler = (value: string) => {
    const parseRes = parseExprResult(
      EraQL.parseExpr(value, {
        attributes,
      })
    );

    onChange(value, parseRes);
  };

  const hints = useHints(
    attributes,
    error === undefined ? getValidOperatorsForType(ty) : [],
    inputRef,
    changeHandler
  );

  const handleChange = (
    currentValue: string,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    changeHandler(currentValue);
    hints.handleChange(currentValue, e);
  };

  return (
    <div className="flex flex-col relative">
      <Input
        spellcheck={false}
        ref={inputRef as any}
        className="min-h-[100px] mb-4"
        label={name}
        placeholder="Enter an EraQL expression"
        value={eraql}
        onChange={handleChange}
      />
      <TypeHints />
      {eraql.length > 0 && error !== undefined && (
        <div
          className={classNames(
            'p-2 rounded mt-4 text-sm border',
            ColorScheme.background.error,
            ColorScheme.text.error,
            ColorScheme.border.error
          )}
        >
          {startCase(error.t)}: {error.msg}
        </div>
      )}
      <TextHints
        hints={hints.columns.options}
        anchorRef={inputRef}
        show={hints.columns.showOptions}
        onSelect={(v) =>
          hints.handleOptionClick(
            {
              value: v,
              trigger: '"',
            },
            eraql
          )
        }
      />
      <TextHints
        hints={hints.operators.options}
        anchorRef={inputRef}
        show={hints.operators.showOptions}
        onSelect={(v) =>
          hints.handleOptionClick(
            {
              value: v,
              trigger: '.',
            },
            eraql
          )
        }
      />
      <TextHints
        hints={hints.functions.options}
        anchorRef={inputRef}
        show={hints.functions.showOptions}
        onSelect={(v) =>
          hints.handleOptionClick(
            {
              value: v,
              trigger: '|>',
            },
            eraql
          )
        }
      />
    </div>
  );
};

const TypeHints = () => (
  <Text.Caption>
    Type <Hint theme="primary">"</Hint> to select a column,{' '}
    <Hint theme="primary">.</Hint> to select an operator
  </Text.Caption>
);
