import {
  And,
  Desc,
  Eq,
  Expression,
  Relation,
  From,
  RankOver,
  RelationRef,
  Ty,
  Random,
  TC,
} from '@cotera/era';
import { SYSTEM_TABLES } from '../runtime/system-tables/system-tables';
import _ from 'lodash';
import { Assert } from '@cotera/utilities';

export class UserDefinedDimensions {
  config: {
    readonly entityName: string;
    readonly attributes: {
      readonly [name: string]: Ty.ExtendedAttributeType;
    };
  };

  /**
   * @param params tableName defaults to '@@global'
   * @param params.key the identifier of the source relation you wish to join with
   */
  constructor(params: {
    readonly entityName: string;
    readonly attributes: {
      readonly [name: string]: Ty.Shorthand;
    };
  }) {
    this.config = {
      entityName: params.entityName,
      attributes: _.mapValues(params.attributes, (ty) => Ty.ty(ty)),
    };
  }

  get entityName(): string {
    return this.config.entityName;
  }

  mostRecentValuesForKey(key: string): Relation {
    const ty = this.config.attributes[key];
    if (!ty) {
      throw new Error(
        `Key "${key}" not found. Available: [${Object.keys(
          this.config.attributes
        )
          .map((key) => `"${key}"`)
          .join(', ')}]`
      );
    }

    return From(SYSTEM_TABLES.user_defined_dimensions)
      .where((t) =>
        And(Eq(t.attr('entity_id'), this.entityName), Eq(t.attr('key'), key))
      )
      .select((t) => ({
        ...t.star(),
        value: t.attr('value').cast(ty),
        rkk: RankOver({
          partitionBy: [t.attr('identifier'), t.attr('key')],
          orderBy: [
            Desc(t.attr('timestamp')),
            // Break ties
            Desc(Random()),
          ],
        }),
      }))
      .where((t) => Eq(t.attr('rkk'), 1));
  }

  join(
    rel: Relation,
    params?: {
      readonly identifier?: (t: RelationRef) => Expression;
    }
  ): Relation {
    let Base: Relation;

    if (params?.identifier) {
      const { identifier } = params;
      Base = rel.select((t) => ({
        ...t.star(),
        __identifier: Expression.wrap(identifier(t)).cast('string'),
      }));
    } else {
      const [idAttr, ...otherIdAttrs] = Object.entries(rel.attributes)
        .filter(([_name, ty]) =>
          [Ty.sid, Ty.iid]
            .map((x) => x(this.entityName))
            .some((req) => TC.implementsTy({ subject: ty, req }))
        )
        .map(([name, _ty]) => name);

      if (idAttr) {
        if (otherIdAttrs.length > 0) {
          const err = new Error(
            `Too many columns (${[idAttr, ...otherIdAttrs]
              .map((name) => `"${name}"`)
              .join(', ')}) are type \`${Ty.displayTy(
              Ty.sid(this.config.entityName)
            )}\`, so the "identifier" parameter must be specified`
          );

          if ((Error as any).captureStackTrace) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            Error.captureStackTrace(err, this.join);
          }

          throw err;
        }

        Base = rel.select((t) => ({
          ...t.star(),
          __identifier: t.attr(idAttr),
        }));
      } else {
        const err = new Error(
          `Relation has no attributes of type \`${Ty.displayTy(
            Ty.sid(this.config.entityName)
          )}\` so the "identifier" parameter must be specified`
        );

        if ((Error as any).captureStackTrace) {
          // eslint-disable-next-line @typescript-eslint/unbound-method
          Error.captureStackTrace(err, this.join);
        }

        throw err;
      }
    }

    return Object.keys(this.config.attributes)
      .reduce(
        (rel, key) =>
          rel.leftJoin(this.mostRecentValuesForKey(key), (left, udds) => {
            const configItem = this.config.attributes[key];
            Assert.assert(
              configItem !== undefined,
              `${key} not found in config`
            );

            return {
              on: Eq(
                left.attr('__identifier').cast('string'),
                udds.attr('identifier')
              ),
              select: {
                ...left.star(),
                [key]: udds.attr('value').tag(...(configItem.tags ?? [])),
              },
            };
          }),
        Base
      )
      .select((t) => t.except('__identifier'));
  }
}
