import { Relation, AST, UI, From, Expression, f } from '@cotera/era';
import { Overview } from './components';
import { ADVENT_CALENDAR_GROWTH_HACKS } from '@cotera/customers/cotera/assumptions';

export const ImpliedCsat = ({
  CxData,
  caption,
  icon = 'chat-bubble',
}: {
  CxData: Relation;
  caption: string | Expression;
  icon?: AST.MarkupIcon;
}): AST._App =>
  UI.App({
    title: 'Implied CSAT',
    icon,
    pages: {
      '/:companyName': Overview({ CxData, caption }),
    },
    menu: [],
  });

export const ImpliedCsatApp = ImpliedCsat({
  CxData: From(ADVENT_CALENDAR_GROWTH_HACKS),
  caption: f`This app shows our implied CSAT model. 

CSAT surveys are great, but not everyone takes one. What if AI could fill in the missing ones?

Based on everything a customer has said to you in support, our AI figures out the CSAT response they're likely to give. It learns from your real responses too!

Check out the [explainer video](https://www.loom.com/share/f60c0573d66d49e4843bf7c6c512c06d).

If you think this is interesting, [book a chat with us here.](https://calendly.com/cotera-founders/30-minutes)`,
});
