import {
  UI,
  AST,
  And,
  BarChart,
  Case,
  If,
  LineChart,
  Now,
  Page,
  Relation,
  Sum,
  UnionAll,
  Constant,
  Ty,
  f,
} from '@cotera/era';
import { valueToDateRange } from '../utils';

const STATUSES = ['Active', 'Inactive', 'New'] as const;

export const Overview = ({
  RfmLifecycleSummary,
  CurrentRateOfChangeAIN,
  CurrentChangeAIN,
  customerSegments,
  memberType,
}: {
  RfmLifecycleSummary: Relation;
  CurrentRateOfChangeAIN: Relation;
  CurrentChangeAIN: Relation;
  customerSegments: {
    active: string[];
    inactive: string[];
    new: string[];
  };
  memberType?: string;
}): AST._Page => {
  const makeCustomerStatus = (rel: Relation, direction: 'asc' | 'desc') =>
    UnionAll(
      STATUSES.map((status) =>
        rel
          .where((t) => t.attr('customerStatus').eq(status))
          .groupBy((t) => ({
            date: t.attr('date').dateTrunc('month'),
            customerStatus: t.attr('customerStatus'),
          }))
          .select((t) => ({
            date: t.attr('date'),
            customerStatus: t.attr('customerStatus'),
            value: Sum(t.attr('count')),
          }))
          .select((t) => t.star(), {
            limit: 1,
            orderBy: (t) => ({
              expr: t.attr('date'),
              direction,
            }),
          })
      )
    );

  return Page([], () => [
    UI.Header({
      title: `Lifecycle ${
        memberType ? `(${memberType}) ` : ''
      }Overview - Active, Inactive and New Customers (AIN)`,
      caption: 'Customers Recency, Frequency and Monetary Value - (RFM)',
    }),
    UI.State(
      {
        timePeriod: Constant('1y', {
          ty: Ty.e(['3m', '4m', '6m', '1y', '2y', 'max']),
        }),
        tab: Constant('Active Customers', {
          ty: Ty.e(['Active Customers', 'Inactive Customers', 'New Customers']),
        }),
      },
      ({ timePeriod: timePeriodValue, tab }) => {
        const { start, end } = valueToDateRange(timePeriodValue);
        const RfmLifecycleSummaryFiltered = RfmLifecycleSummary.where((t) =>
          t
            .attr('date')
            .between(start, end)
            .and(t.attr('date').dateTrunc('month').lt(Now().dateTrunc('month')))
        ).select((t) => ({
          ...t.star(),
          customerStatus: Case([
            {
              when: t.attr('segment').oneOf(customerSegments.active),
              then: 'Active',
            },
            {
              when: t.attr('segment').oneOf(customerSegments.inactive),
              then: 'Inactive',
            },
            {
              when: t.attr('segment').oneOf(customerSegments.new),
              then: 'New',
            },
          ]),
        }));
        const CurrentRateOfChangeAINFiltered = CurrentRateOfChangeAIN.where(
          (t) => t.attr('date').between(start, end)
        );

        return [
          UI.Row([timePeriodValue.Toggle()]),
          UI.Block([
            UI.Stats([
              makeCustomerStatus(RfmLifecycleSummaryFiltered, 'desc')
                .leftJoin(
                  makeCustomerStatus(RfmLifecycleSummaryFiltered, 'asc'),
                  (current, previous) => {
                    return {
                      on: current
                        .attr('customerStatus')
                        .eq(previous.attr('customerStatus')),
                      select: {
                        value: current.attr('value').cast('int'),
                        from: previous.attr('value').cast('int'),
                        title: current.attr('customerStatus'),
                        style: Case([
                          {
                            when: current
                              .attr('value')
                              .gt(previous.attr('value')),
                            then: If(
                              current
                                .attr('customerStatus')
                                .eq('Active')
                                .or(current.attr('customerStatus').eq('New')),
                              {
                                then: 'positive',
                                else: 'negative',
                              }
                            ),
                          },
                          {
                            when: current
                              .attr('value')
                              .lt(previous.attr('value')),
                            then: If(
                              current
                                .attr('customerStatus')
                                .eq('Active')
                                .or(current.attr('customerStatus').eq('New')),
                              {
                                then: 'negative',
                                else: 'positive',
                              }
                            ),
                          },
                        ]),
                      },
                    };
                  }
                )
                .chart.Stat((t) => t.pick('value', 'from', 'style', 'title')),
            ]),
          ]),
          UI.Block([
            LineChart({
              title: 'Customers per segment',
              rel: RfmLifecycleSummaryFiltered.groupBy((t) => ({
                category: t.attr('customerStatus'),
                date: t.attr('date').dateTrunc('month'),
              })).select((t) => ({
                x: t.attr('date'),
                y: Sum(t.attr('count')),
                category: t.attr('category'),
                style: Case([
                  {
                    when: t.attr('category').eq('New'),
                    then: 'neutral',
                  },
                  {
                    when: t.attr('category').eq('Inactive'),
                    then: 'negative',
                  },
                  {
                    when: t.attr('category').eq('Active'),
                    then: 'positive',
                  },
                ]),
              })),
              axis: {
                x: {
                  scale: 'month',
                },
                y: {
                  label: 'Number of customers',
                },
              },
            }),
          ]),
          UI.Block([
            CurrentChangeAIN.chart.SankeyChart(
              (t) => ({
                from: t.attr('starting_segment'),
                to: t.attr('current_segment'),
                value: t.attr('count'),
                style: Case([
                  {
                    when: t.attr('current_segment').eq('New Customers'),
                    then: 'neutral',
                  },
                  {
                    when: t.attr('current_segment').eq('Inactive Customers'),
                    then: 'negative',
                  },
                  {
                    when: t.attr('current_segment').eq('Active Customers'),
                    then: 'positive',
                  },
                ]),
              }),
              {
                title: 'Customer Status Transitions (last 3 months)',
                size: 'lg',
              }
            ),
          ]),
          UI.Block(
            [
              UI.TabsV2(tab, [
                BarChart({
                  title: f`${tab} per month`,
                  size: 'lg',
                  type: 'grouped',
                  trendline: true,
                  rel: CurrentRateOfChangeAINFiltered.where((x) =>
                    And(
                      x.attr('starting_rfm_category').eq(tab),
                      x.attr('date').gt(Now().dateSub('days', 365))
                    )
                  )
                    .select((t) => ({
                      x: t.attr('date'),
                      y: t.attr('status_count'),
                      status: tab,
                      category: Case([
                        {
                          when: t
                            .attr('category_status')
                            .eq('Segment Retention Rate'),
                          then: 'Retained',
                        },
                        {
                          when: t
                            .attr('category_status')
                            .eq('Segment Loss Rate'),
                          then: 'Exited',
                        },
                        {
                          when: t
                            .attr('category_status')
                            .eq('Segment Improvement Rate'),
                          then: 'Improved',
                        },
                      ]),
                    }))
                    .select((t) => ({
                      ...t.star(),
                      style: Case([
                        {
                          when: t.attr('category').eq('Exited'),
                          then: 'negative',
                        },
                        {
                          when: t.attr('category').eq('Improved'),
                          then: 'positive',
                        },
                        {
                          when: t
                            .attr('category')
                            .eq('Retained')
                            .and(t.attr('status').neq('Inactive Customers')),
                          then: 'neutral',
                        },
                        {
                          when: t
                            .attr('category')
                            .eq('Retained')
                            .and(t.attr('status').eq('Inactive Customers')),
                          then: 'negative',
                        },
                      ]),
                    })),
                  axis: {
                    x: {
                      scale: 'month',
                    },
                    y: {
                      label: `Number of Events`,
                    },
                  },
                }),
                CurrentRateOfChangeAINFiltered.where((x) =>
                  And(
                    x.attr('starting_rfm_category').eq(tab),
                    x.attr('date').gt(Now().dateSub('days', 365))
                  )
                )
                  .select((t) => ({
                    x: t.attr('date'),
                    y: t.attr('rate'),
                    status: tab,
                    category: Case([
                      {
                        when: t
                          .attr('category_status')
                          .eq('Segment Retention Rate'),
                        then: 'Retained',
                      },
                      {
                        when: t.attr('category_status').eq('Segment Loss Rate'),
                        then: 'Exited',
                      },
                      {
                        when: t
                          .attr('category_status')
                          .eq('Segment Improvement Rate'),
                        then: 'Improved',
                      },
                    ]),
                  }))
                  .chart.LineChart(
                    (t) => ({
                      ...t.pick('x', 'y', 'category'),
                      style: Case([
                        {
                          when: t.attr('category').eq('Exited'),
                          then: 'negative',
                        },
                        {
                          when: t.attr('category').eq('Improved'),
                          then: 'positive',
                        },
                        {
                          when: t
                            .attr('category')
                            .eq('Retained')
                            .and(t.attr('status').neq('Inactive Customers')),
                          then: 'neutral',
                        },
                        {
                          when: t
                            .attr('category')
                            .eq('Retained')
                            .and(t.attr('status').eq('Inactive Customers')),
                          then: 'negative',
                        },
                      ]),
                    }),
                    {
                      type: 'area',
                      title: `Share of segment improvement per month`,
                      size: 'sm',
                      axis: {
                        x: { scale: 'month' },
                        y: { label: 'Count' },
                      },
                    }
                  ),
                '* Share of segment improvement is the percentage of customers who improved their RFM category in a given month',
              ]),
            ],
            {
              border: false,
            }
          ),
        ];
      }
    ),
  ]);
};
