import { Text, RelationPicker } from '@cotera/client/app/components';
import { useState } from 'react';
import {
  CodeBracketIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useAppData } from '@cotera/client/app/stores/org';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@cotera/client/app/components/headless';
import { ViewSqlForRelId } from '@cotera/client/app/components/app';

export const AvailableDefinitions: React.FC<{}> = () => {
  const [showSql, setShowSql] = useState<string | null>(null);
  const { definitions } = useAppData((x) => x.skeleton);

  return (
    <div className="min-h-full flex flex-col items-center w-full">
      <div className="mx-auto max-w-7xl p-8 w-full">
        <Text.Title
          type="section"
          className="text-lg font-medium leading-6 text-standard-text"
        >
          Select a data set
        </Text.Title>
        <Text.Caption className="mt-1 text-sm mb-6">
          Get started by choosing a data set to explore.
        </Text.Caption>
        <RelationPicker
          definitions={definitions}
          wrapper={(props) => (
            <>
              <Link to={`/explore/data/${props.id}`} className="flex-1">
                {props.children}
              </Link>
              <Dropdown>
                <DropdownTrigger>
                  <EllipsisVerticalIcon className="h-5 w-5 text-muted-text shrink-0 cursor-pointer hover:text-accent-text mr-2 ml-4" />
                </DropdownTrigger>
                <DropdownContent side="top">
                  <DropdownItem
                    icon={<CodeBracketIcon className="h-4 w-4" />}
                    active={false}
                    onClick={() => setShowSql(props.id)}
                  >
                    View SQL
                  </DropdownItem>
                </DropdownContent>
              </Dropdown>
            </>
          )}
        />
        {showSql && (
          <ViewSqlForRelId
            open
            relId={showSql}
            onChange={() => setShowSql(null)}
          />
        )}
      </div>
    </div>
  );
};
