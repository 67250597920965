import _ from 'lodash';
import { AST } from '../ast';
import { ExprFR } from '../ast/expr';
import { Ty } from '../ty';
import { TyStackTrace } from '../type-checker/ty-stack-trace';
import { Assert } from '../utils';
import type { ExprTypeCheck } from './expr/check-expr';
import { checkVars } from './check-vars';
import { mergeMacroVars } from './merge-marco-vars';
import { TypeCheckError } from './type-check-error';
import type { RelTypeCheck } from './check-rel';
import {
  Errs,
  checkExpr,
  checkRel,
  implementsRel,
  implementsTy,
} from './type-checker';
import { NO_URL, mergeUrlConfigs } from './merge-url-configs';
import { MacroArgsType } from '../ast/base';
import {
  RelInterface,
  RelInterfaceShorthand,
  relShorthandInterfaceToFull,
} from './rel-interface';

export type MarkupTypeCheck = {
  readonly vars: { readonly [scope: string]: AST.MacroArgsType };
  readonly url: AST._UiState['url'];
};

const _CHART_REL_REQUIREMENTS: Record<
  AST.Chart._ChartConfig['t'],
  RelInterfaceShorthand
> = {
  'relation-info': {},
  'data-grid': {},
  'radar-chart': { value: ['float'], category: ['string'], group: ['string'] },
  'bar-chart': {
    x: ['string', 'timestamp', 'float', 'int'],
    y: ['int', 'float'],
    category: ['string'],
  },
  'index-list': {
    value: ['string'],
    link: {
      allowed: [
        {
          k: 'struct',
          fields: {
            to: 'string',
          },
        },
      ],
      optional: true,
    },
  },
  'heatmap-chart': {
    x: ['string'],
    value: ['int', 'float'],
    y: ['string', 'timestamp'],
  },
  'histogram-chart': {
    count: ['float', 'int'],
    start: ['int', 'float'],
    end: ['int', 'float'],
    category: ['string'],
  },
  'line-chart': {
    x: ['string', 'timestamp'],
    y: ['int', 'float'],
    category: ['string'],
  },
  'pie-chart': { value: ['float'], category: ['string'] },
  'sankey-chart': { to: ['string'], from: ['string'], value: ['float'] },
  'scatter-plot': {
    x: ['int', 'float', 'string', 'timestamp'],
    y: ['int', 'float', 'string'],
    z: ['int', 'float'],
    category: ['string'],
    label: ['string'],
  },
  'summary-chart': { value: ['float'], category: ['string'] },
  'llm-summary': {},
  'semantic-search-results': {
    value: ['string'],
    similarity: {
      allowed: ['int', 'float'],
      optional: true,
    },
    id: ['string'],
    timestamp: ['timestamp'],
  },
};

export const CHART_REL_REQUIREMENTS: Record<
  AST.Chart._ChartConfig['t'],
  RelInterface
> = _.mapValues(_CHART_REL_REQUIREMENTS, (req) =>
  relShorthandInterfaceToFull(req)
);

type MarkupChecker<T extends AST.Mu> = (
  markup: T
) => MarkupTypeCheck | TyStackTrace;

const MARKUP_TYPE_CHECK_CACHE: WeakMap<AST.Mu, MarkupTypeCheck | TyStackTrace> =
  new WeakMap();

export const checkMarkup: MarkupChecker<AST.Mu> = (section) => {
  const existing = MARKUP_TYPE_CHECK_CACHE.get(section);
  let check: MarkupTypeCheck | TyStackTrace | undefined = existing;
  if (check === undefined) {
    const { t } = section;

    switch (t) {
      case 'noop': {
        check = { vars: {}, url: NO_URL };
        break;
      }
      case 'text':
        check = checkText(section);
        break;
      case 'divider':
        check = checkDivider(section);
        break;
      case 'image':
        check = checkImage(section);
        break;
      case 'chart':
        check = checkChart(section);
        break;
      case 'tabs':
        check = checkTabs(section);
        break;
      case 'block':
        check = checkBlock(section);
        break;
      case 'sections':
        check = checkSections(section);
        break;
      case 'macro-apply-vars-to-markup':
        check = checkApplyVarsToMu(section);
        break;
      case 'markup-var':
        check = checkMarkupVar(section);
        break;
      case 'stats':
        check = checkStats(section);
        break;
      case 'page':
        check = checkPage(section);
        break;
      case 'for-each':
        check = checkForEach(section);
        break;
      case 'header':
        check = checkHeader(section);
        break;
      case 'callout':
        check = checkCallout(section);
        break;
      case 'width':
        check = checkWidth(section);
        break;
      case 'insights':
        check = checkInsights(section);
        break;
      case 'macro-section-case':
        check = checkMacroMarkupCase(section);
        break;
      case 'ui-state':
        check = checkUiState(section);
        break;
      case 'expr-controls-v2':
        check = checkExprControlsV2(section);
        break;
      case 'rel-controls-v2':
        check = checkRelControlsV2(section);
        break;
      default:
        return Assert.unreachable(t);
    }
  }

  if (existing === undefined) {
    Object.freeze(check);
    MARKUP_TYPE_CHECK_CACHE.set(section, check);
  }

  return check;
};

const checkChart: MarkupChecker<AST._Chart> = (sec) => {
  const uncheckedVars: Record<string, AST.MacroArgsType>[] = [];

  if (sec.title !== null) {
    const checkedTitle = checkExprIsInterpertable({
      expr: sec.title,
      propertyName: 'title',
    });

    if (checkedTitle instanceof TyStackTrace) {
      return checkedTitle;
    }

    uncheckedVars.push(checkedTitle.vars);
  }

  const checkedRel = checkRel(sec.rel, {
    implements: { attributes: CHART_REL_REQUIREMENTS[sec.config.t] },
  });

  if (checkedRel instanceof TyStackTrace) {
    return checkedRel.withFrame({ frame: sec });
  }

  uncheckedVars.push(checkedRel.vars);

  const vars = mergeMacroVars(...uncheckedVars);

  if (vars instanceof TypeCheckError) {
    return vars.toStackTrace({ frame: sec });
  }

  const res: MarkupTypeCheck = {
    vars,
    url: NO_URL,
  };

  return res;
};

const checkRelControlsV2: MarkupChecker<AST._RelVarControlsV2> = (sec) => {
  const relCheck = checkRel(sec.var);

  if (relCheck instanceof TyStackTrace) {
    return relCheck.withFrame({ frame: sec });
  }

  const res: MarkupTypeCheck = {
    vars: relCheck.vars,
    url: NO_URL,
  };

  return res;
};

const checkExprControlsV2: MarkupChecker<AST._ExprControlsV2> = (sec) => {
  const exprVarD = checkExpr(sec.var);
  if (exprVarD instanceof TyStackTrace) {
    return exprVarD.withFrame({ frame: sec });
  }

  let additonalVars: Record<string, MacroArgsType> = {};

  if (sec.config !== null) {
    switch (sec.config.t) {
      case 'picklist': {
        if (!implementsTy({ subject: exprVarD.ty, req: 'string' })) {
          return TyStackTrace.fromErr(
            { frame: sec },
            new Errs.InvalidUiControlsConfig({
              got: exprVarD.ty,
              configType: sec.config.t,
            })
          );
        }
        break;
      }
      case 'pickfrom': {
        if (!implementsTy({ subject: exprVarD.ty, req: 'string' })) {
          return TyStackTrace.fromErr(
            { frame: sec },
            new Errs.InvalidUiControlsConfig({
              got: exprVarD.ty,
              configType: sec.config.t,
            })
          );
        }

        const relCheck = checkRel(sec.config.rel);

        if (relCheck instanceof TyStackTrace) {
          return relCheck.withFrame({ frame: sec });
        }

        additonalVars = relCheck.vars;

        const reqs = { option: [exprVarD.ty] };

        const implCheck = implementsRel({
          subject: relCheck.attributes,
          reqs,
        });

        if (implCheck.isErr()) {
          return implCheck.error.toStackTrace({
            frame: sec,
          });
        }

        break;
      }
      default:
        return Assert.unreachable(sec.config);
    }
  }

  const vars = mergeMacroVars(additonalVars, exprVarD.vars);

  if (vars instanceof TypeCheckError) {
    return vars.toStackTrace({ frame: sec });
  }

  const res: MarkupTypeCheck = {
    vars,
    url: NO_URL,
  };

  return res;
};

const checkUiState: MarkupChecker<AST._UiState> = (sec) => {
  const checkedMacro = checkMarkup(sec.body.macro);

  if (checkedMacro instanceof TyStackTrace) {
    return checkedMacro.withFrame({ frame: sec });
  }

  const register = mergeUrlConfigs([checkedMacro.url, sec.url], {});

  if (register instanceof TypeCheckError) {
    return register.toStackTrace({ frame: sec });
  }

  const { [sec.body.scope]: _, ...newVars } = checkedMacro.vars;
  return { vars: newVars, url: register };
};

const checkMacroMarkupCase: MarkupChecker<AST._MacroMarkupCase> = (sec) => {
  const checkedWhens: ExprTypeCheck[] = [];
  const checkedThens: MarkupTypeCheck[] = [];

  for (const { when, then } of sec.cases) {
    const whenD = checkExpr(when);

    if (whenD instanceof TyStackTrace) {
      return whenD.withFrame({ frame: sec });
    }

    if (!implementsTy({ subject: whenD.ty, req: 'boolean' })) {
      return TyStackTrace.fromErr(
        { frame: sec, location: 'condition' },
        new Errs.TypeDoesNotMatchExpectation({
          expected: 'boolean',
          found: whenD.ty,
        })
      );
    }

    checkedWhens.push(whenD);

    const thenD = checkMarkup(then);

    if (thenD instanceof TyStackTrace) {
      return thenD.withFrame({ frame: sec });
    }

    checkedThens.push(thenD);
  }

  const checkedElse = checkMarkup(sec.else);

  if (checkedElse instanceof TyStackTrace) {
    return checkedElse.withFrame({ frame: sec });
  }

  const vars = mergeMacroVars(
    checkedElse.vars,
    ...checkedWhens.map((x) => x.vars),
    ...checkedThens.map((x) => x.vars)
  );

  if (vars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: sec }, vars);
  }

  const register = mergeUrlConfigs(
    [checkedElse.url, ...checkedThens.map((x) => x.url)],
    { requireUniqueParams: false }
  );

  if (register instanceof TypeCheckError) {
    return register.toStackTrace({ frame: sec });
  }

  if (register.path !== null) {
    return new Errs.DynamiclySizedMarkupElementsCantConfigUrl({
      names: ['_path'],
    }).toStackTrace({ frame: sec });
  }

  const res: MarkupTypeCheck = { vars, url: register };

  return res;
};

export const checkPage: MarkupChecker<AST._Page> = (section) => {
  const checkedMacro = checkMarkup(section.body.macro);

  if (checkedMacro instanceof TyStackTrace) {
    return checkedMacro.withFrame({ frame: section });
  }

  const { [section.body.scope]: _, ...restOfScopes } = checkedMacro.vars;
  const res: MarkupTypeCheck = {
    vars: restOfScopes,
    url: checkedMacro.url,
  };

  return res;
};

export const checkApp = (sec: AST._App): AST._App | TyStackTrace => {
  for (const [_key, page] of Object.entries(sec.pages)) {
    const checkedPage = checkPage(page);
    if (checkedPage instanceof TyStackTrace) {
      return checkedPage;
    }
  }

  return sec;
};

const checkHeader: MarkupChecker<AST._Header> = (sec) => {
  const checkedCaption = checkExprIsInterpertable({
    expr: sec.caption,
    propertyName: 'caption',
  });

  if (checkedCaption instanceof TyStackTrace) {
    return checkedCaption.withFrame({ frame: sec });
  }

  const checkedTitle = checkExprIsInterpertable({
    expr: sec.title,
    propertyName: 'title',
  });

  if (checkedTitle instanceof TyStackTrace) {
    return checkedTitle.withFrame({ frame: sec });
  }

  const vars = mergeMacroVars(checkedCaption.vars, checkedTitle.vars);

  if (vars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: sec }, vars);
  }

  return { vars, url: NO_URL };
};

const checkText: MarkupChecker<AST._Text> = (sec) => {
  const checked = checkExpr(sec.text);

  if (checked instanceof TyStackTrace) {
    return checked.withFrame({ frame: sec });
  }

  return { vars: checked?.vars ?? {}, url: NO_URL };
};

const checkDivider: MarkupChecker<AST._Divider> = (sec) => {
  const checked = checkExpr(sec.text);

  if (checked instanceof TyStackTrace) {
    return checked.withFrame({ frame: sec });
  }

  return { vars: checked.vars ?? {}, url: NO_URL };
};

const checkImage: MarkupChecker<AST._Image> = (_sec) => {
  return { vars: {}, url: NO_URL };
};

const checkMarkupVar: MarkupChecker<AST._MarkupVar> = (section) => {
  return {
    url: NO_URL,
    vars: {
      [section.scope]: {
        exprs: {},
        rels: {},
        sections: {
          [section.identifier]: { type: 'section', defaulted: false },
        },
      },
    },
  };
};

export const checkApplyVarsToMu: MarkupChecker<AST._MacroApplyVarsToMarkup> = (
  section
) => {
  const fromSection = checkMarkup(section.section);

  if (fromSection instanceof TyStackTrace) {
    return fromSection.withFrame({ frame: section, location: 'from' });
  }

  const scope = fromSection.vars[section.scope] ?? {
    exprs: {},
    sections: {},
    rels: {},
  };

  const checkedVars = checkVars(scope, section.vars);

  if (typeof checkedVars === 'function') {
    return checkedVars(section);
  }

  const varRes = mergeMacroVars(
    fromSection.vars,
    ...Object.values(checkedVars.rels).map((rel) => rel.vars),
    ...Object.values(checkedVars.sections).map((section) => section.vars),
    ...Object.values(checkedVars.exprs).map((expr) => expr.vars)
  );

  if (varRes instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: section }, varRes);
  }

  const register = mergeUrlConfigs([
    fromSection.url,
    ...Object.values(checkedVars.sections).map((section) => section.url),
  ]);

  if (register instanceof TypeCheckError) {
    return register.toStackTrace({ frame: section });
  }

  const { [section.scope]: _, ...newVars } = varRes;
  return { vars: newVars, url: register };
};

const checkBlock: MarkupChecker<AST._Block> = (sec) => {
  const checkedTitle = checkExpr(sec.title);

  if (checkedTitle instanceof TyStackTrace) {
    return checkedTitle.withFrame({ frame: sec });
  }

  const checkedSections = [];

  for (const section of sec.sections) {
    const checkedSection = checkMarkup(section);
    if (checkedSection instanceof TyStackTrace) {
      return checkedSection;
    }

    checkedSections.push(checkedSection);
  }

  const vars = mergeMacroVars(
    ...checkedSections.map((x) => x.vars),
    checkedTitle.vars
  );

  if (vars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: sec }, vars);
  }

  const register = mergeUrlConfigs(checkedSections.map((x) => x.url));

  if (register instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: sec }, register);
  }

  return { vars, url: register };
};

const checkWidth: MarkupChecker<AST._Width> = (sec) => {
  const checkedSection = checkMarkup(sec.section);

  if (checkedSection instanceof TyStackTrace) {
    return checkedSection.withFrame({ frame: sec });
  }
  return checkedSection;
};

const checkSections: MarkupChecker<AST._Sections> = (sec) => {
  const checkedSections = [];

  for (const section of sec.sections) {
    const checkedSection = checkMarkup(section);
    if (checkedSection instanceof TyStackTrace) {
      return checkedSection;
    }

    checkedSections.push(checkedSection);
  }

  const vars = mergeMacroVars(...checkedSections.map((x) => x.vars));

  if (vars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: sec }, vars);
  }

  const register = mergeUrlConfigs(checkedSections.map((x) => x.url));

  if (register instanceof TypeCheckError) {
    return register.toStackTrace({ frame: sec });
  }

  return { vars, url: register };
};

const checkTabs: MarkupChecker<AST._Tabs> = (sec) => {
  const checkedTabs = [];

  for (const { section, title } of sec.tabs) {
    const checkedSection = checkMarkup(section);

    if (checkedSection instanceof TyStackTrace) {
      return checkedSection;
    }

    checkedTabs.push({ title, section: checkedSection });
  }

  const vars = mergeMacroVars(...checkedTabs.map((tab) => tab.section.vars));

  if (vars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: sec }, vars);
  }

  const register = mergeUrlConfigs(checkedTabs.map((tab) => tab.section.url));

  if (register instanceof TypeCheckError) {
    return register.toStackTrace({ frame: sec });
  }

  return { vars, url: register };
};

export const checkStat = (stat: AST._Stat): MarkupTypeCheck | TyStackTrace => {
  const { rel, config } = stat;

  const configTyAllowed: Record<keyof AST._Stat['config'], Ty.Shorthand[]> = {
    title: ['string'],
    style: ['string'],
    info: ['string'],
    unit: ['string'],
    caption: ['string'],
  };

  const relInterface: RelInterfaceShorthand = {
    value: ['int', 'float'],
    from: { allowed: ['int', 'float'], optional: true },
    ..._.mapValues(configTyAllowed, (allowed) => ({
      allowed,
      optional: true,
    })),
  };

  const checkedRel = checkRel(rel, {
    implements: { attributes: relInterface },
  });

  if (checkedRel instanceof TyStackTrace) {
    return checkedRel;
  }

  const checkedConfig: Record<keyof AST._Stat['config'], ExprTypeCheck | null> =
    {
      title: null,
      info: null,
      unit: null,
      style: null,
      caption: null,
    };

  const configKeys = Object.keys(
    checkedConfig
  ) as (keyof AST._Stat['config'])[];

  for (const keyName of configKeys) {
    const relImplsKey = keyName in checkedRel.attributes;
    const configVal = config[keyName];

    if (relImplsKey) {
      if (configVal !== null) {
        return TyStackTrace.fromErr(
          {},
          new Errs.DuplicateStatsKey({ keyName })
        );
      }
    }

    if (configVal === null) {
      continue;
    }

    const checkedConfigValue = checkExpr(configVal, {
      implements: configTyAllowed[keyName],
    });

    if (checkedConfigValue instanceof TyStackTrace) {
      return checkedConfigValue;
    }

    checkedConfig[keyName] = checkedConfigValue;
  }

  const mergedVars = mergeMacroVars(
    ...Object.values(checkedConfig).map((arg) => arg?.vars ?? {})
  );

  if (mergedVars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: null }, mergedVars);
  }

  return { vars: mergedVars, url: NO_URL };
};

const checkStats: MarkupChecker<AST._Stats> = (section) => {
  const { caption, stats } = section;

  const checkedCaption =
    caption === null
      ? null
      : checkExprIsInterpertable({
          propertyName: 'caption',
          expr: caption,
        });

  if (checkedCaption instanceof TyStackTrace) {
    return checkedCaption;
  }

  const checkedStats: MarkupTypeCheck[] = [];

  for (const stat of stats) {
    const checkedStat = checkStat(stat);

    if (checkedStat instanceof TyStackTrace) {
      return checkedStat.withFrame({ frame: section });
    }

    checkedStats.push(checkedStat);
  }

  const vars = mergeMacroVars(
    checkedCaption !== null ? checkedCaption.vars : {},
    ...checkedStats.map((stat) => stat.vars)
  );

  if (vars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: section }, vars);
  }

  return { vars, url: NO_URL };
};

const checkInsights: MarkupChecker<AST._Insights> = (section) => {
  const checkedRel = checkRel(section.rel, {
    implements: {
      attributes: {
        title: ['string'],
        style: {
          allowed: [Ty.enumOf(['negative', 'positive', 'neutral', 'warning'])],
          optional: true,
        },
      },
    },
  });

  if (checkedRel instanceof TyStackTrace) {
    return checkedRel;
  }

  const vars = mergeMacroVars(checkedRel.vars);

  if (vars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: section }, vars);
  }
  return { vars, url: NO_URL };
};

const checkCallout: MarkupChecker<AST._Callout> = (sec) => {
  const { rels } = sec;
  const checkedRels: RelTypeCheck[] = [];
  for (const rel of rels) {
    const check = checkRel(rel, {
      implements: {
        attributes: {
          title: ['string'],
          value: ['string', 'timestamp'],
          style: ['string'],
          unit: ['string'],
        },
      },
    });

    if (check instanceof TyStackTrace) {
      return check;
    }

    checkedRels.push(check);
  }

  const vars = mergeMacroVars(...checkedRels.map((x) => x.vars));

  if (vars instanceof TypeCheckError) {
    return vars.toStackTrace({ frame: sec });
  }

  return { vars, url: NO_URL };
};

const checkExprIsInterpertable = (params: {
  expr: ExprFR;
  propertyName: string;
  type?: Ty.AttributeType | Ty.PrimitiveAttributeType;
}): ExprTypeCheck | TyStackTrace => {
  const { type = 'string', expr, propertyName } = params;

  const checked = checkExpr(expr);

  if (checked instanceof TyStackTrace) {
    return checked;
  }

  if (
    !implementsTy({
      subject: checked.ty.ty,
      req: Ty.ty(type),
    })
  ) {
    return TyStackTrace.fromErr(
      { frame: expr },
      new Errs.InvalidTypeForAttribute({
        name: propertyName,
        actual: checked.ty,
        wanted: [type],
      })
    );
  }

  return checked;
};

const checkForEach: MarkupChecker<AST._ForEach> = (section) => {
  const checkedMacro = checkMarkup(section.body.macro);

  if (checkedMacro instanceof TyStackTrace) {
    return checkedMacro.withFrame({ frame: section });
  }

  const registeredUrl = checkedMacro.url.path !== null;

  if (checkedMacro.url.params.length > 0 || registeredUrl) {
    return TyStackTrace.fromErr(
      { frame: section },
      new Errs.DynamiclySizedMarkupElementsCantConfigUrl({
        names: [...checkedMacro.url.params, ...(registeredUrl ? ['url'] : [])],
      })
    );
  }

  const scopedVars = checkedMacro.vars[section.body.scope];

  let attributes: {
    readonly [name: string]: Ty.ExtendedAttributeType;
  } = {};

  if (scopedVars?.exprs['data']) {
    Assert.assert(scopedVars.exprs['data'].type.ty.k === 'struct');
    attributes = scopedVars.exprs['data'].type.ty.fields;
  }

  const checkedRel = checkRel(section.rel, {
    implements: {
      attributes: _.mapValues(attributes, (ty) => [ty]),
    },
  });

  if (checkedRel instanceof TyStackTrace) {
    return checkedRel;
  }

  const mergedVars = mergeMacroVars(checkedMacro.vars, checkedRel.vars);

  if (mergedVars instanceof TypeCheckError) {
    return TyStackTrace.fromErr({ frame: section }, mergedVars);
  }

  const { [section.body.scope]: _unused, ...restOfScopes } = mergedVars;
  return { vars: restOfScopes, url: NO_URL };
};
