import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  Badge,
  Button,
  Divider,
  Loading,
  Modal,
  Title,
} from '@cotera/client/app/components/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import { DisplayError } from '@cotera/client/app/components/app';
import { CopilotIcon } from '@cotera/client/app/components/app/icons/copilot';
import { Assert } from '@cotera/utilities';
import { useDrafts } from './context';
import { makeStore, StateSetter } from '@cotera/client/app/etc';
import { ChildrenProps } from '../../../components/utils';

type State = {
  discardedSuggestions: string[];
  removedFeatures: string[];
};

const actions = (set: StateSetter<State>) => ({
  discard: (suggestion: string) => {
    set((state) => {
      return {
        discardedSuggestions: [...state.discardedSuggestions, suggestion],
      };
    });
  },
  remove: (featureId: string) => {
    set((state) => {
      return {
        removedFeatures: [...state.removedFeatures, featureId],
      };
    });
  },
});

type Actions = ReturnType<typeof actions>;

export const { hook: useSuggestions, provider: Provider } = makeStore<
  State,
  Actions
>();

export const SuggestionsProvider: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Provider
      state={{ discardedSuggestions: [], removedFeatures: [] }}
      actions={actions}
    >
      {children}
    </Provider>
  );
};

const useSuggestedTopics = (props: {
  topicModelId: string;
  versionId: string;
}) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.suggestedTopics({
      orgId,
      topicModelId: props.topicModelId,
      versionId: props.versionId,
    })
  );

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.suggestTopics({
          topicModelId: props.topicModelId,
          versionId: props.versionId,
        })
      ),
    queryKey,
  });
};

export const SuggestedTopicsContainer: React.FC<{
  show: boolean;
  onClose: () => void;
  topicModelId: string;
  versionId: string;
}> = ({ show, onClose, topicModelId, versionId }) => {
  return (
    <SuggestionsProvider>
      <Modal open={show} onClose={onClose} contentClassName="w-1/2">
        <ErrorBoundary
          fallbackRender={({ error }) => <DisplayError error={error} />}
        >
          <Suspense fallback={<Loading.Dots />}>
            <SuggestedTopics
              versionId={versionId}
              topicModelId={topicModelId}
            />
          </Suspense>
        </ErrorBoundary>
      </Modal>
    </SuggestionsProvider>
  );
};

const SuggestedTopics: React.FC<{
  topicModelId: string;
  versionId: string;
}> = ({ topicModelId, versionId }) => {
  const hiddenSuggestions = useSuggestions((s) => s.discardedSuggestions);
  const removedFeatures = useSuggestions((s) => s.removedFeatures);
  const hide = useSuggestions((s) => s.actions.discard);
  const remove = useSuggestions((s) => s.actions.remove);
  const assign = useDrafts((s) => s.actions.assign);
  const existingTopics = useDrafts((s) => s.topics);
  const addTopic = useDrafts((s) => s.actions.addTopic);

  const { data, refetch, isFetching } = useSuggestedTopics({
    topicModelId,
    versionId,
  });

  const suggestedTopics = data.suggestions.map((suggestion) => {
    return {
      ...suggestion,
      id: existingTopics.find((x) => x.name === suggestion.name)?.id,
    };
  });

  const features = useDrafts((s) => s.features);

  return (
    <div className="flex flex-col w-full overflow-y-scroll">
      <div className="flex flex-row items-center mb-4 justify-between mt-4">
        <div className="flex items-center">
          <CopilotIcon theme="dark" />
          <Title type="title"> Copilot Suggestions</Title>
        </div>
        <div className="flex space-x-2 items-center">
          {isFetching && <Loading.Spinner variant="sm" />}
          <Button
            text="Refresh"
            icon="sparkles"
            onClick={async () => await refetch()}
            small
          />
        </div>
      </div>
      <Divider className="mb-4" />
      <ul>
        {suggestedTopics
          .filter((s) => !hiddenSuggestions.includes(s.name))
          .map((suggestion) => {
            const suggestionFeatures = suggestion.features
              .map((feature) =>
                features.find((x) => x.featureId === feature.id)
              )
              .filter((x) => x !== undefined)
              .filter((x) => !removedFeatures.includes(x.featureId));

            if (suggestionFeatures.length === 0) {
              return null;
            }
            return (
              <li
                key={suggestion.name}
                className="flex flex-col border border-divider py-3 px-4 rounded mb-3"
              >
                <div className="flex justify-between mb-3">
                  <div className="flex items-center">
                    <Title type="section" title={suggestion.name} />
                    {suggestion.new && (
                      <Badge theme="primary" className="ml-2">
                        New
                      </Badge>
                    )}
                    {
                      <Badge theme="secondary" className="ml-2">
                        {suggestionFeatures.reduce(
                          (acc, curr) => acc + curr.documentCount,
                          0
                        )}{' '}
                        messages
                      </Badge>
                    }
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      small
                      text="Accept"
                      icon="check"
                      theme="secondary"
                      onClick={() => {
                        if (suggestion.id !== undefined) {
                          assign(
                            suggestion.id,
                            null,
                            ...suggestionFeatures.map((x) => x.featureId)
                          );
                        } else {
                          addTopic(
                            suggestion.name,
                            null,
                            suggestionFeatures.map((x) => x.featureId)
                          );
                        }
                        hide(suggestion.name);
                      }}
                    />
                    <Button
                      small
                      iconOnly
                      text="Discard"
                      icon="x-mark"
                      theme="error"
                      onClick={() => {
                        hide(suggestion.name);
                      }}
                    />
                  </div>
                </div>
                <ul className="flex">
                  {suggestionFeatures.map((feature) => {
                    return (
                      <li key={feature.featureId} className="mr-2 mb-2">
                        <Badge theme="regular" className="text-nowrap">
                          {feature.content}{' '}
                          <Button
                            small
                            iconOnly
                            text="remove"
                            inline
                            icon="x-mark"
                            onClick={() => remove(feature.featureId)}
                          />
                        </Badge>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
