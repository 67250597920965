import { z, ZodObject, ZodSchema } from 'zod';
import {
  Text,
  NotFound,
  DisplayError,
  Loading,
} from '@cotera/client/app/components';
import { useParams } from 'react-router-dom';
import React, { ReactNode, useCallback, useState } from 'react';
import { useTenantedClient } from '../../stores/org';
import { FIVETRAN_CONNECTOR_SCHEMAS } from '@cotera/api';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Layout } from '@cotera/client/app/layout';
import { useFivetranConnectors } from '@cotera/client/app/hooks/use-fivetran-connections';
import { ZodInput } from '@cotera/client/app/components/forms/zod-form/input';
import { Button } from '@cotera/client/app/components/ui';

const sourcePageParams = z.object({ type: z.string() });

export const SourcePage = () => {
  const params = useParams();
  const parsedParams = sourcePageParams.safeParse(params);
  const { data, error, isLoading, isError } = useFivetranConnectors();

  if (isLoading || data === undefined) {
    return (
      <Layout>
        <Loading.Dots />
      </Layout>
    );
  }

  if (isError) {
    return <Layout>{JSON.stringify(error)}</Layout>;
  }

  if (!parsedParams.success) {
    return (
      <Layout>
        <NotFound resource={`Source Type ${params['type']}`} />
      </Layout>
    );
  }

  const connectorId = parsedParams.data.type;

  const metadata = FIVETRAN_CONNECTOR_SCHEMAS[connectorId];

  if (metadata === undefined) {
    throw new Error(`Connector metadata for type "${connectorId}" not found`);
  }

  const connectors =
    data === null ? [] : data.connectors.map((c) => c.connector);

  if (!connectors.includes(connectorId) || data === null) {
    return <Source {...metadata} />;
  }

  // We can now assume that the connector is in the list of connectors.
  const connector = data.connectors.filter(
    (c) => c.connector === connectorId
  )[0]!;

  return (
    <Layout>
      <ConnectedSource
        name={metadata.name}
        connectorId={connectorId}
        connector={connector}
      />
    </Layout>
  );
};

const ConnectedSource: React.FC<{
  name: string;
  connectorId: string;
  connector: {
    id: string;
    config?: any;
  };
}> = ({ name, connectorId, connector }) => {
  const client = useTenantedClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const reAuthenticate = useCallback(async () => {
    setLoading(true);
    // Create the connect card
    const cardResult = await client.warehouse.etl.createConnectCard({
      id: connector.id,
    });

    if (cardResult.isErr()) {
      setError(error);
      return;
    }

    if (cardResult.value === null) {
      setError(error);
      return;
    }

    const { url } = cardResult.value;

    // Open the connect card in a new tab.
    window.open(url, '_blank');
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <div className="flex flex-col justify-center items-center w-full h-full -translate-y-36">
      <SourceLayout name={name} connectorId={connectorId}>
        <Text.Title type="title" className="mb-2">
          {name}
        </Text.Title>
        <Text.Caption className="mb-8">
          You have already connected {name} to Cotera.
        </Text.Caption>
        <div className="mt-8 flex justify-start">
          <button
            className="bg-gray-700 text-gray-50 hover:bg-gray-800 px-3 py-2 rounded text-sm font-medium"
            onClick={reAuthenticate}
            disabled={loading}
          >
            {loading ? 'Please wait...' : 'Re-authenticate'}
          </button>
        </div>
      </SourceLayout>
    </div>
  );
};

const Source: React.FC<{
  id: string;
  name: string;
  type: string;
  schema: ZodSchema;
}> = ({ id: connectorId, name, schema }) => {
  const client = useTenantedClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      schema: connectorId,
    },
  });

  // Omit schema from the form as we provide a default value.
  const objectSchema = (schema as ZodObject<any>).omit({ schema: true });

  const onSubmit = useCallback(
    async (config: any) => {
      setLoading(true);

      // Create the connector
      const connResult = await client.warehouse.etl.createConnector({
        connector: connectorId,
        config,
      });
      if (connResult.isErr()) {
        setError(error);
        return;
      }
      const { id } = connResult.value;

      // Create the connect card
      const cardResult = await client.warehouse.etl.createConnectCard({ id });

      if (cardResult.isErr()) {
        setError(error);
        return;
      }

      if (cardResult.value === null) {
        setError(error);
        return;
      }

      const { url } = cardResult.value;

      // Open the connect card in a new tab.
      window.open(url, '_blank');
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );

  return (
    <Layout>
      <FormProvider {...methods}>
        <form
          className="flex flex-col justify-center items-center w-full h-full -translate-y-36"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <SourceLayout name={name} connectorId={connectorId}>
            <Text.Title type="title" className="mb-2">
              Connect {name} to Cotera
            </Text.Title>
            <Text.Caption className="mb-8">
              Once you click the button below you'll be taken to a new page to
              authenticate with {name}.
            </Text.Caption>
            <ZodInput
              zodSchema={objectSchema}
              name=""
              optional={false}
              hideOptionals={true}
            />
            <div className="mt-8 flex justify-end">
              {error !== null && <DisplayError error={error} />}
              {error === null && (
                <Button
                  theme="primary"
                  text={loading ? 'Please wait...' : 'Authenticate'}
                  disabled={loading}
                />
              )}
            </div>
          </SourceLayout>
        </form>
      </FormProvider>
    </Layout>
  );
};

const SourceLayout: React.FC<{
  children: ReactNode;
  name: string;
  connectorId: string;
}> = ({ children, name, connectorId }) => {
  return (
    <div className="w-[400px]">
      <div className="mb-8 flex flex-row items-center">
        <img
          src={`https://cotera.co/images/${connectorId}/public`}
          alt={name}
          className="h-24 w-24 flex-none rounded-lg bg-white object-cover ring-1 ring-slate-900/10"
        />
      </div>
      {children}
    </div>
  );
};
