import {
  DisplayError,
  Loading,
  NotFound,
  ResultBoundary,
} from '@cotera/client/app/components';
import {
  DatasetNode,
  FilterNode,
  SampleNode,
  SideModal,
  SideModalProvider,
  TransformableRelation,
  WorkspaceNodes,
  WorkspaceViewModel,
} from '@cotera/client/app/components/app';
import { Layout } from '@cotera/client/app/layout';
import React, { Suspense } from 'react';
import { Assert } from '@cotera/utilities';
import { useParams } from 'react-router-dom';
import { Center } from '@cotera/client/app/components/ui';
import { Relation } from '@cotera/era';
import { ErrorBoundary } from 'react-error-boundary';
import { useDefinition } from '../../etc/data/manifest';
import { useViewModel } from '@cotera/client/app/etc';
import { useEraScopesAwareRelIR } from '@cotera/client/app/pages/apps/compiler/macro-expansion/scopes';

export const ExploreDataSet = () => {
  return (
    <Layout>
      <ErrorBoundary
        fallbackRender={({ error }) => <DisplayError error={error} />}
      >
        <Suspense
          fallback={
            <Center>
              <Loading.Dots />
            </Center>
          }
        >
          <SideModalProvider>
            <ExploreDataSetView />
            <SideModal />
          </SideModalProvider>
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
};

const ExploreDataSetView = () => {
  const { id } = useParams();
  Assert.assert(id !== undefined);
  const { data: def } = useDefinition({ id });

  return (
    <ResultBoundary
      result={def}
      fallback={() => <NotFound resource={`Definition "${id}"`} />}
    >
      {(def) => <WithDef id={id} def={Relation.wrap(def)} key={id} />}
    </ResultBoundary>
  );
};

const WithDef: React.FC<{ id: string; def: Relation }> = ({ id, def: rel }) => {
  const chartRelIR = useEraScopesAwareRelIR(rel);
  const dataset = new DatasetNode.ViewModel(
    id,
    { x: 0, y: 0 },
    TransformableRelation.fromRel(Relation.wrap(chartRelIR))
  );

  const filter = new FilterNode.ViewModel(
    'Filter',
    { x: 0, y: 0 },
    { connective: 'and', items: [] },
    dataset,
    {
      useAutoFilters: false,
    }
  );

  const sample = new SampleNode.ViewModel(id, { x: 0, y: 0 }, filter);

  const workspace = new WorkspaceViewModel([dataset, filter, sample]);

  const vm = useViewModel(workspace, [id]);

  return <WorkspaceNodes vm={vm} />;
};
