import {
  UI,
  Page,
  AST,
  f,
  BarChart,
  Now,
  And,
  UnionAll,
  Not,
  Case,
  If,
  LineChart,
  Sum,
  Relation,
  Expression,
} from '@cotera/era';
import _ from 'lodash';

const LastTwoFullMonths = ({
  rel,
  segment,
}: {
  rel: Relation;
  segment: string | Expression;
}) =>
  rel
    .where((t) =>
      t.attr('date').lte(Now().dateSub('weeks', 4).dateTrunc('month'))
    )
    .where((t) => t.attr('segment').eq(segment))
    .select((t) => t.star(), {
      limit: 1,
      orderBy: (t) => ({
        expr: t.attr('date'),
        direction: 'desc',
      }),
    })
    .innerJoin(
      rel
        .where((t) =>
          And(
            t
              .attr('date')
              .dateTrunc('month')
              .eq(Now().dateSub('weeks', 8).dateTrunc('month')),
            t.attr('segment').eq(segment)
          )
        )
        .select((t) => t.star(), {
          limit: 1,
          orderBy: (t) => ({
            expr: t.attr('date'),
            direction: 'desc',
          }),
        }),
      (current, previous) => ({
        on: true,
        select: {
          ...current.renameWith((n) => `current_${n}`),
          ...previous.renameWith((n) => `previous_${n}`),
        },
      })
    );

export const Segment = ({
  RfmLifecycleSummary,
  CurrentChange,
  CurrentRateOfChange,
  boundaryCustomerCategory,
  customerSegments,
  UserAnalysis,
  metrics,
}: {
  RfmLifecycleSummary: Relation;
  CurrentChange: Relation;
  CurrentRateOfChange: Relation;
  UserAnalysis?: Relation;
  boundaryCustomerCategory: string;
  customerSegments: {
    active: string[];
    inactive: string[];
    new: string[];
  };
  metrics: Record<string, { total: string; avg: string; unit?: string }>;
}): AST._Page =>
  Page(['segment'] as const, ({ segment }) => [
    UI.Header({
      title: segment,
      caption: 'Breakdown of customer behaviour in this segment',
    }),
    UI.Block([
      UI.Stats([
        UnionAll([
          LastTwoFullMonths({
            rel: RfmLifecycleSummary,
            segment,
          }).select((t) => ({
            id: 'customers',
            title: 'Total Customers in segment',
            value: t.attr('current_count'),
            from: t.attr('previous_count'),
            unit: 'number',
          })),
          ...Object.entries(metrics).map(([metric, { total, unit }]) =>
            LastTwoFullMonths({
              rel: RfmLifecycleSummary,
              segment,
            }).select((t) => ({
              id: 'metric',
              title: `Total ${_.startCase(metric)} in segment`,
              value: t.attr(`current_${total}`),
              from: t.attr(`previous_${total}`),
              unit: unit ?? 'number',
            }))
          ),
        ])
          .where((t) =>
            segment
              .oneOf([...customerSegments.active, ...customerSegments.new])
              .or(
                Not(
                  segment.oneOf([
                    ...customerSegments.active,
                    ...customerSegments.new,
                  ])
                ).and(t.attr('id').eq('customers'))
              )
          )
          .chart.Stat((t) => t.pick('value', 'from', 'unit', 'title')),
      ]),
    ]),
    UI.Block([
      CurrentChange.where((t) =>
        t.attr('starting_segment').eq(segment)
      ).chart.SankeyChart(
        (t) => ({
          from: t.attr('starting_segment'),
          to: t.attr('current_segment'),
          value: t.attr('count'),
          style: Case(
            [
              {
                when: t.attr('current_segment').eq(boundaryCustomerCategory),
                then: 'neutral',
              },
              {
                when: t
                  .attr('current_segment')
                  .oneOf(customerSegments.inactive),
                then: 'negative',
              },
            ],
            { else: 'positive' }
          ),
        }),
        {
          size: 'lg',
          title: 'Customer Transitions (last 3 months)',
        }
      ),
    ]),
    UserAnalysis
      ? UI.Block([
          BarChart({
            title: 'LTV By First Month Purchase',
            rel: UserAnalysis.where((t) =>
              And(
                t.attr('RFM_CATEGORY').eq(segment),
                t
                  .attr('FIRST_ORDER_MONTH')
                  .gt(Now().dateSub('weeks', 104).dateTrunc('month'))
              )
            )
              .groupBy((t) => ({
                cohort_month: t.attr('FIRST_ORDER_MONTH'),
              }))
              .select((t) => ({
                x: t.attr('cohort_month'),
                y: Sum(t.attr('LIFETIME_TOTAL_SPENT')),
                category: 'LTV Of Cohort',
              })),
            type: 'stacked',
            trendline: false,
            axis: {
              x: {
                scale: 'month',
              },
              y: {
                label: 'LTV Of Cohort',
              },
            },
            format: {
              y: 'USD',
            },
          }),
        ])
      : null,
    [
      UI.Block([
        BarChart({
          title: f`${segment} per month`,
          size: 'lg',
          type: 'grouped',
          trendline: true,
          rel: CurrentRateOfChange.where((t) =>
            t.attr('starting_rfm_category').eq(segment)
          )
            .select((t) => ({
              x: t.attr('date'),
              y: t.attr('status_count'),
              category: Case([
                {
                  when: t.attr('category_status').eq('Segment Retention Rate'),
                  then: 'Retained',
                },
                {
                  when: t.attr('category_status').eq('Segment Loss Rate'),
                  then: 'Exited',
                },
                {
                  when: t
                    .attr('category_status')
                    .eq('Segment Improvement Rate'),
                  then: 'Improved',
                },
              ]),
            }))
            .select((t) => {
              const style = Case([
                {
                  when: t.attr('category').eq('Retained'),
                  then: 'neutral',
                },
                {
                  when: t.attr('category').eq('Exited'),
                  then: 'negative',
                },
                {
                  when: t.attr('category').eq('Improved'),
                  then: 'positive',
                },
              ]);
              return {
                ...t.star(),
                style: Case(
                  [
                    {
                      when: segment
                        .oneOf([...customerSegments.inactive])
                        .and(t.attr('category').eq('Retained')),
                      then: If(style.eq('neutral'), {
                        then: 'negative',
                        else: style,
                      }),
                    },
                  ],
                  { else: style }
                ),
              };
            }),
          axis: {
            x: { scale: 'month' },
            y: { label: `Number of Customers in segment` },
          },
        }),
      ]),
      UI.Block([
        LineChart({
          size: 'sm',
          title: 'Customers in segment per month',
          rel: CurrentRateOfChange.where((t) =>
            t.attr('starting_rfm_category').eq(segment)
          )
            .groupBy((t) => ({
              category: t.attr('starting_rfm_category'),
              date: t.attr('date').dateTrunc('month'),
            }))
            .select((t) => ({
              ...t.pick('category', 'date'),
              count: Sum(t.attr('status_count')),
            }))
            .select((t) => ({
              x: t.attr('date'),
              y: t.attr('count'),
              category: t.attr('category'),
              style: Case([
                {
                  when: t.attr('category').eq(boundaryCustomerCategory),
                  then: 'neutral',
                },
                {
                  when: segment.oneOf(customerSegments.inactive),
                  then: 'negative',
                },
                {
                  when: segment.oneOf(customerSegments.active),
                  then: 'positive',
                },
              ]),
            })),
          axis: {
            x: { scale: 'month' },
            y: { label: 'Number of customers' },
          },
        }),
      ]),
    ],
  ]).preload({
    segment: [
      ...customerSegments.active,
      ...customerSegments.new,
      ...customerSegments.inactive,
    ],
  });
