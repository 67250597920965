import {
  Asc,
  CountOver,
  LeadOver,
  Relation,
  Today,
  Values,
  And,
} from '@cotera/era';
import { rfmNumComparison } from './helpers';

export const makeCurrentRateOfChangeAIN = ({
  HistoricalCustomerRfm,
}: {
  HistoricalCustomerRfm: Relation;
}) => {
  const CurrentRateOfChangeAIN = HistoricalCustomerRfm.where((t) =>
    t.attr('TODAY').eq(t.attr('TODAY').dateTrunc('month'))
  )
    .select((t) => ({
      ...t.pick('TODAY'),
      CUSTOMER_ID: t.attr('__COTERA_CUSTOMER_ID'),
      STARTING_RFM_CATEGORY: t.attr('ACTIVE_INACTIVE_NEW'),
      STARTING_RFM_NUM: t.attr('ACTIVE_INACTIVE_NEW_NUM'),
      ENDING_RFM_CATEGORY: LeadOver(t.attr('ACTIVE_INACTIVE_NEW'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
      ENDING_RFM_NUM: LeadOver(t.attr('ACTIVE_INACTIVE_NEW_NUM'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
    }))
    .select((t) => ({
      ...t.star(),
      CATEGORY_STATUS: rfmNumComparison(t),
    }))
    .where((t) => t.attr('ENDING_RFM_CATEGORY').isNotNull())
    .select(
      (t) => ({
        ...t.pick(
          'TODAY',
          'STARTING_RFM_CATEGORY',
          'STARTING_RFM_NUM',
          'CATEGORY_STATUS'
        ),
        STATUS_COUNT: CountOver(true, {
          partitionBy: [
            t.attr('STARTING_RFM_CATEGORY'),
            t.attr('CATEGORY_STATUS'),
            t.attr('TODAY'),
          ],
        }),
        TOTAL_COUNT: CountOver(true, {
          partitionBy: [t.attr('STARTING_RFM_CATEGORY'), t.attr('TODAY')],
        }),
      }),
      { distinct: true }
    )
    .select((t) => ({
      ...t.except('TODAY'),
      DATE: t.attr('TODAY'),
      RATE: t.attr('STATUS_COUNT').cast('float').div(t.attr('TOTAL_COUNT')),
    }))
    .orderBy((t) => [
      Asc(t.attr('STARTING_RFM_CATEGORY')),
      Asc(t.attr('CATEGORY_STATUS')),
      Asc(t.attr('DATE')),
    ])
    .select((t) => t.renameWith((name) => name.toLowerCase()));

  return CurrentRateOfChangeAIN;
};

export const makeCurrentRateOfChangeAINAnnual = ({
  HistoricalCustomerRfm,
}: {
  HistoricalCustomerRfm: Relation;
}) => {
  const YearsToAdd = Values([
    { YEAR_COUNT: 0 },
    { YEAR_COUNT: 1 },
    { YEAR_COUNT: 2 },
    { YEAR_COUNT: 3 },
    { YEAR_COUNT: 4 },
    { YEAR_COUNT: 5 },
  ]).select((t) => ({
    ...t.star(),
    DAYS_TO_ADD: t.attr('YEAR_COUNT').mul(365),
  }));

  const CustomerAnnualBenchmarks = HistoricalCustomerRfm.where((t) =>
    t.attr('CREATED_AT').between(t.attr('TODAY'), t.attr('DAY_AFTER'))
  ).leftJoin(YearsToAdd, (t, y) => ({
    on: t.attr('TODAY').dateAdd('days', y.attr('DAYS_TO_ADD')).lt(Today()),
    select: {
      ...t.pick('__COTERA_CUSTOMER_ID', 'CREATED_AT'),
      ...y.pick('YEAR_COUNT', 'DAYS_TO_ADD'),
      DAY_OF_INTEREST: t.attr('TODAY').dateAdd('days', y.attr('DAYS_TO_ADD')),
    },
  }));

  const CurrentRateOfChangeAIN = HistoricalCustomerRfm.innerJoin(
    CustomerAnnualBenchmarks,
    (rfm, bench) => ({
      on: And(
        rfm.attr('__COTERA_CUSTOMER_ID').eq(bench.attr('__COTERA_CUSTOMER_ID')),
        bench.attr('DAY_OF_INTEREST').gte(rfm.attr('TODAY')),
        bench.attr('DAY_OF_INTEREST').lt(rfm.attr('DAY_AFTER'))
      ),
      select: {
        ...rfm.star(),
      },
    })
  )
    .select((t) => ({
      TODAY: t.attr('TODAY').dateTrunc('year'),
      CUSTOMER_ID: t.attr('__COTERA_CUSTOMER_ID'),
      STARTING_RFM_CATEGORY: t.attr('ACTIVE_INACTIVE_NEW'),
      STARTING_RFM_NUM: t.attr('ACTIVE_INACTIVE_NEW_NUM'),
      ENDING_RFM_CATEGORY: LeadOver(t.attr('ACTIVE_INACTIVE_NEW'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
      ENDING_RFM_NUM: LeadOver(t.attr('ACTIVE_INACTIVE_NEW_NUM'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
    }))
    .select((t) => ({
      ...t.star(),
      CATEGORY_STATUS: rfmNumComparison(t),
    }))
    .where((t) => t.attr('ENDING_RFM_CATEGORY').isNotNull())
    .select(
      (t) => ({
        ...t.pick(
          'TODAY',
          'STARTING_RFM_CATEGORY',
          'STARTING_RFM_NUM',
          'CATEGORY_STATUS'
        ),
        STATUS_COUNT: CountOver(true, {
          partitionBy: [
            t.attr('STARTING_RFM_CATEGORY'),
            t.attr('CATEGORY_STATUS'),
            t.attr('TODAY'),
          ],
        }),
        TOTAL_COUNT: CountOver(true, {
          partitionBy: [t.attr('STARTING_RFM_CATEGORY'), t.attr('TODAY')],
        }),
      }),
      { distinct: true }
    )
    .select((t) => ({
      ...t.except('TODAY'),
      DATE: t.attr('TODAY'),
      RATE: t.attr('STATUS_COUNT').cast('float').div(t.attr('TOTAL_COUNT')),
    }))
    .orderBy((t) => [
      Asc(t.attr('STARTING_RFM_CATEGORY')),
      Asc(t.attr('CATEGORY_STATUS')),
      Asc(t.attr('DATE')),
    ])
    .select((t) => t.renameWith((name) => name.toLowerCase()));

  return CurrentRateOfChangeAIN;
};
