import { Contract, GET, POST } from '@cotera/contracts';
import z from 'zod';

export const TopicsContract = Contract.new({
  detectedFeatures: GET({
    params: z.object({
      topicModelId: z.string(),
      versionId: z.string(),
      search: z.object({
        query: z.string().optional(),
        topicId: z.string().optional(),
        assigned: z.boolean().optional(),
        ignored: z.boolean().optional(),
      }),
      limit: z.number().optional(),
    }),
    output: z.object({
      features: z
        .object({
          featureId: z.string(),
          ignored: z.boolean(),
          content: z.string(),
          type: z.string(),
          createdAt: z.date(),
          sentiment: z.number().nullable(),
          documentCount: z.number(),
          topics: z
            .object({
              name: z.string(),
              id: z.string().nullable(),
            })
            .array(),
        })
        .array(),
      total: z.number(),
    }),
    errors: z.object({
      errorType: z.enum(['NotFound']),
      message: z.string().optional(),
    }),
  }),

  feature: GET({
    params: z.object({
      topicModelId: z.string(),
      featureName: z.string(),
    }),
    output: z.object({
      featureId: z.string(),
      ignored: z.boolean(),
      content: z.string(),
      type: z.string(),
      createdAt: z.date(),
    }),
    errors: z.object({
      errorType: z.enum(['NotFound']),
      message: z.string().optional(),
    }),
  }),
  suggestTopics: GET({
    params: z.object({
      topicModelId: z.string(),
      versionId: z.string(),
    }),
    output: z.object({
      suggestions: z
        .object({
          name: z.string(),
          new: z.boolean(),
          features: z.array(
            z.object({
              id: z.string(),
            })
          ),
        })
        .array(),
    }),
    errors: z.object({
      errorType: z.enum(['LlmError', 'ValidationError', 'NotFound']),
      message: z.string().optional(),
    }),
  }),
  versions: GET({
    params: z.object({
      topicModelId: z.string(),
    }),
    output: z
      .object({
        version: z.string(),
        id: z.string(),
        published: z.boolean(),
        topics: z
          .object({
            id: z.string(),
            name: z.string(),
          })
          .array(),
      })
      .array(),
    errors: z.never(),
  }),
  topics: GET({
    params: z.object({
      versionId: z.string(),
      filter: z
        .object({
          topicId: z.string().optional(),
        })
        .optional(),
    }),
    output: z
      .object({
        name: z.string(),
        id: z.string(),
        description: z.string().nullable(),
        features: z
          .object({
            id: z.string(),
            content: z.string(),
            documentCount: z.number(),
            ignored: z.boolean(),
          })
          .array(),
      })
      .array(),
    errors: z.never(),
  }),
  createModel: POST({
    params: z.object({
      entityId: z.string(),
      columnName: z.string(),
    }),
    output: z.object({
      topicModelId: z.string(),
    }),
    errors: z.object({
      errorType: z.literal('AlreadyExists'),
      message: z.string().optional(),
    }),
  }),
  save: POST({
    params: z.object({
      versionId: z.string(),
      topics: z
        .object({
          id: z.string(),
          action: z.enum(['delete', 'upsert']).optional(),
          name: z.string(),
          description: z.string().nullable(),
          features: z.array(z.string()),
        })
        .array(),
      features: z
        .object({
          id: z.string(),
          ignored: z.boolean(),
        })
        .array(),
    }),
    output: z.object({}),
    errors: z.object({
      errorType: z.literal('SyncError'),
      message: z.string().optional(),
    }),
  }),
  match: POST({
    params: z.object({
      topicModelId: z.string(),
      summaryUddId: z.string(),
      summaryPromptId: z.string(),
      topicExtractionPromptId: z.string(),
      versionId: z.string(),
      messages: z
        .object({
          content: z.string(),
          id: z.string(),
          coteraStableId: z.string(),
        })
        .array(),
    }),
    output: z.object({
      matches: z
        .object({
          topicId: z.string(),
          sentiment: z.number(),
          coteraStableId: z.string(),
        })
        .array(),
    }),
    errors: z.object({
      message: z.string(),
      errorType: z.enum([
        'NotFound',
        'LlmError',
        'EmbeddingsError',
        'ValidationError',
        'NoMatch',
      ]),
    }),
  }),
  documentsForFeatures: GET({
    params: z.object({
      featureIds: z.string().array(),
    }),
    output: z.object({
      documents: z
        .object({
          coteraStableId: z.string(),
          features: z
            .object({
              id: z.string(),
              content: z.string(),
            })
            .array(),
        })
        .array(),
    }),
    errors: z.never(),
  }),
});
