import { Relation, Ty } from '@cotera/era';
import _ from 'lodash';
import { err, ok, Result } from 'neverthrow';
import { UserDefinedDimensions } from '../cookbook/user-defined-dimensions';
import { Assumption, PrimaryKey } from './assumption';
import type { CoteraCol, ResolutionError, ViewResolution } from './cotera-col';

export class EntityResolver {
  static COLUMN_NAMESPACE_SEPERATOR: string = '__';

  static isValidBaseColumnName(name: string): boolean {
    return (
      /^[_a-zA-Z][_a-zA-Z0-9]*$/.test(name) &&
      !name.includes(EntityResolver.COLUMN_NAMESPACE_SEPERATOR)
    );
  }

  static nameViewToColumnRef({
    name,
    view,
  }: {
    name: string;
    view: string | null;
  }) {
    return view === null
      ? name
      : `${name}${EntityResolver.COLUMN_NAMESPACE_SEPERATOR}${view}`;
  }

  static parseColumnRef(colRef: string): { name: string; view: string | null } {
    const breakPoint = colRef.indexOf(
      EntityResolver.COLUMN_NAMESPACE_SEPERATOR
    );

    if (breakPoint === -1) {
      return { name: colRef, view: null };
    }

    return {
      name: colRef.substring(0, breakPoint),
      view: colRef.substring(
        breakPoint + EntityResolver.COLUMN_NAMESPACE_SEPERATOR.length
      ),
    };
  }

  constructor(readonly pk: PrimaryKey, private readonly columns: CoteraCol[]) {}

  get attributes(): Record<string, Ty.ExtendedAttributeType> {
    return Object.fromEntries(
      [...this.columns, this.pk].map((col) => [col.name, col.ty])
    );
  }

  attributesWithViews(): {
    [name: string]: {
      ty: Ty.ExtendedAttributeType;
      views: Record<string, Ty.ExtendedAttributeType>;
    };
  } {
    return Object.fromEntries(
      [...this.columns, this.pk].map((col) => [
        col.name,
        { ty: col.ty, views: col.availableViews() },
      ])
    );
  }

  tryForColumns(
    colNames: readonly string[]
  ): Result<Relation, ResolutionError> {
    const visited = new Set<string>();
    const inProgress = new Set<string>();
    const resolutions: { name: string; resolution: ViewResolution }[] = [];

    const dfs = (colName: string): Result<null, ResolutionError> => {
      if (inProgress.has(colName)) {
        return err({ t: 'cycle-detected', name: colName });
      }

      if (!visited.has(colName)) {
        inProgress.add(colName);
        const { view, name } = EntityResolver.parseColumnRef(colName);
        const col = [...this.columns, this.pk].find((col) => col.name === name);
        if (col === undefined) {
          return err({ t: 'col-not-found', name });
        }

        const resolution = col.resolve(view === null ? 'default' : { view });

        if (resolution.isErr()) {
          return err(resolution.error);
        }

        const deps =
          'dependsOn' in resolution.value ? resolution.value.dependsOn : [];

        for (const dep of deps) {
          const res = dfs(dep);
          if (res.isErr()) {
            return err(res.error);
          }
        }
        inProgress.delete(colName);
        visited.add(colName);
        resolutions.push({ name: colName, resolution: resolution.value });
      }

      return ok(null);
    };

    for (const colName of colNames) {
      if (!visited.has(colName)) {
        const res = dfs(colName);

        if (res.isErr()) {
          return err(res.error);
        }
      }
    }

    const baseAssumptions = Assumption.mergeAssumptions(
      this.pk,
      resolutions.flatMap((x) =>
        x.resolution.t === 'assume' ? [x.resolution.assume] : []
      )
    );

    if (baseAssumptions.isErr()) {
      return err(baseAssumptions.error);
    }

    let rel: Relation = baseAssumptions.value;

    const udds = resolutions.flatMap(({ name, resolution }) =>
      resolution.t === 'udd' ? [{ name, ...resolution }] : []
    );

    if (udds.length > 0) {
      const uddConfig = new UserDefinedDimensions({
        entityName: this.pk.idType.name,
        attributes: Object.fromEntries(udds.map(({ name, ty }) => [name, ty])),
      });

      rel = uddConfig.join(rel, { identifier: (t) => t.attr(this.pk.name) });
    }

    let pending = resolutions
      .map((res) =>
        res.resolution.t === 'apply'
          ? {
              name: res.name,
              apply: res.resolution.apply,
              dependsOn: res.resolution.dependsOn ?? [],
            }
          : null
      )
      .filter((x) => x !== null);

    while (pending.length > 0) {
      const [level, remaining] = _.partition(pending, (x) =>
        x.dependsOn.every((name) => name in rel.attributes)
      );

      if (level.length === 0 && remaining.length !== 0) {
        throw new Error('Unresolvable??');
      }

      rel = rel.select((t) => {
        const newAttrs = level.map((x) => [
          x.name,
          x.apply.t == 'expr' ? x.apply.expr : t.sql([x.apply.eraql]),
        ]);

        return {
          ...t.star(),
          ...Object.fromEntries(newAttrs),
        };
      });

      pending = remaining;
    }

    return ok(rel.select((t) => t.pick('id', ...colNames)));
  }
}
