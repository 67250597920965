import { ChildrenProps } from './components/utils';
import { Runtime } from '@cotera/sdk/core';
import { useAppData, useWhoami } from './stores/org';
import { ProvideEraScopes } from './pages/apps/compiler/macro-expansion/scopes';
import { useIdeConfig } from './hooks/entities';
import { useSinkCursors } from './pages/ide/events/hooks';
import { Relation } from '@cotera/era';
import { useWarehouseConnection } from './hooks';
import { useEntities } from './pages/entities/hooks';
import { useDecisionTreeRDAData } from './hooks/use-decision-tree-rda';

export const RuntimeDataProvider: React.FC<ChildrenProps & {}> = ({
  children,
}) => {
  const org = useWhoami((x) => x.org);
  const { data: ide } = useIdeConfig();
  const { data: cursors } = useSinkCursors();
  const { data: entities } = useEntities();
  const eventStreams = useAppData((x) => x.skeleton.eventStreams);
  const definitions = useAppData((x) => x.skeleton.definitions);
  const { data: wc } = useWarehouseConnection();
  const WRITE_SCHEMA = wc?.writeSchema ?? '@@write-schema';
  const { data: decisionTreeOutputs } = useDecisionTreeRDAData();

  const data: Runtime.RuntimeDataProvider = {
    '@@cotera-manifest-eager-defs': Object.fromEntries(
      Object.entries(definitions)
        .map(([name, def]) =>
          def.eager ? ([name, Relation.wrap(def.eager)] as const) : null
        )
        .filter((x) => x !== null)
    ),
    '@@cotera-entity-refs': Object.fromEntries(
      entities.map((entity) => [
        entity.name,
        { name: entity.name, cols: entity.columns },
      ])
    ),
    '@@cotera-system': { WRITE_SCHEMA },
    '@@cotera-org': {
      ORG_ID: org.id,
      ORG_NAME: org.name,
    },
    '@@cotera-entities': {
      WRITE_SCHEMA,
      DEFINITIONS: ide.entities.map((entity) => ({
        id: entity.id,
        name: entity.name,
      })),
    },
    '@@cotera-manifest-def-information-schema': {
      INFORMATION: Object.entries(definitions).flatMap(([table_name, def]) =>
        def.eager
          ? Object.entries(def.attributes).map(([column_name, ty]) => ({
              table_name,
              column_name,
              ty,
            }))
          : []
      ),
    },
    '@@cotera-events': {
      CURSORS: cursors.map((x) => ({
        event_stream_id: x.streamId,
        cotera_stable_id: x.coteraStableId,
        paused_at: x.pausedAt,
        sink_id: x.sinkId,
        entity_id: x.entityId,
        detected_at_time: x.detectedAt,
      })),
    },
    '@@cotera-manifest-event-streams': {
      STREAMS: eventStreams.map((stream) => ({
        entity_id: stream.entityId.name,
        event_stream_id: stream.name,
      })),
      SINKS: eventStreams.flatMap((stream) =>
        Object.entries(stream.sinks).map(([sink_id, { config }]) => ({
          event_stream_id: stream.name,
          entity_id: stream.entityId.name,
          sink_id,
          type: config.t,
        }))
      ),
    },
    '@@cotera-decision-tree-outputs': decisionTreeOutputs.data,
  };

  const scopes = Runtime.provide(data);
  return <ProvideEraScopes scopes={scopes}>{children}</ProvideEraScopes>;
};
