import { createContext, useCallback } from 'react';
import {
  LinkProps,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { classNames } from '../../utils';
import {
  StateSetter,
  makeStoreProvider,
  makeStoreContextHook,
} from '@cotera/client/app/etc';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Button } from '@cotera/client/app/components/ui';
import { ChildrenProps } from '@cotera/client/app/components/utils';

const NEW_TAB_VIEW_ID = '__internal_new_tab__';

type TabsState = {
  default?: string;
  active?: string;
  mode: 'link' | 'controlled';
};

type TabActions = (set: (state: Partial<TabsState>) => void) => {
  onChange?: (tabId: string) => void;
  setActive: (tabId: string) => void;
};

const TabContext = createContext<TabsState>(undefined as any);

const TabProvider = makeStoreProvider<TabsState, ReturnType<TabActions>>(
  TabContext
);

const useTabs = makeStoreContextHook<TabsState, ReturnType<TabActions>>(
  TabContext
);

const useRelativeLinkFactory = () => {
  const [searchParams] = useSearchParams();

  const queryParams = Array.from(searchParams.entries());

  return {
    create: (props: { to: LinkProps['to'] }) => {
      return `${queryParams.length > 0 ? `?${searchParams.toString()}` : ''}${
        props.to
      }`;
    },
  };
};

export const useActiveTab = () => {
  const navigate = useNavigate();
  const { create } = useRelativeLinkFactory();
  const mode = useTabs((s) => s.mode);
  const defaultTab = useTabs((s) => s.default);
  const active = useTabs((s) => s.active);
  const setActive = useTabs((s) => s.actions.setActive);
  const c = useLocation();
  const hash = c.hash.replace('#', '');

  if (mode === 'link') {
    return {
      active: hash.length > 0 ? hash : defaultTab,
      changeTab: (id: string) => navigate(create({ to: id })),
    };
  }

  return {
    active: active ?? defaultTab,
    changeTab: (id: string) => {
      setActive(id);
    },
  };
};

const Container = ({
  children,
  onChange,
  ...state
}: {
  children: React.ReactNode;
  onChange?: ReturnType<TabActions>['onChange'];
} & TabsState) => {
  const actions = useCallback(
    (set: StateSetter<TabsState>) => ({
      onChange,
      setActive: (id: string) => set(() => ({ active: id })),
    }),
    [onChange]
  );

  return (
    <TabProvider actions={actions} state={state}>
      {children}
    </TabProvider>
  );
};

const TabSelector = ({ tabs }: { tabs: { id: string; name: string }[] }) => {
  const setActive = useTabs((s) => s.actions.setActive);
  const { active } = useActiveTab();
  const tab = tabs.find((t) => t.id === active) ?? tabs[0]!;
  return (
    <div className="flex flex-col">
      <Listbox value={active} onChange={setActive}>
        {({ open }) => (
          <>
            <Listbox.Button
              className={classNames(
                'text-left text-sm cursor-pointer self-end py-1.5 font-medium'
              )}
            >
              <span className="inline-block flex items-center">
                {tab.name} <ChevronDownIcon className="h-4 w-4 ml-2" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={
                  'absolute mt-8 z-10 mt-1 max-h-56 min-w-[150px] overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                }
              >
                {tabs.map((tab) => (
                  <Listbox.Option
                    key={tab.id}
                    value={tab.id}
                    className={
                      'text-standard-text relative cursor-pointer select-none py-2 pl-3 pr-9 hover:text-primary-text'
                    }
                  >
                    {tab.name}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

const Panel = ({
  children,
  className,
  border = true,
  type: style = 'tabs',
  padding = true,
}: {
  children: React.ReactNode;
  className?: string;
  border?: boolean;
  type?: 'tabs' | 'select';
  padding?: boolean;
}) => {
  return (
    <div
      className={classNames(
        'w-full border-divider',
        style === 'tabs' ? 'rounded bg-white' : '',
        border && style === 'tabs' ? 'border' : '',
        padding ? 'py-1.5 px-2' : ''
      )}
    >
      <nav
        className={classNames(
          'overflow-x-scroll no-scrollbar flex space-x-2',
          className
        )}
        aria-label="Tabs"
      >
        {children}
      </nav>
    </div>
  );
};

const TabButton = ({
  id,
  text,
  active,
  to,
  className,
}: {
  id: string;
  text: string;
  active?: string;
  to?: string;
  className?: string;
}) => {
  const onChange = useTabs((s) => s.actions.onChange);
  const setActive = useTabs((s) => s.actions.setActive);

  return (
    <Button
      key={id}
      inline
      className={className}
      onClick={() => {
        setActive(id);
        onChange && onChange(id);
      }}
      active={active === id}
      text={text}
      link={to ? { to } : undefined}
    />
  );
};

const LinkTab = ({ id, text }: { id: string; text: string }) => {
  const { create } = useRelativeLinkFactory();
  const { active } = useActiveTab();

  return (
    <TabButton
      text={text}
      id={id}
      active={active}
      to={create({ to: `#${id}` })}
    />
  );
};

const Tab = ({
  id,
  text,
  className,
}: {
  id: string;
  text: string;
  className?: string;
}) => {
  const { active } = useActiveTab();
  return (
    <TabButton id={id} active={active} text={text} className={className} />
  );
};

const Content = ({
  id,
  children,
  scroll = true,
  padding = true,
  className,
}: {
  id: string;
  children: React.ReactNode;
  scroll?: boolean;
  padding?: boolean;
  className?: string;
}) => {
  const { active } = useActiveTab();

  if (id === active) {
    return (
      <div
        className={classNames(
          'w-full h-full',
          scroll ? 'overflow-y-auto' : '',
          padding ? 'p-3' : '',
          className
        )}
      >
        {children}
      </div>
    );
  }

  return null;
};

const NewTab = ({ onClick }: { onClick?: () => void }) => {
  const onChange = useTabs((s) => s.actions.onChange);
  const setActive = useTabs((s) => s.actions.setActive);
  const { active } = useActiveTab();

  const defaultOnClick = useCallback(() => {
    setActive(NEW_TAB_VIEW_ID);
    onChange && onChange(NEW_TAB_VIEW_ID);
  }, [onChange, setActive]);

  return (
    <button onClick={onClick ? onClick : defaultOnClick} className="p-1">
      <PlusIcon
        className={classNames(
          'h-5 w-5 hover:text-primary-text',
          active === NEW_TAB_VIEW_ID ? 'text-primary-text' : ''
        )}
      />
    </button>
  );
};

export const NewTabView = ({ children }: ChildrenProps) => {
  const { active } = useActiveTab();

  if (active === NEW_TAB_VIEW_ID) {
    return <>{children}</>;
  }

  return null;
};

export const Tabs = {
  Container,
  Panel,
  LinkTab: LinkTab,
  Tab,
  Content,
  NewTab,
  NewTabView,
  NEW_TAB_VIEW_ID,
  TabSelector,
};
