import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  useDropdown,
} from '../../headless';
import { Button, Divider, Icon } from '../../ui';
import { DataGridViewModel, Registry } from '../types';
import { useSubscribe } from '@cotera/client/app/etc';
import { IconName } from '@cotera/client/app/components/ui';
import { ChildrenProps } from '../../utils';
import { Link } from 'react-router-dom';

export const ColumnMenu = ({
  column,
  vm,
  registry,
}: {
  column: string;
  vm: DataGridViewModel;
  registry: Registry;
}) => {
  const rel = useSubscribe(vm, (s) => s.rel);
  const sortColumns = useSubscribe(vm, (s) => s.sort);
  const actions = registry.getHeaderActions(
    rel,
    column,
    rel.attributes[column]!
  );

  const SearchView = registry.getSearch(rel, column, rel.attributes[column]!);

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button
            small
            icon="bars-3-bottom-right"
            inline
            iconOnly
            overrides={{ height: 'h-fit', py: '' }}
          />
        </DropdownTrigger>
        <DropdownContent>
          {SearchView}
          <DropdownItem
            icon={<Icon icon="arrow-up" />}
            active={sortColumns.some(
              (s) => s.column === column && s.direction === 'asc'
            )}
            onClick={() => {
              vm.addSort(column, 'asc');
            }}
          >
            Sort Asc
          </DropdownItem>
          <DropdownItem
            icon={<Icon icon="arrow-down" />}
            active={sortColumns.some(
              (s) => s.column === column && s.direction === 'desc'
            )}
            onClick={() => {
              vm.addSort(column, 'desc');
            }}
          >
            Sort Desc
          </DropdownItem>
          <DropdownItem
            icon={<Icon icon="arrows-up-down" />}
            active={!sortColumns.some((s) => s.column === column)}
            onClick={() => {
              vm.removeSort(column);
            }}
          >
            No Sort
          </DropdownItem>
          <DropdownItem
            icon={<Icon icon="eye-slash" />}
            active={false}
            onClick={() => {
              vm.hideColumn(column);
            }}
          >
            Hide
          </DropdownItem>
          {actions.length > 0 && <Divider caption="Actions" />}
          {actions.map((props) => (
            <Item {...props} key={props.label} />
          ))}
        </DropdownContent>
      </Dropdown>
    </>
  );
};

const Item: React.FC<{
  label: string;
  icon: IconName;
  onClick?: () => void;
  link?: string;
}> = ({ label, icon, onClick, link }) => {
  const toggle = useDropdown((s) => s.actions.toggle);

  const Wrapper = (props: ChildrenProps) =>
    link !== undefined ? (
      <Link to={link}>{props.children}</Link>
    ) : (
      props.children
    );

  return (
    <Wrapper>
      <DropdownItem
        icon={<Icon icon={icon} />}
        key={label}
        onClick={() => {
          toggle();
          onClick?.();
        }}
      >
        {label}
      </DropdownItem>
    </Wrapper>
  );
};
