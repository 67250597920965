import { Layout } from '@cotera/client/app/layout';
import { Link } from 'react-router-dom';
import { useAppData } from '@cotera/client/app/stores/org';
import {
  ChildrenProps,
  ThemeInstance,
} from '@cotera/client/app/components/utils';
import { ICON_MAPPING, Title } from '@cotera/client/app/components/ui';
import { Text } from '@cotera/client/app/components/ui';

const theme = new ThemeInstance().theme('random');

export const AppStore = () => {
  const skeleton = useAppData((s) => s.skeleton);

  return (
    <Layout>
      <div className="min-h-full flex flex-col items-center w-full">
        <div className="mx-auto max-w-7xl p-8 px-2 w-full ">
          <div className="flex space-x-8">
            <div className="w-full flex flex-col mr-8">
              <Text.Title
                type="section"
                className="text-lg font-medium leading-6 text-accent"
              >
                Installed Apps
              </Text.Title>
              <p className="mt-1 text-sm text-standard-text mb-6">
                Get started by selecting an app.
              </p>
              <ul className="grid grid-cols-2 gap-6">
                {Object.entries(skeleton.apps).map(([key, app]) => {
                  const Icon = ICON_MAPPING[app.icon];

                  const Wrapper = (props: ChildrenProps) => (
                    <Link
                      to={`/apps/${key}`}
                      className={'hover:text-primary-text'}
                    >
                      {props.children}
                    </Link>
                  );

                  return (
                    <li
                      key={key}
                      className="bg-white border border-divider rounded-xl p-4 text-standard-text"
                    >
                      <Wrapper key={key}>
                        <div className="flex items-center">
                          <div className="rounded-full border-divider border h-[35px] w-[35px] text-alt-text flex justify-center items-center flex-shrink-0 flex-grow-0 mr-4">
                            <span
                              className="w-6 h-6 m-w-6 inline-block"
                              style={{
                                color: theme.forLabel(key).color,
                              }}
                            >
                              <Icon />
                            </span>
                          </div>
                          <Text.Title type="section" className="capitalize">
                            {app.title}
                          </Text.Title>
                        </div>
                      </Wrapper>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
