import { forwardRef } from 'react';
import { ColorScheme } from '../../utils/colors/color-scheme';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { Theme } from '../../types/utils';

export const Caption = forwardRef<
  HTMLParagraphElement,
  ChildrenProps & {
    className?: string;
    theme?: Theme;
    onClick?: () => void;
    style?: React.CSSProperties;
  }
>(({ children, className, onClick, theme = 'muted', style }, ref) => {
  return (
    <p
      onClick={onClick}
      style={style}
      ref={ref}
      className={classNames(ColorScheme.text[theme], 'text-xs', className)}
    >
      {children}
    </p>
  );
});
