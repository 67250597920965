import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  areArraysEqual,
  Semaphore,
  useSubscribe,
  WatchableViewModel,
} from '@cotera/client/app/etc';
import { ApplyFn } from '../new-column';
import {
  Case,
  Constant,
  Count,
  Expression,
  Relation,
  Sum,
  TC,
} from '@cotera/era';
import {
  AdvancedFilterBuilder,
  convertToFilterGroup,
  DisplayError,
  filterGroupToExpression,
  makeDefaultFilterGroup,
  useOptions,
} from '@cotera/client/app/components/app';
import React, { Suspense, useEffect } from 'react';
import {
  Badge,
  Button,
  Center,
  Divider,
  Icon,
  Loading,
  Title,
  Text,
  Tooltip,
  toast,
} from '@cotera/client/app/components/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { useState } from 'react';
import {
  Accordion,
  useAccordion,
} from '@cotera/client/app/components/headless';
import {
  ChildrenProps,
  classNames,
  ColorScheme,
  Formatters,
} from '@cotera/client/app/components/utils';
import { useFilterBuilder } from '../../../app/filter-builder/hooks';
import { Inputs } from '@cotera/client/app/components/forms';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { useEntity } from '@cotera/client/app/pages/entities/hooks';
import { Assert } from '@cotera/utilities';
import { useDeepMemo } from '@cotera/client/app/hooks/deep-memo';
import { startCase } from 'lodash';
import { ExpressionEditor } from '../components/expression-editor';
import { useUpsertEraqLCol } from '@cotera/client/app/hooks/entities';
import { DecisionTreeViewModel, ExpressionItem } from './view-model';
import { NewColumnAction } from '../column-action.type';
import { ColumnName } from '../components/column-name';
import { FilterGroup } from '@cotera/client/app/components/app/filter-builder/types';
import { ok } from 'neverthrow';

const queryRateLimiter = new Semaphore(1, { delay: 300 });

export const DecisionTree: React.FC<{
  vm: WatchableViewModel<{
    rel: Relation;
    baseRel: Relation;
    isRelReady(): boolean;
  }>;
  additionalProps: {
    entityName: string;
    columnName?: string;
    eraql?: string;
  };
  onApply: ApplyFn;
}> = ({
  additionalProps: { entityName, columnName, eraql },
  vm: relVm,
  onApply,
}) => {
  const entity = useEntity(entityName);
  Assert.assert(entity !== undefined);

  const vm = useDeepMemo(
    () => new DecisionTreeViewModel(entity, relVm, columnName, eraql),
    [entity, eraql, columnName]
  );

  return (
    <Suspense
      fallback={
        <Center>
          <Loading.Dots />
        </Center>
      }
    >
      <ErrorBoundary
        fallbackRender={({ error }) => <DisplayError error={error} />}
      >
        <ReadyContainer vm={vm}>
          <Main vm={vm} onApply={onApply} key={eraql}>
            <View vm={vm} />
          </Main>
        </ReadyContainer>
      </ErrorBoundary>
    </Suspense>
  );
};

const ReadyContainer: React.FC<
  {
    vm: DecisionTreeViewModel;
  } & ChildrenProps
> = ({ vm, children }) => {
  const isReady = useSubscribe(vm, (s) => s.isReady);

  if (!isReady) {
    return (
      <Center>
        <Loading.Dots />
      </Center>
    );
  }

  return children;
};

const Main: React.FC<
  ChildrenProps & {
    vm: DecisionTreeViewModel;
    onApply: ApplyFn;
  }
> = ({ children, vm, onApply }) => {
  const columnName = useSubscribe(vm, (s) => s.columnName);

  return (
    <div className="p-4 w-full h-[calc(100%-35px)] flex flex-col justify-start">
      <div className="flex items-center justify-between mb-4">
        <Title
          type="title"
          subtitle="Visualize and Analyze Data with Interactive Decision Trees"
        >
          Decision Tree
        </Title>
        <ApplyButton vm={vm} onApply={onApply} />
      </div>
      <Divider className="mb-4" />
      <div className="flex flex-col">
        <div className="flex flex-row items-center mb-2">
          <Title type="label" className="mr-4 shrink-0">
            {!vm.isNew ? columnName : 'Column Name'}
          </Title>
          <SparkPreviewContainer>
            <OverallSparkPreview vm={vm} />
          </SparkPreviewContainer>
        </div>
        {vm.isNew && <ColumnName showLabel={false} vm={vm} className="mb-4" />}
      </div>
      <Divider />
      {children}
    </div>
  );
};

const ApplyButton: React.FC<{
  vm: DecisionTreeViewModel;
  onApply: ApplyFn;
}> = ({ vm, onApply }) => {
  const hasErrors = useSubscribe(vm, (s) => s.hasErrors());
  const errors = useSubscribe(
    vm,
    (s) => s.errors,
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
  const eraql = useSubscribe(vm, (s) => s.caseEraQl);
  const caseExpr = useSubscribe(
    vm,
    (s) => s.caseExpression,
    (a, b) => JSON.stringify(a.ir()) === JSON.stringify(b.ir())
  );
  const items = useSubscribe(
    vm,
    (s) => s.items,
    (a, b) =>
      areArraysEqual(
        a.map((x) => x.id),
        b.map((x) => x.id)
      )
  );
  const columnName = useSubscribe(vm, (s) => s.columnName);

  const upsert = useUpsertEraqLCol({
    entityId: vm.entity.id,
    onSuccess: () => {},
  });

  return (
    <Tooltip
      text={
        hasErrors
          ? Object.values(errors)
              .filter((e) => e !== undefined)
              .join('\n')
          : 'Apply your case statement'
      }
      side="left"
    >
      <Button
        disabled={hasErrors}
        theme="secondary"
        text="Apply"
        onClick={async () => {
          onApply({
            t: 'decision-tree',
            value: caseExpr,
            column: columnName,
          });
          void upsert
            .mutateAsync({
              data: {
                eraql,
                t: 'eraql',
                k: {
                  t: 'decision-tree',
                  variants: items.map((x) => x.then.textValue),
                },
              },
              columnName,
            })
            .then(() => {
              toast.success('Column configuration saved');
              vm.clear();
            })
            .catch(() => {
              toast.error('Failed to save column configuration');
            });
        }}
      />
    </Tooltip>
  );
};

const View: React.FC<{
  vm: DecisionTreeViewModel;
}> = ({ vm }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const items = useSubscribe(
    vm,
    (s) => s.items,
    (a, b) =>
      areArraysEqual(
        a.map((x) => x.id),
        b.map((x) => x.id)
      )
  );

  const errors = useSubscribe(
    vm,
    (s) => s.errors,
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Accordion.Root expanded={[]} multiple={false}>
        {errors.empty && (
          <div className="p-2 rounded mt-2 text-sm bg-error">
            <Text.Caption>{errors.empty}</Text.Caption>
          </div>
        )}
        <div
          ref={scrollRef}
          className="flex flex-col w-full h-full overflow-auto pb-[50px]"
        >
          {items.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <DropArea index={index} vm={vm} />
                <Item
                  vm={vm}
                  item={item}
                  onRemove={() => {
                    vm.removeItem(item.id);
                  }}
                  index={index}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div className="w-full flex justify-center mt-4">
          <Button
            iconOnly
            tooltip="right"
            className="w-fit !rounded-full"
            icon="add"
            onClick={() => {
              vm.addItem(`Item ${items.length + 1}`);
              scrollRef.current?.scrollTo({
                top: scrollRef.current.scrollHeight + 50,
                behavior: 'smooth',
              });
            }}
            text="Add Item"
          />
        </div>
      </Accordion.Root>
    </DndProvider>
  );
};

const DropArea: React.FC<{
  index: number;
  vm: DecisionTreeViewModel;
}> = ({ index, vm }) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'item',
      drop: (item: { id: string; name: string; index: number }) => {
        vm.moveItem(item.index, index);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [index]
  );

  return (
    <div
      className={classNames(
        'mt-1 w-full max-h-5 flex items-center justify-center transition-all ease-in-out duration-200 overflow-hidden shrink-0',
        isOver
          ? 'h-10 opacity-100 border-dashed border-divider border bg-indigo-50'
          : 'h-5 opacity-0'
      )}
      ref={drop}
    >
      <Text.Caption>Drop here to move</Text.Caption>
    </div>
  );
};

const ItemTrigger: React.FC<{
  item: ExpressionItem;
  onRemove: () => void;
  index: number;
  vm: DecisionTreeViewModel;
  isExpanded: boolean;
}> = ({ item, onRemove, index, isExpanded, vm }) => {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'item',
    item: {
      id: item.id,
      name: item.name,
      index,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const filter = useSubscribe(item, (s) => s.filter);
  const name = useSubscribe(item, (s) => s.name);

  return (
    <div
      className={classNames(
        'flex items-start shrink-0 w-full overflow-hidden',
        isDragging ? 'opacity-50' : ''
      )}
    >
      <Button ref={drag} icon="drag-handle" inline className="pt-[2px] !pl-0" />
      <div className="flex flex-col w-[calc(100%-20px)]">
        <div className="flex items-center justify-between w-full">
          <div className="flex">
            <Button
              className="!pl-0 !mt-0 !pt-0"
              inline
              theme="error"
              iconOnly
              icon="trash"
              onClick={onRemove}
            />
            <div className="flex items-center flex-wrap ml-2 mt-1">
              <Text.P ref={preview} className="shrink-0 mb-2">
                {name}
              </Text.P>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center mr-2">
              <SparkPreviewContainer>
                <SparkPreview vm={vm} item={item} />
              </SparkPreviewContainer>
            </div>
            <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} />
          </div>
        </div>
        {!isExpanded && filter.textValue && (
          <Text.Caption className="mr-2 break-words text-left	mb-2 border-l mt-2 border-divider pl-2 truncate">
            {filter.textValue}
          </Text.Caption>
        )}
        <Divider className="mt-2" />
      </div>
    </div>
  );
};

const ItemBody: React.FC<{
  vm: DecisionTreeViewModel;
  item: ExpressionItem;
}> = ({ vm, item }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);
  const filter = useSubscribe(item, (s) => s.filter);
  const name = useSubscribe(item, (s) => s.name);
  const isDefault = item.filter.expr.expr.ast.t === 'scalar';
  const filterGroup = isDefault
    ? ok(makeDefaultFilterGroup())
    : convertToFilterGroup(item.filter.expr.expr);

  const [type, setType] = useState<'Filter' | 'Expression'>(
    filterGroup.isErr() ? 'Expression' : 'Filter'
  );

  return (
    <>
      <div className="w-full justify-between flex items-center mb-2">
        <Badge className="shrink-0" theme="primary">
          IF
        </Badge>
        <Inputs.Toggle
          compact
          value={type}
          options={['Filter', 'Expression']}
          onChange={(v) => setType(v!)}
        />
      </div>
      {type === 'Filter' && filterGroup.isOk() && (
        <FilterBuilder
          rel={rel}
          item={item}
          filterGroup={filterGroup.value}
          dangerouslyOptInToDynamicOptions={true}
        />
      )}
      {type === 'Filter' && filterGroup.isErr() && (
        <Text.Caption className="mb-4" theme="error">
          Filter Builder not supported for this expression
        </Text.Caption>
      )}
      {type === 'Expression' && (
        <div className="mb-6">
          <ExpressionEditor
            ty={filter?.expr.expr.ty}
            attributes={rel.attributes}
            eraql={filter?.textValue ?? ''}
            error={filter?.error}
            onChange={(v, res) => {
              if (res.isErr()) {
                item.setFilter(null, v, res.error);
              } else {
                item.setFilter(
                  {
                    tc: res.value.tc,
                    expr: Expression.fromAst(res.value.ast),
                  },
                  v
                );
              }
            }}
          />
        </div>
      )}
      <div className="flex items-center justify-between mb-4">
        <Badge className="shrink-0 mr-2" theme="primary">
          THEN
        </Badge>
        <Inputs.Text
          className="flex-1"
          value={name}
          onChange={(v) => {
            const expr = Constant(v);

            const exprTyRes = TC.checkExpr(expr.ast);

            item.setThen(
              {
                tc: exprTyRes as TC.ExprTypeCheck,
                expr,
              },
              v
            );
          }}
        />
      </div>
      {/* <ItemExamples vm={vm} item={item} /> */}
    </>
  );
};

const Item: React.FC<{
  onRemove: () => void;
  vm: DecisionTreeViewModel;
  item: ExpressionItem;
  index: number;
}> = ({ onRemove, vm, item, index }) => {
  const expanded = useAccordion((s) => s.expanded);
  const isExpanded = expanded.includes(item.id);

  return (
    <>
      <Accordion.Trigger id={item.id}>
        <ItemTrigger
          item={item}
          onRemove={onRemove}
          index={index}
          vm={vm}
          isExpanded={isExpanded}
        />
      </Accordion.Trigger>
      <Accordion.Item id={item.id} className="shrink-0">
        <div className="flex flex-col mt-4 h-[250px] overflow-auto border-b border-divider">
          <ExpandedContainer expanded={isExpanded}>
            <ItemBody vm={vm} item={item} />
          </ExpandedContainer>
        </div>
      </Accordion.Item>
    </>
  );
};

const ExpandedContainer: React.FC<
  {
    expanded: boolean;
  } & ChildrenProps
> = ({ children, expanded }) => {
  const [wasExpanded, setWasExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      setWasExpanded(true);
    }
  }, [expanded]);

  if (!wasExpanded) {
    return null;
  }

  return children;
};

const FilterBuilder: React.FC<{
  item: ExpressionItem;
  rel: Relation;
  filterGroup: FilterGroup;
  dangerouslyOptInToDynamicOptions?: boolean;
}> = ({ item, rel, filterGroup, dangerouslyOptInToDynamicOptions }) => {
  const options = useOptions(rel.attributes);

  const [state] = useFilterBuilder({
    filterGroup,
    options,
    onChange: (state) => {
      const expr = filterGroupToExpression(
        rel.ref('from'),
        Constant(false)
      )(state);

      const exprTyRes = TC.checkExpr(expr.ast);

      if (exprTyRes instanceof TC.TyStackTrace) {
        item.setFilter(null, undefined, {
          t: 'Type Error',
          msg: exprTyRes.message.toString(),
        });
        return;
      }

      item.setFilter({
        tc: exprTyRes,
        expr,
      });
    },
  });

  return (
    <div className="flex flex-col">
      <AdvancedFilterBuilder
        dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
        rel={rel}
        fillRow
        filterGroup={state}
        options={options}
        showRun={false}
      />
      {item.filter.error !== undefined && (
        <div
          className={classNames(
            'p-2 rounded mt-2 text-sm border',
            ColorScheme.background.error,
            ColorScheme.text.error,
            ColorScheme.border.error
          )}
        >
          {startCase(item.filter.error.t)}: {item.filter.error.msg}
        </div>
      )}
    </div>
  );
};

const SparkPreviewContainer: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Suspense fallback={null}>
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <Tooltip text={error.message} side="top">
            <div className="flex">
              <Icon icon="x-circle" theme="error" />
            </div>
          </Tooltip>
        )}
      >
        {children}
      </ErrorBoundary>
    </Suspense>
  );
};

const SparkPreview: React.FC<{
  vm: DecisionTreeViewModel;
  item: ExpressionItem;
}> = ({ vm, item }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);
  const filter = useSubscribe(item, (s) => s.filter);

  const { data: pieData } = useDuckDBQuery({
    rateLimiter: queryRateLimiter,
    rel: rel
      .groupBy((_) => ({
        category: Case(
          [
            {
              when: filter.expr.expr,
              then: 'Match',
            },
          ],
          {
            else: 'No Match',
          }
        ),
      }))
      .select((t) => ({
        category: t.attr('category'),
        value: Count(),
      })),
  });

  const arrayData = pieData.data.toArray();
  const total = arrayData.reduce((acc, row) => acc + Number(row['value']), 0);
  const countMatch =
    arrayData.filter((row) => row['category'] === 'Match').at(0)?.['value'] ??
    0;
  const percentageMatching = (Number(countMatch) / total) * 100;

  return (
    <Badge theme={'regular'}>
      {Formatters.number(percentageMatching, '%')}
    </Badge>
  );
};

const OverallSparkPreview: React.FC<{
  vm: DecisionTreeViewModel;
}> = ({ vm }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);
  const numItems = useSubscribe(vm, (s) => s.items.length);
  const caseExpr = useSubscribe(
    vm,
    (s) => s.caseExpression,
    (a, b) => JSON.stringify(a.ir()) === JSON.stringify(b.ir())
  );

  const { data: pieData } = useDuckDBQuery({
    rateLimiter: queryRateLimiter,
    rel: rel
      .groupBy((_) => ({
        category: caseExpr,
      }))
      .select((t) => ({
        category: t.attr('category'),
        value: Count(),
      }))
      .groupBy((t) => ({
        category: Case(
          [
            {
              when: t.attr('category').isNotNull(),
              then: 'Match',
            },
          ],
          {
            else: 'No Match',
          }
        ),
      }))
      .select((t) => ({
        category: t.attr('category'),
        value: Sum(t.attr('value')),
      })),
  });

  const arrayData = pieData.data.toArray();
  const total = arrayData.reduce((acc, row) => acc + Number(row['value']), 0);

  const countMatch =
    arrayData.filter((row) => row['category'] === 'Match').at(0)?.['value'] ??
    0;
  const percentageMatching = (Number(countMatch) / total) * 100;

  return (
    <Text.Caption>
      {Formatters.number(
        countMatch === 0 || numItems === 0 ? 0 : percentageMatching,
        '%'
      )}{' '}
      Matched
    </Text.Caption>
  );
};

export const DecisionTreeColumn: NewColumnAction = {
  icon: 'filter',
  View: DecisionTree as NewColumnAction['View'],
  label: 'Decision Tree',
};
