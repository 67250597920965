import { Text, CoteraLink } from '@cotera/client/app/components/ui';
import { LogoComponent } from '../logo/logo-svg';

export const NotFound: React.FC<{ resource?: string }> = ({
  resource = 'page',
}) => {
  return (
    <div className="h-full flex-1 flex flex-col justify-center items-center mr-8">
      <div className="h-[20px] w-[20px] mb-16">
        <LogoComponent />
      </div>
      <Text.Caption className="text-primary-text font-bold text-2xl mb-3">
        404
      </Text.Caption>
      <Text.Title type="title" className="mb-3 capitalize">
        {resource} Not Found
      </Text.Title>
      <Text.Caption className="mb-6">
        Sorry, we couldn't find the {resource} you were looking for.
      </Text.Caption>

      <CoteraLink to="/">
        Got back home <span aria-hidden="true"> &rarr;</span>
      </CoteraLink>
    </div>
  );
};
