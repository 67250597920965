import { AST, UI, Case, Expression, RelationRef, Relation } from '@cotera/era';
import { Overview } from './overview';
import { Orders } from './activity';
import { Segment } from './segment';
import { makeCurrentRateOfChange } from './definitions/current-rate-of-change';
import { makeCurrentChange } from './definitions/current-change';
import { makeCurrentRateOfChangeAIN } from './definitions/current-rate-of-change-ain';
import { makeCurrentChangeAIN } from './definitions/current-change-ain';
import _ from 'lodash';

const CATEGORIES = ['active', 'new', 'inactive'] as const;
type Category = (typeof CATEGORIES)[number];

export const Rfm = ({
  RfmLifecycleSummary: BaseRfmLifecycleSummary,
  HistoricalCustomerRfm,
  categoryMapping,
  customerIdColumn,
  UserAnalysis,
  boundaryCustomerCategory,
  memberType,
  activity,
}: {
  RfmLifecycleSummary: Relation;
  HistoricalCustomerRfm: Relation;
  categoryMapping: { [category: string]: Category };
  customerIdColumn: (t: RelationRef) => Expression;
  UserAnalysis?: Relation;
  boundaryCustomerCategory: string;
  memberType?: string;
  activity?: Record<string, { total: string; avg: string; unit?: string }>;
}): AST._App => {
  const AllSegments = Object.keys(categoryMapping);

  const reverseAin = (category: string): string[] =>
    Object.entries(categoryMapping)
      .filter(([_name, c]) => c === category)
      .map(([name, _c]) => name);

  const ainCategoryAssigment = (t: RelationRef) =>
    Case(
      CATEGORIES.map((cat) => ({
        when: t.attr('RFM_CATEGORY').oneOf(reverseAin(cat)),
        then: `${_.startCase(cat)} Customers`,
      }))
    );

  const ainNumericAssigment = (t: RelationRef) =>
    Case(
      CATEGORIES.map((cat, i) => ({
        when: t.attr('RFM_CATEGORY').oneOf(
          Object.entries(categoryMapping)
            .filter(([_name, c]) => c === cat)
            .map(([name, _c]) => name)
        ),
        then: i + 1,
      }))
    );

  const HistoricalRfm = HistoricalCustomerRfm.select((t) => ({
    ...t.star(),
    __COTERA_CUSTOMER_ID: customerIdColumn(t),
    ACTIVE_INACTIVE_NEW: ainCategoryAssigment(t),
    ACTIVE_INACTIVE_NEW_NUM: ainNumericAssigment(t),
  }));

  const CurrentRateOfChange = makeCurrentRateOfChange({
    HistoricalCustomerRfm: HistoricalRfm,
  }).assertCacheable();

  const CurrentChange = makeCurrentChange({
    HistoricalCustomerRfm: HistoricalRfm,
  }).assertCacheable();

  const CurrentRateOfChangeAIN = makeCurrentRateOfChangeAIN({
    HistoricalCustomerRfm: HistoricalRfm,
  }).assertCacheable();

  const CurrentChangeAIN = makeCurrentChangeAIN({
    HistoricalCustomerRfm: HistoricalRfm,
  }).assertCacheable();

  const RfmLifecycleSummary = BaseRfmLifecycleSummary;

  const customerSegments = {
    active: reverseAin('active'),
    new: reverseAin('new'),
    inactive: reverseAin('inactive'),
  };

  return UI.App({
    context: `
      You are a ecommerce data analyst.  
      The data is customer retention data.
      The data is on a month per month basis, and you are to give insights about customer retentation changes and growth in customer segments.
    `,
    title: `Lifecycle${memberType ? ` (${memberType})` : ''}`,
    icon: 'briefcase',
    pages: {
      '/': Overview({
        RfmLifecycleSummary,
        CurrentRateOfChangeAIN,
        CurrentChangeAIN,
        customerSegments,
      }),
      '/activity': Orders({
        RfmLifecycleSummary,
        customerSegments,
        tabs: activity ?? {},
      }),
      '/:segment': Segment({
        RfmLifecycleSummary,
        CurrentChange,
        CurrentRateOfChange,
        boundaryCustomerCategory,
        UserAnalysis,
        customerSegments,
        metrics: activity ?? {},
      }),
    },
    menu: [
      { title: 'Lifecycle Overview', slug: './', icon: 'arrow-trending-up' },
      { title: 'Activity Overview', slug: './activity', icon: 'dollar' },
      ...AllSegments.map((segment) => ({
        title: segment,
        slug: `./${segment}`,
      })),
    ],
  });
};
