import { Relation, AST, UI, From, f, Expression } from '@cotera/era';
import { Overview } from './components';
import { ADVENT_CALENDAR_GROWTH_HACKS } from '@cotera/customers/cotera/assumptions';

export const AccomplishmentsApp = ({
  CxData,
  appTopic = 'Product Review',
  caption,
  columnName = 'PRODUCT_SUBCATEGORY',
  tableColumns = ['PRODUCT_SUBCATEGORY', 'PRODUCT_SUMMARY', 'SENTIMENT'],
  icon = 'gift',
}: {
  CxData: Relation;
  appTopic?: string;
  caption: string | Expression;
  columnName?: string;
  tableColumns?: string[];
  icon?: AST.MarkupIcon;
}): AST._App =>
  UI.App({
    title: `${appTopic} Accomplishments`,
    icon,
    pages: {
      '/:companyName': Overview({
        CxData,
        columnName,
        tableColumns,
        appTopic,
        caption,
      }),
    },
    menu: [],
  });

export const ProductAccomplishmentsApp = AccomplishmentsApp({
  CxData: From(ADVENT_CALENDAR_GROWTH_HACKS),
  caption: f`We've trained our AI to look through your public review data and identify product feedback. It looks through every review, identifies product feedback, and then groups them into common categories for anaysis.

Check out the [explainer video](https://www.loom.com/share/86361f02441247b88684fe9c76264412).

If you think this is interesting, [book a chat with us here.](https://calendly.com/cotera-founders/30-minutes)`,
});
