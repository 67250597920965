import { Token, KEYWORDS, OPERATORS, GROUPING } from './tokens';
import type { LexRule, WithMeta } from './types';
import * as T from './tokens';

const sanatizeForRegex = (token: string): string =>
  [...token]
    .map((char) => ('/.*+?|(){}[]^'.includes(char) ? `\\${char}` : char))
    .join('');

const regex = (literals: readonly string[]) =>
  new RegExp(`^(${literals.map((op) => sanatizeForRegex(op)).join('|')})`);

const lexDate = (match: string): Token => {
  const num = Date.parse(`${match.slice(1)}Z`);
  return isNaN(num) ? T.unknown(match) : T.lit(new Date(num));
};

export const LEX_RULES: LexRule<Token>[] = [
  // White Space
  [/^\s+/, T.ws],
  // Comments
  [/^\/\/.*?(?=\n|$)/, (t) => T.comment(t.slice(2, t.length))],
  // Keywords
  [regex(KEYWORDS), T.kw],
  // Grouping
  [regex(GROUPING), T.group],
  // Ident
  [/^".*?"/, (t) => T.ident(t.slice(1, -1))],
  // String Literal
  [/^'.*?'/, (t) => T.lit(t.slice(1, -1))],
  // Number literal
  [/^-?\d+(\.\d+)?/, (token) => T.lit(Number(token))],
  // Bool Literal
  [/^(true|false)/, (x) => T.lit(x === 'true')],
  // Day Literal
  [/^@[\d-]+/, lexDate],
  // Operator
  [regex(OPERATORS), T.op],
  // Tag
  [
    /^@(".*?"|[a-zA-Z0-9-_]+)/,
    (x) => T.tag(x.slice(1).replace(/^"/, '').replace(/"$/, '')),
  ],
  // Symbol
  [/^[a-zA-Z_]+[!?]?/, T.sym],
];

export function* lex(
  str: string,
  opts?: { offset?: number }
): Generator<WithMeta<Token>> {
  const { offset = 0 } = opts ?? {};
  let cursor = 0;

  outer: while (cursor < str.length) {
    const unprocessed = str.slice(cursor);

    for (const [match, process] of LEX_RULES) {
      const found = match.exec(unprocessed);

      if (found) {
        const rawToken = found[0];
        const end = cursor + rawToken.length;
        const token = process(rawToken);
        yield [token, { range: [cursor + offset, end + offset] }];
        cursor = end;
        continue outer;
      }
    }

    break;
  }
  if (cursor < str.length) {
    yield [
      { t: 'unknown', found: str.slice(cursor) },
      { range: [cursor, str.length] },
    ];
  }
  return;
}
