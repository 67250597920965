import {
  Relation,
  UI,
  f,
  SumOver,
  RowNumberOver,
  Not,
  Page,
  AST,
  Ty,
  Expression,
} from '@cotera/era';
import { CompanyNamePrettify } from '../constants';
export const GetTopNCategories = (
  CompanyData: Relation,
  columnName: string,
  n: number
) => {
  const Totals = CompanyData.countBy((t) => t.pick(columnName, 'SENTIMENT'))
    .select((t) => ({
      ...t.star(),
      TOTAL: SumOver(t.attr('COUNT'), {
        partitionBy: t.attr(columnName),
      }),
      PERCENT: t
        .attr('COUNT')
        .safeDiv(
          SumOver(t.attr('COUNT'), {
            partitionBy: t.attr(columnName),
          })
        )
        .mul(100)
        .round(1),
    }))
    .where((t) => t.attr('SENTIMENT').eq('positive'))
    .select((t) => ({
      ...t.star(),
      RANK: RowNumberOver({ orderBy: t.attr('PERCENT').desc() }),
    }))
    .where((t) => t.attr('RANK').lte(n));

  return Totals;
};

export const Overview = ({
  CxData,
  columnName,
  tableColumns,
  appTopic,
  caption,
}: {
  CxData: Relation;
  columnName: string;
  tableColumns: string[];
  appTopic: string;
  caption: string | Expression;
}): AST._Page => {
  return Page(['companyName'] as const, ({ companyName }) => {
    const CompanyData = CxData.where((t) =>
      t
        .attr(columnName)
        .isNotNull()
        .and(Not(t.attr(columnName).like('%[%')))
        .and(t.attr(columnName).isDistinctFrom(''))
        .and(t.attr('COMPANY').eq(CompanyNamePrettify(companyName)))
    );

    const SliceRelationForSubCategory = CompanyData.select(
      (t) => ({
        SUBCATEGORY: t.attr(columnName).tag(Ty.CARD_LT_1000_TAG),
      }),
      { distinct: true }
    );

    return [
      UI.Header({
        title: f`${CompanyNamePrettify(companyName)}`,
      }),
      UI.State(
        {
          SliceRelationForSubCategory,
        },
        ({ SliceRelationForSubCategory }) => {
          const Top5ProductCategories = GetTopNCategories(
            CompanyData.innerJoin(
              SliceRelationForSubCategory,
              (data, slice) => ({
                on: data.attr(columnName).eq(slice.attr('SUBCATEGORY')),
                select: {
                  ...data.star(),
                },
              })
            ),
            columnName,
            5
          );

          return [
            caption,
            UI.Divider(),
            SliceRelationForSubCategory.SliceBuilder(),
            UI.Block(
              [
                Top5ProductCategories.innerJoin(
                  SliceRelationForSubCategory,
                  (data, slice) => ({
                    on: data.attr(columnName).eq(slice.attr('SUBCATEGORY')),
                    select: {
                      ...data.star(),
                    },
                  })
                )
                  .orderBy((t) => t.attr('PERCENT').desc())
                  .chart.BarChart(
                    (t) => ({
                      x: t.attr(columnName),
                      y: t.attr('PERCENT'),
                      category: t.attr(columnName),
                    }),
                    {
                      axis: {
                        x: {
                          label: 'Category',
                        },
                        y: {
                          label: 'Positive Sentiment (%)',
                        },
                      },
                      format: {
                        y: '%',
                      },
                    }
                  ),
              ],
              {
                title: `Top 5 ${appTopic} Categories`,
              }
            ),
            UI.Block(
              [
                `The AI can take a little bit to think, but it's worth it!`,
                CompanyData.innerJoin(
                  SliceRelationForSubCategory,
                  (data, slice) => ({
                    on: data.attr(columnName).eq(slice.attr('SUBCATEGORY')),
                    select: {
                      ...data.pick(...tableColumns, 'TIMESTAMP', 'TEXT'),
                    },
                  })
                )
                  .orderBy((t) => t.attr('TIMESTAMP').desc())
                  .limit(1000)
                  .chart.LLMSummary({
                    prompt: `You are an analyst for an ecommerce business. You are tasked with analyzing customer feedback to higlight any trends or insights that could be useful for the company. You should look for trends in any of the columns. Explicitly mention specific topics that are receiving positive feedback.
Keep response to strictly the facts of the data, do not infer or speculate on the impacts of the comments, or talk about the customer's feelings towards what happened.
We are looking for insights that indicate customers are having a positive experience. 
Choose the 5 most common topics from the ${columnName} column and for each topic you identify, include the proportion of tickets, a short explainer, and a quote from a ticket that supports your insight using the TEXT column.`,
                    model: 'gpt-4o-mini',
                  }),
              ],
              { title: `${appTopic} Summary` }
            ),
            UI.Block(
              [
                CompanyData.innerJoin(
                  SliceRelationForSubCategory,
                  (data, slice) => ({
                    on: data.attr(columnName).eq(slice.attr('SUBCATEGORY')),
                    select: {
                      ...data.pick(...tableColumns),
                      REVIEW_TEXT: data.attr('TEXT'),
                    },
                  })
                )
                  .where((t) => t.attr(columnName).isDistinctFrom(''))
                  .limit(100),
              ],
              { title: `${appTopic} Overview` }
            ),
          ];
        }
      ),
    ];
  });
};
