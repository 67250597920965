import {
  BaseWorkspaceNodeViewModel,
  NodeTransform,
  TransformableRelation,
} from './base';
import {
  FilterGroup,
  FilterItem,
} from '@cotera/client/app/components/app/filter-builder/types';
import {
  filterGroupToExpression,
  FilterBuilder,
  useOptions,
} from '@cotera/client/app/components/app/filter-builder';
import { Relation, TC } from '@cotera/era';
import { useSubscribe } from '@cotera/client/app/etc';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';

const TIMESTAMP_FIELD_NAME_HUERISTICS = [
  'created_at',
  'timestamp',
  'detected_at',
];

const DEFAULT_TIME_RANGE: [Date, Date] = [
  DateTime.now().minus({ year: 1 }).toJSDate(),
  DateTime.now().toJSDate(),
];

export class FilterNodeViewModel extends BaseWorkspaceNodeViewModel {
  t = 'filter';
  override parent: BaseWorkspaceNodeViewModel;
  filters: FilterGroup;
  readonly transforms: NodeTransform[] = [];
  private _rel: TransformableRelation;

  constructor(
    name: string,
    position: { x: number; y: number },
    filters: FilterGroup,
    parent: BaseWorkspaceNodeViewModel,
    opts: {
      useAutoFilters?: boolean;
    }
  ) {
    super(name, v4(), position);
    this.filters = filters;
    this.parent = parent;
    this._rel = parent.rel;

    parent.subscribe(() => {
      this._rel = parent.rel.apply(this.transforms);
      this.notifySubscribers();
    });

    if (opts.useAutoFilters) {
      this.updateFilters({
        connective: 'and',
        items: this.getAutoFilters(),
      });
    }
  }

  updateFilters = (filters: FilterGroup) => {
    this.filters = filters;

    this.setTransform((rel) => {
      return rel.where((t) => filterGroupToExpression(t)(filters));
    }, 'filter');
  };

  override setTransform(fn: (rel: Relation) => Relation, type: string): void {
    super.setTransform(fn, type, false);
    this._rel = this.parent.rel.apply(this.transforms);

    this.notifySubscribers();
  }

  get baseRel() {
    return this.parent.rel;
  }

  get rel() {
    return this._rel;
  }

  get artifactId() {
    return this.parent.artifactId;
  }

  override getRel(type: 'source' | 'artifact'): Relation {
    return this.parent.getRel(type);
  }

  private getAutoFilters(): FilterItem[] {
    const timestampFilters = this.getAutoTimeFilters();

    return [...timestampFilters].map((x) => ({
      id: v4(),
      value: {
        ...x,
        key: x.attr,
        value: x.value.map(String),
        compact: true,
      },
    }));
  }

  private getAutoTimeFilters() {
    const timestampFields = Object.entries(this.rel.attributes).filter(
      ([_, ty]) => TC.implementsTy({ req: 'timestamp', subject: ty })
    );

    const candidates = timestampFields.filter(([attr]) =>
      TIMESTAMP_FIELD_NAME_HUERISTICS.some(
        (x) => x.toLocaleLowerCase() === attr.toLocaleLowerCase()
      )
    );

    return candidates.map(([attr, t]) => {
      return {
        value: DEFAULT_TIME_RANGE,
        attr,
        operator: 'between' as const,
        t,
      };
    });
  }
}

const FilterBuilderNode: React.FC<{ node: FilterNodeViewModel }> = ({
  node,
}) => {
  const rel = useSubscribe(node, (s) => s.baseRel);
  const filters = useSubscribe(node, (s) => s.filters);
  const options = useOptions(rel.attributes);

  return (
    <FilterBuilder
      key={JSON.stringify(filters)}
      filterGroup={filters}
      options={options}
      autoRun={false}
      rel={rel}
      runEnabled
      onRun={({ filterGroup }) => {
        node.updateFilters(filterGroup);
      }}
    />
  );
};

export const FilterNode = {
  ViewModel: FilterNodeViewModel,
  View: FilterBuilderNode,
};
