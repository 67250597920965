import { Case, RelationRef } from '@cotera/era';

export const rfmNumComparison = (t: RelationRef) =>
  Case([
    {
      when: t.attr('STARTING_RFM_NUM').eq(t.attr('ENDING_RFM_NUM')),
      then: 'Segment Retention Rate',
    },
    {
      when: t.attr('STARTING_RFM_NUM').gt(t.attr('ENDING_RFM_NUM')),
      then: 'Segment Improvement Rate',
    },
    {
      when: t.attr('STARTING_RFM_NUM').lt(t.attr('ENDING_RFM_NUM')),
      then: 'Segment Loss Rate',
    },
  ]);
