import {
  UI,
  AST,
  BarChart,
  LineChart,
  Now,
  Page,
  Relation,
  Sum,
  f,
} from '@cotera/era';
import _ from 'lodash';

export const Orders = ({
  RfmLifecycleSummary,
  customerSegments,
  tabs,
}: {
  RfmLifecycleSummary: Relation;
  customerSegments: {
    active: string[];
    new: string[];
  };
  tabs: Record<string, { total: string; avg: string }>;
}): AST._Page => {
  const dynamicAttrs = Object.values(tabs).reduce<Record<string, 'float'>>(
    (acc, curr) => {
      acc[curr.avg] = 'float';
      acc[curr.total] = 'float';

      return acc;
    },
    {}
  );

  const LastTwoFullMonths = ({ rel }: { rel: Relation }) =>
    rel
      .groupBy((t) => ({
        date: t.attr('date').dateTrunc('month'),
      }))
      .select((t) => ({
        date: t.attr('date').dateTrunc('month'),
        ...Object.fromEntries(
          Object.entries(dynamicAttrs).map(([key]) => [key, Sum(t.attr(key))])
        ),
      }))
      .where((t) =>
        t
          .attr('date')
          .dateTrunc('month')
          .lte(Now().dateSub('weeks', 4).dateTrunc('month'))
      )
      .select((t) => t.star(), {
        limit: 2,
        orderBy: (t) => ({
          expr: t.attr('date'),
          direction: 'desc',
        }),
      });

  const LastTwoMonthsJoined = ({ rel }: { rel: Relation }) =>
    LastTwoFullMonths({ rel })
      .select((t) => t.star(), {
        limit: 1,
        orderBy: (t) => ({
          expr: t.attr('date'),
          direction: 'desc',
        }),
      })
      .innerJoin(
        LastTwoFullMonths({ rel }).select((t) => t.star(), {
          limit: 1,
          orderBy: (t) => ({
            expr: t.attr('date'),
            direction: 'asc',
          }),
        }),
        (current, previous) => ({
          on: true,
          select: {
            ...current.renameWith((n) => `current_${n}`),
            ...previous.renameWith((n) => `previous_${n}`),
          },
        })
      );

  return Page([], () => [
    UI.Header({
      title: 'Activity Overview',
      caption:
        'Monthly activity breakdown per customer segment (excluding inactive customers).',
    }),
    //stats order increase, revenue increase, order items increase
    [
      UI.Block([
        UI.Stats(
          Object.entries(tabs)
            .map(([key, value]) => ({
              title: _.startCase(key),
              attr: value.total,
            }))
            .map(({ attr, title }) =>
              LastTwoMonthsJoined({ rel: RfmLifecycleSummary }).chart.Stat(
                (t) => ({
                  value: t.attr(`current_${attr}`),
                  from: t.attr(`previous_${attr}`),
                }),
                { title }
              )
            )
        ),
      ]),
      UI.Block(
        [
          UI.Tabs({
            style: 'tabs',
            tabs: Object.entries(tabs)
              .map(([key, value]) => ({
                title: _.startCase(key),
                total: value.total,
                avg: value.avg,
              }))
              .map((tab) => ({
                title: tab.title,
                section: [
                  LineChart({
                    title: `Total ${tab.title} per customer segment`,
                    rel: RfmLifecycleSummary.groupBy((t) => ({
                      date: t.attr('date').dateTrunc('month'),
                      segment: t.attr('segment'),
                    }))
                      .select((t) => ({
                        y: Sum(t.attr(tab.total)),
                        x: t.attr('date'),
                        category: t.attr('segment'),
                      }))
                      .where((t) =>
                        t
                          .attr('category')
                          .oneOf([
                            ...customerSegments.active,
                            ...customerSegments.new,
                          ])
                      ),
                    axis: {
                      x: {
                        scale: 'month',
                      },
                      y: {
                        label: `Number of ${tab.title}`,
                      },
                    },
                  }),
                  BarChart({
                    title: `Share of ${tab.title} per customer segment (%)`,
                    size: 'sm',
                    rel: RfmLifecycleSummary.groupBy((t) => ({
                      date: t.attr('date').dateTrunc('month'),
                    }))
                      .select((t) => ({
                        all_total: Sum(t.attr(tab.total)),
                        date: t.attr('date'),
                      }))
                      .innerJoin(
                        RfmLifecycleSummary.groupBy((t) => ({
                          date: t.attr('date').dateTrunc('month'),
                          segment: t.attr('segment'),
                        }))
                          .select((t) => ({
                            y: Sum(t.attr(tab.total)),
                            x: t.attr('date'),
                            category: t.attr('segment'),
                          }))
                          .where((t) =>
                            t
                              .attr('category')
                              .oneOf([
                                ...customerSegments.active,
                                ...customerSegments.new,
                              ])
                          ),
                        (totals, segment) => ({
                          on: totals.attr('date').eq(segment.attr('x')),
                          select: {
                            y: segment
                              .attr('y')
                              .div(totals.attr('all_total'))
                              .mul(100)
                              .cast('int'),
                            x: segment.attr('x'),
                            category: segment.attr('category'),
                          },
                        })
                      )
                      .select((t) => t.star(), {
                        orderBy: (t) => ({
                          expr: t.attr('x'),
                          direction: 'asc',
                        }),
                      }),
                    format: {
                      x: 'month',
                    },
                    axis: {
                      x: {},
                      y: {
                        label: `Percentage of ${tab.title}`,
                      },
                    },
                  }),
                  LineChart({
                    title: `Average ${tab.title} per customer segment`,
                    rel: RfmLifecycleSummary.groupBy((t) => ({
                      date: t.attr('date').dateTrunc('month'),
                      segment: t.attr('segment'),
                    }))
                      .select((t) => ({
                        y: Sum(t.attr(tab.avg)),
                        x: t.attr('date'),
                        category: f`${t.attr('segment')}`,
                      }))
                      .where((t) =>
                        t
                          .attr('category')
                          .oneOf([
                            ...customerSegments.active,
                            ...customerSegments.new,
                          ])
                      ),
                    axis: {
                      x: {
                        scale: 'month',
                      },
                      y: {
                        label: `Number of ${tab.title}`,
                      },
                    },
                  }),
                ],
              })),
          }),
        ],
        {
          border: false,
        }
      ),
      UI.Block([RfmLifecycleSummary], { title: 'Sample Data' }),
    ],
    //heatmap showing percentage of active customers per month
  ]);
};
