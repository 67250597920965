import { ComponentType, useEffect, useRef } from 'react';
import { Title } from '@cotera/client/app/components/ui/text/title';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

export const NameWrapper: React.FC<
  ChildrenProps & { name?: string; compact: boolean }
> = ({ children, name, compact }) => {
  if (name && !compact) {
    return (
      <div className="flex flex-col w-full">
        <Title title={name} type="label" className="mb-related" />
        {children}
      </div>
    );
  }

  return <>{children}</>;
};

export const FormLabel: React.FC<{
  label?: string;
  compact?: boolean;
  disabled?: boolean;
  hasText?: boolean;
}> = ({ label, compact, disabled, hasText = true }) => {
  return (
    label &&
    compact && (
      <label
        className={classNames(
          'shrink-0 text-sm  text-xs font-medium h-full flex rounded-l-md pr-2 items-center',
          disabled ? 'cursor-default' : 'cursor-pointer',
          hasText ? 'border-r border-divider' : ''
        )}
      >
        {label}
      </label>
    )
  );
};

export function withAutoFocus<T>(WrappedComponent: ComponentType<T>) {
  return function AutoFocusComponent(props: T) {
    const elementRef = useRef<HTMLElement>(null);

    useEffect(() => {
      if (elementRef.current) {
        elementRef.current.focus();
      }
    }, []);

    return <WrappedComponent {...props} ref={elementRef as any} />;
  };
}
