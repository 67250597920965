import {
  Center,
  Loading,
  ToolPanel,
  Text,
} from '@cotera/client/app/components/ui';
import { Layout } from '@cotera/client/app/layout';
import { useStore } from './store';
import { Chart } from './chart';
import { Filter } from './filter';
import { Results } from './results';
import { downloadFile, jsonToCsv } from '@cotera/client/app/components/utils';
import { matchesForExport } from './queries';
import { useAppData } from '@cotera/client/app/stores/org';
import { mapArrowToEraTypes } from '@cotera/client/app/etc/duckdb/map-arrow-to-era-types';
import { useParams, useSearchParams } from 'react-router-dom';
import { AST, Relation } from '@cotera/era';
import { Suspense, useEffect } from 'react';
import { ResultBoundary } from '@cotera/client/app/components';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { DuckDBQueryResult } from '@cotera/client/app/etc/duckdb';
import { Button } from '@cotera/client/app/components/ui';
import { Card, Tabs } from '@cotera/client/app/components/headless';
import { NotFound } from '@cotera/client/app/components/app';
import { useDefinition } from '@cotera/client/app/etc/data/manifest';
import { FilterBuilderForRelation } from '../../components/app/filter-builder/for-relation';
import { useFirstValue } from '../../hooks/use-first-value';

const ARTIFACT_SIZE = 50_000;

export const SemanticSearch = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <SemanticSearchView />
      </Suspense>
    </Layout>
  );
};

const SemanticSearchView: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { data: definition } = useDefinition({ id });

  return (
    <ResultBoundary
      result={definition}
      fallback={() => <NotFound resource={`Data set "${id}"`} />}
    >
      {(definition) => (
        <Suspense
          fallback={
            <Center>
              <Loading.Dots />
            </Center>
          }
        >
          <DataSet definition={definition} />
        </Suspense>
      )}
    </ResultBoundary>
  );
};

const DataSet: React.FC<{
  definition: AST.RelFR;
}> = ({ definition }) => {
  const [params] = useSearchParams();
  const setData = useStore((s) => s.setData);
  const setRel = useStore((s) => s.setRel);
  const rel = useStore((s) => s.rel);
  const { data: res } = useDuckDBQuery({
    rel: Relation.wrap(definition).limit(
      params.get('rows') ? Number(params.get('rows')) : ARTIFACT_SIZE
    ),
  });

  useEffect(() => {
    setData(res.data);

    setRel(res.artifactRel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  if (!rel) {
    return (
      <Center>
        <Loading.Dots />
      </Center>
    );
  }

  return <SearchAndResults />;
};

const SearchAndResults: React.FC = () => {
  const initedDb = useAppData((s) => s.initedDb);
  const cutoff = useStore((s) => s.cutoff);
  const cutoffPos = useStore((s) => s.cutoffPos);
  const searchTerms = useStore((s) => s.searchTerms);
  const data = useStore((s) => s.data);
  const rel = useStore((s) => s.rel)!;
  const setRel = useStore((s) => s.setRel);
  const setData = useStore((s) => s.setData);

  const initialRel = useFirstValue(rel);

  useEffect(() => {
    const load = async () => {
      setData(new DuckDBQueryResult(await (await initedDb).query(rel), rel));
    };

    void load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rel.eraHash(), initedDb, setData]);

  return (
    <ToolPanel.Container
      panel={
        <div className="px-4 py-4">
          <Filter />
          <div className="pt-4">
            <Text.Title type="section">Matches</Text.Title>
            <div className="mt-4 flex flex-col space-y-4">
              <Stat
                label="Similarity Cutoff"
                value={Math.round(cutoff * 100)}
              />
              <Stat label="Number of Matches" value={cutoffPos + 1} />
              <Stat label="Total Records" value={data?.numRows ?? 0} />
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                onClick={async () => {
                  const table = await matchesForExport(
                    initedDb,
                    cutoff,
                    searchTerms,
                    rel
                  );
                  const rows = table
                    .toArray()
                    .map((row) => mapArrowToEraTypes(row, table.schema.fields));
                  downloadFile(
                    [await jsonToCsv(rows)],
                    'csv',
                    'semantic_search_matches'
                  );
                }}
                text="Export"
                theme="primary"
              />
            </div>
          </div>
        </div>
      }
    >
      <FilterBuilderForRelation
        rel={initialRel}
        onRun={(rel) => {
          setRel(rel);
        }}
      />
      <Card.Container>
        <Tabs.Container mode="controlled" default="results">
          <div className="border-b border-divider w-full">
            <Tabs.Panel border={false}>
              <Tabs.Tab id="results" text="Results" />
              <Tabs.Tab id="chart" text="Chart" />
            </Tabs.Panel>
          </div>
          <Tabs.Content id="results">
            <Card.Content>
              <Results />
            </Card.Content>
          </Tabs.Content>
          <Tabs.Content id="chart">
            <Chart />
          </Tabs.Content>
        </Tabs.Container>
      </Card.Container>
    </ToolPanel.Container>
  );
};

const Stat: React.FC<{ label: string; value: number }> = ({ label, value }) => {
  return (
    <div className="flex justify-between">
      <span className="text-sm text-gray-500">{label}</span>
      <span className="text-md font-medium">{value}</span>
    </div>
  );
};
