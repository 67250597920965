import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const CodeBlock: React.FC<{ code: string; language: string }> = ({
  code,
  language,
}) => {
  return (
    <SyntaxHighlighter language={language} style={oneLight}>
      {code}
    </SyntaxHighlighter>
  );
};
