import { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useStore } from './store';
import { useAppData, useTenantedClient } from '@cotera/client/app/stores/org';
import { lookupSimilarItems } from './queries';
import { toast, Text } from '@cotera/client/app/components/ui';
import { v4 } from 'uuid';
import { Inputs } from '@cotera/client/app/components/forms';

export const Filter: React.FC = () => {
  const initedDb = useAppData((s) => s.initedDb);
  const setLoading = useStore((s) => s.setLoading);
  const setData = useStore((s) => s.setData);
  const [search, setSearch] = useState<string>('');
  const searchTerms = useStore((s) => s.searchTerms);
  const addSearchTerm = useStore((s) => s.addSearchTerm);
  const removeSearchTerm = useStore((s) => s.removeSearchTerm);
  const rel = useStore((s) => s.rel);
  const client = useTenantedClient();

  useEffect(() => {
    const load = async () => {
      if (searchTerms.length === 0) return;
      setLoading(true);

      try {
        const res = await lookupSimilarItems(initedDb, searchTerms, rel!);
        setData(res);
      } catch (e) {
        toast.error('Failed to query embeddings');
      } finally {
        setLoading(false);
      }
    };

    void load();
  }, [searchTerms, initedDb, setLoading, setData, rel]);

  return (
    <div>
      <div className="flex flex-col mb-4 ">
        <Text.Title type="section" className="mb-2">
          Items like
        </Text.Title>
        <Inputs.Text
          id="search"
          placeholder="Search"
          value={search}
          onChange={setSearch}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              const id = v4();
              const result = await client.llm.embedding({
                messages: [{ content: search, id }],
              });

              if (result.isErr()) {
                toast.error('Failed to query embeddings');
                return;
              }

              const embedding = result.value.embeddings[0]!.embedding;

              addSearchTerm({
                term: search,
                embedding,
              });
              setSearch('');
            }
          }}
        />
      </div>
      <div className="text-sm mb-4">
        <ul className="flex flex-col space-y-2">
          {searchTerms.map((search, i) => (
            <li
              key={i}
              className="flex flex-row bg-white justify-between py-1.5 px-2 rounded border border-divider"
            >
              <Text.P className="italic text-standard-text text-sm">
                {search.term}
              </Text.P>
              <div className="w-8 h-full my-auto flex justify-end">
                <TrashIcon
                  className="h-4 w-4 text-red-300 cursor-pointer hover:text-red-500 my-auto"
                  onClick={() => {
                    removeSearchTerm(search.term);
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
