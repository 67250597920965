import React from 'react';
import { classNames } from '@cotera/client/app/components/utils';
import {
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';

export interface AlertProps {
  variant: 'success' | 'info' | 'warn' | 'error';
  message: React.ReactNode;
  detail?: React.ReactNode;
}

const icons = {
  success: (
    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
  ),
  info: (
    <InformationCircleIcon
      className="h-5 w-5 text-blue-400"
      aria-hidden="true"
    />
  ),
  warn: (
    <ExclamationTriangleIcon
      className="h-5 w-5 text-yellow-400"
      aria-hidden="true"
    />
  ),
  error: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
};

const styles = {
  success: { bg: 'bg-green-50', msg: 'text-green-800', body: 'text-green-700' },
  info: { bg: 'bg-blue-50', msg: 'text-blue-800', body: 'text-blue-700' },
  warn: { bg: 'bg-yellow-50', msg: 'text-yellow-800', body: 'text-yellow-700' },
  error: { bg: 'bg-red-50', msg: 'text-red-800', body: 'text-red-700' },
};

export const Alert: React.FC<AlertProps> = ({
  variant = 'info',
  message,
  detail,
}) => {
  const { bg, msg, body } = styles[variant];
  return (
    <div className={classNames('rounded p-4', bg)}>
      <div className="flex w-full">
        <div className="flex-shrink-0">{icons[variant]}</div>
        <div className="w-full ml-3">
          <h3 className={classNames('font-medium', msg)}>{message}</h3>
          <div className={classNames('mt-2', body)}>{detail}</div>
        </div>
      </div>
    </div>
  );
};
