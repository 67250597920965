/* eslint-disable @typescript-eslint/unbound-method */

import type { WarehouseType } from '../parser/credentials';
import _ from 'lodash';
import { err, ok, Result } from 'neverthrow';
import { AST } from '../ast';
import { EraHash } from '../hash';
import { expandRel } from '../macros/expand-rel';
import { SQL } from '../sql';
import { Dialects, SqlDialect } from '../sql/dialects';
import { Ty } from '../ty';
import { TC } from '../type-checker';
import { TyStackTrace } from '../type-checker/ty-stack-trace';
import { Count } from './aggregate';
import { Expression } from './expression';
import { Chart } from './markup/chart';
import { Grouping } from './fluent-grouping';
import { GroupedRelationRef, RelationRef } from './relation-ref';
import { Asc, Constant } from './utilities';
import { RowNumberOver } from './window';
import type { RelTypeCheck } from '../type-checker/check-rel';
import { Markup } from './markup';
import { EraQL } from '../eraql';
import { Traverse } from '../traverse';
import { FilterOperators } from '../ast/mu';
import type { EraVar } from './era-var';
import deepEqual from 'fast-deep-equal';
import { displayTy } from '../ty/ty';

export type FluentOrderBy = {
  readonly expr: Expression;
  readonly direction: AST.SortDirection;
};

export const ARTIFACT_SIZE = 50_000;

/**
 * `OverClause` is used in window functions to specify the partition and ordering variables.
 * @param opts.partitionBy Indicates the columns to partition by.
 * @param opts.orderBy Indicates the columns to order by.
 */
export type OverClause = {
  readonly partitionBy?: Expression | readonly Expression[];
  readonly orderBy?:
    | Expression
    | FluentOrderBy
    | readonly (FluentOrderBy | Expression)[];
  readonly frame?: AST.WindowFrame;
};

const asArray = <T>(input: T | T[]): T[] =>
  Array.isArray(input) ? input : [input];

const SQL_HASH_CACHE: WeakMap<AST.RelFR, string> = new WeakMap();
const ERA_HASH_CACHE: WeakMap<AST.RelFR, string> = new WeakMap();
const RELATION_CACHE: WeakMap<AST.RelFR, WeakRef<Relation>> = new WeakMap();

/**
 * A relation is a table-like data structure that can be queried and transformed.
 */
export class Relation {
  protected constructor(
    readonly ast: AST.RelFR,
    readonly typecheck: RelTypeCheck
  ) {}

  static wrap(rel: AST.RelFR | Relation): Relation {
    if (rel instanceof Relation) {
      return rel;
    }

    return this.fromAst(rel, { jsStackPointer: this.wrap });
  }

  static fromAst(
    ast: AST.RelFR,
    opts?: { jsStackPointer?: Function }
  ): Relation {
    const res = this.tryFromAst(ast);
    if (res.isErr()) {
      throw res.error.toError({
        jsStackPointer: opts?.jsStackPointer ?? this.fromAst,
      });
    }

    return res.value;
  }

  static tryFromAst(ast: AST.RelFR): Result<Relation, TyStackTrace> {
    const existing = RELATION_CACHE.get(ast)?.deref();

    if (existing) {
      return ok(existing);
    }

    const checked = TC.checkRel(ast);

    if (checked instanceof TyStackTrace) {
      return err(checked);
    }

    Object.freeze(ast);
    const rel = new Relation(ast, checked);
    Object.freeze(rel);
    RELATION_CACHE.set(ast, new WeakRef(rel));
    return ok(rel);
  }

  tap(callback: (self: Relation) => void): Relation {
    callback(this);
    return this;
  }

  get displayAttributes(): string {
    const tys = Object.entries(this.attributes)
      .map(([name, ty]) => `  "${name}": ${displayTy(ty)}`)
      .join('\n');

    return `(\n${tys}\n)`;
  }

  get attributes(): { [name: string]: Ty.ExtendedAttributeType } {
    return this.typecheck.attributes;
  }

  sqlForDialect(
    dialect: WarehouseType,
    opts?: SQL.SqlGenOpts
  ): SQL.CompiledQuery {
    switch (dialect) {
      case 'BIGQUERY':
        return this.bigQuerySql(opts);
      case 'POSTGRES':
        return this.postgresSql(opts);
      case 'SNOWFLAKE':
        return this.snowflakeSql(opts);
      case 'REDSHIFT':
        return this.redshiftSql(opts);
      case 'DUCKDBWASM':
        return this.duckdbwasmSql(opts);
      case 'DUCKDBNATIVE':
        return this.duckdbNativeSql(opts);
    }
  }

  redshiftSql(opts?: SQL.SqlGenOpts): SQL.CompiledQuery {
    return this.sql(Dialects.Redshift, opts ?? {});
  }

  bigQuerySql(opts?: SQL.SqlGenOpts): SQL.CompiledQuery {
    return this.sql(Dialects.BigQuery, opts ?? {});
  }

  postgresSql(opts?: SQL.SqlGenOpts): SQL.CompiledQuery {
    return this.sql(Dialects.Postgres, opts ?? {});
  }

  duckdbNativeSql(opts?: SQL.SqlGenOpts): SQL.CompiledQuery {
    return this.sql(Dialects.DuckDbNative, opts ?? {});
  }

  duckdbwasmSql(opts?: SQL.SqlGenOpts): SQL.CompiledQuery {
    return this.sql(Dialects.DuckDbWasm, opts ?? {});
  }

  snowflakeSql(opts?: SQL.SqlGenOpts): SQL.CompiledQuery {
    return this.sql(Dialects.Snowflake, opts ?? {});
  }

  assumptions(): AST._TableContract[] {
    return Traverse.assumptionsOfRel(this.ast);
  }

  get freeVars(): { readonly [scope: string]: AST.MacroArgsType } {
    return this.typecheck.vars;
  }

  get chart(): Chart {
    return Chart.fromRel(this);
  }

  ir(): AST.RelIR {
    return expandRel(this.ast, {});
  }

  maxPossibleRows(): number | null {
    return SQL.maxPossibleRows(this.ir());
  }

  sql(dialect: SqlDialect, opts: SQL.SqlGenOpts): SQL.CompiledQuery {
    return SQL.toSql(this.ast, { dialect, sqlGen: opts });
  }

  withMeta(meta: Record<string, string | boolean>): Relation {
    return this.ast.t === 'select'
      ? Relation.fromAst({ ...this.ast, meta: { ...this.ast.meta, ...meta } })
      : this.select((t) => t.star(), { meta });
  }

  renameAttributes(mapper: (name: string) => string): Relation {
    return this.select((t) =>
      Object.fromEntries(
        Object.entries(t.star()).map(([name, attr]) => [mapper(name), attr])
      )
    );
  }
  /**
   * `.select` allows you to select and/or transform attributes from a
   * {@link Relation}.
   * @param selection Select attributes from the preceding {@link Relation}.
   * Form: (t) => ({}), where (t) is the {@link RelationRef} and the object,
   * ({}), contains the column name and attribute selection/transformation
   * pairings.
   * @param opts.condition Boolean expression that is evaluated for each row
   * @param opts.orderBy Indicate which columns you want to order by and in
   * which direction. Form: (t) => [], where (t) is the {@link RelationRef} and
   * [] contains a list of ordered attributes (See {@link orderBy}).
   * @param opts.distinct Indicates whether to remove duplicate rows from the
   * result set
   * @param opts.limit Indicates the maximum number of rows to return
   * @param opts.offset Indicates the number of rows to skip before returning
   * rows
   * @returns A {@link Relation} with the specified attributes selected or
   * transformed from the preceding {@link Relation}.
   * @example
   * ```ts
   * rel.select((t) => ({ ...t.pick('name'), age: t.attr('age').cast('int') }))
   * ```
   */
  select(
    selection: (t: RelationRef) => {
      readonly [name: string]: Ty.Scalar | Expression;
    },
    opts?: {
      condition?: (t: RelationRef) => boolean | Expression;
      orderBy?: (
        t: RelationRef
      ) => Expression | FluentOrderBy | (FluentOrderBy | Expression)[];
      distinct?: boolean;
      limit?: number;
      offset?: number;
      meta?: Record<string, string | boolean>;
      jsStackPointer?: Function;
    }
  ): Relation {
    const {
      distinct = false,
      limit = null,
      offset = null,
      meta = {},
    } = opts ?? {};
    const ref = this.ref('from');

    const exprs = Object.fromEntries(
      Object.entries(selection(ref)).map(([name, expr]) => {
        const { ast } = expr instanceof Expression ? expr : Constant(expr);
        return [name, ast];
      })
    );

    const condition = opts?.condition?.(ref);

    // Order by is on the types of the output of the selection
    const orderByRelRef = new RelationRef(
      { attributes: _.mapValues(exprs, (e) => Expression.fromAst(e).ty) },
      'from'
    );

    return Relation.fromAst(
      {
        t: 'select',
        distinct,
        selection: exprs,
        meta,
        limit,
        offset,
        condition:
          condition === undefined ? null : Expression.wrap(condition).ast,
        sources: {
          from: this.ast,
        },
        orderBys: asArray(opts?.orderBy?.(orderByRelRef) ?? []).map((by) => {
          if (by instanceof Expression) {
            return { expr: by.ast, direction: 'asc' };
          } else {
            return { expr: by.expr.ast, direction: by.direction };
          }
        }),
      },
      { jsStackPointer: opts?.jsStackPointer ?? this.select }
    );
  }
  /**
   * `.where` allows you to filter a {@link Relation} based on a condition or
   * set of conditions.
   * @param condition Boolean expression that is evaluated for each row
   * @returns A {@link Relation} with only the rows that satisfy the condition
   * from the preceding Relation. All columns are retained in this operation.
   * @example
   * ```ts
   * rel.where((t) => t.attr('age').gt(18))
   * ```
   */
  where(
    condition: (t: RelationRef) => boolean | Expression,
    opts?: {
      jsStackPointer?: Function;
    }
  ): Relation {
    return this.select((t) => t.star(), {
      condition,
      jsStackPointer: opts?.jsStackPointer,
    });
  }
  /**
   * `.leftJoin` allows you to join two relations based on a condition, where
   * all rows from the left relation are retained, and rows from the right
   * relation are retained if they satisfy the join condition
   * ({@link https://www.w3schools.com/SQL/sql_join_left.asp | SQL docs}).
   * @param right the {@link Relation} to join with the preceding
   * {@link Relation}
   * @param condition A boolean expression or set of expressions that are
   * evaluated for each row in the joined relation
   * @returns A relation with all rows from the left Relation and only the rows
   * from the right Relation that satisfy the join condition
   * @example
   * ```ts
   * rel.leftJoin(foo, (left, right) => ({ on: left.attr('id').eq(right.attr('id')), select: { ...left.star(), ...right.star() } }))
   * ```
   */
  leftJoin(
    right: Relation,
    condition: (
      left: RelationRef,
      right: RelationRef
    ) => {
      on: boolean | Expression;
      select: { readonly [name: string]: Ty.Scalar | Expression };
    }
  ): Relation {
    return this.join('left', right, condition);
  }
  /**
   * `.rightJoin` allows you to join two relations based on a condition, where
   * all rows from the right relation are retained, and rows from the left
   * relation are retained if they satisfy the join condition
   * {@link https://www.w3schools.com/SQL/sql_join_right.asp | SQL docs}.
   * @param right the {@link Relation} to join with the preceding
   * {@link Relation}
   * @param condition A boolean expression or set of expressions that are
   * evaluated for each row in the joined relation
   * @returns A relation with all rows from the right Relation and only the rows
   * from the left Relation that satisfy the join condition
   * @example
   * ```ts
   * rel.rightJoin(foo, (left, right) => ({ on: left.attr('id').eq(right.attr('id')), select: { ...left.star(), ...right.star() } }))
   * ```
   */
  rightJoin(
    right: Relation,
    condition: (
      left: RelationRef,
      right: RelationRef
    ) => {
      on: boolean | Expression;
      select: { readonly [name: string]: Ty.Scalar | Expression };
    }
  ): Relation {
    return this.join('right', right, condition);
  }

  /**
   * `.innerJoin` allows you to join two relations based on a condition, where
   * only the rows that satisfy the join condition in both relations are
   * retained
   * ({@link https://www.w3schools.com/SQL/sql_join_inner.asp | SQL docs})
   * @param right the {@link Relation} to join with the preceding
   * {@link Relation}
   * @param condition A boolean expression or set of expressions that are
   * evaluated for each row in the joined relation
   * @returns A relation with only the rows from both the left and right
   * Relations that satisfy the join condition
   * @example
   * ```ts
   * rel.innerJoin(foo, (left, right) => ({ on: left.attr('id').eq(right.attr('id')), select: { ...left.star(), ...right.star() } }))
   * ```
   */
  innerJoin(
    right: Relation,
    condition: (
      left: RelationRef,
      right: RelationRef
    ) => {
      on: boolean | Expression;
      select: { readonly [name: string]: Ty.Scalar | Expression };
    }
  ): Relation {
    return this.join('inner', right, condition);
  }

  private join(
    how: AST.JoinType,
    right: Relation,
    condition: (
      left: RelationRef,
      right: RelationRef
    ) => {
      on: boolean | Expression;
      select: { readonly [name: string]: Ty.Scalar | Expression };
    }
  ): Relation {
    const { on, select } = condition(this.ref('left'), right.ref('right'));

    return Relation.fromAst(
      {
        t: 'join',
        how,
        condition: (on instanceof Expression ? on : Constant(on)).ast,
        selection: Object.fromEntries(
          Object.entries(select).map(([name, expr]) => [
            name,
            expr instanceof Expression ? expr.ast : Constant(expr).ast,
          ])
        ),
        sources: {
          left: this.ast,
          right: right.ast,
        },
      },
      { jsStackPointer: this.join }
    );
  }

  estimateAstSize(): number {
    return JSON.stringify(this.ast).length;
  }

  /**
   * Order a Relation on one or more columns.
   * @param orderBys Indicate which columns you want to order by and in which
   * direction. Form: (t) => [], where (t) is the {@link RelationRef} and []
   * contains a list of ordered attributes.
   * @param opts Other options like limit or offset
   * @returns A Relation ordered by the specified columns
   * @example
   * ```ts
   * rel.orderBy((t) => [Asc(t.attr('name')), Desc(t.attr('age'))])
   * ```
   */
  orderBy(
    orderBys: (
      t: RelationRef
    ) => Expression | FluentOrderBy | (Expression | FluentOrderBy)[],
    opts?: { limit?: number; offset?: number }
  ): Relation {
    return this.select((t) => t.star(), { ...opts, orderBy: orderBys });
  }

  /**
   * `.groupBy` is the method that establishes groups for aggregation.
   * @param grouped Indicates which columns to group by. Form: (t) => ({}),
   * where (t) is the {@link RelationRef} and the object, ({}), contains the
   * column name and attribute selection/transformation pairings for your
   * groups.
   * @returns A grouped Relationß
   * @example
   * ```ts
   * rel.groupBy((t) => t.pick('name', 'age')).select((t) => ({ ...t.group(), count: Count() }))
   * ```
   */
  groupBy(
    grouped: (t: RelationRef) => {
      readonly [name: string]: Ty.Scalar | Expression;
    }
  ): Grouping {
    let attrs: readonly string[];

    // Create a new namespace that includes all the grouped attributes
    const rel = this.select((t) => {
      const selection = grouped(t);
      attrs = Object.keys(selection);
      return { ...t.star(), ...selection };
    });

    return new Grouping(attrs!, rel);
  }
  /**
   * `.countBy` is a shorthand method that represents a groupBy followed by a
   * {@link select} with a {@link Count}.
   * @param grouper Indicates which columns to group by for the count. Form: (t)
   * => ({}), where (t) is the {@link RelationRef} and the object, ({}),
   * contains the column name and attribute selection/transformation pairings
   * for your groups.
   * @returns A Relation with columns for each grouping variable and a count of
   * the number of rows in each group.
   * @example
   * ```ts
   * rel.countBy((t) => t.pick('name', 'age'))
   * ```
   * is equivalent to
   * ```ts
   * rel.groupBy((t) => t.pick('name', 'age')).select((t) => ({ ...t.group(), COUNT: Count() }))
   * ```
   */
  countBy(
    grouper: (t: RelationRef) => {
      readonly [name: string]: Ty.Scalar | Expression;
    }
  ): Relation {
    return this.groupBy(grouper).select((t) => ({
      ...t.group(),
      COUNT: Count(),
    }));
  }

  /**
   * `.summary` is a method that allows you to create aggregates of a Relation
   * with no grouping variables.
   * @param selection Indicates the names and aggregates to be calculated. Form:
   * (t) => ({}), where (t) is the {@link RelationRef} and the object, ({}),
   * contains the column name and aggregate pairings.
   * @returns A relation containing a single row with the specified aggregates.
   * @example
   * ```ts
   * rel.summary((t) => ({ total: Sum(t.attr('amount')), avg: Avg(t.attr('amount'))}))
   * ```
   */
  summary(
    selection: (t: GroupedRelationRef) => {
      readonly [name: string]: Ty.Scalar | Expression;
    }
  ): Relation {
    const ref = new GroupedRelationRef(Object.keys(this.attributes), this);
    const exprs = Object.fromEntries(
      Object.entries(selection(ref)).map(([name, expr]) => {
        const { ast } = expr instanceof Expression ? expr : Constant(expr);
        return [name, ast];
      })
    );

    return Relation.fromAst(
      {
        t: 'aggregate',
        groupedAttributes: [],
        selection: exprs,
        sources: {
          from: this.ast,
        },
      },
      { jsStackPointer: this.summary }
    );
  }

  /**
   * Limit the number of rows in a Relation.
   * @param n Number of rows to limit the {@link Relation} to
   * @returns A Relation with a maximum of n rows
   */
  limit(n: number): Relation {
    return this.select((t) => t.star(), { limit: n });
  }

  /**
   * Assert that the number of rows in a Relation is below a certain threshold.
   * @param n Number of rows to limit the {@link Relation} to
   * @returns A Relation with a maximum of n rows
   */
  assertLimit(n: number): Relation {
    const key = '____ROW_NUM';

    return this.select((t) => ({
      ...t.star(),
      [key]: RowNumberOver({ orderBy: Asc(1) }),
    }))
      .select((t) =>
        _.mapValues(this.attributes, (_ty, name) =>
          t.attr(name).invariants({
            [`LIMIT IS BELOW ${n}`]: t.attr(key).lte(n),
          })
        )
      )
      .limit(n);
  }

  /**
   * Assert that the number of rows in a Relation is below a certain threshold
   * using `.invariants`. Fails the query if this condition does not hold.
   * @returns A relation with a maximum number of rows. It will fail the query
   * if relation exceed the runtime caching threshold.
   */
  assertCacheable(): Relation {
    return this.assertLimit(ARTIFACT_SIZE);
  }

  ref(source: AST._Attribute['source']): RelationRef {
    return new RelationRef(this, source);
  }

  execute<T extends DriverLike>(
    driver: T | DriverLike['execute'],
    opts?: SQL.SqlGenOpts
  ): ReturnType<DriverLike['execute']> {
    return typeof driver === 'function'
      ? driver(this)
      : driver.execute(this, opts);
  }

  eraHash(): string {
    const existing = ERA_HASH_CACHE.get(this.ast);

    if (existing) {
      return existing;
    }

    const hash = EraHash.hashRel(this.ast);
    ERA_HASH_CACHE.set(this.ast, hash);
    return hash;
  }

  sqlHash(): string {
    const existing = SQL_HASH_CACHE.get(this.ast);

    if (existing) {
      return existing;
    }

    const hash = SQL.hashIR(this.ir());
    SQL_HASH_CACHE.set(this.ast, hash);
    return hash;
  }

  async andThenAsync<X>(callback: (x: Relation) => Promise<X>) {
    return callback(this);
  }

  andThen(callback: (x: Relation) => Relation): Relation {
    return callback(this);
  }

  sqlWhere(
    rawCode: readonly string[],
    ...keys: readonly (Ty.Scalar | Expression)[]
  ): Relation {
    const compiler = EraQL.makeQlExpressionCompiler(
      { attributes: this.ref('from').star() },
      { jsStackPointer: this.sqlWhere }
    );

    const expr = compiler(rawCode, ...keys);
    return this.where((_t) => expr, { jsStackPointer: this.sqlWhere });
  }

  isAstEq(rhs: Relation): boolean {
    return deepEqual(this.ast, rhs.ast);
  }
}

export type QuickFilter = {
  left: string;
  operator: FilterOperators;
  right: unknown;
  t: Ty.PrimitiveAttributeType;
};

export class RelVar extends Relation implements EraVar {
  protected constructor(
    readonly relVar: AST._RelVar<AST.RelMacroChildren>,
    typecheck: RelTypeCheck
  ) {
    super(relVar, typecheck);
    Object.freeze(this);
  }

  static create(params: {
    name: string;
    scope: string;
    ty: Record<string, Ty.Shorthand>;
    default?: Relation;
  }): RelVar {
    const ast: AST._RelVar<AST.RelMacroChildren> = {
      t: 'rel-var',
      name: params.name,
      scope: params.scope,
      attributes: _.mapValues(params.ty, (ty) => Ty.shorthandToTy(ty)),
      default: params.default?.ast ?? null,
    };

    const checked = TC.checkRel(ast);

    if (checked instanceof TyStackTrace) {
      throw checked.toError({ jsStackPointer: this.create });
    }

    return new this(ast, checked);
  }

  EditableTable() {
    return Markup.fromAst({
      t: 'rel-controls-v2',
      var: this.relVar,
      config: { t: 'editable-table' },
    });
  }

  DefinitionPicker() {
    return Markup.fromAst({
      t: 'rel-controls-v2',
      var: this.relVar,
      config: { t: 'defintion-picker' },
    });
  }

  SliceBuilder(quickFilters: QuickFilter[] = []) {
    return Markup.fromAst({
      t: 'rel-controls-v2',
      var: this.relVar,
      config: { t: 'slice-builder', quickFilters },
    });
  }

  SemanticSearch() {
    return Markup.fromAst({
      t: 'rel-controls-v2',
      var: this.relVar,
      config: { t: 'semantic-search' },
    });
  }

  FilePicker() {
    return Markup.fromAst({
      t: 'rel-controls-v2',
      var: this.relVar,
      config: { t: 'file-picker' },
    });
  }
  isVariableIn(target: Expression | Relation | Markup): boolean {
    return (
      target.freeVars[this.relVar.scope]?.rels[this.relVar.name] !== undefined
    );
  }

  variableId(): { readonly name: string; readonly scope: string } {
    return { name: this.relVar.name, scope: this.relVar.scope };
  }
}

export type DriverLike = {
  execute: (
    rel: Relation,
    opts?: SQL.SqlGenOpts
  ) => Promise<Record<string, Ty.Scalar>[]>;
};
