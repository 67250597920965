import { CountDistinct, ExprVar, Relation } from '@cotera/era';
import {
  AgentStats,
  DailyTrendWithSummary,
  ImperfectionProportionByDay,
  TicketSummaryStats,
  TicketSample,
} from './components';
import _ from 'lodash';

export const OverviewPage = (rel: Relation, agent_toggle: ExprVar) => {
  const maxScoreRel = rel.summary((t) => ({
    MAX_SCORE: CountDistinct(t.attr('sub_category')),
  }));

  return [
    TicketSummaryStats(rel, maxScoreRel),
    DailyTrendWithSummary(rel),
    AgentStats(rel, agent_toggle),
    ImperfectionProportionByDay(rel, maxScoreRel),
    TicketSample(rel),
  ];
};

export const DetailPage = (
  rel: Relation,
  agent_toggle: ExprVar,
  page: ExprVar
) => {
  const detailRel = rel
    .select((t) => ({
      ...t.except('category'),
      category: t.attr('sub_category'),
    }))
    .where((t) =>
      t.attr('category').lower().eq(page.replace(' ', '_').lower())
    );

  const maxScoreRel = detailRel.summary((t) => ({
    MAX_SCORE: CountDistinct(t.attr('category')),
  }));

  return [
    TicketSummaryStats(detailRel, maxScoreRel),
    ...OverviewPage(detailRel, agent_toggle).slice(1, 3),
    ImperfectionProportionByDay(detailRel, maxScoreRel),
    TicketSample(detailRel),
  ];
};
