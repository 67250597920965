import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useTenantedQueryKey } from './use-tenanted-query-key';
import { queryKeys } from './query-cache-keys';

export const useTopicVersions = (topicModelId: string) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.versions({ topicModelId, orgId })
  );

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.versions({
          topicModelId,
        })
      ),
    queryKey,
  });
};

export const useTopics = (topicModelId: string, versionId: string) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.topics({ topicModelId, versionId, orgId })
  );

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.topics({
          versionId,
        })
      ),
    queryKey,
  });
};
