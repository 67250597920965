import { Text as UiText } from '@cotera/client/app/components/ui';
import { AST } from '@cotera/era';
import Markdown from 'markdown-to-jsx';
import { startCase } from 'lodash';
import { useTypedExpressionValue } from './macro-expansion/scopes';
import { z } from 'zod';

const MODIFIERS: Record<AST._Text['modifier'], (x: string) => string> = {
  none: (x) => x,
  'start-case': (x) => startCase(x),
};

export const Text: React.FC<{
  section: AST._Text;
}> = ({ section }) => {
  const rendered = useTypedExpressionValue(section.text, z.string().nullable());

  // TODO: Better loading state and not just default to empty string
  const str = rendered ?? '';
  const modedString = MODIFIERS[section.modifier](str);

  return (
    <Markdown
      className={'mb-4 text-sm text-standard-text'}
      options={{
        overrides: {
          h1: {
            component: UiText.Title,
            props: {
              className: 'mb-4',
            },
          },
          h2: {
            component: UiText.Title,
            props: {
              className: 'mb-6',
            },
          },
          h3: {
            component: UiText.Title,
            props: {
              className: 'mb-4',
            },
          },
          h4: {
            component: UiText.Title,
            props: {
              className: 'mb-4',
            },
          },
          p: {
            component: UiText.P,
            props: {
              className: 'mb-4 text-sm text-standard-text',
            },
          },
          span: {
            props: {
              className: 'mb-4 text-sm text-standard-text',
            },
          },
          a: {
            component: 'a',
            props: {
              className: 'text-primary-text hover:underline',
            },
          },
        },
      }}
    >
      {modedString}
    </Markdown>
  );
};
