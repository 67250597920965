import { FunctionTypingRule } from './function-call';
import { err, ok, Result } from 'neverthrow';
import { Ty } from '../../ty';
import * as Errs from '../type-check-error';
import { Assert } from '../../utils';
import { parseTy } from '../../eraql/eraql';

export const tagTypingRule: FunctionTypingRule = (_name, args) => {
  const [target, tag] = args;
  Assert.assert(target !== undefined && tag !== undefined);

  const e: Errs.TypeCheckError = new Errs.SingleVariantEnumRequired({
    got: tag,
  });

  if (tag.ty.k !== 'enum') {
    return err(e);
  }

  const [tagName, ...restVariants] = tag.ty.t;

  if (tagName === undefined || restVariants.length > 0) {
    return err(e);
  }

  return ok(Ty.tag(target, [tagName]));
};

export const typeOfTypingRule: FunctionTypingRule = (_name, args) => {
  const [target] = args;
  Assert.assert(target !== undefined);
  return ok(Ty.nn(Ty.e([Ty.displayTy(target)])));
};

export const emptyArrayOfTypingRule: FunctionTypingRule = (_name, args) => {
  const [ty] = args;
  Assert.assert(ty !== undefined);
  return reqTyFromEnumVariant(ty).map((ty) => Ty.nn(Ty.a(ty)));
};

export const nullOfTypingRule: FunctionTypingRule = (_name, args) => {
  const [ty] = args;
  Assert.assert(ty !== undefined);
  return reqTyFromEnumVariant(ty).andThen((ty) =>
    !ty.nullable
      ? err(
          new Errs.CantHaveNullOfNonNullableType({
            attempted: ty,
          })
        )
      : ok(ty)
  );
};

const reqTyFromEnumVariant = (
  ty: Ty.ExtendedAttributeType
): Result<Ty.ExtendedAttributeType, Errs.TypeCheckError> => {
  if (ty.ty.k !== 'enum' || ty.ty.t.length !== 1) {
    return err(
      new Errs.SingleVariantEnumRequired({
        got: ty,
      })
    );
  }

  const tyString = ty.ty.t[0]!;
  const parse = parseTy(tyString);

  if (parse.isErr()) {
    return err(
      new Errs.CantParseAsTy({ err: parse.error, original: tyString })
    );
  }

  return ok(parse.value);
};
