import { Relation } from '@cotera/era';
import { Watchable } from '@cotera/client/app/etc';

export type NodeTransform = {
  type?: string;
  fn: (rel: Relation) => Relation;
};

export abstract class BaseWorkspaceNodeViewModel extends Watchable {
  abstract t: string;
  abstract transforms: NodeTransform[];
  parent: BaseWorkspaceNodeViewModel | null = null;

  constructor(
    public name: string,
    public id: string,
    public position: { x: number; y: number } = {
      x: 0,
      y: 0,
    }
  ) {
    super();
  }

  abstract get rel(): TransformableRelation;

  abstract get artifactId(): string | null;

  abstract getRel(type: 'source' | 'artifact'): Relation;

  addTransform(fn: (rel: Relation) => Relation) {
    this.transforms.push({ fn });
    this.notifySubscribers();
  }

  setTransforms(fns: NodeTransform[]) {
    this.transforms = fns;
    this.notifySubscribers();
  }

  setTransform(
    fn: (rel: Relation) => Relation,
    type: string,
    notify: boolean = true
  ) {
    //this needs to update if exists or append
    if (this.transforms.find((t) => t.type === type)) {
      this.transforms = [
        ...this.transforms.map((t) => {
          if (t.type === type) {
            return {
              type,
              fn: (rel: Relation) => fn(rel),
            };
          }
          return t;
        }),
      ];
    } else {
      this.transforms = [
        ...this.transforms,
        {
          type,
          fn: (rel: Relation) => fn(rel),
        },
      ];
    }
    if (notify) {
      this.notifySubscribers();
    }
  }

  findParentOfType(
    node: BaseWorkspaceNodeViewModel,
    type: string
  ): BaseWorkspaceNodeViewModel | undefined {
    if (node.t === type) {
      return node;
    } else {
      if (node.parent === null) {
        return undefined;
      }
      return this.findParentOfType(node.parent, type);
    }
  }
}

export class TransformableRelation extends Relation {
  public constructor(ast: Relation['ast'], typecheck: Relation['typecheck']) {
    super(ast, typecheck);
  }

  static fromRel(rel: Relation) {
    return new TransformableRelation(rel.ast, rel.typecheck);
  }

  apply = (fns: NodeTransform[]) => {
    let rel = new Relation(this.ast, this.typecheck);

    for (const fn of fns) {
      rel = fn.fn(rel);
    }

    return new TransformableRelation(rel.ast, rel.typecheck);
  };

  isEqual = (other: TransformableRelation) => {
    return this.sqlHash() === other.sqlHash();
  };
}
