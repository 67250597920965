import {
  Asc,
  Count,
  Desc,
  LeadOver,
  Relation,
  MaxOver,
  RankOver,
} from '@cotera/era';

export const makeCurrentChange = ({
  HistoricalCustomerRfm,
}: {
  HistoricalCustomerRfm: Relation;
}) => {
  const NUM_TIME_PERIODS_LAG = 11;

  const lag3Months = HistoricalCustomerRfm.select(
    (t) => ({
      ...t.pick('TODAY'),
    }),
    { distinct: true }
  )
    .select((t) => ({
      ...t.pick('TODAY'),
      TODAY_RANK: RankOver({ orderBy: Desc(t.attr('TODAY')) }),
    }))
    .where((t) => t.attr('TODAY_RANK').eq(NUM_TIME_PERIODS_LAG));

  const CurrentChange = HistoricalCustomerRfm.leftJoin(
    lag3Months,
    (rfm, lagday) => ({
      on: true,
      select: {
        ...rfm.star(),
        LAG_DATE: lagday.attr('TODAY'),
      },
    })
  )
    .select((t) => ({
      ...t.star(),
      MAX_DATE: MaxOver(t.attr('TODAY'), {}),
    }))
    .where((t) =>
      t
        .attr('TODAY')
        .eq(t.attr('MAX_DATE'))
        .or(t.attr('TODAY').eq(t.attr('LAG_DATE')))
    )
    .select((t) => ({
      ...t.pick('__COTERA_CUSTOMER_ID'),
      STARTING_SEGMENT: t.attr('RFM_CATEGORY'),
      STARTING_SEGMENT_NUM: t.attr('RFM_NUM'),
      CURRENT_SEGMENT: LeadOver(t.attr('RFM_CATEGORY'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
      CURRENT_SEGMENT_NUM: LeadOver(t.attr('RFM_NUM'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
    }))
    .where((t) => t.attr('CURRENT_SEGMENT').isNotNull())
    .groupBy((t) =>
      t.pick(
        'STARTING_SEGMENT_NUM',
        'STARTING_SEGMENT',
        'CURRENT_SEGMENT_NUM',
        'CURRENT_SEGMENT'
      )
    )
    .select((t) => ({
      ...t.group(),
      COUNT: Count(),
    }))
    .select((t) => t.renameWith((name) => name.toLowerCase()));

  return CurrentChange;
};
