import {
  CoteraLink,
  Text,
  Collapse,
  FadeIn,
  Main,
  Button,
} from '@cotera/client/app/components/ui';
import { Ilustration } from './components/ilustration';
import { useState } from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { Controller, useForm } from 'react-hook-form';
import { config } from '../../../config/config';
import axios from 'axios';
import { Inputs } from '@cotera/client/app/components/forms';
import { FormErrors } from '../../components/app/error/index';
import { Logo } from '@cotera/client/app/components/app';

export const Login: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmitted = async (e: Record<string, string>) => {
    await axios({
      url: `${config.apiUrl}/sessions/magic_links`,
      method: 'POST',
      data: { email: e['email']! },
    });
    setSubmitted(true);
  };

  return (
    <Main>
      <div className="h-full flex flex-row">
        <div className="flex-1 flex flex-col justify-center items-center mr-8">
          <div className="w-[400px]">
            <Logo className="mb-4" />
            <Collapse in={!submitted}>
              <Text.Title type="title" className="mb-3">
                Sign in with Magic Link
              </Text.Title>
              <Text.Caption className="mb-3 text-sm">
                Enter your email address below. If you or your organization have
                an account with us, you will recieve a login link.
              </Text.Caption>
              <p className="mb-8">
                Or{' '}
                <CoteraLink to="https://cotera.co/">
                  join our waitlist
                </CoteraLink>
              </p>
              <form onSubmit={handleSubmit(onSubmitted)}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Email is required' },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value is not a valid email',
                    },
                  }}
                  render={({ field }) => (
                    <Inputs.Text
                      {...field}
                      label="Email Address"
                      id="email"
                      type="email"
                      value={field.value ?? ''}
                      className="mb-3"
                    />
                  )}
                />
                <Button
                  className="w-full justify-center"
                  onClick={handleSubmit(onSubmitted)}
                  text="Send Email"
                  theme="primary"
                />
              </form>
              <FormErrors errors={errors} className="mt-3" />
            </Collapse>
            <FadeIn in={submitted} delay={300}>
              <div className="flex flex-row items-center">
                <Text.Title type="title" className="mb-2 mt-2 mr-4">
                  Check your email
                </Text.Title>
                <EnvelopeIcon className="text-primary-text" height={20} />
              </div>
              <Text.Caption>
                If you have an account, you will recieve a login link shortly.
                <br />
                You can close this tab.
              </Text.Caption>
            </FadeIn>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-center ml-8 hidden md:flex">
          <Ilustration className="w-full h-full max-w-[800px] max-h-[800px]" />
        </div>
      </div>
    </Main>
  );
};
