import React, { useEffect } from 'react';
import { areArraysEqual, useSubscribe } from '@cotera/client/app/etc';
import { DataGridViewModel, Registry } from '../types';
import {
  Button,
  Divider,
  ProgressBar,
  Text,
  Title,
  toast,
} from '@cotera/client/app/components/ui';
import { ManageDataGridView } from './data-grid-actions';
import { joinElements } from '../../utils/utils';
import { useSideModal } from '@cotera/client/app/components/app';
import { Tabs } from '@cotera/client/app/components/headless';
import { jsonToCsv } from '@cotera/client/app/components/utils';
import { useFirstValue } from '@cotera/client/app/hooks/use-first-value';

export const Footer: React.FC<{
  vm: DataGridViewModel;
  items?: React.ReactNode[];
  registry: Registry;
}> = ({ vm, items = [], registry }) => {
  const openSideModel = useSideModal((s) => s.actions.push);
  const inViewRows = useSubscribe(vm, (s) => s.inViewRows);
  const totalRowCount = useSubscribe(vm, (s) => s.totalRowCount);
  const loadedRowCount = useSubscribe(vm, (s) => s.loadedRows);
  const hiddenColumns = useSubscribe(vm, (s) => s.hiddenItems);
  const columns = useSubscribe(vm, (s) => s.columns);
  const maxInViewIndex =
    inViewRows.length === 0
      ? Math.min(50, totalRowCount)
      : Math.max(...inViewRows);
  const selectedRows = useSubscribe(vm, (s) => s.selectedRows);
  const rowSelectionHandler = (
    e: Pick<React.KeyboardEvent<any>, 'preventDefault'>
  ) => {
    if (vm.selectedRows.length > 0) {
      e.preventDefault();
      openSideModel({
        view: () => <DetailView vm={vm} registry={registry} />,
      });
    }
  };
  return (
    <>
      <div className="flex flex-col w-[calc(100%-1em)] border-t sticky left-0 bottom-0 pt-2">
        {totalRowCount > 0 && (
          <ProgressBar
            theme="inverse"
            percent={
              loadedRowCount === 0
                ? 0
                : (Math.min(maxInViewIndex, totalRowCount) / totalRowCount) *
                  100
            }
            className="w-full h-1"
          />
        )}

        <div className="w-full flex bg-white pt-2 h-[35px] left-0 items-center justify-between">
          <div className="flex items-center">
            {joinElements(
              [
                <NumColumns
                  registry={registry}
                  vm={vm}
                  hiddenColumns={hiddenColumns}
                  columns={columns}
                />,
                ...items,
                totalRowCount > 0 && (
                  <Text.Caption className="text-center h-full">
                    Loaded {Math.min(loadedRowCount, totalRowCount)} of{' '}
                    {totalRowCount} rows
                  </Text.Caption>
                ),
              ],
              (i) => (
                <div
                  key={i}
                  className="flex items-center h-full w-[1px] border-r border-divider ml-4 mr-4 my-2"
                >
                  &nbsp;
                </div>
              )
            )}
          </div>
          {selectedRows.length > 0 && (
            <Button
              compact
              small
              theme="secondary"
              text={`${selectedRows.length} rows selected`}
              onClick={rowSelectionHandler}
              hint={{
                targetKey: (k) => k.key === ' ',
                display: 'Space',
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const NumColumns: React.FC<{
  registry: Registry;
  vm: DataGridViewModel;
  hiddenColumns: string[];
  columns: string[];
}> = ({ registry, vm, columns, hiddenColumns }) => {
  const sideModal = useSideModal((s) => s.actions.push);
  return (
    <Text.Caption
      className="text-center h-full cursor-pointer hover:text-primary-text"
      onClick={() => {
        sideModal({
          view: () => <ManageDataGridView vm={vm} registry={registry} />,
        });
      }}
    >
      {columns.length - hiddenColumns.length}/{columns.length} columns
    </Text.Caption>
  );
};

const DetailView: React.FC<{
  vm: DataGridViewModel;
  registry: Registry;
}> = ({ vm, registry }) => {
  const visibleColumns = useSubscribe(vm, (s) => s.visibleColumns);
  const data = useSubscribe(vm, (s) => s.data);
  const selectedRows = useSubscribe(vm, (s) => s.selectedRows);
  const attributes = useSubscribe(vm, (s) => s.attributes);
  const close = useSideModal((s) => s.actions.close);

  const firstValue = useFirstValue(selectedRows);

  useEffect(() => {
    if (!areArraysEqual(firstValue, selectedRows)) {
      close();
    }
  }, [selectedRows, firstValue, close]);

  return (
    <>
      <Tabs.Container mode="controlled" active={String(selectedRows[0])}>
        <Tabs.Panel border={false} className="!px-0 mx-4" padding={false}>
          {selectedRows.map((row, index) => {
            return (
              <Tabs.Tab
                id={String(row)}
                key={index}
                text={`Row ${row + 1}`}
                className="!px-0 pr-2"
              />
            );
          })}
        </Tabs.Panel>
        <Divider className="mb-4 mx-2" />
        {selectedRows.map((row) => {
          return (
            <Tabs.Content
              id={String(row)}
              key={row}
              className="flex-1 h-full flex-grow mx-2"
            >
              {visibleColumns.map((column, index) => {
                const ty = attributes[column]!;
                const Cell = registry.getPreviewRenderer(column, ty);
                return (
                  <div className="mb-4" key={index}>
                    <Title type="label">{column}</Title>
                    <div className="-ml-3">
                      <Cell
                        column={column}
                        ty={ty}
                        value={data?.column(column).valueAt(row)}
                      />
                    </div>
                  </div>
                );
              })}
            </Tabs.Content>
          );
        })}
      </Tabs.Container>
      <div className="flex flex-row justify-end shrink-0 flex-0 h-[92px] border-t border-divider pt-4 px-4">
        <Button
          className="mr-4"
          theme="primary"
          icon="clipboard-document"
          text="Copy as JSON"
          onClick={async () => {
            await navigator.clipboard.writeText(
              JSON.stringify(
                selectedRows.map((x) => data?.row(x).toArray()).flat()
              )
            );

            toast.success('Copied as JSON');
          }}
        />
        <Button
          theme="primary"
          icon="clipboard-document"
          text="Copy as CSV"
          onClick={async () => {
            const csv = await jsonToCsv(
              selectedRows.map((x) => data?.row(x).toArray()).flat()
            );

            await navigator.clipboard.writeText(csv);

            toast.success('Copied as CSV');
          }}
        />
      </div>
    </>
  );
};
