import { CredentialsMap, WAREHOUSE_TYPES } from './utils';
import { Link } from 'react-router-dom';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Layout } from '@cotera/client/app/layout';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { Card } from '@cotera/client/app/components/headless';
import { Text } from '@cotera/client/app/components/ui';
import { useWarehouseConnection } from '../../hooks/warehouse';

const Tag = ({ name, value }: { name: string; value: string }) => {
  return (
    <div className="flex flex-row items-center space-x-2">
      <Text.Caption className="text-sm font-medium">{name}:</Text.Caption>
      <Text.Caption className="text-sm">{value}</Text.Caption>
    </div>
  );
};

export const ListConnectionsPage: React.FC<{}> = () => {
  const warehouse = useWarehouseConnection().data;
  const t = warehouse?.type.toLowerCase() as (typeof WAREHOUSE_TYPES)[number];
  const displayConfig = CredentialsMap[t];
  const client = useTenantedClient();
  const { data: integrations } = useSuspenseQuery({
    queryFn: async () => {
      const res = await client.admin.connections.all({
        pagination: { page: 0, perPage: 100 },
      });
      return Assert.assertOk(res);
    },
    queryKey: ['connections'],
  });

  return (
    <Layout>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-2 space-x-4">
          <Card.Container>
            <Card.Header>
              <Card.Title>Warehouses</Card.Title>
            </Card.Header>
            <Card.Content>
              <ul>
                <li>
                  <div className="flex flex-col">
                    <div className="mb-2">
                      <displayConfig.icon height={25} />
                    </div>
                    <Tag name="Type" value={warehouse?.type ?? 'UNKNOWN'} />
                    <Tag name="Is Primary" value="Yes" />
                  </div>
                </li>
              </ul>
            </Card.Content>
          </Card.Container>
          <Card.Container>
            <Card.Header>
              <Card.Title>Active Integrations</Card.Title>
            </Card.Header>
            <Card.Content>
              <ul className="flex flex-row w-full mb-4">
                {integrations.map((t, i) => {
                  const integration = CredentialsMap[t.type];

                  return (
                    <li key={i} className="pr-4 mr-4">
                      <div className="flex flex-col">
                        <div
                          className="mb-2"
                          style={{
                            height: 30,
                            width: 150,
                          }}
                        >
                          <integration.icon height={25} />
                        </div>
                        <Tag name="Name" value={t.displayName} />
                        <Tag name="Enabled" value={t.enabled ? 'Yes' : 'No'} />
                        <Tag name="Id" value={t.id} />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Card.Content>
          </Card.Container>
        </div>

        <Card.Container noBackground>
          <Card.Header>
            <Card.Title size="lg">Available Integrations</Card.Title>
          </Card.Header>
          <Card.Content>
            <ul className="w-full grid grid-cols-2 md:grid-cols-3 mb-4">
              {Object.entries(CredentialsMap)
                .filter(([key]) => !WAREHOUSE_TYPES.includes(key as any))
                .map(([key, x]) => {
                  return (
                    <li key={key} className="mr-4">
                      <Link to={key}>
                        <Card.Container className="bg-white hover:bg-gray-100 rounded-lg px-4 py-4">
                          <div
                            style={{
                              height: 30,
                              width: 150,
                            }}
                          >
                            <x.icon height={25} />
                          </div>
                        </Card.Container>
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <Text.Caption>
              For early stage integrations,{' '}
              <a href="mailto:support@cotera.co" className="text-primary">
                please contact support.
              </a>
            </Text.Caption>
          </Card.Content>
        </Card.Container>

        <Card.Container noBackground>
          <Card.Header>
            <Card.Title size="lg">Data Sources</Card.Title>
            <span className="block text-sm text-gray-800">
              Data sources are the raw data that you want to analyze. You can
              connect to a variety of data sources including databases, cloud
              storage, and APIs.
            </span>
          </Card.Header>
          <Card.Content>
            <Link
              to="sources"
              className="bg-gray-700 text-gray-50 hover:bg-gray-800 px-4 py-3 rounded text-sm font-medium"
            >
              Manage Data Sources
            </Link>
          </Card.Content>
        </Card.Container>
      </div>
    </Layout>
  );
};
