import { err } from 'neverthrow';
import { Assert } from '../../../utils';
import { combineMeta } from '../../lex';
import { Call } from '../ast';
import { compileAst } from '../compile';
import { TY_SPECIAL_FORMS } from './ty-special-form';
import { SpecialForm } from './types';
import { EXPR_SPECIAL_FORMS } from './expr-special-forms';
import { sym } from '../../lex/tokens';

const pipe: SpecialForm = ([lhs, call], ctx) => {
  Assert.assert(
    lhs !== undefined && call !== undefined,
    'Artiy already checked'
  );

  const [_lhsAst, lhsMeta] = lhs;
  const [callAst, callMeta] = call;

  switch (callAst.t) {
    case 'call': {
      const newCall: Call = {
        ...callAst,
        args: [lhs, ...callAst.args],
        display: 'pipe',
      };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    case 'symbol': {
      const newCall: Call = {
        t: 'call',
        op: [callAst, callMeta],
        args: [lhs],
        display: 'pipe',
      };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    default:
      return err([{ t: 'cant-pipe-into' }, callMeta]);
  }
};

const callInfix =
  (op: string): SpecialForm =>
  ([lhs, rhs], ctx) => {
    Assert.assert(
      lhs !== undefined && rhs !== undefined,
      'Artiy already checked'
    );

    const newMeta = combineMeta(lhs[1], rhs[1]);

    const newCall: Call = {
      t: 'call',
      op: [sym(op), newMeta],
      args: [lhs, rhs],
      display: 'infix',
    };
    return compileAst([newCall, newMeta], ctx);
  };

const semicolon: SpecialForm = ([lhs, rhs], ctx) => {
  Assert.assert(lhs !== undefined && rhs !== undefined);
  const left = compileAst(lhs, ctx);

  if (left.isErr()) {
    return err(left.error);
  }

  const { bindings } = left.value;
  return compileAst(rhs, {
    ...ctx,
    bindings: { ...ctx.bindings, ...bindings },
  });
};

export const SPECIAL_FORMS: Record<
  string,
  [artiy: number | number[] | 'any', fn: SpecialForm]
> = {
  ';': [2, semicolon],
  '+': [2, callInfix('add')],
  '-': [2, callInfix('sub')],
  '*': [2, callInfix('mul')],
  '/': [2, callInfix('div')],
  '^': [2, callInfix('to_the_power_of')],
  '>': [2, callInfix('gt')],
  '>=': [2, callInfix('gte')],
  '<': [2, callInfix('lt')],
  '<=': [2, callInfix('lte')],
  '==': [2, callInfix('eq')],
  '!=': [2, callInfix('neq')],
  '??': [2, callInfix('coalesce')],
  '||': [2, callInfix('or')],
  '&&': [2, callInfix('and')],
  '|>': [2, pipe],
  ...EXPR_SPECIAL_FORMS,
  ...TY_SPECIAL_FORMS,
};
