import { Relation } from '@cotera/era';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { DuckDBQueryResult } from '../duckdb';
import { CancelablePromise } from '@cotera/client/app/etc';
import { useAppData, useWhoami } from '@cotera/client/app/stores/org';
import { DataProvider } from './fn.type';
import { useGetOrCreateArtifact } from './artifacts';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Assert } from '@cotera/utilities';
import { useEraScopesAwareRelIR } from '../../pages/apps/compiler/macro-expansion/scopes';
import { useAbortController } from './hook';
import { NoopRateLimiter, RateLimiter } from '../semaphore';

export type QueryData = {
  data: DuckDBQueryResult;
  artifactRel: Relation;
  fromArtifactId: string | null;
};

export const useLoadArtifact: DataProvider<
  {
    rel: Relation;
  },
  QueryData
> = ({ ...props }, opts) => {
  const abort = useAbortController({
    abortOnUnmount: true,
  });
  const ir = useEraScopesAwareRelIR(props.rel);
  const { data: artifact } = useGetOrCreateArtifact(
    { rel: Relation.wrap(ir) },
    {
      abort: opts?.abort ?? abort,
    }
  );

  const sqlHash = Relation.wrap(ir).sqlHash();
  return useSuspenseQuery({
    queryKey: [
      queryKeys.artifacts.artifact({
        orgId: useWhoami((x) => x.org.id),
        sqlHash,
      }),
    ],
    queryFn: async () => {
      if (artifact.isErr()) {
        throw new Error(artifact.error.message);
      }

      const artifactRel = Relation.fromAst(artifact.value.ir);

      return {
        artifactRel,
        fromArtifactId: artifact.value.meta?.artifactId ?? null,
      };
    },
  });
};

export const useDuckDBQuery: DataProvider<
  {
    rel: Relation;
    onArtifact?: (props: {
      id: string | null;
      sourceHash: string;
      rel: Relation;
    }) => void;
    rateLimiter?: RateLimiter;
  },
  QueryData
> = ({ rateLimiter = new NoopRateLimiter(), ...props }, opts) => {
  const { data: artifact } = useLoadArtifact({ rel: props.rel }, opts);
  const initedDb = useAppData((s) => s.initedDb);
  const abort = useAbortController({
    abortOnUnmount: true,
  });

  return useSuspenseQuery({
    queryKey: queryKeys.artifacts.data({
      orgId: useWhoami((x) => x.org.id),
      sqlHash: artifact.artifactRel.sqlHash(),
    }),
    queryFn: async () => {
      try {
        await rateLimiter.acquire();

        const db = await initedDb;

        const data = await new CancelablePromise(
          db.query(artifact.artifactRel),
          {
            controller: opts?.abort ?? abort,
          }
        );

        const result = new DuckDBQueryResult(data, artifact.artifactRel);

        return {
          ...artifact,
          data: result,
        };
      } finally {
        await rateLimiter.release();
      }
    },
  });
};

export const useArtifactQuery: DataProvider<
  {
    baseRel: Relation;
    rel: (rel: Relation) => Relation;
    limit?: number;
    rateLimiter?: RateLimiter;
  },
  QueryData
> = (props, opts) => {
  const { data: artifact } = useGetOrCreateArtifact({
    rel: props.baseRel.limit(props.limit ?? 50_000),
  });
  const artifactRel = Relation.wrap(Assert.assertOk(artifact).ir);
  return useDuckDBQuery(
    { rel: props.rel(artifactRel), rateLimiter: props.rateLimiter },
    opts
  );
};
