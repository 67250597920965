import { LogoComponent } from '../logo/logo-svg';
import { Text } from '@cotera/client/app/components/ui';

type Props = {
  status?: number;
  message?: string;
};

export const ErrorView: React.FC<Props> = ({
  status = 500,
  message = 'Sorry, we encountered an unexpected error.',
}) => {
  return (
    <div className="h-full flex-1 flex flex-col justify-center items-center mr-8">
      <div className="h-[20px] w-[20px] mb-16">
        <LogoComponent />
      </div>
      <Text.Caption className="text-primary-text font-bold text-2xl mb-3">
        {status}
      </Text.Caption>
      <Text.Title type="title" className="mb-3">
        Something went wrong
      </Text.Title>
      <Text.Caption className="mb-6 text-center">
        {message}
        <br />
        <a href="mailto:support@cotera.co">Please contact support.</a>
      </Text.Caption>
    </div>
  );
};
