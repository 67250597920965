import { useState, Suspense } from 'react';
import { TopicFeature, useDrafts } from './context';
import {
  Button,
  Divider,
  Modal,
  Searchable,
  Title,
  Text,
  Loading,
  Badge,
  Editable,
} from '@cotera/client/app/components/ui';
import { Inputs } from '@cotera/client/app/components/forms';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { Card } from '@cotera/client/app/components/headless';
import { ErrorBoundary } from 'react-error-boundary';
import { DisplayError } from '@cotera/client/app/components/app';
import { BarChart } from '@cotera/client/app/components/data-vis';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './types';
import { classNames } from '@cotera/client/app/components/utils';
import { SuggestedTopicsContainer } from './suggestions';
import { uniqBy } from 'lodash';

const AddTopicModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const addTopic = useDrafts((s) => s.actions.addTopic);

  return (
    <Modal
      priority="medium"
      open={open}
      onClose={(v) => {
        if (!v) {
          onClose();
        }
      }}
      contentClassName="w-1/3"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addTopic(name, description);
          onClose();
        }}
      >
        <Title title="Add Topic" type="section" className="mb-3" />
        <Divider className="mb-4" />
        <Inputs.Text
          className="mb-2"
          label="Name"
          value={name}
          onChange={setName}
        />
        <Inputs.Text
          className="mb-4"
          label="Description"
          value={description}
          onChange={setDescription}
        />
        <div className="flex justify-end">
          <Button theme="primary" text="Add" type="submit" icon="save" />
        </div>
      </form>
    </Modal>
  );
};

const useFilteredTopics = (
  focusedFeature: string | null,
  searchValue: string
) => {
  const topics = useDrafts((s) => s.topics).filter(
    (x) => x.action !== 'delete'
  );
  const topicsForFeature = useFuzzySearch(topics, ['name', 'description'], {
    threshold: 1,
  });
  const search = useFuzzySearch(topicsForFeature(focusedFeature ?? ''), [
    'name',
    'description',
  ]);

  return search(searchValue);
};

export const Topics: React.FC<{
  topicModelId: string;
  versionId: string;
}> = ({ topicModelId, versionId }) => {
  const focusedFeature = useDrafts((s) => s.focusedFeature);
  const [searchValue, setSearchValue] = useState('');
  const [addTopic, setAddTopic] = useState(false);

  const topics = useFilteredTopics(
    focusedFeature?.content ?? null,
    searchValue
  );
  const features = useDrafts((s) => s.features);

  const sortedTopics = topics
    .map((topic) => ({
      category: topic.name,
      value: topic.features.reduce((acc, x) => acc + x.documentCount, 0),
      style: 'random' as const,
    }))
    .sort((a, b) => b.value - a.value);

  const other = sortedTopics.slice(5);
  const unassined = {
    category: 'Unassigned',
    value: features
      .filter((x) => x.topics.length === 0)
      .reduce((acc, x) => acc + x.documentCount, 0),
    style: 'warning' as const,
  };
  const chartData = [
    ...sortedTopics.slice(0, 5),
    {
      category: 'Other',
      value: other.reduce((acc, x) => acc + x.value, 0),
      style: 'random' as const,
    },
    unassined,
  ];
  const [showSuggestions, setShowSuggestions] = useState(false);

  return (
    <div className="flex flex-col h-full">
      <Card.Container className="h-[60%]">
        <Card.Content className="h-full">
          <div className="flex items-center justify-between mb-4">
            <Searchable
              className="w-full"
              value={searchValue}
              onChange={setSearchValue}
            >
              <Title title="Topics" type="section" />
            </Searchable>
            <div className="flex items-center">
              <Text.Caption className="mr-2 text-nowrap">
                {topics.length} Results
              </Text.Caption>
              <Button
                icon="plus"
                text="Add Topic"
                iconOnly
                tooltip="left"
                onClick={() => {
                  setAddTopic(true);
                }}
                className="mr-2"
              />
              <Button
                icon="lightbulb"
                text="Suggest Topics"
                iconOnly
                tooltip="left"
                onClick={() => {
                  setShowSuggestions(true);
                }}
              />
            </div>
          </div>
          <Suspense fallback={<Loading.Dots />}>
            <ErrorBoundary
              fallbackRender={({ error }) => <DisplayError error={error} />}
            >
              <ul
                className="overflow-scroll h-[calc(100%-35px)]"
                key={focusedFeature?.featureId}
              >
                {topics.map((topic) => {
                  return <TopicItem key={topic.id} topic={topic} />;
                })}
              </ul>
            </ErrorBoundary>
          </Suspense>
        </Card.Content>
      </Card.Container>
      <Card.Container className="h-[38%] overflow-scroll">
        <Card.Content className="h-full mb-4">
          <Title type="section">Occurences per Topic</Title>
          <BarChart
            axis={{
              x: {},
              y: {},
            }}
            direction="horizontal"
            data={chartData.map((x) => ({
              ...x,
              y: x.value,
              x: x.category,
            }))}
            loading={false}
            onLegendClick={() => {}}
          />
        </Card.Content>
      </Card.Container>
      <AddTopicModal open={addTopic} onClose={() => setAddTopic(false)} />
      <SuggestedTopicsContainer
        show={showSuggestions}
        onClose={() => setShowSuggestions(false)}
        topicModelId={topicModelId}
        versionId={versionId}
      />
    </div>
  );
};

const TopicItem: React.FC<{
  topic: {
    id: string;
    name: string;
    description: string | null;
    features: TopicFeature[];
  };
}> = ({ topic }) => {
  const assignFeature = useDrafts((s) => s.actions.assign);
  const update = useDrafts((s) => s.actions.updateTopic);
  const deleteTopic = useDrafts((s) => s.actions.deleteTopic);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.ITEM,
      drop: (item: { featureId: string; topicId: string | null }) => {
        assignFeature(topic.id, item.topicId, item.featureId);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <li
      ref={drop}
      className={classNames(
        'border-dashed border rounded py-3 px-3 mb-2 flex justify-between transition-all duration-150 overflow-x-scroll',
        isOver ? 'h-[180px] border-indigo-400' : 'border-indigo-200'
      )}
    >
      <div className="flex flex-col w-full">
        <div className="w-full flex justify-between">
          <Editable
            value={topic.name}
            onChange={(v) => {
              update(topic.id, v, topic.description);
            }}
          >
            {(value) => <Title type="label">{value}</Title>}
          </Editable>
          <div className="flex items-center">
            <Button
              icon="edit"
              theme="regular"
              small
              className="mr-2"
              text="Edit"
              link={{
                to: `${topic.name}`,
              }}
            />
            <Button
              icon="trash"
              inline
              theme="error"
              iconOnly
              tooltip="left"
              text="Delete Topic"
              onClick={() => {
                deleteTopic(topic.id);
              }}
            />
          </div>
        </div>
        {topic.description && <Text.Caption>{topic.description}</Text.Caption>}
        <div className="mt-2 flex items-center flex-wrap">
          {uniqBy(topic.features, 'featureId').map((feature) => {
            return (
              <TopicFeatureItem
                key={feature.featureId}
                feature={feature}
                topicId={topic.id}
              />
            );
          })}
        </div>
        {isOver && (
          <div className="w-full flex-grow flex items-center justify-center">
            <Text.Caption>Drop here to assign</Text.Caption>
          </div>
        )}
      </div>
    </li>
  );
};

const TopicFeatureItem: React.FC<{
  feature: TopicFeature;
  topicId: string;
}> = ({ feature, topicId }) => {
  const removeFromTopic = useDrafts((s) => s.actions.remove);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ITEM,
    item: {
      featureId: feature.featureId,
      topicId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={classNames(
        isDragging ? 'opacity-50' : '',
        'flex items-center mr-2 mb-2'
      )}
    >
      <Badge theme="regular" className="text-nowrap">
        <Button
          noPadding
          ref={drag}
          inline
          icon="drag-handle"
          className="mr-2"
        />
        {feature.content}
        <Button
          inline
          icon="x-mark"
          onClick={() => removeFromTopic(topicId, feature.featureId)}
        />
      </Badge>
    </div>
  );
};
