import { Card, Tabs } from '@cotera/client/app/components/headless';
import { Layout } from '@cotera/client/app/layout';
import { Chart } from './chart';
import { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useStore } from './store';
import { lookupSimilarItems } from './queries';
import {
  toast,
  Text,
  ToolPanel,
  Button,
} from '@cotera/client/app/components/ui';
import { downloadFile, jsonToCsv } from '@cotera/client/app/components/utils';
import { matchesForExport } from './queries';
import { useAppData } from '@cotera/client/app/stores/org';
import { mapArrowToEraTypes } from '@cotera/client/app/etc/duckdb/map-arrow-to-era-types';
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Item as ItemT, readSliceFromWorker } from './queries';
import { EmptyState } from './empty-state';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid';
import { classNames } from '@cotera/client/app/components/utils';
import { openai } from '@cotera/client/app/etc/openapi';
import { Inputs } from '@cotera/client/app/components/forms';

const getEmbeddingFromOpenai = async (input: string) => {
  return await openai.embeddings.create({
    model: 'text-embedding-3-small',
    dimensions: 512,
    input: input,
  });
};

export const Embeddings: React.FC = () => {
  const loading = useStore((s) => s.loading);
  return (
    <Layout loading={loading}>
      <SearchAndResults />
    </Layout>
  );
};

const SearchAndResults: React.FC = () => {
  const initedDb = useAppData((s) => s.initedDb);
  const data = useStore((s) => s.data);
  const cutoff = useStore((s) => s.cutoff);
  const cutoffPos = useStore((s) => s.cutoffPos);
  const searchTerms = useStore((s) => s.searchTerms);
  const rel = useStore((s) => s.rel);

  if (data === null || rel == null) {
    return <EmptyState />;
  }

  return (
    <ToolPanel.Container
      panel={
        <div className="px-4 py-4">
          <Filter />
          <div className="pt-4">
            <Text.Title type="section">Matches</Text.Title>
            <div className="mt-4 flex flex-col space-y-4">
              <Stat
                label="Similarity Cutoff"
                value={Math.round(cutoff * 100)}
              />
              <Stat label="Number of Matches" value={cutoffPos + 1} />
              <Stat label="Total Records" value={data.numRows} />
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                onClick={async () => {
                  const table = await matchesForExport(
                    initedDb,
                    cutoff,
                    searchTerms,
                    rel!
                  );
                  const rows = table
                    .toArray()
                    .map((row) => mapArrowToEraTypes(row, table.schema.fields));
                  downloadFile(
                    [await jsonToCsv(rows)],
                    'csv',
                    'semantic_search_matches'
                  );
                }}
                text="Export"
                theme="primary"
              />
            </div>
          </div>
        </div>
      }
    >
      <Card.Container>
        <Tabs.Container mode="controlled" default="results">
          <div className="border-b border-divider w-full">
            <Tabs.Panel border={false}>
              <Tabs.Tab id="results" text="Results" />
              <Tabs.Tab id="chart" text="Chart" />
            </Tabs.Panel>
          </div>
          <Tabs.Content id="results">
            <Card.Content>
              <Results />
            </Card.Content>
          </Tabs.Content>
          <Tabs.Content id="chart">
            <Chart />
          </Tabs.Content>
        </Tabs.Container>
      </Card.Container>
    </ToolPanel.Container>
  );
};

const Stat: React.FC<{ label: string; value: number }> = ({ label, value }) => {
  return (
    <div className="flex justify-between">
      <span className="text-sm text-gray-500">{label}</span>
      <span className="text-md font-medium">{value}</span>
    </div>
  );
};

const PAGE_SIZE = 50;

export const Results: React.FC = () => {
  const data = useStore((s) => s.data);
  const [page, setPage] = useState(0);

  if (data === null) {
    return <EmptyState />;
  }

  const nPages = Math.ceil(data.numRows / PAGE_SIZE) - 1;
  const pageStart = page * PAGE_SIZE;
  const pageEnd = pageStart + PAGE_SIZE;
  const slice = readSliceFromWorker(data, pageStart, pageEnd);

  return (
    <div>
      <ul className="w-full divide-y divide-gray-100 bg-white rounded">
        {slice.map((item, i) => {
          return <Item pos={pageStart + i} key={item.id} item={item} />;
        })}
      </ul>
      <Pagination
        nPages={nPages}
        page={page}
        setPage={(page: number) => {
          document.getElementById('scene')?.scrollTo({ top: 0 });
          setPage(page);
        }}
      />
    </div>
  );
};

export const Item: React.FC<{ pos: number; item: ItemT }> = ({ pos, item }) => {
  const ts = new Date(item.timestamp);
  return (
    <li
      key={item.id}
      className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-indigo-50 sm:px-6 lg:px-8"
    >
      <span className="p-1.5 bg-indigo-50 text-primary-text rounded border border-indigo-200 text-xs font-medium text-center align-middle m-auto">
        {Math.round((item.cosine_similarity ?? 0) * 100)}
      </span>
      <div className="flex-auto">
        <div className="flex items-baseline justify-between gap-x-4">
          <time
            className="text-gray-800 font-medium"
            dateTime={ts.toLocaleString()}
          >
            {ts.toLocaleString()}
          </time>
          <div />
          <p className="text-sm leading-6 text-gray-500 italic">{item.id}</p>
        </div>
        <p className="mt-1 line-clamp-2 text-sm leading-6 text-standard-text overflow-ellipsis">
          {item.content}
        </p>
      </div>
      <div className="my-auto">
        <Actions
          content={item.content}
          pos={pos}
          score={item.cosine_similarity ?? 0}
        />
      </div>
    </li>
  );
};

export const Pagination: React.FC<{
  nPages: number;
  page: number;
  setPage: (page: number) => void;
}> = ({ nPages, page, setPage }) => {
  return (
    <nav className="flex items-center justify-around border-t border-divider px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(0)}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
          Start
        </button>
      </div>
      <div className="-mt-px flex w-0 flex-1">
        <button
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(page - 1)}
          disabled={page === 0}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      <span className="pt-4 text-sm font-medium text-gray-500 ">
        Page {page + 1}
      </span>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(page + 1)}
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(nPages)}
        >
          End
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
};

export const Actions: React.FC<{
  content: string;
  pos: number;
  score: number;
}> = ({ content, pos, score }) => {
  const setCutoff = useStore((s) => s.setCutoff);
  const addSearchTerm = useStore((s) => s.addSearchTerm);

  return (
    <Menu as="div" className="relative flex-none">
      <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-standard-text">
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  active ? 'bg-standard-background' : '',
                  'block px-3 py-1 text-sm leading-6 text-standard-text w-full'
                )}
                onClick={() =>
                  setCutoff({
                    cutoff: score,
                    cutoffPos: pos,
                  })
                }
              >
                Confirm Match
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  active ? 'bg-standard-background' : '',
                  'block px-3 py-1 text-sm leading-6 text-standard-text w-full'
                )}
                onClick={async () => {
                  const openaiResult = await getEmbeddingFromOpenai(content);

                  const embedding = openaiResult.data[0]!.embedding!;

                  addSearchTerm({
                    term: content,
                    embedding,
                  });
                }}
              >
                Add to Search
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export const Filter: React.FC = () => {
  const initedDb = useAppData((s) => s.initedDb);
  const setLoading = useStore((s) => s.setLoading);
  const setData = useStore((s) => s.setData);
  const [search, setSearch] = useState<string>('');
  const searchTerms = useStore((s) => s.searchTerms);
  const addSearchTerm = useStore((s) => s.addSearchTerm);
  const removeSearchTerm = useStore((s) => s.removeSearchTerm);
  const rel = useStore((s) => s.rel);

  useEffect(() => {
    const load = async () => {
      if (searchTerms.length === 0) return;
      setLoading(true);

      try {
        const res = await lookupSimilarItems(initedDb, searchTerms, rel!);
        setData(res);
      } catch (e) {
        toast.error('Failed to query embeddings');
      } finally {
        setLoading(false);
      }
    };

    void load();
  }, [searchTerms, initedDb, setLoading, setData, rel]);

  return (
    <div>
      <div className="flex flex-col mb-4 ">
        <Text.Title type="section" className="mb-2">
          Items like
        </Text.Title>
        <Inputs.Text
          id="search"
          placeholder="Search"
          value={search}
          onChange={setSearch}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              const openaiResult = await getEmbeddingFromOpenai(search);

              const embedding = openaiResult.data[0]!.embedding!;

              addSearchTerm({
                term: search,
                embedding,
              });
              setSearch('');
            }
          }}
        />
      </div>
      <div className="text-sm mb-4">
        <ul className="flex flex-col space-y-2">
          {searchTerms.map((search, i) => (
            <li
              key={i}
              className="flex flex-row bg-white justify-between py-1.5 px-2 rounded border border-divider"
            >
              <Text.P className="italic text-standard-text text-sm">
                {search.term}
              </Text.P>
              <div className="w-8 h-full my-auto flex justify-end">
                <TrashIcon
                  className="h-4 w-4 text-red-300 cursor-pointer hover:text-red-500 my-auto"
                  onClick={() => {
                    removeSearchTerm(search.term);
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
