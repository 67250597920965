import { Parser } from '@cotera/era';
import { Contract, GET, POST } from '@cotera/contracts';
import { z } from 'zod';

export const EntityColumnAssumptionTableRefSchema = z.object({
  tableSchema: z.string(),
  tableName: z.string(),
  tableColName: z.string(),
  primaryKeyColName: z.string(),
  warehouseTypeName: z.string(),
});

const AssumptionMetaSchema = z.object({
  t: z.literal('assumption'),
  ref: EntityColumnAssumptionTableRefSchema,
  partial: z.boolean(),
});

export const ExpressionColumnSchema = z.object({
  t: z.literal('eraql'),
  eraql: z.string(),
  k: z
    .discriminatedUnion('t', [
      z.object({
        t: z.literal('topic'),
        versionId: z.string(),
      }),
      z.object({
        t: z.literal('decision-tree'),
        variants: z.string().array(),
      }),
    ])
    .optional(),
});

export type ExpressionColumn = z.infer<typeof ExpressionColumnSchema>;

export const DecisionTreeSchema = z.object({
  t: z.literal('decision-tree'),
  eraql: z.string(),
  k: z.object({
    variants: z.string().array(),
  }),
});

export const UddColumnSchema = z.object({
  t: z.literal('udd'),
  k: z
    .discriminatedUnion('t', [
      z.object({
        t: z.literal('topics'),
        id: z.string(),
        inputEraql: z.string(),
        promptId: z.string(),
      }),
    ])
    .optional(),
});

export const EntityColumnMetaSchema = z.discriminatedUnion('t', [
  UddColumnSchema,
  ExpressionColumnSchema,
  AssumptionMetaSchema,
]);

export const EntityColumnInfoSchema = z.object({
  type: Parser.ExtendedAttributeTypeSchema,
  dependsOn: z.string().array(),
  meta: EntityColumnMetaSchema,
  visibility: z.enum(['visible', 'hidden']),
});

export type EntityColumnAssumptionTableRef = z.infer<
  typeof EntityColumnAssumptionTableRefSchema
>;
export type EntityColumnAssumptionMeta = z.infer<typeof AssumptionMetaSchema>;
export type EntityColumnMeta = z.infer<typeof EntityColumnMetaSchema>;
export type EntityColumnInfo = z.infer<typeof EntityColumnInfoSchema>;

export const EntityColumnCreateErrorSchema = z.object({
  t: z.enum([
    'eraql-error',
    'type-check-error',
    'invalid-column-name',
    'mismatched-type',
    'circular-dependency',
    'entity-not-found',
    'invalid-dep',
  ]),
});

export type EntityColumnUpsertError = z.infer<
  typeof EntityColumnCreateErrorSchema
>;

const entity = z.object({
  id: z.string(),
  name: z.string(),
  columns: z.record(EntityColumnInfoSchema),
});

export type Entity = z.infer<typeof entity>;

export const EntityColumnsContract = Contract.new({
  entities: GET({
    params: z.object({}),
    output: z.object({
      entities: entity.array(),
    }),
    errors: z.never(),
  }),
  columnsForEntity: GET({
    params: z.object({ entityId: z.string() }),
    output: z.record(EntityColumnInfoSchema),
    errors: z.never(),
  }),
  upsertEntity: POST({
    params: z.object({
      entityId: z.string().optional(),
      name: z.string(),
      assumptions: z
        .object({
          columnName: z.string().optional(),
          partial: z.boolean(),
          ref: EntityColumnAssumptionTableRefSchema,
          ty: Parser.ExtendedAttributeTypeSchema,
        })
        .array(),
    }),
    errors: z.discriminatedUnion('t', [
      z.object({
        t: z.literal('assumption'),
        err: EntityColumnCreateErrorSchema,
      }),
      z.object({
        t: z.literal('entity'),
        err: z.object({ msg: z.string() }),
      }),
    ]),
    output: z.object({
      id: z.string(),
    }),
    mustBeAbleTo: [['manage', 'entities']],
  }),
  upsertEraqlColumn: POST({
    params: z.object({
      entityId: z.string(),
      columnName: z.string(),
      data: ExpressionColumnSchema,
    }),
    errors: z.discriminatedUnion('t', [EntityColumnCreateErrorSchema]),
    output: z.object({}),
    mustBeAbleTo: [['manage', 'entities']],
  }),
  deleteEntityColumn: POST({
    params: z.object({
      entityId: z.string().uuid(),
      columnName: z.string(),
    }),
    errors: z.object({
      errorType: z.literal('has-dependents'),
      dependents: z.string().array(),
    }),
    output: z.object({}),
    mustBeAbleTo: [['manage', 'entities']],
  }),
  createAssumption: POST({
    params: z.object({
      entityId: z.string(),
      columnName: z.string().optional(),
      partial: z.boolean(),
      ref: EntityColumnAssumptionTableRefSchema,
      ty: Parser.ExtendedAttributeTypeSchema,
    }),
    errors: EntityColumnCreateErrorSchema,
    output: z.object({}),
    mustBeAbleTo: [['manage', 'entities']],
  }),
});
