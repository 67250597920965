import React from 'react';
import { HomeIcon } from '@heroicons/react/24/solid';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger,
} from '@cotera/client/app/components/headless';
import { startCase, times } from 'lodash';
import { z } from 'zod';
import { classNames } from '@cotera/client/app/components/utils';

export type Crumb = {
  name: string;
  to: string;
  capitalize: boolean;
  alternatives?: Crumb[];
  icon?: (props: { className: string }) => JSX.Element | React.ReactNode;
};

const isUuid = (str: string) => z.string().uuid().safeParse(str).success;

export type BreadcrumbsProps = {
  breadcrumbs?: (pages: Crumb[]) => Crumb[];
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs = (pages) => pages,
}) => {
  const location = useLocation();

  const parts = location.pathname.split('/').filter((part) => part !== '');

  const pages = times(parts.length).map((i) => {
    const part = parts[i]!;
    const path = parts.slice(0, i + 1).join('/');

    return {
      capitalize: true,
      name: part,
      to: `/${path}`,
    };
  });

  return (
    <nav className="flex flex-1 mr-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2">
        <li>
          <div>
            <Link to={'/'} className="text-muted-text hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs(pages).map(
          ({ name, to, capitalize, icon: Icon, alternatives = [] }, i) => (
            <li key={name}>
              <div className="flex items-center">
                <span className="text-sm text-standard-text">/</span>
                <Link
                  to={to}
                  className={classNames(
                    'ml-2 text-sm text-standard-text hover:text-standard-text flex items-center word-break-keep whitespace-nowrap',
                    capitalize ? 'capitalize' : '',
                    i === pages.length - 1 ? 'font-medium' : ''
                  )}
                >
                  {Icon && <Icon className="h-4 w-4 text-standard-text mr-2" />}{' '}
                  {isUuid(name) ? name : startCase(decodeURIComponent(name))}
                  {alternatives.length > 0 && (
                    <Dropdown>
                      <DropdownTrigger>
                        <ChevronDownIcon className="h-4 w-3 text-standard-text ml-1" />
                      </DropdownTrigger>
                      <DropdownContent align="center" side="bottom">
                        {alternatives.map(
                          ({ name, to, capitalize, icon: Icon }) => (
                            <Link
                              to={to}
                              key={name}
                              className={classNames(
                                'block px-4 py-2 text-standard-text hover:bg-slate-100 flex items-center text-sm',
                                capitalize ? 'capitalize' : ''
                              )}
                            >
                              {Icon && (
                                <Icon className="h-4 w-4 text-standard-text mr-2" />
                              )}
                              {startCase(name)}
                            </Link>
                          )
                        )}
                      </DropdownContent>
                    </Dropdown>
                  )}
                </Link>
              </div>
            </li>
          )
        )}
      </ol>
    </nav>
  );
};
