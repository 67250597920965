import React, { useEffect } from 'react';
import { classNames } from '@cotera/client/app/components/utils';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Text,
  Divider,
  Label,
  Logo,
  Animated,
} from '@cotera/client/app/components';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { create } from 'zustand';
import { openai } from '../../etc/openapi';
import { LLMClient, useLLMClient } from '../../etc/llm.client';
import { getRandomItems } from './utils';
import { AppsPreview } from './apps-preview';
import { config } from '@cotera/client/config/config';
import axios from 'axios';
import { z } from 'zod';
import { Button } from '@cotera/client/app/components/ui';
import { Inputs, ComboBox } from '@cotera/client/app/components/forms';
import { CopilotIcon } from '@cotera/client/app/components/app/icons/copilot';

type Step = {
  key: string;
  title: string;
  caption: string;
  description: string;
};

const STEPS: Step[] = [
  {
    key: 'company',
    title: 'Company',
    caption: 'What is your company type?',
    description: "We'll use this to help you get the most out of Cotera.",
  },
  {
    key: 'goals',
    title: 'Goals',
    caption: 'What are your goals?',
    description:
      'By letting us know your goals, we can help you get the most out of Cotera.',
  },
  {
    key: 'email',
    title: 'Email',
    caption: 'What is your email?',
    description: 'We will use this to send you updates and notifications.',
  },
];

type State = {
  company?: {
    name?: string;
    sector?: string;
    size?: string;
  };
  email?: string;
  goals: string[];
  setCompany: (company: Partial<State['company']>) => void;
  setEmail: (email: State['email']) => void;
  setGoals: (goals: State['goals']) => void;
  removeGoal: (goal: string) => void;
};

//zustand state
const useStore = create<State>((set, get) => ({
  company: undefined,
  email: undefined,
  goals: [],
  setCompany: (company) => set({ company: { ...get().company, ...company } }),
  setEmail: (email) => set({ email }),
  setGoals: (goals) => {
    set({ goals: [...new Set(goals)] });
  },
  removeGoal: (goal) => {
    set({ goals: get().goals.filter((g) => g !== goal) });
  },
}));

export const SignupPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const steps = (
    searchParams.get('steps')?.split(',') ?? ['company', 'goals', 'email']
  ).map((key) => STEPS.find((step) => step.key === key)) as Step[];
  const activeStep = Number(searchParams.get('current')) ?? 1;
  const setActiveStep = (step: number) => {
    setSearchParams({
      steps: steps.map((step) => step!.key).join(','),
      current: String(step),
    });
  };

  return (
    <main className={classNames('h-screen md:flex sm:flex-col')}>
      <div className="flex flex-col md:w-2/3 h-full py-6 px-10 sm:w-full">
        <div className="flex justify-between items-center text-sm font-medium">
          <Logo />
          <div className="flex">
            <p className="text-standard-text disabled px-4 border-divider border-r ">
              Signup
            </p>
            <Link
              to="/login"
              className="text-standard-text px-4 hover:text-primary-text"
            >
              Login
            </Link>
          </div>
        </div>
        <div className="flex flex-col mt-[20%] items-center h-full">
          <div className="flex flex-col w-[70%]">
            {activeStep > 0 ? (
              <div
                className="flex flex-row items-center text-xs mb-4 cursor-pointer hover:text-primary-text"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                <ArrowLeftIcon className="w-4 h-4 mr-2" />
                <p>Back</p>
              </div>
            ) : (
              <div className="h-8"></div>
            )}
            <Text.Title type="title" className="mb-2">
              Setup your account
            </Text.Title>
            <Text.Caption className="mb-8">
              {steps[activeStep]!.caption}
            </Text.Caption>
            <div
              className={classNames(
                'flex flex-row',
                steps.length > 2
                  ? 'justify-between items-center w-full mb-10'
                  : ''
              )}
            >
              {steps
                .filter((step) => step?.key !== 'waitlist')
                .map((step, i) => {
                  return (
                    <div
                      className="flex flex-col items-center text-xs text-alt-text"
                      key={step?.key}
                    >
                      <div className="flex items-center">
                        <div
                          className={classNames(
                            'cursor-pointer rounded-full shrink-0 mb-2 h-6 w-6 flex items-center justify-center',
                            activeStep === i
                              ? 'bg-primary-background'
                              : 'bg-gray-200 hover:bg-gray-400'
                          )}
                          onClick={() => setActiveStep(i)}
                        >
                          {i + 1}
                        </div>
                      </div>
                      <p
                        className={classNames(
                          'text-xs',
                          activeStep === i
                            ? 'text-standard-text'
                            : 'text-standard-text'
                        )}
                      >
                        {step!.title}
                      </p>
                    </div>
                  );
                })
                .map((step, i) => {
                  const divider =
                    i === steps.length - 1 ? null : (
                      <Divider
                        key={i}
                        className="w-full mb-6 mx-4"
                        theme="regular"
                      />
                    );
                  return [step, divider];
                })
                .flat()}
            </div>
            {steps[activeStep]!.key === 'company' && (
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={600}
                animationOutDuration={600}
                isVisible={true}
              >
                <Company />
              </Animated>
            )}
            {steps[activeStep]!.key === 'goals' && (
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={600}
                animationOutDuration={600}
                isVisible={true}
              >
                <Goals />
              </Animated>
            )}
            {steps[activeStep]!.key === 'email' && (
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={600}
                animationOutDuration={600}
                isVisible={true}
              >
                <Email />
              </Animated>
            )}
            <div className="flex items-center w-full">
              <CTA
                activeStep={activeStep}
                onClick={() => setActiveStep(activeStep + 1)}
                steps={steps}
              />
              {activeStep !== steps.length - 1 && (
                <Button
                  theme="regular"
                  text="Go to demo"
                  link={{
                    to: config.demoSiteUrl,
                  }}
                  className="w-fit mb-8"
                />
              )}
            </div>
            <Text.P className="mb-4 text-sm">
              {steps[activeStep]!.description}
            </Text.P>
          </div>
        </div>
      </div>
      <AppsPreview />
    </main>
  );
};

const CTA = ({
  activeStep,
  onClick,
  steps,
}: {
  activeStep: number;
  onClick: () => void;
  steps: Step[];
}) => {
  const email = useStore((state) => state.email);
  const company = useStore((state) => state.company);
  const goals = useStore((state) => state.goals);

  const isEmailValid = z
    .string()
    .email()
    .safeParse(email ?? '');

  const internalClick = async () => {
    if (activeStep < steps.length - 1) {
      onClick();
    } else if (
      activeStep === steps.length - 1 &&
      (email || company || goals.length)
    ) {
      await axios({
        url: `${config.apiUrl}/sessions/signup`,
        method: 'POST',
        data: {
          email,
          company,
          goals,
        },
      });
    }
  };

  if (activeStep === steps.length - 1) {
    if (isEmailValid.success) {
      return (
        <Button
          className="w-fit mb-8 mr-4"
          onClick={internalClick}
          text="Sign up"
          icon="pencil"
          link={{
            to: '/waitlist',
          }}
        />
      );
    } else {
      return (
        <Button
          theme="primary"
          className="w-fit mb-8 mr-4"
          onClick={internalClick}
          link={{
            to: config.demoSiteUrl,
          }}
          text="Go to demo"
        />
      );
    }
  }
  return (
    <Button
      theme="primary"
      text="Next"
      icon="chevron-right"
      className="w-fit mb-8 mr-4"
      onClick={internalClick}
    />
  );
};

const Email = () => {
  const email = useStore((state) => state.email);
  const setEmail = useStore((state) => state.setEmail);
  return (
    <div className="flex flex-col mb-4">
      <Label className="mb-1">Email</Label>
      <Inputs.Text
        className="mb-4 w-[320px]"
        autoFocus
        value={email ?? ''}
        onChange={setEmail}
      />
    </div>
  );
};

const Company = () => {
  const company = useStore((state) => state.company);
  const setCompany = useStore((state) => state.setCompany);

  return (
    <div className="flex flex-col mb-4">
      <Label className="mb-1">Company Name</Label>
      <Inputs.Text
        className="mb-4 w-[320px]"
        autoFocus
        value={company?.name ?? ''}
        onChange={(value) => setCompany({ name: value })}
      />
      <div className="flex flex-wrap mb-6">
        <div className="flex flex-col mr-4 w-[250px]">
          <Label className="mb-1">Company Type</Label>
          <ComboBox.Select
            options={[
              'Startup',
              'B2B',
              'B2C',
              'E-commerce',
              'SaaS',
              'Other',
            ].map((x) => ({ display: x, value: x }))}
            onChange={(value) =>
              setCompany({ sector: value?.value ?? undefined })
            }
          />
        </div>
        <div className="flex flex-col mr-4 w-[250px]">
          <Label className="mb-1">Size</Label>
          <ComboBox.Select
            options={[
              '1-10',
              '11-50',
              '51-100',
              '101-500,',
              '501-1000',
              '1000-5000',
              '5000+',
            ].map((x) => ({ display: x, value: x }))}
            onChange={(value) =>
              setCompany({ size: value?.value ?? undefined })
            }
          />
        </div>
      </div>
    </div>
  );
};

const MAX_NUM_SUGGESTIONS = 8;

const SUGGESTIONS = [
  {
    goal: 'Improve repeat order rate',
    companyTypes: ['E-commerce'],
  },
  {
    goal: 'Win back lapsed users',
    companyTypes: ['E-commerce', 'B2C'],
  },
  {
    goal: 'Personalize email flows',
    companyTypes: ['E-commerce', 'B2C'],
  },
  {
    goal: 'Understand customer feedback',
    companyTypes: ['E-commerce', 'B2C', 'B2B'],
  },
  {
    goal: 'Alerts on metrics',
    companyTypes: ['E-commerce', 'B2C', 'B2B'],
  },
  {
    goal: 'Improve activation rates',
    companyTypes: ['B2B'],
  },
];

const client = new LLMClient<{ goals: string[] }>(
  openai,
  'asst_h0PnLeVZdGlE7VxG87Xoqazw',
  (data) => JSON.parse(data)
);

const Goals = () => {
  const company = useStore((state) => state.company);
  const goals = useStore((state) => state.goals);
  const setGoals = useStore((state) => state.setGoals);
  const removeGoal = useStore((state) => state.removeGoal);
  const [freehandGoal, setFreehandGoal] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const [suggestions, setSuggestions] = React.useState<string[]>(
    SUGGESTIONS.map((s) => s.goal)
  );

  const { client: llmClient } = useLLMClient(client);

  useEffect(() => {
    const act = async () => {
      if (
        (!company?.name && goals.length === 0) ||
        suggestions.length >= MAX_NUM_SUGGESTIONS
      ) {
        return;
      }
      setLoading(true);
      const res = await llmClient.send(
        JSON.stringify({
          ...(company ?? {}),
          goals: goals,
        })
      );
      setLoading(false);
      if (res.isOk()) {
        const data = res.value;
        const numNewSuggestions = MAX_NUM_SUGGESTIONS - suggestions.length;

        setSuggestions([
          ...suggestions,
          ...getRandomItems(
            data.goals.filter((goal) => !suggestions.includes(goal)),
            numNewSuggestions
          ),
        ]);
      }
    };

    void act();

    return () => {
      setLoading(false);
    };
  }, [goals, company, llmClient, suggestions]);

  return (
    <div className="flex flex-col">
      <Inputs.Text
        className="mb-4"
        value={freehandGoal}
        onChange={setFreehandGoal}
        placeholder="Type a goal"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setGoals([...goals, freehandGoal!]);
            setFreehandGoal('');
          }
        }}
      />
      <div className="flex flex-wrap mb-2 mt-2 w-full">
        {goals.map((goal) => {
          return (
            <div key={goal} onClick={() => removeGoal(goal)}>
              <Text.P className="cursor-pointer bg-gray-100 rounded px-4 py-4 mr-4 mb-4 text-sm hover:bg-gray-200 border-indigo-200 border">
                {goal}
              </Text.P>
            </div>
          );
        })}
      </div>
      <Divider caption="Suggestions" className="mb-8" />
      <div className="flex">
        <div className="flex flex-wrap w-full mb-8">
          {suggestions.map((goal) => {
            return (
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={600}
                animationOutDuration={600}
                isVisible={true}
                key={goal}
              >
                <div
                  onClick={() => {
                    if (goals.includes(goal)) {
                      removeGoal(goal);
                    } else {
                      setGoals([...goals, goal]);
                      setSuggestions(suggestions.filter((g) => g !== goal));
                    }
                  }}
                >
                  <Text.P
                    className={classNames(
                      'cursor-pointer bg-gray-100 rounded px-4 py-4 mr-4 mb-4 text-sm hover:bg-gray-200'
                    )}
                  >
                    {goal}
                  </Text.P>
                </div>
              </Animated>
            );
          })}
        </div>
        <CopilotIcon
          className="w-8 h-8 self-end"
          theme="primary"
          loading={loading}
          tooltip="Suggestions by Cotera Copilot"
        />
      </div>
    </div>
  );
};
