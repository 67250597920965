import { And, Asc, Case, Median, Sum, Yesterday } from '@cotera/era';
import { Props } from './props.type';

export const dateForMetrics = Yesterday()
  .dateTrunc('month')
  .dateSub('days', 1)
  .dateTrunc('month');

export const makeKpiRelations = ({
  CurrentRateOfChange,
  RfmLifecycleSummary,
  customerSegments,
}: Props) => {
  const ActiveCustomerGroups = [
    ...customerSegments.repeat,
    ...customerSegments.top,
    ...customerSegments.newCustomers,
    ...customerSegments.atRisk,
  ];
  const ChurnedCustomerGroups = [
    ...customerSegments.lost,
    ...customerSegments.hibernating,
    ...customerSegments.lapsed,
  ];
  const ReactivationOpportunity = RfmLifecycleSummary.where((t) =>
    And(
      t
        .attr('segment')
        .oneOf([...customerSegments.lapsed, ...customerSegments.hibernating]),
      t.attr('date').eq(dateForMetrics)
    )
  )
    .summary((t) => ({
      count: Sum(t.attr('count')),
    }))
    .chart.Stat(
      (t) => ({
        value: t.attr('count').cast('float'),
        unit: 'people',
      }),
      {
        title: 'Reactivation Opportunity',
      }
    );

  const CurrentReactivationRate = CurrentRateOfChange.where((t) =>
    And(
      t
        .attr('starting_rfm_category')
        .oneOf([
          ...customerSegments.atRisk,
          ...customerSegments.lapsed,
          ...customerSegments.hibernating,
          ...customerSegments.lost,
        ]),
      t.attr('date').eq(dateForMetrics),
      t.attr('category_status').eq('Segment Improvement Rate')
    )
  )
    .summary((t) => ({
      rate: Sum(t.attr('status_count'))
        .div(Sum(t.attr('total_count')))
        .cast('float')
        .mul(100),
    }))
    .chart.Stat(
      (t) => ({
        value: t.attr('rate').cast('float'),
        unit: '%',
      }),
      { title: 'Current Reactivation Rate' }
    );

  const ExpectedReactivationRate = CurrentRateOfChange.where((t) =>
    And(
      t
        .attr('starting_rfm_category')
        .oneOf([
          ...customerSegments.atRisk,
          ...customerSegments.lapsed,
          ...customerSegments.hibernating,
          ...customerSegments.lost,
        ]),
      t.attr('date').eq(dateForMetrics),
      t.attr('category_status').eq('Segment Improvement Rate')
    )
  )
    .select((t) => ({
      ...t.star(),
      status_count: Case([
        {
          when: t
            .attr('starting_rfm_category')
            .oneOf([...customerSegments.atRisk, ...customerSegments.lapsed]),
          then: t.attr('status_count').mul(1.25).cast('float'),
        },
        {
          when: t
            .attr('starting_rfm_category')
            .oneOf(customerSegments.hibernating),
          then: t.attr('status_count').mul(1.1).cast('float'),
        },
        {
          when: t.attr('starting_rfm_category').oneOf(customerSegments.lost),
          then: t.attr('status_count').mul(1.05).cast('float'),
        },
      ]),
    }))
    .summary((t) => ({
      rate: Sum(t.attr('status_count'))
        .div(Sum(t.attr('total_count')))
        .cast('float')
        .mul(100),
    }))
    .chart.Stat(
      (t) => ({
        value: t.attr('rate'),
        unit: '%',
      }),
      { title: 'Expected Reactivation Rate' }
    );

  const LostRevenueKPIs = RfmLifecycleSummary.where((t) =>
    And(
      t.attr('segment').oneOf(ActiveCustomerGroups),
      t.attr('date').eq(dateForMetrics)
    )
  )
    .summary((t) => ({
      AOV_OVERALL: Sum(t.attr('total_spend'))
        .div(Sum(t.attr('total_order_count')))
        .cast('float'),
    }))
    .leftJoin(
      CurrentRateOfChange.where((t) =>
        And(
          t
            .attr('starting_rfm_category')
            .oneOf([
              ...customerSegments.atRisk,
              ...customerSegments.lapsed,
              ...customerSegments.hibernating,
              ...customerSegments.lost,
            ]),
          t.attr('date').eq(dateForMetrics),
          t.attr('category_status').eq('Segment Improvement Rate')
        )
      )
        .select((t) => ({
          ...t.star(),
          exp_status_count: Case([
            {
              when: t
                .attr('starting_rfm_category')
                .oneOf([
                  ...customerSegments.atRisk,
                  ...customerSegments.lapsed,
                ]),
              then: t.attr('status_count').mul(1.25).cast('float'),
            },
            {
              when: t
                .attr('starting_rfm_category')
                .oneOf(customerSegments.hibernating),
              then: t.attr('status_count').mul(1.1).cast('float'),
            },
            {
              when: t
                .attr('starting_rfm_category')
                .oneOf(customerSegments.lost),
              then: t.attr('status_count').mul(1.05).cast('float'),
            },
          ]),
        }))
        .select((t) => ({
          ...t.pick(
            'starting_rfm_category',
            'status_count',
            'total_count',
            'rate'
          ),
          EXPECTED_RATE: t
            .attr('exp_status_count')
            .div(t.attr('total_count'))
            .cast('float'),
        })),
      (aov, new_rate) => ({
        on: true,
        select: {
          ...aov.star(),
          ...new_rate.star(),
        },
      })
    )
    .leftJoin(
      RfmLifecycleSummary.where((t) =>
        And(
          t
            .attr('segment')
            .oneOf([
              ...customerSegments.atRisk,
              ...customerSegments.lapsed,
              ...customerSegments.hibernating,
              ...customerSegments.lost,
            ]),
          t.attr('date').eq(dateForMetrics)
        )
      ),
      (aov, pop) => ({
        on: aov.attr('starting_rfm_category').eq(pop.attr('segment')),
        select: {
          ...aov.star(),
          ...pop.pick('count'),
        },
      })
    )
    .select((t) => ({
      ...t.star(),
      EXPECTED_INCREASE: t
        .attr('count')
        .mul(t.attr('EXPECTED_RATE').sub(t.attr('rate')))
        .cast('float')
        .mul(t.attr('AOV_OVERALL')),
    }));

  const LostRevenueValue = LostRevenueKPIs.summary((t) => ({
    value: Sum(t.attr('EXPECTED_INCREASE')),
  })).chart.Stat((t) => ({ ...t.pick('value'), unit: 'USD' }), {
    title: 'Saveable Revenue',
  });

  const ChurnedCustomerValue = CurrentRateOfChange.where((t) =>
    And(
      t.attr('starting_rfm_category').oneOf(ActiveCustomerGroups),
      t.attr('category_status').eq('Segment Loss Rate'),
      t.attr('date').eq(dateForMetrics)
    )
  )
    .summary((t) => ({
      status_count: Sum(t.attr('status_count')),
    }))
    .summary((t) => ({
      status_count: Median(t.attr('status_count')),
    }))
    .leftJoin(
      LostRevenueKPIs.where((t) =>
        t.attr('starting_rfm_category').oneOf(customerSegments.atRisk)
      ),
      (churn, kpi) => ({
        on: true,
        select: {
          ...churn.star(),
          ...kpi.pick('AOV_OVERALL', 'EXPECTED_RATE', 'rate'),
          EXPECTED_INCREASE: churn
            .attr('status_count')
            .mul(kpi.attr('EXPECTED_RATE').sub(kpi.attr('rate')))
            .cast('float')
            .mul(kpi.attr('AOV_OVERALL')),
        },
      })
    );

  const ChurnedCustomerMonthlyValue = ChurnedCustomerValue.chart.Stat(
    (t) => ({
      value: t.attr('EXPECTED_INCREASE').round(2),
      unit: 'USD',
    }),
    { title: 'Churned Customer Monthly Value' }
  );

  const ChurnedCustomerCount = ChurnedCustomerValue.chart.Stat(
    (t) => ({
      value: t.attr('status_count'),
      unit: 'people',
    }),
    { title: 'Churned Customer Count' }
  );

  const ChurnedCustomerRateChange = ChurnedCustomerValue.chart.Stat(
    (t) => ({
      value: t.attr('EXPECTED_RATE'),
      from: t.attr('rate'),
    }),
    {
      title: 'Change in Reactivation Rate',
    }
  );

  const ChurnedCustomerAOV = ChurnedCustomerValue.chart.Stat(
    (t) => ({
      value: t.attr('AOV_OVERALL').round(2),
      unit: 'USD',
    }),
    { title: 'Churned Customer AOV' }
  );

  const ChurnedCustomersByMonth = CurrentRateOfChange.where((t) =>
    t
      .attr('category_status')
      .eq('Segment Improvement Rate')
      .or(t.attr('category_status').eq('Segment Loss Rate'))
  )
    .groupBy((t) => t.pick('date', 'category_status'))
    .select((t) => ({
      ...t.group(),
      status_count: Sum(t.attr('status_count')),
      label: Case(
        [
          {
            when: t.attr('category_status').eq('Segment Improvement Rate'),
            then: 'Newly Retained',
          },
          {
            when: t.attr('category_status').eq('Segment Retention Rate'),
            then: 'Existing Retained',
          },
        ],
        {
          else: 'Churned',
        }
      ),
    }))
    .orderBy((t) => Asc(t.attr('date')));

  return {
    ChurnedCustomerMonthlyValue,
    ChurnedCustomersByMonth,
    CurrentReactivationRate,
    ExpectedReactivationRate,
    LostRevenueValue,
    ReactivationOpportunity,
    ActiveCustomerGroups,
    ChurnedCustomerGroups,
    ChurnedCustomerCount,
    ChurnedCustomerRateChange,
    ChurnedCustomerAOV,
  };
};
