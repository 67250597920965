import {
  Center,
  Divider,
  Loading,
  Section,
  Title,
} from '@cotera/client/app/components/ui';
import { Layout } from '@cotera/client/app/layout';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useFeature } from '../hooks';
import { Documents } from './documents';
import { useDocumentsForFeatures } from './hooks';
import { PieChart } from '@cotera/client/app/components/data-vis';
import { Card } from '@cotera/client/app/components/headless';
import {
  DisplayError,
  LLMSummary,
  TransformableRelation,
} from '@cotera/client/app/components/app';
import { DocumentsViewModel } from './viewmodel';
import { v4 } from 'uuid';
import { Assert } from '@cotera/utilities';
import { useDefinition } from '@cotera/client/app/etc/data/manifest';
import { useSearchParam } from 'react-use';
import { useEntity } from '@cotera/client/app/hooks/entities';
import { Relation, Ty } from '@cotera/era';
import { FFI } from '@cotera/sdk/core';
import {
  useSubscribe,
  useViewModelContext,
  ViewModelProvider,
} from '@cotera/client/app/etc';
import { ErrorBoundary } from 'react-error-boundary';
import { useEraScopesAwareRelIR } from '@cotera/client/app/pages/apps/compiler/macro-expansion/scopes';

export const NlpFeaturePage: React.FC = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <View />
      </Suspense>
    </Layout>
  );
};

const View: React.FC = () => {
  const { featureName, topicModelId, entityName } = useParams() as {
    featureName: string;
    topicModelId: string;
    entityName: string;
  };

  const { data: feature } = useFeature({
    topicModelId,
    featureName,
  });

  return (
    <Section direction="vertical" className="w-[calc(100%-35px)]" top={false}>
      <div className="flex w-full items-center justify-between  mb-4">
        <div className="flex w-full items-center justify-between">
          <Title
            title={feature.content}
            type="title"
            className="mr-2"
            subtitle={feature.featureId}
          />
        </div>
      </div>
      <Divider className="mb-4" />
      <Suspense fallback={<Loading.Dots />}>
        <DocumentsContainer entityName={entityName} feature={feature}>
          <div className="flex w-full h-[calc(100%-54px)]">
            <div className="flex flex-col w-1/2 mr-4">
              <Documents
                features={[
                  {
                    id: feature.featureId,
                    content: feature.content,
                  },
                ]}
                entityName={entityName}
                forceRender={0}
                onRefreshClick={() => {}}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <StatsContainer />
            </div>
          </div>
        </DocumentsContainer>
      </Suspense>
    </Section>
  );
};

const DocumentsContainer: React.FC<{
  feature: { featureId: string };
  entityName: string;
  children: React.ReactNode;
}> = ({ feature, entityName, children }) => {
  const columnName = useSearchParam('column');
  const entity = useEntity({ entityName });

  const getTopicsColumnName = (
    attrs: Record<string, Ty.ExtendedAttributeType>
  ) => {
    if (columnName !== null) {
      return columnName;
    }
    return Object.entries(attrs).find(([_, ty]) =>
      FFI.isFFI(ty, FFI.TOPICS_TAG)
    )?.[0];
  };

  const definitionWithTargetColumn = Object.entries(entity.definitions).find(
    ([_, { attributes }]) => {
      return getTopicsColumnName(attributes) !== undefined;
    }
  )?.[0];

  Assert.assert(
    definitionWithTargetColumn !== undefined,
    'No definitons match the column'
  );

  const def = useDefinition({
    id: definitionWithTargetColumn,
  });

  Assert.assert(def.data.isOk());

  const baseRel = Relation.wrap(def.data.value);
  const topicsColumn = getTopicsColumnName(baseRel.attributes)!;
  const rel = baseRel.select((t) => ({
    ...t.star(),
    value: t.attr(topicsColumn).getField('params').get('content'),
    id: t.attr(topicsColumn).getField('params').get('coteraStableId'),
  }));
  const scopedRel = Relation.wrap(useEraScopesAwareRelIR(rel));
  const { data: featuresWithDocuments } = useDocumentsForFeatures([
    feature.featureId,
  ]);

  const vm = new DocumentsViewModel(
    'documents',
    v4(),
    TransformableRelation.fromRel(scopedRel),
    featuresWithDocuments.documents
  );

  return <ViewModelProvider model={vm}>{children}</ViewModelProvider>;
};

const StatsContainer: React.FC<{}> = () => {
  const vm = useViewModelContext<DocumentsViewModel>();

  return <Stats features={vm.featuresWithCounts()} />;
};

const Stats: React.FC<{
  features: { id: string; content: string }[];
}> = ({ features }) => {
  //count features by content
  const countedFeatures = Object.entries(
    features.reduce((acc, f) => {
      if (acc[f.content] !== undefined) {
        acc[f.content] = acc[f.content]! + 1;
      } else {
        acc[f.content] = 1;
      }
      return acc;
    }, {} as Record<string, number>)
  ).map(([content, count]) => ({
    category: content,
    value: count,
  }));

  const sortedFeatures = countedFeatures.sort((a, b) => b.value - a.value);
  const chartData = sortedFeatures
    .slice(0, 10)
    .concat([
      {
        category: 'Other',
        value: sortedFeatures.slice(10).reduce((acc, x) => acc + x.value, 0),
      },
    ])
    .filter((x) => x.value > 0);

  return (
    <div className="flex flex-col h-full">
      <Card.Container className="overflow-x-scroll">
        <div className="flex pb-6 flex-col">
          <Title
            type="section"
            title="Breakdown of Features"
            subtitle="Counts of features that occur alongside this feature"
            className="w-fit px-4 pt-4 pb-2"
          />
          <div className="mx-4">
            <PieChart chartHeight={400} data={chartData} />
          </div>
        </div>
      </Card.Container>
      <Card.Container className="max-h-[calc(100%-450px)] px-4 py-4 overflow-y-scroll">
        <div className="flex pb-6 flex-col">
          <Title type="section" title="Copilot Summary" className="pb-2" />
          <ErrorBoundary
            fallbackRender={({ error }) => <DisplayError error={error} />}
          >
            <LLMSummaryView />
          </ErrorBoundary>
        </div>
      </Card.Container>
    </div>
  );
};

const LLMSummaryView: React.FC = () => {
  const vm = useViewModelContext<DocumentsViewModel>();
  const rel = useSubscribe(vm, (s) => s.rel);

  return (
    <LLMSummary
      prompt={`
        You are an analyst looking for trends in chunks of text called "documents".
        Highlight any common themes you find.

        Keep your output strictly factual and avoid making any assumptions.

        Use percentages where possible.

        DO NOT provide an overview or title in your response.
      `}
      rel={rel}
    />
  );
};
