import { Text, Divider } from '@cotera/client/app/components/ui';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Row } from './row';

const Wrapper: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <div className="flex flex-row justify-center w-full h-full space-x-4 mt-3 mb-3">
      {children}
    </div>
  );
};

const Inner: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <div className={'flex flex-col h-full px-4 md:px-4 w-full mt-1'}>
      {children}
    </div>
  );
};

const Header: React.FC<{ title: string; caption?: string | null }> = ({
  title,
  caption,
}) => {
  return (
    <>
      <Text.Title type="title" className="mb-2">
        {title}
      </Text.Title>
      {caption && <Text.Caption>{caption}</Text.Caption>}
      <Row>
        <Divider className="mb-4 mt-6" theme="regular" />
      </Row>
    </>
  );
};

export const Dashboard = {
  Wrapper,
  Inner,
  Header,
};
