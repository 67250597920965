import React from 'react';
import { classNames } from '../../components/utils';
import { FIVETRAN_CONNECTOR_SCHEMAS } from '@cotera/api';
import { Link } from 'react-router-dom';

const CONNECTOR_COLORS: { [key: string]: string } = {
  HumanResources: 'bg-blue-100 text-blue-800 border-blue-200',
  Engineering: 'bg-indigo-100 text-indigo-800 border-indigo-200',
  Marketing: 'bg-rose-100 text-rose-800 border-rose-200',
  Finance: 'bg-amber-100 text-amber-800 border-amber-200',
  Support: 'bg-pink-100 text-pink-800 border-pink-200',
  Productivity: 'bg-emerald-100 text-emerald-800 border-emerald-200',
  Sales: 'bg-cyan-100 text-cyan-800 border-cyan-200',
  Events: 'bg-sky-100 text-sky-800 border-sky-200',
  Database: 'bg-slate-100 text-slate-800 border-slate-200',
  Security: 'bg-violet-100 text-violet-800 border-violet-200',
  File: 'bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200',
  API: 'bg-red-100 text-red-800 border-red-200',
  Function: 'bg-yellow-100 text-yellow-800 border-yellow-200',
  Free: 'bg-lime-100 text-lime-800 border-lime-200',
};

export const Connector: React.FC<{
  connector: string;
  alreadyConnected: boolean;
}> = ({ connector, alreadyConnected }) => {
  const meta = FIVETRAN_CONNECTOR_SCHEMAS[connector];
  if (!meta) {
    throw new Error(`Fivetran Connector of type "${connector}" not found`);
  }
  const { id, name, type, description } = meta;
  return (
    <li key={id} className="rounded-lg border border-slate-200 overflow-clip">
      <div className="flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 p-6">
        <img
          src={`https://cotera.co/images/${id}/public`}
          alt={name}
          className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-slate-900/10"
        />
        <div className="text-md font-medium leading-6 text-slate-800 tracking-tight">
          {name}
        </div>
        <div className="flex-grow" />
        <Link
          to={`/connections/sources/${id}`}
          className="bg-white px-3 py-1.5 border-slate-200 border hover:border-2 rounded-lg flex flex-row"
        >
          <span className="text-sm font-medium text-gray-700">
            {alreadyConnected ? 'Manage' : 'Connect'}
          </span>
        </Link>
      </div>

      <dl className="-my-3 divide-y divide-slate-100 px-6 py-4 text-sm leading-6">
        <div className="flex justify-between gap-x-4 py-3">
          <span className="text-slate-500">Type</span>
          <span
            className={classNames(
              'text-left border px-1.5 py-1 rounded-lg',
              CONNECTOR_COLORS[type]!
            )}
          >
            {type}
          </span>
        </div>
        <div className="flex justify-between gap-x-4 py-3">
          <span className="text-slate-700 text-left">{description}</span>
        </div>
      </dl>
    </li>
  );
};
