import { From, Ty } from '@cotera/era';

export const CLOTHING_STORE = From({
  schema: 'DEMO',
  name: 'CLOTHING_STORE',
  attributes: {
    AD_CONVERTED_FROM: 'string',
    AVERAGE_DAYS_BETWEEN_VISITS: 'float',
    AVERAGE_DOLLARS_SPENT: 'float',
    AVERAGE_NUMBER_ITEMS_IN_ORDER: 'float',
    AVERAGE_TAX: 'float',
    CAMPAIGN_CONVERTED_FROM: 'string',
    CUSTOMER_NPS: 'string',
    EMAIL: 'string',
    GEOGRAPHIC_TIMEZONE: 'string',
    ID: 'string',
    MEDIUM_CONVERSION: 'string',
    NUMBER_OF_ORDERS: 'string',
    NUMBER_OF_VISTS_TO_FIRST_CONVERSION: 'float',
    PRIMARY_APP_EXPERIENCE: 'string',
    TOTAL_DOLLARS_SPENT_IN_LAST_YEAR: 'float',
    USER_TAG_MOST_PURCHASED: 'string',
  },
});

export const B2B = From({
  schema: 'DEMO',
  name: 'B_2_B_MODE',
  attributes: {
    AD_CONVERTED_FROM: 'string',
    API: 'float',
    CAMPAIGN_CONVERTED_FROM: 'string',
    DOLLARS_PROCESSED_NO_FEE: 'float',
    EMAIL: 'string',
    GEOGRAPHIC_TIMEZONE: 'string',
    ID: 'string',
    MEDIUM_CONVERSION: 'string',
    NUMBER_OF_EMPLOYEES: 'float',
    PRIMARY_APP_EXPERIENCE: 'string',
    PRIMARY_PLATFORM_USE_CASE: 'string',
    TIME_FROM_SIGNING_UP_TO_ONBOARDING: 'string',
    TIME_TO_FINISH_ONBOARDING: 'float',
    UPSELL_PROBABILITY: 'string',
  },
});

export const WEBFLIX = From({
  schema: 'DEMO',
  name: 'WEBFLIX',
  attributes: {
    AD_CONVERTED_FROM: 'string',
    AVERAGE_MINUTES_WATCHED: 'float',
    AVERAGE_SECONDS_BUFFERED: 'float',
    BROOKLYN_99_WATCHES: 'float',
    CAMPAIGN_CONVERTED_FROM: 'string',
    CHURN_PROBABILITY: 'string',
    GEOGRAPHIC_TIMEZONE: 'string',
    HIGH_DEFINITION_EPISODE_WATCHES: 'float',
    INITIAL_REFERRAL_MEDIUM: 'string',
    LAW_AND_ORDER_WATCHES: 'float',
    MEDIUM_CONVERSION: 'string',
    MOBILE_OR_DESKTOP_USER: 'string',
    SOURCE_CONVERTED_FROM: 'string',
    STANDARD_DEFINITION_EPISODE_WATCHES: 'float',
    TOTAL_MINUTES_WATCHED: 'float',
    TOTAL_SECONDS_BUFFERED: 'float',
    USER_CUSTOM_ID: 'string',
    USER_DEVICE_TYPE: 'string',
    USER_DOMAIN_ID: 'string',
    USER_PREFERRED_GENRE: 'string',
  },
});

export const OREO_REVIEWS = From({
  schema: 'COTERA_DATA',
  name: 'OREO_REVIEWS',
  attributes: {
    SOURCE: 'string',
    PRODUCT__COLOR: 'string',
    REVIEW_ID: 'float',
    __COTERA_CUSTOMER_ID: 'string',
    CREATED_AT: 'timestamp',
    PRODUCT__TASTE: 'string',
    CUSTOMER_SERVICE: 'string',
    OREO_ID: 'float',
    ORDERING: 'string',
    ID: 'float',
    PRODUCT_NAME: 'string',
    PROMPT: 'string',
    PRODUCT__FILLING: 'string',
    REVIEW_CONTENT: 'string',
    PRODUCT__PACKAGING: 'string',
    PRODUCT__PRICE: 'string',
    WEBSITE: 'string',
    PRODUCT__TEXTURE: 'string',
  },
});

export const AMAZON_REVIEWS = From({
  schema: 'GROWTH_HACKS',
  name: 'AMAZON_REVIEWS',
  attributes: {
    ID: Ty.nn('string'),
    ASIN: 'string',
    BRAND: 'string',
    PRODUCT_NAME: 'string',
    REVIEW_TITLE: 'string',
    REVIEW: 'string',
    REVIEW_DATE: 'timestamp',
    STAR_RATING: 'int',
    BRAND_ID: 'string',
  },
});

export const GORGIAS_COMMENTS = From({
  schema: 'COTERA_DATA',
  name: 'VIEW_JESSAPP_GORGIAS_TICKET_DATA',
  attributes: {
    TICKET_ID: { ty: 'int', nullable: false },
    TAGS: { ty: 'string', nullable: false },
    INTIAL_CHANNEL: { ty: 'string', nullable: false },
    LAST_USED_INTEGRATION_NAME: { ty: 'string', nullable: false },
    LAST_USED_INTEGRATION_TYPE: { ty: 'string', nullable: false },
    IS_AGENT_CREATED: { ty: 'boolean', nullable: false },
    SUBJECT: { ty: 'string', nullable: false },
    CREATED_DATE: { ty: 'timestamp', nullable: false },
    CLOSED_DATE: { ty: 'timestamp', nullable: false },
    SURVEY_SCORE: { ty: 'int', nullable: false },
    ASSIGNEE_NAME: { ty: 'string', nullable: false },
    ASSIGNEE_EMAIL: { ty: 'string', nullable: false },
    CUSTOMER_EMAIL: { ty: 'string', nullable: false },
    CUSTOMER_NAME: { ty: 'string', nullable: false },
    FIRST_RESPONSE_TIME: { ty: 'int', nullable: false },
    RESOLUTION_TIME: { ty: 'int', nullable: false },
    NUMBER_OF_AGENT_MESSAGES: { ty: 'int', nullable: false },
    NUMBER_OF_CUSTOMER_MESSAGES: { ty: 'int', nullable: false },
    PRODUCT: { ty: 'string', nullable: false },
    RESOLUTION: { ty: 'string', nullable: false },
    CONTACT_CATEGORY: { ty: 'string', nullable: false },
    EVENT_CATEGORY: { ty: 'string', nullable: false },
    PRIMARY_CONTACT_REASON: { ty: 'string', nullable: false },
    SECONDARY_CONTACT_REASON: { ty: 'string', nullable: false },
  },
});

export const GORGIAS_REVIEWS = From({
  //comes from a casting of the CSV
  schema: 'COTERA_DATA',
  name: 'JESSAPP_GORGIAS_REVIEWS',
  attributes: {
    CUSTOMER: { ty: 'string', nullable: false },
    ASSIGNEE: { ty: 'string', nullable: false },
    CREATED_AT: { ty: 'timestamp', nullable: false },
    RATING: { ty: 'int', nullable: false },
    COMMENT: { ty: 'string', nullable: false },
    DATEVALUE: { ty: 'timestamp', nullable: false },
  },
});

export const OKENDO_REVIEWS = From({
  //comes from a casting of the CSV
  schema: 'COTERA_DATA',
  name: 'JESSAPP_PRODUCT_REVIEWS',
  attributes: {
    ID: { ty: 'string', nullable: false },
    TITLE: { ty: 'string', nullable: false },
    BODY: { ty: 'string', nullable: false },
    CREATED_AT: { ty: 'timestamp', nullable: false },
    RATING: { ty: 'int', nullable: false },
    PRODUCT_HANDLE: { ty: 'string', nullable: false },
  },
});

export const ECOMM_STORES_TWEETS = From({
  schema: 'GROWTH_HACKS',
  name: 'ECOMM_STORES_TWEETS',
  attributes: {
    QUERY: 'string',
    COMPANY_NAME: 'string',
    TWEET_ID: 'string',
    TWEET_TIME: 'timestamp',
    TWEET_TEXT: 'string',
    TWEET_REPEAT_COUNT: 'int',
    QUOTE_COUNT: 'int',
    REPLY_COUNT: 'int',
    FAVORITE_COUNT: 'int',
    BOOKMARK_COUNT: 'int',
    CONVERSATION_ID: 'string',
    URLS: 'string',
    MENTIONED_COMPANIES: 'string',
    VIEW_COUNT: 'int',
    TWEETER_NAME: 'string',
    TWEETER_HANDLE: 'string',
    TWEETER_ID: 'string',
    FOLLOWERS_COUNT: 'int',
    FAVORITES_COUNT: 'int',
    FRIENDS_COUNT: 'int',
    LISTED_COUNT: 'int',
    LOCATION: 'string',
    MEDIA_COUNT: 'int',
    NORMAL_FOLLOWERS_COUNT: 'int',
    STATUS_COUNT: 'int',
    VERIFIED: 'boolean',
    TWITTER_BLUE: 'boolean',
    CAN_DM: 'boolean',
    TWEETER_DESCRIPTION: 'string',
  },
});

export const ECOMM_REVIEWS = From({
  schema: 'GROWTH_HACKS',
  name: 'ECOMM_STORES_GOOGLE_REVIEWS',
  attributes: {
    COMPANY: { ty: 'string', nullable: false },
    PRODUCT: { ty: 'string', nullable: false },
    ID: { ty: 'string', nullable: false },
    TITLE: { ty: 'string', nullable: false },
    AUTHOR: { ty: 'string', nullable: false },
    SOURCE: { ty: 'string', nullable: false },
    URL: { ty: 'string', nullable: false },
    TEXT: { ty: 'string', nullable: false },
    RATING: { ty: 'int', nullable: false },
    DATE: { ty: 'timestamp', nullable: false },
  },
});

export const TELEGRAPH_ARTICLES = From({
  schema: 'GROWTH_HACKS',
  name: 'TELEGRAPH_UK',
  attributes: {
    ID: { ty: 'string', nullable: false },
    URL: { ty: 'string', nullable: false },
    BODY: { ty: 'string', nullable: false },
    DATE: { ty: 'string', nullable: false },
    PROCESSED: { ty: 'string', nullable: false },
    PAGE_ID: { ty: 'string', nullable: false },
  },
});

export const TELEGRAPH_COMMENTS = From({
  schema: 'GROWTH_HACKS',
  name: 'TELEGRAPH_COMMENTS',
  attributes: {
    CONTENT_ID: { ty: 'int', nullable: false },
    ORIGINAL_ID: { ty: 'int', nullable: false },
    CONTENT: { ty: 'string', nullable: false },
    IS_ACTOR_GHOSTBANNED: { ty: 'boolean', nullable: false },
    TOTAL_REPLIES: { ty: 'int', nullable: false },
    TOTAL_DIRECT_REPLIES: { ty: 'int', nullable: false },
    TOTAL_FLAGS: { ty: 'int', nullable: false },
    TOTAL_BANNED_REPLIES: { ty: 'int', nullable: false },
  },
});

export const OBFF_TEST_20240428 = From({
  schema: 'GROWTH_HACKS',
  name: 'OBFF_GROWTH_TEST_2024_04_14',
  attributes: {
    COMPANY_ID: { ty: 'string', nullable: false },
    COMPANY: { ty: 'string', nullable: false },
    PRODUCT_NAME: { ty: 'string', nullable: false },
    REVIEWS: { ty: 'string', nullable: false },
  },
});

export const LINKEDIN_POSTS_GROWTH = From({
  schema: 'GROWTH_HACKS',
  name: 'LINKEDIN_POSTS_GROWTH',
  attributes: {
    POSTURL: 'string',
    TEXT: 'string',
    TOTALREACTIONCOUNT: 'int',
    COMMENTSCOUNT: 'int',
    REPOSTSCOUNT: 'int',
    AUTHOR_FIRSTNAME: 'string',
    AUTHOR_LASTNAME: 'string',
    AUTHOR_URL: 'string',
    URN: 'string',
    INSERTED_AT: 'timestamp',
    POSTEDDATE: 'timestamp',
  },
});

export const LINKEDIN_POSTS_AI = From({
  schema: 'GROWTH_HACKS',
  name: 'LINKEDIN_POSTS_AI',
  attributes: {
    URN: 'string',
    RESPOND: 'string',
  },
});

export const SCRAPER_REVIEW_TAGS = From({
  schema: 'SCRAPER_OPERATION',
  name: 'REVIEW_TAGS',
  attributes: {
    REVIEW_ID: 'string',
    TAG_KEY: 'string',
    TAG_VALUE: Ty.enumOf(['-1', '0', '1']),
  },
});

export const SCRAPER_PRODUCT_REVIEWS = From({
  schema: 'SCRAPER_OPERATION',
  name: 'PRODUCT_REVIEWS',
  attributes: {
    SOURCE: 'string',
    ID: 'string',
    TIMESTAMP: 'timestamp',
    COMMENT: 'string',
    DOMAIN: 'string',
  },
});

/**
 * @example create view cotera_data.similarweb_competitors as
(
with raws as (select domain,
                     competitors['topSimilarityCompetitors'][0]['domain']::string competitor_1,
                     competitors['topSimilarityCompetitors'][1]['domain']::string competitor_2
              from scraper_operation.similarweb_data),

     competitor_list as (select a.domain,
                                a.competitor_1,
                                a.competitor_2
                         from raws a)

select *
from competitor_list
    );
 */
export const SIMILARWEB_COMPETITORS = From({
  schema: 'COTERA_DATA',
  name: 'SIMILARWEB_COMPETITORS',
  attributes: {
    DOMAIN: 'string',
    COMPETITOR_1: 'string',
    COMPETITOR_2: 'string',
  },
});

export const GLASSDOOR_REVIEWS = From({
  schema: 'SCRAPER_OPERATION',
  name: 'GLASSDOOR_REVIEWS',
  attributes: {
    REVIEW_ID: 'string',
    SUMMARY: 'string',
    PROS: 'string',
    CONS: 'string',
    RATING: 'float',
    REVIEW_LINK: 'string',
    JOB_TITLE: 'string',
    REVIEW_DATETIME: 'timestamp',
    EMPLOYMENT_STATUS: 'string',
    YEARS_OF_EMPLOYMENT: 'int',
    ADVICE_TO_MANAGEMENT: 'string',
    HELPFUL_COUNT: 'int',
    NOT_HELPFUL_COUNT: 'int',
    BUSINESS_OUTLOOK_RATING: 'string',
    CAREER_OPPORTUNITIES_RATING: 'int',
    CEO_RATING: 'string',
    COMPENSATION_AND_BENEFITS_RATING: 'int',
    CULTURE_AND_VALUES_RATING: 'int',
    DIVERSITY_AND_INCLUSION_RATING: 'int',
    RECOMMEND_TO_FRIEND_RATING: 'string',
    SENIOR_MANAGEMENT_RATING: 'int',
    WORK_LIFE_BALANCE_RATING: 'int',
    LANGUAGE: 'string',
    LOCATION: 'string',
    DOMAIN: 'string',
  },
});

export const ADVENT_CALENDAR_GROWTH_HACKS = From({
  schema: 'GROWTH_HACKS',
  name: 'ADVENT_CALENDAR_DATA',
  attributes: {
    ID: 'string',
    TIMESTAMP: 'timestamp',
    TEXT: 'string',
    SUMMARY: 'string',
    PRODUCT_SUMMARY: 'string',
    CX_SUMMARY: 'string',
    SENTIMENT: 'string',
    FEATURE_REQUEST: 'string',
    CSAT: 'string',
    RISK: 'string',
    PRODUCT_SUBCATEGORY: 'string',
    CX_SUBCATEGORY: 'string',
    FEATURE_SUBCATEGORY: 'string',
    COMPANY: 'string',
  },
});

/**
 * create table "GROWTH_HACKS"."DEMO_QA_DATA__MAT" as (
    select * from "GROWTH_HACKS"."DEMO_QA_DATA"
)
 */
export const DEMO_QA_MODEL = From({
  schema: 'GROWTH_HACKS',
  name: 'DEMO_QA_DATA__MAT',
  attributes: {
    AGENT: 'string',
    TIMESTAMP: 'timestamp',
    CLEANED_DATA: 'string',
    SPELLING: 'string',
    COMPLETE_RESOLUTION: 'string',
    POSITIVE_LANGUAGE: 'string',
    EMPATHY_OWNERSHIP: 'string',
    ID: 'string',
  },
});
