import { Relation, Ty } from '@cotera/era';
import {
  CellRenderer,
  CellView,
  HeaderView,
  LoadingCellView,
  Registry,
  SearchView,
} from './types';
import { Cell } from './components/cell';
import React from 'react';
import { BaseHeader } from '../data-vis/data-grid/header';
import { IconName, Loading } from '@cotera/client/app/components/ui';
import { mapValues } from 'lodash';
import { HeaderColumnAction } from './columns/column-action.type';
import { ChildrenProps } from '@cotera/client/app/components/utils';

export const PreviewCell: React.FC<
  {
    column: string;
    onClick?: () => void;
    width?: number;
    style?: React.CSSProperties;
    className?: string;
  } & ChildrenProps
> = (props) => {
  return <Cell {...props} enableHover={false} className="!border-none" />;
};

const DefaultLoadingCell: React.FC<{
  column: string;
}> = ({ column }) => (
  <Cell column={column}>
    <Loading.Shimmer className={'h-full w-full'} />
  </Cell>
);

type ColumnActionCreator = (
  rel: Relation,
  column: string,
  ty: Ty.ExtendedAttributeType
) => HeaderColumnAction;

export class DataGridRegistry implements Registry {
  private readonly _registry: {
    matcher: (column: string, ty: Ty.ExtendedAttributeType) => boolean;
    header?: HeaderView;
    search?: SearchView;
    headerActions?: ((
      rel: Relation,
      column: string,
      ty: Ty.ExtendedAttributeType
    ) => HeaderColumnAction)[];
    cell?: CellView;
    preview?: CellView;
    loadingCell?: LoadingCellView;
    icon?: IconName;
    displayType?: React.ReactNode;
  }[] = [];

  clone(): DataGridRegistry {
    const registry = new DataGridRegistry();
    registry._registry.push(...this._registry);

    return registry;
  }

  getHeader(
    rel: Relation,
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return (
      view?.header?.(rel, column, ty) ??
      BaseHeader({
        attr: { name: column, ty, detailPages: {} },
        children: null,
      })
    );
  }

  getSearch(
    rel: Relation,
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode | null {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return view?.search?.(rel, column, ty) ?? null;
  }

  getHeaderActions(
    rel: Relation,
    column: string,
    ty: Ty.ExtendedAttributeType
  ): HeaderColumnAction[] {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return view?.headerActions?.map((v) => v(rel, column, ty)) ?? [];
  }

  getCell(column: string, ty: Ty.ExtendedAttributeType): CellRenderer {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    const renderer: CellRenderer = (props) =>
      view?.cell?.(props.column, props.ty, props.value) ??
      Cell({
        column: props.column,
        children: String(props.value) as React.ReactNode,
      });

    return renderer;
  }

  getPreviewRenderer(
    column: string,
    ty: Ty.ExtendedAttributeType
  ): CellRenderer {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    const renderer: CellRenderer = (props) =>
      view?.preview?.(props.column, props.ty, props.value) ??
      PreviewCell({
        column: props.column,
        children: String(props.value) as React.ReactNode,
      });

    return renderer;
  }

  getCellRenderers(rel: Relation): Record<string, CellRenderer> {
    return mapValues(rel.attributes, (ty, column) => {
      return this.getCell(column, ty);
    });
  }

  getLoadingCell(
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode | null {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return view?.loadingCell?.(column) ?? DefaultLoadingCell({ column });
  }

  getIcon(ty: Ty.ExtendedAttributeType): IconName {
    return (
      this._registry.find(({ matcher }) => matcher('', ty))?.icon ?? 'text'
    );
  }

  getDisplayType(ty: Ty.ExtendedAttributeType): React.ReactNode {
    return (
      this._registry.find(({ matcher }) => matcher('', ty))?.displayType ??
      Ty.displayTy(ty)
    );
  }

  add(
    rule: (column: string, ty: Ty.ExtendedAttributeType) => boolean,
    views: {
      cell?: CellView;
      header?: HeaderView;
      icon?: IconName;
      headerActions?: ColumnActionCreator[];
      search?: SearchView;
      loadingCell?: LoadingCellView;
      displayType?: React.ReactNode;
      preview?: CellView;
    }
  ) {
    this._registry.push({ matcher: rule, ...views });

    return this;
  }

  unshift(
    rule: (column: string, ty: Ty.ExtendedAttributeType) => boolean,
    views: {
      icon?: IconName;
      cell?: CellView;
      header?: HeaderView;
      headerActions?: ColumnActionCreator[];
      search?: SearchView;
      loadingCell?: LoadingCellView;
      displayType?: React.ReactNode;
      preview?: CellView;
    }
  ) {
    this._registry.unshift({ matcher: rule, ...views });

    return this;
  }
}
