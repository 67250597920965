import { ConfigSection } from './section';
import { AST, Expression, TC } from '@cotera/era';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { LLMModelConfigViewModel } from '../view-model';
import { Button, Text, Loading } from '@cotera/client/app/components/ui';
import { DataPreview } from '../../components/data-preview';
import { Copyable, DisplayError } from '@cotera/client/app/components/app';
import { useSubscribe } from '@cotera/client/app/etc';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useCompletion } from '@cotera/client/app/hooks/use-completion';
import { LLMMessage } from '@cotera/api';
import React from 'react';
import { responseShapeToShorthand } from '../utils';

export const Preview: React.FC<{
  vm: LLMModelConfigViewModel;
}> = ({ vm }) => {
  const eraQl = useSubscribe(vm, (rel) => rel.promptEraQl);
  const prompt = useSubscribe(vm, (rel) => rel.prompt);
  const [runKey, setRunKey] = React.useState<string>('');

  if (prompt.length === 0) {
    return (
      <ConfigSection>
        <Text.Caption>
          You must enter a prompt to preview the model.
        </Text.Caption>
      </ConfigSection>
    );
  }

  return (
    <ConfigSection>
      <div className="flex items-center justify-end mb-4">
        <Button
          iconPosition="right"
          icon="play"
          text="Run Model"
          theme="primary"
          onClick={() => {
            setRunKey(Date.now().toString());
          }}
        />
      </div>
      {eraQl.isErr() ? (
        <DisplayError
          error={{
            message: 'You must enter a valid prompt to preview the model.',
          }}
        />
      ) : (
        <Suspense fallback={<Loading.Dots />}>
          <ErrorBoundary
            fallbackRender={({ error }) => <DisplayError error={error} />}
          >
            <MemoedPreviewExpression
              runKey={runKey}
              vm={vm}
              expr={eraQl.value}
            />
          </ErrorBoundary>
        </Suspense>
      )}
    </ConfigSection>
  );
};

const PreviewExpression: React.FC<{
  vm: LLMModelConfigViewModel;
  expr: {
    tc: TC.ExprTypeCheck;
    ast: AST.ExprIR;
    dTy: string;
  };
  runKey: string;
}> = ({ vm, expr }) => {
  const inputCol = `input`;
  const rel = useSubscribe(vm, (rel) => rel.rel);
  const sourceColumns = expr.tc.attrReqs.from ?? {};
  const { data } = useDuckDBQuery({
    rel: rel
      .select(
        (t) => ({
          ...t.pick(...Object.keys(sourceColumns)),
          [inputCol]: Expression.fromAst(expr.ast),
          model_preview: Expression.fromAst(expr.ast),
        }),
        {
          distinct: true,
        }
      )
      .limit(6),
  });

  return (
    <DataPreview
      records={data.data.toArray()}
      columnName="model_preview"
      renderers={{
        model_preview: ({ value }) => {
          return <PreviewItem vm={vm} value={value} />;
        },
      }}
      hideColumns={[inputCol]}
    />
  );
};

const MemoedPreviewExpression = React.memo(PreviewExpression, (prev, next) => {
  return prev.runKey === next.runKey && prev.vm.isEqual(next.vm);
});

const PreviewItem: React.FC<{
  value: string;
  vm: LLMModelConfigViewModel;
}> = ({ value, vm }) => {
  return (
    <Suspense fallback={<Loading.Spinner variant="sm" />}>
      <ErrorBoundary
        fallbackRender={({ error }) => <DisplayError error={error} />}
      >
        <PreviewData value={value} vm={vm} />
      </ErrorBoundary>
    </Suspense>
  );
};

const PreviewData: React.FC<{
  value: string;
  vm: LLMModelConfigViewModel;
}> = ({ vm, value }) => {
  const systemMessage = useSubscribe(vm, (s) => s.systemMessage);
  const outputShape = useSubscribe(vm, (s) => s.outputShape);

  const expectedType = responseShapeToShorthand(outputShape.value);

  const messages: LLMMessage[] = [
    ...(systemMessage.length > 0
      ? [{ role: 'system' as const, content: systemMessage }]
      : []),
    {
      role: 'user',
      content: value,
    },
  ];

  const { data: completion } = useCompletion({
    messages,
    expectedType,
  });

  if (completion.isErr()) {
    return (
      <DisplayError
        error={{
          message: 'Error fetching completion',
          errorType: completion.error.t,
        }}
      />
    );
  }

  return (
    <div className="flex flex-col">
      <Copyable>
        {expectedType.ty.k === 'primitive'
          ? String(completion.value.completion)
          : JSON.stringify(completion.value.completion, null, 2)}
      </Copyable>
    </div>
  );
};
