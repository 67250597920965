import { ADVENT_CALENDAR_GROWTH_HACKS } from '@cotera/customers/cotera/assumptions';
import { From, f } from '@cotera/era';
import { AccomplishmentsApp } from '../product-accomplishments/app';

export const CustomerSupportFeedbackApp = AccomplishmentsApp({
  CxData: From(ADVENT_CALENDAR_GROWTH_HACKS),
  appTopic: 'Customer Support Feedback',
  columnName: 'CX_SUBCATEGORY',
  tableColumns: ['CX_SUBCATEGORY', 'CX_SUMMARY'],
  caption: f`We've trained our AI to look through your public review data and identify CX feedback. It looks through every review, identifies feedback for your CX team, and then groups them into common categories for anaysis.

Check out the [explainer video](https://www.loom.com/share/662d516132d044d59be381b425ac2a79).

If you think this is interesting, [book a chat with us here.](https://calendly.com/cotera-founders/30-minutes)`,
});
