import { ExprVar, RelVar, Ty, InformationSchema } from '@cotera/era';
import { STABLE_IDS_ATTRIBUTES } from './system-tables/system-tables';

export const MANIFEST_EAGER_DEF = (params: {
  name: string;
  attributes: Record<string, Ty.Shorthand>;
}) =>
  RelVar.create({
    scope: '@@cotera-manifest-eager-defs',
    name: params.name,
    ty: params.attributes,
  });

export const ORG_ID = ExprVar.create({
  scope: '@@cotera-org',
  name: 'ORG_ID',
  ty: Ty.nn('string'),
});

export const ORG_NAME = ExprVar.create({
  scope: '@@cotera-org',
  name: 'ORG_NAME',
  ty: Ty.nn('string'),
});

export const STABLE_IDS_FOR_ENTITY = (id: Ty.IdType): RelVar =>
  RelVar.create({
    scope: '@@cotera-entities',
    name: `${id.name}-stable-ids`,
    ty: STABLE_IDS_ATTRIBUTES,
  });

export const ENTITY_DEFINITIONS = RelVar.create({
  scope: '@@cotera-entities',
  name: 'DEFINITIONS',
  ty: {
    name: Ty.nn('string'),
    id: Ty.nn('string'),
  },
});

export const DECISION_TREE_OUTPUTS = (entityId: Ty.IdType) =>
  RelVar.create({
    scope: '@@cotera-decision-tree-outputs',
    name: entityId.name,
    ty: {
      column: Ty.ty('string'),
      variant: Ty.ty('string'),
    },
  });

export const MANIFEST_DEF_INFORMATION_SCHEMA = RelVar.create({
  scope: '@@cotera-manifest-def-information-schema',
  name: 'INFORMATION',
  ty: InformationSchema({ schemas: ['@@dummy'], type: 'columns' }).attributes,
});

export const EVENT_CURSORS = RelVar.create({
  scope: '@@cotera-events',
  name: 'CURSORS',
  ty: {
    paused_at: 'timestamp',
    cotera_stable_id: Ty.nn('string'),
    entity_id: Ty.nn('string'),
    sink_id: Ty.nn('string'),
    event_stream_id: Ty.nn('string'),
    detected_at_time: Ty.nn('timestamp'),
  },
});

export const MANIFEST_EVENT_STREAM_SINKS = RelVar.create({
  scope: '@@cotera-manifest-event-streams',
  name: 'SINKS',
  ty: {
    entity_id: Ty.nn('string'),
    sink_id: Ty.nn('string'),
    event_stream_id: Ty.nn('string'),
    type: Ty.nn('string'),
  },
});

export const MANIFEST_EVENT_STREAMS = RelVar.create({
  scope: '@@cotera-manifest-event-streams',
  name: 'STREAMS',
  ty: {
    entity_id: Ty.nn('string'),
    event_stream_id: Ty.nn('string'),
  },
});
