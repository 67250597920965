import _ from 'lodash';
import { AST, Relation, Page, TC, Ty } from '@cotera/era';
import { Definition, ManifestShorthand, ManifestAST } from './ast';
import { ManifestSkeleton, _Sink, Schedule } from '@cotera/api';
import { ScheduledJob } from './scheduled-job';
import { Materialization } from '../materialization/materialization';
import { EventStreamThrottle } from '../builder';

const shorthandToManifest = (shorthand: ManifestShorthand): ManifestAST => {
  const definitions: Record<string, Definition> = _.mapValues(
    shorthand.definitions,
    (def, id) => {
      if (def instanceof Relation) {
        return {
          rel: def.ast,
          displayName: _.startCase(id),
          hidden: false,
          eager: false,
        };
      }

      return {
        rel: def.rel.ast,
        displayName: def.displayName ?? _.startCase(id),
        hidden: def.hidden ?? false,
        eager: def.dangerouslyEagerLoad ?? false,
      };
    }
  );

  const apps: Record<string, AST._App> = _.mapValues(
    shorthand.apps,
    (app, name) => {
      if (
        typeof app === 'object' &&
        app !== null &&
        't' in app &&
        typeof app.t === 'string' &&
        app.t === 'app'
      ) {
        return app;
      }

      const res: AST._App = {
        t: 'app',
        title: name,
        icon: 'command-line',
        pages: {
          '/': Page([], () => app),
        },
        menu: [],
        context: null,
      };

      return res;
    }
  );

  const udds = (shorthand.udds ?? []).map((x) => x.config);

  const materializations = (shorthand.materializations ?? []).map(
    ({ schedule, config }) => ({
      schedule,
      schema: config.config.schema,
      source: config.config.source,
      viewName: config.config.viewName,
    })
  );

  const detailPages: ManifestAST['detailPages'] = _.mapValues(
    shorthand.detailPages ?? {},
    (page) => ({ ty: page.ty, render: page.mu })
  );

  const manifest: ManifestAST = {
    detailPages,
    definitions,
    apps,
    udds,
    materializations,
    scheduledJobs: shorthand.scheduledJobs ?? [],
    schemas: shorthand.schemas ?? { read: [] },
    eventStreams: [
      ...(shorthand.eventStreams ?? []),
      ...(shorthand.alerts ?? []),
    ],
    preloadApps: shorthand.preloadApps ?? true,
  };

  return manifest;
};

export type EventStreamDefinition = {
  readonly entityId: Ty.IdType;
  readonly name: string;
  readonly identifier: AST.ExprFR;
  readonly rel: AST.RelFR;
  readonly when: Schedule;
  readonly onChange: AST.ExprFR;
  readonly sinks: Record<string, _Sink>;
  readonly batchSize: number;
  readonly debounceByIdForSecs?: number;
  readonly throttle?: EventStreamThrottle;
};

export class Manifest {
  readonly udds: readonly {
    readonly entityName: string;
    readonly attributes: Record<string, Ty.ExtendedAttributeType>;
  }[];
  readonly definitions: {
    readonly [id: string]: {
      readonly eager: boolean;
      readonly hidden: boolean;
      readonly displayName: string;
      readonly rel: Relation;
      readonly id: string;
    };
  };

  readonly detailPages: {
    readonly [page: string]: {
      readonly ty: Ty.ExtendedAttributeType;
      readonly render: AST.Mu;
    };
  };

  readonly eventStreams: EventStreamDefinition[];

  readonly materializations: readonly Materialization[];

  readonly scheduledJobs: readonly ScheduledJob[];

  readonly schemas: {
    readonly read: string[];
  };

  readonly apps: {
    readonly [id: string]: AST._App;
  };

  public readonly preloadApps: boolean;

  static fromShorthand(shorthand: ManifestShorthand) {
    return new this(shorthandToManifest(shorthand));
  }

  informationSchema(): readonly {
    readonly column_name: string;
    readonly table_name: string;
    readonly ty: Ty.ExtendedAttributeType;
  }[] {
    return Object.entries(
      this.skeleton({ version: 'info' }).definitions
    ).flatMap(([table_name, def]) =>
      def.eager
        ? Object.entries(def.attributes).map(([column_name, ty]) => ({
            table_name,
            column_name,
            ty,
          }))
        : []
    );
  }

  skeleton(params: { version: string }): ManifestSkeleton {
    const skeleton: ManifestSkeleton = {
      version: params.version,
      udds: this.udds,
      materializations: this.materializations.map(
        ({ viewName, schedule, schema }) => ({
          schema,
          viewName,
          schedule,
        })
      ),
      detailPages: _.mapValues(this.detailPages, ({ ty }) => ty),
      definitions: _.mapValues(this.definitions, (def, id) => ({
        id,
        eager: def.eager ? def.rel.ast : null,
        displayName: def.displayName,
        hidden: def.hidden,
        attributes: def.rel.attributes,
      })),
      apps: _.mapValues(this.apps, (app, id) => ({
        id,
        icon: app.icon,
        title: app.title,
        hasHomePage: Object.keys(app.pages).includes('/'),
      })),
      schemas: this.schemas,
      eventStreams: this.eventStreams.map((s) => ({
        name: s.name,
        when: s.when,
        entityId: s.entityId,
        sinks: s.sinks,
        rel: s.rel,
        identifier: s.identifier,
      })),
    };

    return skeleton;
  }

  getEventStream(name: string): EventStreamDefinition | null {
    const resource = this.eventStreams.find((x) => x.name === name);
    return resource ?? null;
  }

  assumptions(): { readonly [name: string]: AST._TableContract[] } {
    return Object.fromEntries(
      Object.entries(this.definitions).map(([name, def]) => {
        return [name, def.rel.assumptions()];
      })
    );
  }

  constructor(manifest: ManifestAST) {
    this.udds = manifest.udds ?? [];
    this.schemas = manifest.schemas;

    const topLevelDefs = _.mapValues(
      manifest.definitions,
      ({ rel, ...rest }, id) => ({
        ...rest,
        id,
        rel: Relation.fromAst(rel),
      })
    );

    this.definitions = topLevelDefs;
    this.materializations = manifest.materializations;
    this.detailPages = manifest.detailPages;
    this.apps = _.mapValues(manifest.apps, (app) => {
      const checked = TC.MU.checkApp(app);

      if (checked instanceof TC.TyStackTrace) {
        throw checked.toError({});
      }

      return checked;
    });
    this.eventStreams = manifest.eventStreams;
    this.preloadApps = manifest.preloadApps;
    this.scheduledJobs = [...manifest.scheduledJobs];
  }
}
