import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

export const ConfigSection: React.FC<
  ChildrenProps & { className?: string }
> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'flex flex-col mb-4 py-4 border-b border-divider relative',
        className
      )}
    >
      {children}
    </div>
  );
};
