import { Relation, AST, UI } from '@cotera/era';
import {
  AutomatedNotesOverview,
  AutomatedNoteTakingOverview,
} from './components';

export const AutomatedNoteTaking = ({
  CxData,
}: {
  CxData: Relation;
}): AST._App => {
  return UI.App({
    title: 'Automated Note Taking',
    icon: 'gift',
    pages: {
      '/:companyName': AutomatedNoteTakingOverview({ CxData }),
    },
    menu: [],
  });
};

export const AutomatedNotes = ({ CxData }: { CxData: Relation }): AST._App => {
  return UI.App({
    title: 'Automated Notes',
    icon: 'gift',
    pages: {
      '/:companyName/:reviewId': AutomatedNotesOverview({ CxData }),
    },
    menu: [],
  });
};
