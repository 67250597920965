import React from 'react';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import {
  Badge,
  Button,
  ButtonOption,
  ButtonOptions,
  Modal,
} from '@cotera/client/app/components/ui';
import { EXISTANCE_OPERATORS, Operator } from './constants';
import { ConnectiveSelect, GroupPreview, Value } from './components';
import {
  Connective,
  FilterGroup,
  FilterGroupManager,
  FilterItem,
  FilterItemManager,
  Option,
} from './types';
import { Assert } from '@cotera/utilities';
import { makeOperatorsForType, useFilterBuilder } from './hooks';
import { useFilterContext } from '../../contexts/filters';
import { Relation, TC, Ty } from '@cotera/era';
import { v4 } from 'uuid';
import { List } from '@cotera/client/app/components/headless';
import { debounce, omit } from 'lodash';
import hash from 'object-hash';
import { useOnPropChange } from '../../data-grid-2/hooks/use-on-props-change';

type ContextComponent = (props: {
  onClick: (value: any) => void;
}) => React.ReactNode;

export type FilterBuilderProps = {
  filterGroup?: FilterGroup;
  options: Option[];
  onRun: (props: { filterGroup: FilterGroup; limit?: number }) => void;
  runEnabled?: boolean;
  onChange?: (props: { filterGroup: FilterGroup; limit?: number }) => void;
  context?: Record<string, ContextComponent>;
  rel: Relation;
  autoRun?: boolean;
  onAutoRunChange?: (autoRun: boolean) => void;
  dangerouslyOptInToDynamicOptions?: boolean;
};

export const makeDefaultFilterGroup = (): FilterGroup => {
  return {
    connective: 'and',
    items: [],
  };
};

const useGlobalFilters = (options: Option[]): FilterItem[] => {
  const globalFilters = useFilterContext((s) => s.filters);

  return Object.entries(globalFilters)
    .filter(([_key, value]) => {
      return TC.isPrimitive(value.t);
    })
    .map(([key, value]) => {
      Assert.assert(
        value.t.k === 'primitive' ||
          value.t.k === 'enum' ||
          value.t.k === 'range' ||
          value.t.k === 'id',
        'Expected primitive, enum, id, or range'
      );
      const filter = {
        id: v4(),
        value: {
          key,
          t: value.t.t,
          value: value.values,
          operator: makeOperatorsForType(Ty.ty(value.t))[0]!,
        },
      };

      return filter;
    })
    .filter((f) => options.some((x) => x.attr === f.value.key));
};

export const FilterBuilder: React.FC<FilterBuilderProps> = ({
  filterGroup = makeDefaultFilterGroup(),
  options,
  onRun,
  runEnabled,
  onChange,
  context,
  rel,
  autoRun,
  onAutoRunChange,
  dangerouslyOptInToDynamicOptions,
}) => {
  const [limit, setLimit] = React.useState<number | undefined>(undefined);

  const [state, setState] = useFilterBuilder({
    filterGroup,
    options,
    onChange: (state) => {
      onChange?.({
        filterGroup: state,
        limit,
      });
      if (autoRun) {
        onRun({ filterGroup: state, limit });
      }
    },
  });

  const globalFilterItems = useGlobalFilters(options);

  useOnPropChange(
    JSON.stringify(globalFilterItems.map((x) => omit(x, 'id'))),
    () => {
      const globalFilterKeys = globalFilterItems
        .map((x) => x.value?.key)
        .filter((x) => x !== undefined);

      const items = [
        ...state.items.map((x) => {
          if (x.value !== undefined && globalFilterKeys.includes(x.value.key)) {
            return (
              globalFilterItems.find((y) => y.value?.key === x.value?.key) ?? x
            );
          }

          return x;
        }),
        ...globalFilterItems.filter(
          (x) => !state.items.some((y) => y.value?.key === x.value?.key)
        ),
      ];

      onChange?.({
        filterGroup: {
          ...state,
          items,
        },
        limit,
      });

      setState({
        ...state,
        items,
      });
    }
  );
  const topLevelFilters = state.items;
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  return (
    <form
      className="flex flex-row justify-between w-full"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex flex-row items-center flex-wrap space-x-2">
        <FilterPreview
          dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
          topLevelFilters={topLevelFilters}
          rel={rel}
          options={options}
          context={context}
          key={hash(state)}
          use={state.use}
          connective={state.connective}
          onShowAdvanced={() => {
            setShowAdvanced(true);
          }}
        />
      </div>
      <div className="flex flex-row items-start flex-shrink-0 space-x-2 pl-4 mb-2 h-full">
        {limit !== undefined && (
          <Inputs.Number
            label="Limit"
            icon="scissors"
            compact
            value={limit ?? 0}
            onChange={(v) => {
              setLimit(v);
              onChange?.({
                filterGroup: state,
                limit: v,
              });
              if (autoRun) {
                debounce(() => onRun({ filterGroup: state, limit: v }), 500);
              }
            }}
          />
        )}
        <Button
          icon="plus"
          iconOnly
          text="Add Filter"
          onClick={() => {
            state.add();
          }}
          options={
            <ButtonOptions>
              <ButtonOption
                as={Button}
                icon={'scissors'}
                text="Limit"
                inline
                onClick={() => {
                  setLimit(50_000);
                }}
              />
            </ButtonOptions>
          }
        />
        <Button
          icon="academic-cap"
          iconOnly
          text="Advanced"
          onClick={() => {
            setShowAdvanced(!showAdvanced);
          }}
        />
        <Button
          icon={autoRun ? 'arrow-path' : 'play'}
          theme="secondary"
          disabled={!runEnabled && !autoRun}
          iconOnly={!runEnabled && !autoRun}
          text={!autoRun ? 'Run' : 'Auto Run'}
          type="submit"
          onClick={() => {
            onRun({
              filterGroup: state,
              limit,
            });
          }}
          options={
            <List.Ul>
              <List.Li
                as={Button}
                inline
                theme="secondary"
                icon={autoRun ? 'x-mark' : 'check'}
                onClick={() => onAutoRunChange?.(!autoRun)}
                text={autoRun ? 'Disable auto run' : 'Enable auto run'}
              />
            </List.Ul>
          }
        />
      </div>
      <Modal
        contentClassName="w-1/2"
        priority="medium"
        open={showAdvanced}
        onClose={(v) => setShowAdvanced(v)}
      >
        <AdvancedFilterBuilder
          dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
          rel={rel}
          name="WHERE"
          filterGroup={state}
          options={options}
          onRun={() => {
            onRun({
              filterGroup: state,
              limit,
            });
          }}
        />
      </Modal>
    </form>
  );
};

const FilterPreview: React.FC<{
  topLevelFilters: FilterItemManager[];
  rel: Relation;
  options: Option[];
  context?: Record<string, ContextComponent>;
  connective: Connective;
  onShowAdvanced: () => void;
  use: (connective: Connective) => void;
  dangerouslyOptInToDynamicOptions?: boolean;
}> = ({
  topLevelFilters,
  options,
  rel,
  context,
  connective,
  use,
  onShowAdvanced,
  dangerouslyOptInToDynamicOptions,
}) => {
  const filters = topLevelFilters.map((f, i) => {
    if (f.value) {
      return (
        <FilterView
          dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
          rel={rel}
          key={`${f.value.key} + ${i}`}
          display="compact"
          item={f}
          options={options}
          context={(context ?? {})[f.value.key]}
        />
      );
    }
    Assert.assert(f.group !== undefined, 'Expected group');

    return (
      <GroupPreview
        key={f.id}
        name={f.group.name ?? 'Group'}
        onClick={() => {
          onShowAdvanced();
        }}
      />
    );
  });

  return (
    <>
      <ConnectiveSelect
        className="mx-2 mb-2 !ml-0"
        value={connective}
        onChange={use}
      />
      {filters}
    </>
  );
};

const FilterViewRow: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <div className="space-x-2 mb-2 flex flex-row items-center">{children}</div>
  );
};

const Context: React.FC<{
  context?: ContextComponent;
  ty: Ty.ExtendedAttributeType;
  f: FilterItemManager;
}> = ({ context, ty, f }) =>
  context
    ? context({
        onClick: (value) => {
          if (TC.isNumeric(ty)) {
            f.update({
              value: value,
              operator: 'between',
            });
          }
          if (TC.isStringLike(ty)) {
            f.update({
              value: value,
              operator: 'one-of',
            });
          }
        },
      })
    : null;

const FilterView: React.FC<{
  item: FilterItemManager;
  options: Option[];
  display?: 'compact' | 'full';
  context?: ContextComponent;
  rel: Relation;
  dangerouslyOptInToDynamicOptions?: boolean;
}> = ({
  item: f,
  options,
  display = 'full',
  context,
  rel,
  dangerouslyOptInToDynamicOptions,
}) => {
  const getOptionsForAttr = (attr: string) => {
    return options.find((o) => o.attr === attr);
  };

  const getDefaultOperatorForAttr = (attr: string) => {
    return makeOperatorsForType(getOptionsForAttr(attr)!.t)[0];
  };
  Assert.assert(f.value !== undefined, 'Expected value');
  const tyForAttr = options.find((o) => o.attr === f.value?.key)!.t;
  const optionsForAttr =
    options.find((o) => o.attr === f.value?.key)?.values ?? [];
  const removeGlobalFilter = useFilterContext((s) => s.actions.remove);

  if (f.value?.compact) {
    return (
      <FilterViewRow>
        <Value
          dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
          name={f.value.key}
          attr={f.value.key}
          rel={rel}
          value={f.value.value}
          operator={f.value.operator}
          onChange={(value) => {
            f.update({
              value,
            });
          }}
          t={tyForAttr}
          options={optionsForAttr}
        />
        {context && <Context context={context} ty={tyForAttr} f={f} />}
      </FilterViewRow>
    );
  }

  return (
    <FilterViewRow>
      <ComboBox.Single
        compact
        onChange={(value) => {
          const optionsForAttr =
            options.find((o) => o.attr === value!.value)?.values ?? [];

          f.update({
            key: value!.value,
            operator: getDefaultOperatorForAttr(value!.value),
            value: [optionsForAttr[0] ?? null],
          });
        }}
        value={{
          display: f.value.key,
          value: f.value.key,
        }}
        options={(props) => (
          <ComboBox.StaticOptions
            {...props}
            dynamicEnabled={false}
            options={options.map((o) => ({ value: o.attr, display: o.attr }))}
          />
        )}
      />
      <ComboBox.Select
        compact
        key={f.value.key}
        onChange={(value) => {
          f.update({
            operator: value!.value as Operator,
          });
        }}
        value={{
          display: f.value.operator,
          value: f.value.operator,
        }}
        options={makeOperatorsForType(tyForAttr).map((x) => ({
          value: x,
          display: x,
        }))}
      />
      {!EXISTANCE_OPERATORS.includes(f.value.operator as any) && (
        <div
          className={classNames(
            display === 'compact' ? 'w-fit flex-wrap' : 'w-auto'
          )}
        >
          <Value
            dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
            rel={rel}
            attr={f.value.key}
            value={f.value.value}
            operator={f.value.operator}
            onChange={(value) => {
              f.update({
                value,
              });
            }}
            t={tyForAttr}
            options={optionsForAttr}
          />
        </div>
      )}
      {context && <Context context={context} ty={tyForAttr} f={f} />}
      <Button
        icon="trash"
        iconOnly
        text="Remove Filter"
        compact
        inline
        theme="error"
        onClick={() => {
          f.remove();
          if (f.value) {
            removeGlobalFilter(f.value.key);
          }
        }}
      />
    </FilterViewRow>
  );
};

const GroupView = ({
  group,
  depth = 0,
  options,
  rel,
  fillRow,
  dangerouslyOptInToDynamicOptions,
}: {
  group: FilterGroupManager;
  depth?: number;
  options: Option[];
  rel: Relation;
  fillRow?: boolean;
  dangerouslyOptInToDynamicOptions?: boolean;
}) => {
  if (depth > 5) {
    return null;
  }
  const items = group.items.map((item, i) => {
    if (item.value) {
      return (
        <FilterView
          rel={rel}
          key={i}
          item={item}
          options={options}
          dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
        />
      );
    }
    Assert.assert(item.group !== undefined, 'Expected group');

    return item.group.items.length > 0 ? (
      <GroupView
        dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
        rel={rel}
        depth={depth + 1}
        key={hash(item.group)}
        group={item.group}
        options={options}
        fillRow={fillRow}
      />
    ) : null;
  });
  return (
    <div
      className={classNames(
        'flex border-l-4 px-2 mb-2 rounded',
        fillRow ? 'flew-row flex-wrap w-full' : 'flex-col',
        depth % 2 === 0 ? 'border-emerald-300' : 'border-indigo-300'
      )}
    >
      <ConnectiveSelect
        className="mb-2 mr-2"
        value={group.connective}
        onChange={(value) => group.use(value)}
      />
      {items}
      <div className="flex flex-row items-start flex-shrink-0 space-x-2">
        <Button
          icon="plus"
          iconOnly
          text="Add Filter"
          onClick={() => {
            group.add();
          }}
        />
        <Button
          icon="folder-plus"
          iconOnly
          text="Add Group"
          onClick={() => {
            group.addGroup();
          }}
        />
      </div>
    </div>
  );
};

export const AdvancedFilterBuilder: React.FC<{
  filterGroup: FilterGroupManager;
  options: Option[];
  onRun?: () => void;
  rel: Relation;
  showRun?: boolean;
  name?: string;
  fillRow?: boolean;
  dangerouslyOptInToDynamicOptions?: boolean;
}> = ({
  options,
  filterGroup,
  onRun,
  rel,
  showRun = true,
  name,
  fillRow,
  dangerouslyOptInToDynamicOptions,
}) => {
  return (
    <div className="flex flex-col">
      {name && (
        <Badge className="mb-4" theme="primary">
          {name}
        </Badge>
      )}
      <GroupView
        dangerouslyOptInToDynamicOptions={dangerouslyOptInToDynamicOptions}
        key={hash(filterGroup)}
        rel={rel}
        options={options}
        group={filterGroup}
        fillRow={fillRow}
      />
      {showRun && (
        <div className="flex flex-row items-center justify-end mt-4">
          <Button
            icon="play"
            theme="secondary"
            compact
            text="Run"
            className="w-fit"
            onClick={onRun}
          />
        </div>
      )}
    </div>
  );
};
