import {
  CountDistinct,
  ExprVar,
  If,
  M,
  Relation,
  Sum,
  SumOver,
  UI,
  Avg,
  Desc,
  f,
  MakeStruct,
} from '@cotera/era';
import { Stat } from 'libs/era/src/lib/builder/markup/chart';
import _ from 'lodash';

export const snakeToTitle = (s: string) => {
  return s
    .split('_')
    .map((w) => w[0]!.toUpperCase() + w.slice(1))
    .join(' ');
};

const StatThird = (stat: Stat) => UI.Third(UI.Stats([stat]));

export const TicketSummaryStats = (rel: Relation, maxScore: Relation) => {
  const TotalTickets = rel.summary((t) => ({
    count: CountDistinct(t.attr('id')),
  }));

  const TicketScore = rel
    .groupBy((t) => t.pick('id'))
    .select((t) => ({
      ...t.group(),
      score: Sum(If(t.attr('value').eq('PERFECT'), { then: 1, else: 0 })),
    }))
    .leftJoin(maxScore, (t, score) => ({
      on: true,
      select: {
        ...t.star(),
        ...score.pick('MAX_SCORE'),
      },
    }));

  const TotalPerfectTickets = TicketScore.where((t) =>
    t.attr('score').eq(t.attr('MAX_SCORE'))
  ).summary((t) => ({
    count: CountDistinct(t.attr('id')),
  }));

  const AvgImperfections = TicketScore.summary((t) => ({
    count: Avg(t.attr('MAX_SCORE').sub(t.attr('score'))),
  }));

  return UI.Block([
    UI.Row([
      StatThird(
        TotalTickets.chart.Stat((t) => ({
          value: t.attr('count'),
          title: 'Total Tickets',
        }))
      ),
      StatThird(
        TotalPerfectTickets.chart.Stat((t) => ({
          value: t.attr('count'),
          title: 'Total Perfect Tickets',
          style: 'positive',
        }))
      ),
      StatThird(
        AvgImperfections.chart.Stat((t) => ({
          value: t.attr('count'),
          title: 'Average Imperfections per Ticket',
        }))
      ),
    ]),
  ]);
};

export const DailyTrendWithSummary = (rel: Relation) =>
  UI.Block(
    [
      UI.Row([
        UI.TwoThirds(
          rel
            .where((t) => t.attr('value').isDistinctFrom('PERFECT'))
            .countBy((t) => ({
              DATE: t.attr('created_at').dateTrunc('day'),
              ...t.pick('category'),
            }))
            .chart.BarChart(
              (t) => ({
                x: t.attr('DATE'),
                y: t.attr('COUNT'),
                ...t.pick('category'),
              }),
              {
                axis: {
                  x: {
                    scale: 'day',
                  },
                },
              }
            )
        ),
        UI.Third(
          rel
            .where((t) => t.attr('value').isDistinctFrom('PERFECT'))
            .countBy((t) => t.pick('category'))
            .chart.SummaryChart((t) => ({
              category: t.attr('category'),
              value: t.attr('COUNT'),
            }))
        ),
      ]),
    ],
    { title: 'Imperfect Tickets Overview' }
  );

export const AgentStats = (rel: Relation, agent_toggle: ExprVar) => {
  const AgentImperfectTicketbyCategoryCount = rel
    .where((t) => t.attr('value').isDistinctFrom('PERFECT'))
    .countBy((t) => t.pick('agent', 'category'));

  const AgentTotalTickets = rel.countBy((t) => t.pick('agent', 'category'));

  return UI.Block(
    [
      agent_toggle.TabSelector(),
      M.muCase([
        {
          when: agent_toggle.eq('Count of Imperfect Tickets'),
          then: AgentImperfectTicketbyCategoryCount.select((t) => ({
            ...t.star(),
            AGENT_TOTAL: SumOver(t.attr('COUNT'), {
              partitionBy: t.attr('agent'),
            }),
          }))

            .orderBy((t) => t.attr('AGENT_TOTAL').asc())
            .chart.BarChart(
              (t) => ({
                x: t.attr('agent'),
                y: t.attr('COUNT'),
                category: t.attr('category'),
              }),
              {
                axis: {
                  y: {
                    label: 'Count of Imperfect Tickets',
                  },
                },
                direction: 'horizontal',
              }
            ),
        },
        {
          when: agent_toggle.eq('Proportion of Imperfect Tickets'),
          then: AgentImperfectTicketbyCategoryCount.select((t) => ({
            ...t.star(),
            AGENT_TOTAL: SumOver(t.attr('COUNT'), {
              partitionBy: t.attr('agent'),
            }),
            PERCENT: t
              .attr('COUNT')
              .safeDiv(
                SumOver(t.attr('COUNT'), { partitionBy: t.attr('agent') })
              )
              .mul(100),
          }))
            .orderBy((t) => t.attr('AGENT_TOTAL').asc())
            .chart.BarChart(
              (t) => ({
                x: t.attr('agent'),
                y: t.attr('PERCENT'),
                category: t.attr('category'),
              }),
              {
                axis: {
                  y: {
                    label: 'Proportion of Imperfect Tickets (%)',
                  },
                },
                direction: 'horizontal',
              }
            ),
        },
        {
          when: agent_toggle.eq('Imperfect Proportion of Total Tickets'),
          then: [
            AgentImperfectTicketbyCategoryCount.select((t) => ({
              ...t.star(),
              AGENT_TOTAL: SumOver(t.attr('COUNT'), {
                partitionBy: t.attr('agent'),
              }),
            }))
              .leftJoin(AgentTotalTickets, (t, total) => ({
                on: t
                  .attr('agent')
                  .eq(total.attr('agent'))
                  .and(t.attr('category').eq(total.attr('category'))),
                select: {
                  ...t.star(),
                  TOTAL_TICKETS: total.attr('COUNT'),
                  PROPORTION_OF_TOTAL: t
                    .attr('COUNT')
                    .safeDiv(total.attr('COUNT'))
                    .mul(100),
                },
              }))
              .orderBy((t) => t.attr('AGENT_TOTAL').desc())
              .chart.BarChart(
                (t) => ({
                  x: t.attr('agent'),
                  y: t.attr('PROPORTION_OF_TOTAL'),
                  category: t.attr('category'),
                }),
                {
                  axis: {
                    y: {
                      label: 'Proportion of All Tickets (%)',
                    },
                  },
                  type: 'grouped',
                }
              ),
          ],
        },
      ]),
    ],
    { title: 'Performance by Agent' }
  );
};

export const ImperfectionProportionByDay = (
  rel: Relation,
  maxScore: Relation
) => {
  const TicketScore = rel
    .groupBy((t) => ({
      DATE: t.attr('created_at').dateTrunc('day'),
      ...t.pick('id'),
    }))
    .select((t) => ({
      ...t.group(),
      score: Sum(If(t.attr('value').eq('PERFECT'), { then: 1, else: 0 })),
    }))
    .leftJoin(maxScore, (t, score) => ({
      on: true,
      select: {
        ...t.star(),
        ...score.pick('MAX_SCORE'),
      },
    }));

  const AvgImperfectionsPerDay = TicketScore.groupBy((t) =>
    t.pick('DATE')
  ).select((t) => ({
    ...t.group(),
    count: Avg(t.attr('MAX_SCORE').sub(t.attr('score'))),
  }));

  return UI.Block(
    [
      AvgImperfectionsPerDay.chart.LineChart(
        (t) => ({
          x: t.attr('DATE'),
          y: t.attr('count'),
          category: 'Average Imperfections per Ticket',
        }),
        {
          axis: {
            x: {
              scale: 'day',
            },
          },
        }
      ),
    ],
    { title: 'Average Imperfections per Ticket by Day' }
  );
};

const summaryPrompt = `You are an analyst for a company that wants to monitor customer interactions with its support team.
                    You are tasked with performing quality assurance on the outputs of agent interactions with customers, specifically evaluating the tickets that had imperfect interaction scores.
                    You are looking at the ways in which agents are handling different aspects of the coversation, such as politeness, personalization, and effectiveness.

                    You should look for trends in any of the columns, but specifically look for trends in the the types of interaction errors you are seeing. 
                    Explicitly mention specific issues that are causing dissatisfaction. 
                    Keep response to strictly the facts of the data, do not infer or speculate on the impacts of the comments, or talk about the customer's feelings towards what happened.
                    We are looking for insights that can be used to improve the customer experience.

                    Give the response in this format:

                    short summary

                    **insight 1** - (percentage of tickets)
                    short explainer

                    **insight 2** - (percentage of tickets)
                    short explainer

                    **insight 3** - (percentage of tickets)
                   short explainer
                    `;

export const TicketSample = (rel: Relation) => {
  const previous1000 = rel
    .where((t) => t.attr('value').isDistinctFrom('PERFECT'))
    .orderBy((t) => Desc(t.attr('created_at')))
    .limit(1000)
    .select((t) => t.pick('id', 'value'));

  const relWithDetail = rel.select((t) => ({
    'Conversation Details': MakeStruct({
      __link: f`/apps/conversation-detail/${t.attr('id')}/`,
      text: f`${t.attr('id')}`,
      action: 'tab',
    }),
    ...t.star(),
  }));

  return UI.Block(
    [
      UI.Block(
        [
          previous1000.chart.LLMSummary({
            prompt: summaryPrompt,
            model: 'gpt-4o-mini',
          }),
        ],
        { title: 'Insights' }
      ),
      UI.Block([relWithDetail], { title: 'Ticket Sample' }),
    ],
    { title: 'Tickets Overview' }
  );
};
