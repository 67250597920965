import { TC, Ty, RelationRef, Constant, Relation, SQL } from '@cotera/era';
import {
  BaseWorkspaceNodeViewModel,
  NodeTransform,
  TransformableRelation,
} from './base';
import { v4 } from 'uuid';
import { mapValues, merge, uniqBy } from 'lodash';
import { FilterNodeViewModel } from './filter';
import { FilterItem } from '@cotera/client/app/components/app/filter-builder/types';
import { ManagedDataGrid } from '../../../data-grid-2/data-grid';
import { DuckDBQueryResult } from '@cotera/client/app/etc/duckdb';
import { Filter } from '@cotera/client/app/components/data-grid-2/types';
import { Assert } from '@cotera/utilities';

const DEFAULT_LOCAL_STORAGE_DATA = {
  hiddenColumns: [],
};

export class SampleViewModel extends BaseWorkspaceNodeViewModel {
  readonly t = 'sample';
  override transforms: NodeTransform[] = [];
  override parent: BaseWorkspaceNodeViewModel;
  private _sort: { column: string; direction: 'asc' | 'desc' }[] = [];
  private _selectedRows: number[] = [];
  private _data: DuckDBQueryResult | null = null;
  private _filters: Record<string, Filter> = {};
  private _loadedRows: number = 0;
  private _inViewRows: number[] = [];
  private _columnConfig: {
    name: string;
    visible: boolean;
  }[] = [];
  private _rel: TransformableRelation;

  constructor(
    name: string,
    position: { x: number; y: number },
    parent: BaseWorkspaceNodeViewModel,
    private opts: {
      syncFilters?: boolean;
    } = {}
  ) {
    super(name, v4(), position);
    this.parent = parent;
    this._rel = parent.rel;

    const defaultConfig = {
      columnConfig: sortWithIdPriority(Object.keys(this.attributes)).map(
        (x, i) => ({
          name: x,
          visible: i < 10,
        })
      ),
    };

    const savedData: {
      columnConfig: { name: string; visible: boolean }[];
    } = merge(
      defaultConfig,
      JSON.parse(localStorage.getItem(`@@cotera-data-grid-${name}`) ?? '{}')
    );

    const recognisedKeys = savedData.columnConfig.map((c) => c.name);
    this._columnConfig = [
      ...savedData.columnConfig,
      ...Object.keys(parent.rel.attributes)
        .filter((key) => !recognisedKeys.includes(key))
        .map((x) => ({
          name: x,
          visible: false,
        })),
    ];

    parent.subscribe(() => {
      this._rel = parent.rel.apply(this.transforms);
      this.notifySubscribers();
    });
  }

  override setTransform(fn: (rel: Relation) => Relation, type: string): void {
    super.setTransform(fn, type, false);
    this._rel = this.parent.rel.apply(this.transforms);

    this.notifySubscribers();
  }

  override getRel(type: 'source' | 'artifact'): Relation {
    return this.parent.getRel(type);
  }

  get artifactId() {
    return this.parent.artifactId;
  }

  get baseRel(): TransformableRelation {
    return this.parent.rel;
  }

  get rel(): TransformableRelation {
    return this._rel;
  }

  get columns(): string[] {
    return this.columnConfig.map((c) => c.name);
  }

  get attributes() {
    return this.baseRel.attributes;
  }

  get hiddenItems(): string[] {
    return this.columnConfig.filter((c) => !c.visible).map((c) => c.name);
  }

  get selectedRows() {
    return this._selectedRows;
  }

  get visibleColumns(): string[] {
    return this.columnConfig.filter((c) => c.visible).map((c) => c.name);
  }

  get data() {
    return this._data;
  }

  get filters() {
    return this._filters;
  }

  get sort() {
    return this._sort;
  }

  get totalRowCount() {
    return this.data?.length ?? 0;
  }

  get loadedRows() {
    return this._loadedRows;
  }

  get inViewRows() {
    return this._inViewRows;
  }

  get columnConfig() {
    return uniqBy(
      this._columnConfig.filter((x) => this.hasColumn(x.name)),
      (c) => c.name
    );
  }

  addColumn(name: string) {
    this._columnConfig = [
      ...this.columnConfig,
      {
        name,
        visible: true,
      },
    ];

    this.updateColumnConfig(this._columnConfig);
  }

  hasColumn(name: string) {
    return this.attributes[name] !== undefined;
  }

  setInViewRows(rows: number[]) {
    this._inViewRows = rows;
    this.notifySubscribers();
  }

  setLoadedRows(rows: number) {
    this._loadedRows = rows;
    this.notifySubscribers();
  }

  setData(data: DuckDBQueryResult) {
    this._data = data;
    this.notifySubscribers();
  }

  addSort(column: string, direction: 'asc' | 'desc') {
    //preserve sort position if column is already sorted
    const exists = this._sort.find((s) => s.column === column);
    this._sort = this._sort
      .map((s) => {
        if (s.column === column) {
          return {
            column,
            direction,
          };
        }
        return s;
      })
      .concat(exists ? [] : [{ column, direction }]);

    this.setSort(this._sort);
  }

  removeSort(column: string) {
    this._sort = this._sort.filter((s) => s.column !== column);
    this.setSort(this._sort);
  }

  setSort(columns: { column: string; direction: 'asc' | 'desc' }[]) {
    this._sort = columns;

    this.setTransform((rel) => {
      return rel.orderBy((t) =>
        this._sort.map((s) => ({
          expr: t.attr(s.column),
          direction: s.direction,
        }))
      );
    }, 'sort');
  }

  setSelectedRows(rows: number[]) {
    this._selectedRows = rows;
    this.notifySubscribers();
  }

  showColumn(...columns: string[]) {
    this._columnConfig = this.columnConfig.map((c) => {
      if (columns.includes(c.name)) {
        return {
          ...c,
          visible: true,
        };
      }

      return c;
    });

    this.updateColumnConfig(this._columnConfig);
  }

  hideColumn(...columns: string[]) {
    this._columnConfig = this.columnConfig.map((c) => {
      if (columns.includes(c.name)) {
        return {
          ...c,
          visible: false,
        };
      }

      return c;
    });

    this.updateColumnConfig(this._columnConfig);
  }

  moveColumn(from: number, to: number) {
    const visibleColumns = this.columnConfig.filter((x) => x.visible);
    const hiddenColumns = this.columnConfig.filter((x) => !x.visible);
    const [removed] = visibleColumns.splice(from, 1);
    visibleColumns.splice(to, 0, removed!);
    this._columnConfig = visibleColumns;

    this.updateColumnConfig([...visibleColumns, ...hiddenColumns]);
  }

  filter(filters: Record<string, Filter>) {
    this._filters = filters;
    if (this.shouldSyncFilters(filters)) {
      return this.filterParent(filters);
    } else {
      return this.filterBasic(filters);
    }
  }

  private updateColumnConfig(config: { name: string; visible: boolean }[]) {
    this._columnConfig = [
      ...config.filter((c) => c.visible),
      ...config.filter((c) => !c.visible),
    ];

    this.syncToLocalStorage();

    this.setTransform((rel) => {
      return rel.select((t) => ({
        ...t.except(...this.hiddenItems),
      }));
    }, 'visibility');
  }

  isRelReady() {
    const mode = Assert.assertOk(SQL.originForIR(this.rel.ir()));

    return mode === 'file' || mode === null;
  }

  private syncToLocalStorage() {
    const existing = JSON.parse(
      localStorage.getItem(`@@cotera-data-grid-${this.name}`) ??
        JSON.stringify(DEFAULT_LOCAL_STORAGE_DATA)
    );

    localStorage.setItem(
      `@@cotera-data-grid-${this.name}`,
      JSON.stringify({
        ...existing,
        columnConfig: this.columnConfig,
      })
    );
  }

  private shouldSyncFilters(filters: Record<string, Filter>): boolean {
    if (this.opts.syncFilters) {
      const filterParent = this.findParentOfType(this, 'filter');

      const typeRequirements = mapValues(filters, (filter) => {
        switch (filter.t) {
          case 'search':
          case 'one-of':
            return [Ty.ty('string')];
          case 'range':
            return [Ty.ty('int'), Ty.ty('float')];
          case 'is_null':
          case 'is_not_null':
            return [];
        }
      });

      return (
        filterParent !== undefined &&
        TC.implementsRel({
          reqs: typeRequirements,
          subject: filterParent.rel.attributes,
        }).isOk()
      );
    }

    return false;
  }

  private filterParent(filters: Record<string, Filter>) {
    const filterParent = this.findParentOfType(this, 'filter');

    const typeRequirements = mapValues(filters, (filter) => {
      switch (filter.t) {
        case 'search':
        case 'one-of':
          return [Ty.ty('string')];
        case 'range':
          return [Ty.ty('int'), Ty.ty('float')];
        case 'is_null':
        case 'is_not_null':
          return [];
      }
    });

    if (
      filterParent &&
      TC.implementsRel({
        reqs: typeRequirements,
        subject: filterParent.rel.attributes,
      }).isOk()
    ) {
      const existingFilters = filterParent as FilterNodeViewModel;
      const newFilters: FilterItem[] = Object.entries(filters).map(
        ([key, value]) => {
          const existingItem = existingFilters.filters.items.find(
            (item) => item.value?.key === key
          ) ?? {
            id: v4(),
            value: {
              key,
            },
          };
          switch (value.t) {
            case 'one-of':
              return {
                ...existingItem,
                value: {
                  key,
                  value: value.values,
                  operator: 'one-of' as const,
                },
              };
            case 'range':
              return {
                ...existingItem,
                value: {
                  key,
                  value: [value.min, value.max],
                  operator: 'between' as const,
                },
              };
            case 'search':
              return {
                ...existingItem,
                value: {
                  key,
                  value: [value.value],
                  operator: 'contains' as const,
                },
              };
            case 'is_null':
              return {
                ...existingItem,
                value: {
                  key,
                  value: [null],
                  operator: 'does not exist' as const,
                },
              };
            case 'is_not_null':
              return {
                ...existingItem,
                value: {
                  key,
                  value: [null],
                  operator: 'exists' as const,
                },
              };
          }
        }
      );
      return existingFilters.updateFilters({
        ...existingFilters.filters,
        items: [...existingFilters.filters.items, ...newFilters],
      });
    }
  }

  private filterBasic(filters: Record<string, Filter>) {
    const makeFilters = (t: RelationRef) => {
      return Object.entries(filters).reduce((acc, [key, value]) => {
        switch (value.t) {
          case 'one-of':
            return acc.and(
              t
                .attr(key)
                .oneOf(
                  value.values.map((v) =>
                    Constant(v).cast(this.attributes[key]!)
                  )
                )
            );
          case 'range':
            return acc.and(
              t
                .attr(key)
                .cast('float')
                .gte(Constant(value.min).cast('float'))
                .and(
                  t
                    .attr(key)
                    .cast('float')
                    .lte(Constant(value.max).cast('float'))
                )
            );
          case 'search':
            return acc.and(t.attr(key).like(`%${value.value}%`));
          case 'is_null':
            return acc.and(t.attr(key).isNull());
          case 'is_not_null':
            return acc.and(t.attr(key).isNotNull());
          default:
            throw new Error('Invalid filter type');
        }
      }, Constant(true));
    };

    this.setTransform((rel) => rel.where((t) => makeFilters(t)), 'filter');
  }
}

export const SampleNode = {
  ViewModel: SampleViewModel,
  View: ManagedDataGrid,
};

function sortWithIdPriority(items: string[]): string[] {
  return items.sort((a, b) => {
    // Prioritize 'id' and '__id' specifically
    if (a === 'id' || a === '__id') return -1;
    if (b === 'id' || b === '__id') return 1;

    // Items containing 'id' should come before those that do not
    const aHasId = a.toLowerCase().includes('id');
    const bHasId = b.toLowerCase().includes('id');

    if (aHasId && !bHasId) return -1;
    if (!aHasId && bHasId) return 1;

    // Alphabetical sort for remaining items
    return a.localeCompare(b);
  });
}
