import { err, ok, Result } from 'neverthrow';
import { Ty } from '../ty';
import { CompileOutput, ParseCtx, ParseError, ParseRes } from './eraql-ast/ast';
import { WithMeta } from './lex/types';
import { AST } from '../ast';
import { TokenStream } from './lex';
import { buildEraQLAst } from './eraql-ast/build-eraql-ast';
import { compileAst } from './eraql-ast/compile';

type Res<T> = Result<T, WithMeta<ParseError & { original: string }>>;

export const parseTy = (
  str: string,
  ctx?: ParseCtx
): Res<Ty.ExtendedAttributeType> =>
  parse(str, ctx).andThen((res) => {
    switch (res.item.t) {
      case 'ty':
        return ok(res.item.ty);
      case 'expr':
      case 'rel':
        return err([
          { t: 'expected', expected: 'type', original: str },
          { range: [0, str.length] },
        ] as const);
    }
  });

export const parseExpr = (str: string, ctx?: ParseCtx): Res<AST.ExprIR> =>
  parse(str, ctx).andThen((res) => {
    switch (res.item.t) {
      case 'expr':
        return ok(res.item.expr);
      case 'ty':
      case 'rel':
        return err([
          { t: 'expected', expected: 'expression', original: str },
          { range: [0, str.length] },
        ] as const);
    }
  });

export const parseRel = (str: string, ctx?: ParseCtx): Res<AST.RelIR> =>
  parse(str, ctx).andThen((res) => {
    switch (res.item.t) {
      case 'rel':
        return ok(res.item.rel);
      case 'ty':
      case 'expr':
        return err([
          { t: 'expected', expected: 'rel', original: str },
          { range: [0, str.length] },
        ] as const);
    }
  });

export const parse = (str: string, ctx?: ParseCtx): Res<CompileOutput> => {
  const lex = new TokenStream(str);
  const result: Res<CompileOutput> = buildEraQLAst(lex, 0)
    .andThen(
      (eraAst): ParseRes<CompileOutput> =>
        eraAst !== null
          ? compileAst(eraAst, ctx ?? {})
          : err([{ t: 'unexpected-eof' }, { range: [0, 0] }])
    )
    .mapErr(
      ([err, meta]): WithMeta<ParseError & { original: string }> => [
        { ...err, original: lex.originalForMeta(meta) },
        meta,
      ]
    );

  if (result.isErr()) {
    return err(result.error);
  }

  const next = lex.consume();
  if (next !== null) {
    return err([
      {
        t: 'unexpected-token',
        original: lex.originalForMeta(next[1]),
        debug: 'foo',
      },
      next[1],
    ]);
  }

  return result;
};
