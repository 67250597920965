import { makeQaModelApp } from '@cotera/customers/apps/qa-model/app';
import { From } from '@cotera/era';
import { DEMO_QA_MODEL } from '../../assumptions';
import { ConversationDetail } from '@cotera/customers/apps/qa-model/conversation-detail/app';

export const config = [
  {
    category: 'Spelling',
    sub_category: 'SPELLING',
  },
  {
    category: 'Complete Resolution',
    sub_category: 'COMPLETE_RESOLUTION',
  },
  {
    category: 'Positive Language',
    sub_category: 'POSITIVE_LANGUAGE',
  },
  {
    category: 'Empathy Ownership',
    sub_category: 'EMPATHY_OWNERSHIP',
  },
];

export const QaAppInput = From(DEMO_QA_MODEL).select((t) => ({
  ...t.star(),
  created_at: t.attr('TIMESTAMP'),
  content: t.attr('CLEANED_DATA'),
}));

export const QaModelTaggingApp = makeQaModelApp({
  rel: QaAppInput,
  title: 'Quality Assurance Evaluation',
  config,
  quickFilters: [
    {
      t: 'timestamp',
      left: 'created_at',
      right: [new Date('2024-11-01'), new Date()],
      operator: 'between',
    },
  ],
});

export const ConversationDetailApp = ConversationDetail({
  QaAppInput,
  config,
  cleanedMessages: 'cleaned_data',
});
