import { Text, Loading } from '@cotera/client/app/components/ui';
import { useFivetranConnectors } from '../../hooks/use-fivetran-connections';
import { Connector } from './connector';
import { Link } from 'react-router-dom';
import { Layout } from '@cotera/client/app/layout';

type FivetranDestinationAndConnectors = {
  groupId: string;
  connectors: {
    id: string;
    connector: string;
    config?: any;
  }[];
};

export const ListSourcesPage: React.FC = () => {
  const { data, error, isLoading, isError } = useFivetranConnectors();

  if (isLoading || data === undefined) {
    return (
      <Layout>
        <Loading.Dots />
      </Layout>
    );
  }

  if (isError && error !== null) {
    return <Layout>err</Layout>;
  }

  return (
    <Layout className="flex flex-col">
      <ConnectorsWrapper fivetranConnectors={data} />
    </Layout>
  );
};

const ConnectorsWrapper: React.FC<{
  fivetranConnectors: FivetranDestinationAndConnectors | null;
}> = ({ fivetranConnectors }) => {
  const connectors = fivetranConnectors?.connectors ?? [];
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Text.Title type="title">Data Sources</Text.Title>
      <Text.P>Connect your data sources to Cotera.</Text.P>
      <div className="pb-4">
        <Link
          to="new"
          className="bg-gray-700 text-gray-50 hover:bg-gray-800 px-4 py-3 rounded text-sm font-medium"
        >
          New Data Source
        </Link>
      </div>

      <div className="py-2 mt-4 border-t border-gray-200">
        <Text.Title type="section" className="mb-4">
          Connected Data Sources
        </Text.Title>
        <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
          {connectors.map((c) => (
            <Connector
              key={c.id}
              connector={c.connector}
              alreadyConnected={true}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
