import React, { Suspense } from 'react';
import {
  Button,
  Divider,
  Title,
  Text,
  Icon,
  Tooltip,
  Loading,
} from '@cotera/client/app/components/ui';
import { ChildrenProps, classNames } from '../../utils';
import { Inputs } from '../../forms';
import { Ty } from '@cotera/era';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { downloadFile, jsonToCsv } from '@cotera/client/app/components/utils';
import { useSubscribe } from '@cotera/client/app/etc';
import { DataGridViewModel, Registry } from '../types';
import { isEqual } from 'lodash';
import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ShowSql, useSideModal } from '@cotera/client/app/components/app';
import { Tabs } from '@cotera/client/app/components/headless';
import { Link } from 'react-router-dom';
import { useInvalidateArtifactById } from '@cotera/client/app/hooks/artifacts';

export const ManageDataGridView: React.FC<{
  vm: DataGridViewModel;
  registry: Registry;
}> = (props) => {
  return (
    <Tabs.Container mode="controlled" active="properties">
      <Tabs.Panel border={false}>
        <Tabs.Tab id="properties" text="Columns" />
        <Tabs.Tab id="actions" text="Actions" />
      </Tabs.Panel>
      <Divider />
      <Tabs.Content id="properties">
        <Properties {...props} />
      </Tabs.Content>
      <Tabs.Content id="actions">
        <ActionsView {...props} />
      </Tabs.Content>
    </Tabs.Container>
  );
};

const ActionsView: React.FC<{
  vm: DataGridViewModel;
}> = ({ vm }) => {
  const data = useSubscribe(vm, (s) => s.data);
  const [downloading, setDownloading] = React.useState(false);
  const artifactId = useSubscribe(vm, (s) => s.artifactId);
  const push = useSideModal((s) => s.actions.push);
  const sourceRel = useSubscribe(vm, (s) => s.getRel('source'));

  const invalidateArtifact = useInvalidateArtifactById({
    id: artifactId,
  });

  return (
    <>
      <ul className="mb-2 space-y-4">
        <li className="flex justify-between items-center">
          <Button
            loading={downloading}
            icon="arrow-down"
            text="Download as CSV"
            small
            onClick={async () => {
              setDownloading(true);
              downloadFile([await jsonToCsv(data?.toArray() ?? [])], 'csv');
              setDownloading(false);
            }}
          />
        </li>
        <li className="flex justify-between items-center">
          <Button
            icon="film"
            link={{
              to: `/artifacts/${artifactId}`,
            }}
            text="View Source Artifact"
            small
          />
        </li>
        <li className="flex justify-between items-center">
          <Button
            loading={invalidateArtifact.isPending}
            icon="arrow-path"
            text="Refresh Artifact"
            small
            onClick={async () => {
              await invalidateArtifact.mutateAsync();
            }}
          />
        </li>
        <li className="flex justify-between items-center">
          <Button
            icon="code-bracket"
            text="View SQL"
            small
            onClick={() => {
              push({
                view: () => (
                  <Suspense fallback={<Loading.Dots />}>
                    <ShowSql rel={sourceRel} />
                  </Suspense>
                ),
              });
            }}
          />
        </li>
      </ul>
    </>
  );
};

const Properties: React.FC<{
  vm: DataGridViewModel;
  registry: Registry;
}> = ({ vm, registry }) => {
  const allColumns = useSubscribe(vm, (s) => s.columns);
  const hiddenColumns = useSubscribe(vm, (s) => s.hiddenItems);
  const attributes = useSubscribe(
    vm,
    (s) => s.attributes,
    (a, b) => isEqual(Object.keys(a), Object.keys(b))
  );

  const [searchText, setSearch] = React.useState('');
  const search = useFuzzySearch(
    allColumns.map((x) => ({
      key: x,
    })),
    ['key']
  );
  const columns = search(searchText)
    .map((x) => x.key)
    .map((x) => ({
      key: x,
      ty: attributes[x] as Ty.ExtendedAttributeType,
    }));
  const allColumnsHidden = columns.length === hiddenColumns.length;

  const isHidden = (key: string) => hiddenColumns.includes(key);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col justify-center px-4 py-2">
        <Inputs.Text
          placeholder="Search for a property"
          icon="search"
          value={searchText}
          onChange={setSearch}
        />
        <Divider className="mb-4 mt-4" />
        <div className="flex justify-between items-center">
          <Title type="label">Columns</Title>
          <Button
            small
            inline
            text={allColumnsHidden ? 'Show all' : 'Hide all'}
            onClick={() => {
              if (allColumnsHidden) {
                vm.showColumn(...columns.map((attr) => attr.key));
              } else {
                vm.hideColumn(...columns.map((attr) => attr.key));
              }
            }}
          />
        </div>
        {columns.length === 0 && (
          <Text.Caption className="text-left mt-2">No attributes</Text.Caption>
        )}
        <ul className="mb-4">
          {columns
            .filter((x) => !isHidden(x.key))
            .map((attr, i) => (
              <Column
                registry={registry}
                attr={attr}
                index={i}
                hidden={false}
                vm={vm}
                key={i}
              />
            ))}
        </ul>
        <Title type="label">Hidden</Title>
        <ul className="mb-4">
          {columns
            .filter((x) => isHidden(x.key))
            .map((attr, i) => (
              <Column
                registry={registry}
                attr={attr}
                index={i}
                hidden={true}
                vm={vm}
                key={i}
              />
            ))}
        </ul>
      </div>
    </DndProvider>
  );
};

const Column: React.FC<{
  attr: { key: string; ty: Ty.ExtendedAttributeType };
  index: number;
  hidden: boolean;
  vm: DataGridViewModel;
  registry: Registry;
}> = ({ attr, index, hidden, vm, registry }) => {
  const rel = useSubscribe(vm, (s) => s.rel);
  const columnActions = registry.getHeaderActions(rel, attr.key, attr.ty);
  const editAction = columnActions.find((a) => a.label === 'Edit');
  const icon = registry.getIcon(attr.ty);
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'item',
      item: {
        key: attr.key,
        index,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [index, attr.key]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'item',
      drop: (item: { key: string; index: number }) => {
        vm.moveColumn(item.index, index);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [index, attr.key]
  );

  const Wrapper =
    editAction?.link !== undefined
      ? ({ children }: ChildrenProps) => (
          <Link to={editAction.link!}>{children}</Link>
        )
      : ({ children }: ChildrenProps) => <>{children}</>;

  return (
    <li key={attr.key + index} className="flex flex-col" ref={drop}>
      <div
        className={classNames(
          'w-full flex-grow flex items-center justify-center transition-all ease-in-out duration-200 overflow-hidden',
          isOver
            ? 'h-10 opacity-100 border-dashed border-divider border bg-indigo-50'
            : 'h-0 opacity-0'
        )}
      >
        <Text.Caption>Drop here to move</Text.Caption>
      </div>
      <div className="justify-between items-center flex">
        <div
          className={classNames(
            'flex items-center space-x-2 overflow-hidden',
            isDragging ? 'opacity-50' : ''
          )}
        >
          <Button
            ref={drag}
            small
            icon="drag-handle"
            inline
            className="!pl-0 !pt-0 !pb-0 shrink-0"
          />
          <Icon icon={icon} className="shrink-0" />
          <Wrapper>
            <Text.Span
              onClick={() => {
                editAction?.onClick?.();
              }}
              ref={preview}
              className={classNames(
                'overflow-ellipsis',
                editAction ? 'cursor-pointer hover:text-primary-text' : '',
                hidden ? 'line-through' : ''
              )}
            >
              {attr.key}
            </Text.Span>
          </Wrapper>
          <Tooltip text={registry.getDisplayType(attr.ty)} side="top">
            <div className="w-fit grow-0">
              <Text.Caption className="text-ellipsis truncate">
                {registry.getDisplayType(attr.ty)}
              </Text.Caption>
            </div>
          </Tooltip>
        </div>
        <Button
          small
          inline
          className="shrink-0"
          icon={hidden ? 'eye-slash' : 'eye'}
          onClick={() => {
            if (hidden) {
              vm.showColumn(attr.key);
            } else {
              vm.hideColumn(attr.key);
            }
          }}
        />
      </div>
    </li>
  );
};

export const DataGridActions: React.FC<{
  vm: DataGridViewModel;
  registry: Registry;
}> = ({ vm, registry }) => {
  const sideModal = useSideModal((s) => s.actions.push);
  return (
    <Button
      overrides={{ height: 'h-fit', py: '' }}
      small
      compact
      inline
      icon="cog-6-tooth"
      onClick={() => {
        sideModal({
          view: () => <ManageDataGridView vm={vm} registry={registry} />,
        });
      }}
    />
  );
};
