import { err, ok } from 'neverthrow';
import { Assert } from '../../../../../../utilities/src';
import { Ty } from '../../../ty';
import { expectTy } from '../compile';
import { SpecialForm } from './types';

const bang: SpecialForm = ([arg], ctx) => {
  Assert.assert(arg !== undefined, 'already checked arity');
  return expectTy(arg, ctx).map((res) => ({
    item: { t: 'ty', ty: Ty.nn(res.item.ty) },
    bindings: {},
  }));
};

const ARRAY: SpecialForm = ([arg], ctx) => {
  Assert.assert(arg !== undefined, 'Artiy already checked');
  return expectTy(arg, ctx).map((type) => ({
    item: {
      t: 'ty',
      ty: Ty.ty(Ty.a(type.item.ty)),
    },
    bindings: {},
  }));
};

const RECORD: SpecialForm = ([arg], ctx) => {
  Assert.assert(arg !== undefined, 'Artiy already checked');
  return expectTy(arg, ctx).map((type) => ({
    item: {
      t: 'ty',
      ty: Ty.ty(Ty.record(type.item.ty)),
    },
    bindings: {},
  }));
};

const ID_INT: SpecialForm = ([arg]) => {
  Assert.assert(arg !== undefined, 'Artiy already checked');
  const [tyName, argMeta] = arg!;

  return tyName.t === 'literal' && typeof tyName.val === 'string'
    ? ok({ item: { t: 'ty', ty: Ty.ty(Ty.iid(tyName.val)) }, bindings: {} })
    : err([{ t: 'expected', expected: 'string-literal' }, argMeta]);
};

const ID_STRING: SpecialForm = ([arg]) => {
  Assert.assert(arg !== undefined, 'Artiy already checked');
  const [tyName, argMeta] = arg!;

  return tyName.t === 'literal' && typeof tyName.val === 'string'
    ? ok({ item: { t: 'ty', ty: Ty.ty(Ty.sid(tyName.val)) }, bindings: {} })
    : err([{ t: 'expected', expected: 'string-literal' }, argMeta]);
};

const RANGE: SpecialForm = ([start, stop]) => {
  const [startTok, startMeta] = start!;

  if (startTok.t !== 'literal' || typeof startTok.val !== 'number') {
    return err([{ t: 'expected', expected: 'number-literal' }, startMeta]);
  }

  const [stopTok, stopMeta] = stop!;

  if (stopTok.t !== 'literal' || typeof stopTok.val !== 'number') {
    return err([{ t: 'expected', expected: 'number-literal' }, stopMeta]);
  }

  return ok({
    item: { t: 'ty', ty: Ty.ty(Ty.range(startTok.val, stopTok.val)) },
    bindings: {},
  });
};

const ENUM: SpecialForm = (args, _ctx) => {
  const variants: string[] = [];

  for (const [arg, argMeta] of args) {
    if (arg.t !== 'literal' || typeof arg.val !== 'string') {
      return err([{ t: 'invalid-enum-variant' }, argMeta]);
    }
    variants.push(arg.val);
  }

  return ok({
    item: { t: 'ty', ty: Ty.ty(Ty.enumOf(variants)) },
    bindings: {},
  });
};

const STRUCT: SpecialForm = ([arg], ctx) => {
  Assert.assert(arg !== undefined);
  const [kvs, kvsMeta] = arg;

  if (kvs.t !== 'assoc') {
    return err([{ t: 'expected', expected: 'key values pairs' }, kvsMeta]);
  }

  const allowedKvs: [string, Ty.ExtendedAttributeType][] = [];

  for (const [{ key, val }, _kvMeta] of kvs.kvs) {
    const vRes = expectTy(val, ctx);

    if (vRes.isErr()) {
      return err(vRes.error);
    }

    allowedKvs.push([key[0], vRes.value.item.ty]);
  }

  return ok({
    item: {
      t: 'ty',
      ty: Ty.ty(Ty.structOf(Object.fromEntries(allowedKvs))),
    },
    bindings: {},
  });
};

export const TY_SPECIAL_FORMS: Record<
  string,
  [arity: number | 'any', fn: SpecialForm]
> = {
  '!': [1, bang],
  ARRAY: [1, ARRAY],
  RECORD: [1, RECORD],
  ID_INT: [1, ID_INT],
  ID_STRING: [1, ID_STRING],
  RANGE: [2, RANGE],
  ENUM: ['any', ENUM],
  STRUCT: [1, STRUCT],
};
