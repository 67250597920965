import { AST } from '../ast';
import { Expression, Relation } from '../builder';
import { exprPurityAnalysis } from '../type-checker/expr/purity-analysis';

const SKIP_FUNCTIONAL_TRANSFORMS_SPLIT_META_KEY =
  'skip-functional-split-in-cache';

export const skipFunctionalTransfromSplit = (rel: Relation): Relation =>
  rel.withMeta({
    [SKIP_FUNCTIONAL_TRANSFORMS_SPLIT_META_KEY]: true,
  });

export const splitFunctionalTransform = (
  ir: AST.RelIR
): { source: AST.RelIR; next: (x: AST.RelIR) => AST.RelIR } => {
  if (ir.t !== 'select' || ir.condition !== null) {
    return { source: ir, next: (x) => x };
  }

  const isPure = [
    ...Object.values(ir.selection),
    ...ir.orderBys.map((x) => x.expr),
  ].every((expr) => {
    const { isWindowed, isAggregated } = Expression.fromAst(expr);
    const { rand, now, other } = exprPurityAnalysis(expr);
    return [rand, now, other, isAggregated, isWindowed].every((x) => !x);
  });

  if (
    !isPure ||
    ir.distinct ||
    ir.meta[SKIP_FUNCTIONAL_TRANSFORMS_SPLIT_META_KEY] === true
  ) {
    return { source: ir, next: (x) => x };
  }

  const child = splitFunctionalTransform(ir.sources.from);

  const next = (x: AST.RelIR): AST.RelIR => ({
    ...ir,
    sources: { from: child.next(x) },
  });

  return { source: child.source, next };
};
