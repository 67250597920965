import { AST, Expression, Relation, Markup } from '@cotera/era';
import _ from 'lodash';

export const NOT_FOUND_BUT_LOADED_EMPTY_SCOPE: AST.MacroVarReplacements<AST.RelMacroChildren> =
  Object.freeze({ exprs: {}, rels: {}, sections: {} });

export type Val = AST.MacroVarReplacements<AST.RelMacroChildren>;

export const resolveScopeDeps = (
  scopes: string[],
  supplied: Record<string, Val>
): { scope: string; val: Val }[] => {
  const dependencies: Record<string, string[]> = _.mapValues(
    supplied,
    ({ exprs, rels, sections }) => {
      const exprScopeReqs = Object.values(exprs).flatMap((expr) =>
        Object.keys(Expression.fromAst(expr).freeVars)
      );

      const relScopeReqs = Object.values(rels).flatMap((rel) =>
        Object.keys(Relation.wrap(rel).freeVars)
      );

      const muScopeReqs = Object.values(sections).flatMap((mu) =>
        Object.keys(Markup.fromAst(mu).freeVars)
      );

      return _.uniq([...exprScopeReqs, ...relScopeReqs, ...muScopeReqs]);
    }
  );

  const visited = new Set<string>(); // To track processed nodes
  const inProgress = new Set<string>(); // To track nodes currently in the recursion stack (cycle detection)
  const result: string[] = []; // The final ordering (topologically sorted)

  // Helper function for DFS
  const dfs = (scope: string) => {
    if (inProgress.has(scope)) {
      throw new Error(`Cycle detected: "${scope}" is part of a cycle.`);
    }

    if (!visited.has(scope)) {
      inProgress.add(scope);
      for (const dep of dependencies[scope] || []) {
        dfs(dep); // Recurse on all dependencies
      }
      inProgress.delete(scope);
      visited.add(scope);
      result.push(scope); // After all dependencies, push the node to result
    }
  };

  // Start DFS from every node that has dependencies (to ensure we cover all nodes)
  for (const scope of scopes) {
    if (!visited.has(scope)) {
      dfs(scope);
    }
  }

  // Since we've been adding nodes after their dependencies, reverse the result
  return result
    .map((scope) => {
      const val = supplied[scope] ?? NOT_FOUND_BUT_LOADED_EMPTY_SCOPE;
      return { scope, val };
    })
    .reverse(); // Return the nodes in topologically sorted order
};
