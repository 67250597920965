import { Button, Title } from '@cotera/client/app/components/ui';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import React, { useEffect, useState } from 'react';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';
import { createPortal } from 'react-dom';
import { DragHandle } from '../../headless';
import { makeStore, StateSetter } from '@cotera/client/app/etc';

type Child = ChildrenProps['children'];

type Item = {
  title?: string;
  subtitle?: string;
  view: () => Child;
};

type State = {
  history: Item[];
  open: boolean;
};
const actions = (set: StateSetter<State>) => ({
  push: (child: Item) => {
    set((s) => ({ history: [...s.history, child], open: true }));
  },
  pop: () => {
    set((s) => {
      if (s.history.length > 1) {
        return { history: s.history.slice(0, -1) };
      }
      return s;
    });
  },
  close: () => {
    set(() => ({ history: [], open: false }));
  },
});

type Actions = ReturnType<typeof actions>;

const { hook: useProvider, provider: Provider } = makeStore<State, Actions>();

export const useSideModal = useProvider;

export const SideModalProvider: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Provider state={{ history: [], open: false }} actions={actions}>
      {children}
    </Provider>
  );
};

export const SideModal: React.FC = () => {
  const [width, setWidth] = useState(570);
  const [isResizing, setIsResizing] = useState(false);
  const [snapPosition, setSnapPosition] = useState<'left' | 'right'>('right');
  const [isVisible, setIsVisible] = useState(false);

  const close = useProvider((s) => s.actions.close);
  const open = useProvider((s) => s.open);
  const pop = useProvider((s) => s.actions.pop);
  const children = useProvider((s) => s.history.at(-1));
  const history = useProvider((s) => s.history);

  const handleResize = (delta: number) => {
    if (snapPosition === 'right') {
      delta = -delta;
    }
    if (!isResizing) {
      setIsResizing(true);
    }
    setWidth((prevWidth) => Math.max(prevWidth + delta, 570)); // Ensure minimum width
  };

  useKeyPress('Escape', () => {
    if (open) {
      close();
    }
  });

  useEffect(() => {
    if (!isVisible && open) {
      setIsVisible(true);
    } else if (isVisible && !open) {
      setIsVisible(false);
    }
  }, [isVisible, open]);

  if (!open) {
    return null;
  }

  return createPortal(
    <div
      className={classNames(
        'fixed top-0 h-full z-30 overflow-hidden py-4 ',
        isVisible ? 'mx-4 px-4 opacity-100' : 'px-0 opacity-0',
        snapPosition === 'left' ? 'left-0' : 'right-0',
        !isResizing ? 'transition-all ease-in-out duration-150' : ''
      )}
      style={{
        width: isVisible ? `${width}px` : '0px',
      }}
    >
      <div
        className="relative bg-white h-full rounded overflow-hidden border border-divider flex-col"
        style={{
          width: `${width - 20}px`,
          boxShadow:
            snapPosition === 'left'
              ? '3px 3px 12px rgba(0, 0, 0, 0.15)'
              : '-3px 3px 12px rgba(0, 0, 0, 0.15)',
        }}
      >
        <div
          className={classNames(
            'absolute h-full z-[40] top-0',
            snapPosition === 'left' ? 'right-0' : 'left-0'
          )}
        >
          <DragHandle
            onResize={handleResize}
            onResizeFinished={() => {
              setIsResizing(false);
            }}
          />
        </div>
        <div className="pl-2 pr-2 pt-2 flex justify-between w-full items-center flex-1 shrink-0">
          {history.length > 1 ? (
            <Button
              icon={'arrow-left'}
              onClick={() => {
                if (history.length > 1) {
                  pop();
                }
              }}
              text={'Back'}
              inline
              small
            />
          ) : (
            <div />
          )}
          <div className="flex items-center">
            <Button
              small
              onClick={() => {
                setSnapPosition(snapPosition === 'left' ? 'right' : 'left');
              }}
              iconOnly
              tooltip="left"
              text={snapPosition === 'left' ? 'Snap Right' : 'Snap Left'}
              inline
              icon={
                snapPosition === 'left'
                  ? 'arrow-right-end-on-rectangle'
                  : 'arrow-left-end-on-rectangle'
              }
            />
            <Button icon="close" onClick={close} text="Esc" inline small />
          </div>
        </div>
        {children?.title && (
          <Title
            className="text-left ml-4 pt-4 flex-1 shrink-0"
            type="title"
            subtitle={children?.subtitle}
          >
            {children?.title}
          </Title>
        )}
        <div className="flex flex-col flex-1 h-full overflow-hidden">
          {children?.view()}
        </div>
      </div>
    </div>,
    document.body
  );
};
