import { Case, Constant, Expression } from '@cotera/era';

export const COMPANY_LIST = [
  'Away Travel',
  'BarkBox',
  'Bombas',
  'CookUnity',
  'Helix Sleep',
  'Kate Spade',
  'Milk Makeup',
  'Tommy John',
  'LensDirect',
  'Adore Me',
  'Levis',
];

export const COMPANY_LIST_URL = COMPANY_LIST.map((name) =>
  name.replaceAll(' ', '-')
);

export const CompanyNamePrettify = (companyName: Expression): Expression =>
  Case([
    ...COMPANY_LIST.map((name) => ({
      when: Constant(name).lower().replace(' ', '-').eq(companyName),
      then: name,
    })),
  ]);
