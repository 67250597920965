import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ChartAction } from './types';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Button, Text } from '@cotera/client/app/components/ui';
import { Card } from '@cotera/client/app/components/headless';

export const InfoMenuItem: ChartAction = {
  id: 'info',
  label: 'Info',
  icon: ({ className, active, onClick }) => (
    <Button
      inline
      icon="information-circle"
      className={className}
      active={active}
      onClick={onClick}
    />
  ),
};

export const infoHandler = (onClick: () => void) => ({
  info: () => onClick(),
});

export const ChartInfo: React.FC<{
  open: boolean;
  onChange: (open: boolean) => void;
  sections?: { title: string; content: string }[];
}> = ({ open, onChange, sections = [] }) => {
  return (
    <AlertDialog.Root open={open} onOpenChange={onChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          onClick={() => onChange(false)}
          className="fixed inset-0	bg-black opacity-50 animate-fade z-20"
        />
        <AlertDialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 max-w-[600px]">
          <Card.Container bottomPadding={false}>
            <Card.Header>
              <Card.Title className="flex items-center">
                <InformationCircleIcon className="h-5 mr-2" /> Info
              </Card.Title>
            </Card.Header>
            <Card.Content>
              {sections.map((section, i) => (
                <div key={i} className="mb-4">
                  <Text.Title className="mb-2">{section.title}</Text.Title>
                  <Text.P>{section.content}</Text.P>
                </div>
              ))}
            </Card.Content>
          </Card.Container>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
