import type { Schedule, _Sink } from '@cotera/api';
import { ScheduleSchema, SinkConfigSchema } from '@cotera/api';
import type { UI, Ty } from '@cotera/era';
import { AST, Parser, Relation } from '@cotera/era';
import { z } from 'zod';
import { UserDefinedDimensions } from '../cookbook';
import { MaterializationConfig } from '../materialization';
import { EventStreamDefinition } from './manifest';
import { ScheduledJob, ScheduledJobSchema } from './scheduled-job';

export const DefinitionSchema: z.ZodSchema<{
  rel: AST.RelFR;
  displayName: string;
  hidden: boolean;
  eager: boolean;
}> = z.object({
  rel: Parser.RelSchema,
  displayName: z.string(),
  eager: z.boolean(),
  hidden: z.boolean(),
});

export const MatConfigSchema: z.ZodSchema<{
  schema: string;
  viewName: string;
  schedule: Schedule;
  source: AST.RelFR;
}> = z.object({
  viewName: z.string(),
  schema: z.string(),
  schedule: ScheduleSchema,
  source: Parser.RelSchema,
});

type MatConfig = z.infer<typeof MatConfigSchema>;

export type ManifestShorthand = {
  readonly detailPages?: Record<string, UI.DetailPage>;
  readonly definitions: Record<
    string,
    | Relation
    | {
        readonly rel: Relation;
        readonly dangerouslyEagerLoad?: boolean;
        readonly displayName?: string;
        readonly hidden?: boolean;
      }
  >;
  readonly apps: Record<string, AST._App | UI.MarkupShorthand>;
  readonly materializations?: {
    readonly schedule: Schedule;
    readonly config: MaterializationConfig;
  }[];
  readonly scheduledJobs?: ScheduledJob[];
  readonly schemas?: { read: string[] };
  readonly alerts?: EventStreamDefinition[];
  readonly udds?: UserDefinedDimensions[];
  readonly eventStreams?: EventStreamDefinition[];
  readonly preloadApps?: boolean;
};

const SinkSchema: z.ZodSchema<_Sink> = z.object({
  select: z.record(Parser.IdentifierSchema, Parser.ExprSchema),
  condition: Parser.ExprSchema,
  config: SinkConfigSchema,
  manualVerification: z.boolean(),
});

const EventStreamSchema: z.ZodSchema<{
  identifier: AST.ExprFR;
  entityId: Ty.IdType;
  name: string;
  onChange: AST.ExprFR;
  rel: AST.RelFR;
  sinks: Record<string, _Sink>;
  when: Schedule;
  batchSize: number;
}> = z.object({
  identifier: Parser.ExprSchema,
  entityId: Parser.IdTypeSchema,
  name: z.string(),
  onChange: Parser.ExprSchema,
  rel: Parser.RelSchema,
  sinks: z.record(SinkSchema),
  when: ScheduleSchema,
  batchSize: z.number(),
});

export const ManifestASTSchema: z.ZodSchema<{
  detailPages: Record<string, { ty: Ty.ExtendedAttributeType; render: AST.Mu }>;
  apps: Record<string, AST._App>;
  definitions: Record<string, Definition>;
  materializations: MatConfig[];
  preloadApps: boolean;
  scheduledJobs: ScheduledJob[];
  schemas: { read: string[] };
  udds: {
    entityName: string;
    attributes: Record<string, Ty.ExtendedAttributeType>;
  }[];
  eventStreams: EventStreamDefinition[];
}> = z.object({
  detailPages: z.record(
    z.object({
      ty: Parser.ExtendedAttributeTypeSchema,
      render: Parser.MarkupSchema,
    })
  ),
  apps: z.record(Parser.AppSchema),
  definitions: z.record(DefinitionSchema),
  eventStreams: z.array(EventStreamSchema),
  materializations: MatConfigSchema.array(),
  preloadApps: z.boolean(),
  scheduledJobs: z.array(ScheduledJobSchema),
  schemas: z.object({ read: z.array(z.string()) }),
  udds: z.array(
    z.object({
      entityName: z.string(),
      attributes: z.record(Parser.ExtendedAttributeTypeSchema),
    })
  ),
});

export type Definition = z.infer<typeof DefinitionSchema>;
export type ManifestAST = z.infer<typeof ManifestASTSchema>;

export const EMPTY_MANIFEST = (): ManifestAST => ({
  detailPages: {},
  definitions: {},
  apps: {},
  udds: [],
  scheduledJobs: [],
  schemas: { read: [] },
  eventStreams: [],
  preloadApps: true,
  materializations: [],
});
