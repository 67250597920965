import { Relation, AST, UI } from '@cotera/era';
import { Overview } from './components';

export const FeatureRequestApp = ({ CxData }: { CxData: Relation }): AST._App =>
  UI.App({
    title: 'Feature Requests',
    icon: 'gift',
    pages: {
      '/:companyName': Overview({ CxData }),
    },
    menu: [],
  });
