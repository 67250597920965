import { Theme } from '../../types/utils';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';

const CSS: Record<Theme, string> = {
  primary: 'bg-white border-indigo-400',
  secondary: 'bg-white text-emerald-500 border-emerald-400',
  regular: `bg-white border-zinc-200`,
  muted: `bg-white border-zinc-200 `,
  error: 'bg-white border-rose-400',
  warning: 'bg-white border-orange-300',
};

const SIZE_CLASSES = {
  regular: 'h-8',
  large: 'h-10',
  small: 'h-6',
};

export const Badge: React.FC<{
  theme?: Theme;
  children: any;
  className?: string;
  onClick?: () => void;
  capitalize?: boolean;
  size?: 'regular' | 'large' | 'small';
}> = ({
  theme: color = 'regular',
  children,
  className,
  size = 'regular',
  onClick,
  capitalize = false,
}) => {
  const css = classNames(
    CSS[color],
    ColorScheme.text[color],
    'px-2 border',
    SIZE_CLASSES[size],
    'w-fit',
    capitalize ? 'uppercase' : '',
    'text-xs',
    'inline-flex items-center rounded text-nowrap overflow-elipsis',
    className
  );
  return (
    <span className={css} onClick={onClick}>
      {children}
    </span>
  );
};
