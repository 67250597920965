import { UI, Relation, AST, Ty, Values, Case, Constant, M } from '@cotera/era';
import { DetailPage, OverviewPage } from './pages';
import { snakeToTitle } from './components';
import { QuickFilter } from 'libs/era/src/lib/builder/relation';

export const makeQaModelApp = ({
  rel,
  title,
  config,
  description = '',
  icon = 'check',
  quickFilters = [],
}: {
  rel: Relation;
  title: string;
  config: {
    category: string;
    sub_category: string;
  }[];
  description?: string;
  icon?: AST.MarkupIcon;
  quickFilters?: QuickFilter[];
}) => {
  const PivotCols = Values(config);

  const LongData = rel
    .innerJoin(PivotCols, (row, pivotCols) => ({
      on: true,
      select: {
        ...pivotCols.star(),
        ...row.star(),
      },
    }))
    .select((t) => ({
      ...t.pick('category', 'sub_category'),
      value: Case(
        config.map((name) => ({
          when: t.attr('sub_category').eq(name.sub_category),
          then: t.attr(name.sub_category),
        }))
      ),
      ...t.except(
        'category',
        'sub_category',
        ...config.map((name) => name.sub_category)
      ),
    }))
    .select((t) => t.renameWith((name) => name.toLowerCase()));

  return UI.App({
    title,
    icon,
    pages: {
      '/': [
        UI.Header({
          title: title,
          caption: description,
        }),
        UI.State(
          {
            input: LongData,
            page: Constant('Overview', {
              ty: Ty.e([
                'Overview',
                ...config.map((name) =>
                  snakeToTitle(name.category.toLowerCase())
                ),
              ]),
            }),
            agent_toggle_overview: Constant('Count of Imperfect Tickets', {
              ty: Ty.e([
                'Count of Imperfect Tickets',
                'Proportion of Imperfect Tickets',
                'Imperfect Proportion of Total Tickets',
              ]),
            }),
            agent_toggle_detail: Constant('Count of Imperfect Tickets', {
              ty: Ty.e([
                'Count of Imperfect Tickets',
                'Imperfect Proportion of Total Tickets',
              ]),
            }),
          },
          ({
            input: LongData,
            page,
            agent_toggle_overview,
            agent_toggle_detail,
          }) => {
            return [
              LongData.SliceBuilder([...quickFilters]),
              UI.Divider(),
              UI.Nav(page, [
                M.muIf(page.eq('Overview'), {
                  then: OverviewPage(LongData, agent_toggle_overview),
                  else: DetailPage(LongData, agent_toggle_detail, page),
                }),
              ]),
            ];
          }
        ),
      ],
    },
  });
};
