import { Asc, CountOver, LeadOver, Relation } from '@cotera/era';
import { rfmNumComparison } from './helpers';

export const makeCurrentRateOfChange = ({
  HistoricalCustomerRfm,
}: {
  HistoricalCustomerRfm: Relation;
}) => {
  const CurrentRateOfChange = HistoricalCustomerRfm.where((t) =>
    t.attr('TODAY').eq(t.attr('TODAY').dateTrunc('month'))
  )
    .select((t) => ({
      ...t.pick('TODAY'),
      __COTERA_CUSTOMER_ID: t.attr('__COTERA_CUSTOMER_ID'),
      STARTING_RFM_CATEGORY: t.attr('RFM_CATEGORY'),
      STARTING_RFM_NUM: t.attr('RFM_NUM'),
      ENDING_RFM_CATEGORY: LeadOver(t.attr('RFM_CATEGORY'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
      ENDING_RFM_NUM: LeadOver(t.attr('RFM_NUM'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('TODAY')),
      }),
    }))
    .select((t) => ({
      ...t.star(),
      CATEGORY_STATUS: rfmNumComparison(t),
    }))
    .where((t) => t.attr('ENDING_RFM_CATEGORY').isNotNull())
    .select(
      (t) => ({
        ...t.pick(
          'TODAY',
          'STARTING_RFM_CATEGORY',
          'STARTING_RFM_NUM',
          'CATEGORY_STATUS'
        ),
        STATUS_COUNT: CountOver(true, {
          partitionBy: [
            t.attr('STARTING_RFM_CATEGORY'),
            t.attr('CATEGORY_STATUS'),
            t.attr('TODAY'),
          ],
        }),
        TOTAL_COUNT: CountOver(true, {
          partitionBy: [t.attr('STARTING_RFM_CATEGORY'), t.attr('TODAY')],
        }),
      }),
      { distinct: true }
    )
    .select((t) => ({
      ...t.except('TODAY'),
      DATE: t.attr('TODAY'),
      RATE: t.attr('STATUS_COUNT').cast('float').div(t.attr('TOTAL_COUNT')),
    }))
    .orderBy((t) => [
      Asc(t.attr('STARTING_RFM_CATEGORY')),
      Asc(t.attr('CATEGORY_STATUS')),
      Asc(t.attr('DATE')),
    ])
    .select((t) => t.renameWith((name) => name.toLowerCase()));

  return CurrentRateOfChange;
};
